export const BusinessCategories: {
  businessCategory: string;
  iconName: string;
}[] = [
  {
    businessCategory: "Lost/Hold Reason",
    iconName: "bagel_lost_hold_reason",
  },
  {
    businessCategory: "Support Case/Ticket",
    iconName: "bagel_Support_Case_Ticket",
  },
  { businessCategory: "Churn Reasons", iconName: "bagel_churn_reasons" },
  { businessCategory: "Customer Call", iconName: "bagel_Customer_Call" },
  { businessCategory: "Survey", iconName: "bagel_survey" },
  { businessCategory: "Health Notes", iconName: "bagel_Health_Notes" },
  { businessCategory: "Request", iconName: "bagel_request" },
  { businessCategory: "PM Feedback", iconName: "bagel_pm_feedback" },
  { businessCategory: "General", iconName: "bagel_general" },
];
