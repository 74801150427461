import moment from "moment";
import { Box, Divider, Grid, Paper, Tabs, Text } from "@mantine/core";
import React, { useState } from "react";
import { ExplainCard } from "./ExplainCard";
import { JiraSetup } from "./JiraSetup";
import { SetupLists } from "./SetupLists";
import { SetupRequests } from "./SetupRequests";
import { SetupSolutions } from "./SetupSolutions";
import { SetupCustomField } from "./SetupCustomField";

const JiraIntegration = (props: {
  integration: any;
  reloadIntegration: () => void;
  customizations: any;
}) => {
  const tabs = [
    { name: "General" },
    { name: "Lists" },
    { name: "CustomField" },
    { name: "Requests" },
    { name: "Solutions" },
  ];

  const [activeTab, setActiveTab] = useState<string | null>("General");
  const integration = props.integration;
  const inSetupMode = !integration || integration.setupStep !== -1;
  if (inSetupMode) {
    return (
      <JiraSetup
        integration={integration}
        reloadIntegration={props.reloadIntegration}
        customizations={props.customizations}
      />
    );
  }
  const createdAt = new Date(integration.createdAt);
  const createdAtString = moment(createdAt).format("MMMM Do, YYYY");
  return (
    <>
      <Grid
        mx="sm"
        justify="space-between"
        align="flex-end"
        sx={{ borderBottom: "1px solid #F1F1F1" }}
      >
        <Grid.Col span="content" pb="0">
          <Tabs value={activeTab} onTabChange={setActiveTab}>
            <Tabs.List m="0" sx={{ borderBottom: 0 }}>
              {tabs.map((tab) => (
                <Tabs.Tab key={tab.name} value={`${tab.name}`}>
                  {tab.name}
                </Tabs.Tab>
              ))}
            </Tabs.List>
          </Tabs>
        </Grid.Col>
      </Grid>
      <Box p={"xl"} mt={7} bg={"#F1F3F5"}>
        <ExplainCard />
        {activeTab === "General" && (
          <>
            <Paper mt={"44px"} shadow={"lg"} withBorder>
              <Grid p={0} m={0}>
                <Grid.Col span={2} p={"xl"}>
                  <Text weight={700} color={"#5C5CEA"} size={"md"}>
                    General
                  </Text>
                  <Text size={"sm"} mt={"sm"} mb={"sm"}>
                    Integration details
                  </Text>
                </Grid.Col>
                <Divider size="xs" orientation="vertical" />
                <Grid.Col span={"auto"} p={"xl"}>
                  <Grid>
                    <Grid.Col span={2}>
                      <Text mt={"xs"} color={"#161832"} weight={"500"}>
                        Domain:
                      </Text>
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <Text color={"rgba(22, 24, 50, 0.8)"} mt={"xs"}>
                        {integration.subdomain}
                      </Text>
                    </Grid.Col>
                  </Grid>
                  <Grid>
                    <Grid.Col span={2}>
                      <Text mt={"xs"} color={"#161832"} weight={"500"}>
                        Created At:
                      </Text>
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <Text color={"rgba(22, 24, 50, 0.8)"} mt={"xs"}>
                        {createdAtString}
                      </Text>
                    </Grid.Col>
                  </Grid>
                </Grid.Col>
              </Grid>
            </Paper>
            {/*<Card mt={"44px"} p={0} shadow={"lg"} withBorder>*/}
            {/*  <Grid>*/}
            {/*    <Grid.Col span={2} p={"xl"}>*/}
            {/*      <Text weight={700} color={"#5C5CEA"} size={"md"}>*/}
            {/*          Frequency*/}
            {/*      </Text>*/}
            {/*      <Text size={"sm"} mt={"sm"} mb={"sm"}>*/}
            {/*          Define time interval or manually import in bulk*/}
            {/*      </Text>*/}
            {/*    </Grid.Col>*/}
            {/*    <Divider size="xs" orientation="vertical" />*/}
            {/*    <Grid.Col span={"auto"} p={"xl"}>*/}
            {/*      <SetupImport integration={integration} reloadIntegration={props.reloadIntegration} variant={"settings"}/>*/}
            {/*    </Grid.Col>*/}
            {/*  </Grid>*/}
            {/*</Card>*/}
            <Paper mt={"44px"} shadow={"lg"} withBorder>
              <Grid p={0} m={0}>
                <Grid.Col span={2} p={"xl"}>
                  <Text weight={700} color={"#5C5CEA"} size={"md"}>
                    Connection
                  </Text>
                  <Text size={"sm"} mt={"sm"} mb={"sm"}>
                    Define time interval or manually import in bulk
                  </Text>
                </Grid.Col>
                <Divider size="xs" orientation="vertical" />
                <Grid.Col span={"auto"} p={"xl"}>
                  <Grid>
                    <Grid.Col span={2}>
                      <Text mt={"xs"} color={"#161832"} weight={"500"}>
                        Status:
                      </Text>
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <Text color={"rgba(22, 24, 50, 0.8)"} mt={"xs"}>
                        {integration.status}
                      </Text>
                    </Grid.Col>
                  </Grid>
                </Grid.Col>
              </Grid>
            </Paper>
          </>
        )}
        {activeTab === "Lists" && (
          <SetupLists
            integration={integration}
            reloadIntegration={props.reloadIntegration}
            customization={undefined}
            variant={"settings"}
          />
        )}
        {activeTab === "CustomField" && (
          <SetupCustomField
            integration={integration}
            reloadIntegration={props.reloadIntegration}
            customization={undefined}
            variant={"settings"}
          />
        )}
        {activeTab === "Requests" && (
          <SetupRequests
            integration={integration}
            reloadIntegration={props.reloadIntegration}
            customization={
              props.customizations ? props.customizations.request : undefined
            }
            variant={"settings"}
          />
        )}
        {activeTab === "Solutions" && (
          <SetupSolutions
            integration={integration}
            reloadIntegration={props.reloadIntegration}
            customization={
              props.customizations ? props.customizations.solution : undefined
            }
            variant={"settings"}
          />
        )}
      </Box>
    </>
  );
};

export default JiraIntegration;
