import { useAuth0 } from "@auth0/auth0-react";
import {
  ActionIcon,
  Text,
  Flex,
  Box,
  Tooltip,
  Badge,
  createStyles,
  Group,
  Avatar,
} from "@mantine/core";
import { IconStar } from "@tabler/icons";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getBagelId, getQuarter } from "../../utils";
import { DataTable, DataTableSortStatus } from "mantine-datatable";
import ReactTimeAgo from "react-time-ago";
import { replaceNullsWithZeros } from "../../utils/replaceNullsWithZeros";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";
import { getInitials } from "../../utils/getInitials";
import { useContext } from "react";
import SyncContext from "../../context/SyncContext";
import { SourceIcon } from "../../utils/SourceIcon";
import { BagelAiIcon } from "../../icons/x-symbol-svgrepo-com";
import { evidenceParents } from "./filters/FiltersSpec";
import FiltersContext from "./filters/context/FiltersContext";
import { OverflownText } from "../OverflownText";

const useStyles = createStyles({
  pagination: {
    direction: "rtl",
    div: {
      direction: "ltr",
      marginRight: "30px",
    },
  },
});

export function RequestsTable({
  requests,
  starClicked,
  loadingStar,
  isAllowedToEditDates,
  handlePageChange,
  totalRecords,
  recordsPerPage,
  page,
  sortStatus,
  setSortStatus,
  loading,
  PAGE_SIZE,
}: {
  requests: any[];
  starClicked: (_id: string, bool: boolean) => void;
  handlePageChange: (newPage: string) => void;
  loadingStar: boolean[];
  isAllowedToEditDates: boolean;
  totalRecords: number | undefined,
  recordsPerPage: number,
  page: number,
  sortStatus: DataTableSortStatus;
  setSortStatus: React.Dispatch<React.SetStateAction<DataTableSortStatus>>;  
  loading: boolean;
  PAGE_SIZE: number;
}) {
  const auth0 = useAuth0();
  const navigate = useNavigate();

  const { classes } = useStyles();

  // function handlePageChange(newPage: string) {
  //   const queryParams = new URLSearchParams(location.search);
  //   queryParams.set("page", newPage);
  //   navigate(`/?${queryParams.toString()}`);
  // }

  const handleOnRowClick = (ideaId: string) => {
    const queryParams = new URLSearchParams(location.search);
    const activeFilters = JSON.parse(queryParams.get('filters') || '[]');
  
    /* console.log("handleOnRowClick", {activeFilters}); */
  
    // Filter activeFilters to include only those whose parent is in evidenceParents
    const filteredFilters = activeFilters.filter((filter: any) =>
      evidenceParents.includes(filter.parent.split('.')[0])
    );
  
    /* console.log("filteredFilters", {filteredFilters}); */
  
    // Serialize the filtered filters back into a string
    const filtersString = JSON.stringify(filteredFilters);
  
    // Construct the new URL with the filtered filters parameter if it exists and is not empty
    const url = `/idea/${ideaId}${filtersString ? `?filters=${encodeURIComponent(filtersString)}` : ''}`;
  
    // Save current filters in sessionStorage
    sessionStorage.setItem('lastFilters', location.search);
  
    // Navigate to the new URL
    navigate(url);
  };
  
  const onSortStatusChanged = (sortStatus: DataTableSortStatus) => {
    setSortStatus((prevStatus) => {
      if (prevStatus?.columnAccessor === sortStatus.columnAccessor) {
        return sortStatus;
      } else {
        return { columnAccessor: sortStatus.columnAccessor, direction: "asc" };
      }
    });
  };

  const { requestPriorities, ideaPriorities, displayState, ideasTableDisplay, customEvidenceObj } = useContext(SyncContext);
  const dealBreakerObj = requestPriorities.find((r: any)=> r.key === 'Deal Breaker') || {key: 'Deal Breaker', label: 'Deal Breaker'}

  return (
    <DataTable
      sx={{
        "thead tr:first-of-type": {
          borderColor: "#dee2e6",
          background: "#F3F4F6",
        },
        "thead th": {
          padding: "6px 10px !important",
        },
        "th[role='button'] svg.mantine-9cumsf": {
          display: "none",
        },
      }}
      classNames={classes}
      fetching={loading}
      verticalSpacing="md"
      records={replaceNullsWithZeros(requests)}
      sortStatus={sortStatus}
      onSortStatusChange={onSortStatusChanged}
      idAccessor={"_id"}
      totalRecords={totalRecords}
      recordsPerPage={recordsPerPage}
      page={page}
      onPageChange={(p) => handlePageChange(p.toString())}
      onCellClick={({ record: request, recordIndex, column, columnIndex }) =>
        columnIndex == 0
          ? starClicked(
            request._id,
            request.starred &&
                auth0.user &&
                request.starred?.includes(getBagelId(auth0.user))
              ? false
              : true
          )
          : handleOnRowClick(request._id)
      }
      columns={[
        {
          title: (
            <ActionIcon disabled>
              <IconStar color="#D1D5DB" size={16} strokeWidth={2} />
            </ActionIcon>
          ),
          accessor: "_id",
          render: (request: any) => (
            <ActionIcon
              // onClick={(e) => {
              //   e.preventDefault();
              //   starClicked(
              //     request._id,
              //     request.starred &&
              //       auth0.user &&
              //       request.starred?.includes(getBagelId(auth0.user))
              //       ? false
              //       : true
              //   );
              // }}
              loading={loadingStar[request._id]}
            >
              <IconStar
                color={
                  request.starred &&
                  auth0.user &&
                  request.starred?.includes(getBagelId(auth0.user))
                    ? "#FDAE1680"
                    : "#D1D5DB"
                }
                fill={
                  request.starred &&
                  auth0.user &&
                  request.starred?.includes(getBagelId(auth0.user))
                    ? "#FDAE1680"
                    : "#fff"
                }
                size={16}
                strokeWidth={2}
              />
            </ActionIcon>
          ),
        },
        {
          accessor: "aggregations.feedbacks.new",
          title: "",
          sortable: true,
          render: ({ aggregations, productItem, origin, originId }) => {
            const newCount = aggregations?.requests?.new || 0;
            return (
              <Group spacing="xs" noWrap>
                {newCount > 0 && (
                  <Tooltip
                    position="bottom"
                    sx={{ fontSize: 12 }}
                    label={`${newCount} new evidences`}
                  >
                    <Badge
                      // sx={{ marginLeft: -10, marginRight: -10 }}
                      variant="dot"
                    >
                      {newCount}
                    </Badge>
                  </Tooltip>
                )}
                {/*{aggregations?.matches > 0 && (*/}
                {/*  <Tooltip*/}
                {/*    position="bottom"*/}
                {/*    sx={{ fontSize: 12 }}*/}
                {/*    label={`${aggregations?.matches} suggested evidence`}*/}
                {/*  >*/}
                {/*    <Paper withBorder radius="xl">*/}
                {/*      <Group spacing={4} noWrap>*/}
                {/*        <Image*/}
                {/*          src="/bagel_logo-06 2.png"*/}
                {/*          width={18}*/}
                {/*          height={18}*/}
                {/*        />*/}
                {/*        <Text*/}
                {/*          size={11.5}*/}
                {/*          weight={600}*/}
                {/*          mt={-1}*/}
                {/*          mb={-1}*/}
                {/*          pr="xs"*/}
                {/*          color="#495057"*/}
                {/*        >*/}
                {/*          {aggregations?.matches}*/}
                {/*        </Text>*/}
                {/*      </Group>*/}
                {/*    </Paper>*/}
                {/*  </Tooltip>*/}
                {/*)}*/}
                {/* TODO check for jira task on it.. (productItem) */}
                {origin && (
                  <Tooltip
                    position="bottom"
                    sx={{ fontSize: 12 }}
                    label={`${origin} sync - ${originId}`}
                  >
                    <Box mb={-2} p={0}>
                      <SourceIcon sourceName={origin} width={16} height={16} />
                    </Box>
                  </Tooltip>
                )}
              </Group>
            );
          },
        },
        {
          accessor: "title",
          title: (
            <Text sx={{ whiteSpace: "nowrap" }} size="sm">
              Idea title
            </Text>
          ),
          sortable: true,
          textAlignment: "left",

          render: ({ title }) => {
            return (
              <Box
                sx={{
                  maxWidth: "350px",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                <OverflownText
                  sx={{
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                  size={15}
                  weight={500}
                >{title}</OverflownText>
              </Box>
            );
          },
        },
        {
          accessor: "ownerName",
          title: (
            <Text sx={{ whiteSpace: "nowrap" }} size="sm">
              Owner
            </Text>
          ),
          sortable: true,
          render: ({ owner }) => {
            const userName = owner?.username ?? null;
            const ownerName = owner?.name ?? null;
            const userNameById = owner?.name;
            const ownerByIdPic = owner?.picture;
            const userPic = owner?.picture;
            const ownerPic = owner?.picture;

            const jsxOwner = (
              <Box ml={5}>
                {" "}
                {ownerByIdPic ? (
                  <Tooltip label={userNameById}>
                    <ActionIcon>
                      {" "}
                      <Avatar src={ownerByIdPic} radius="xl">
                        {getInitials(userNameById)}
                      </Avatar>
                    </ActionIcon>
                  </Tooltip>
                ) : ownerName ? (
                  <Tooltip label={ownerName}>
                    <ActionIcon>
                      {" "}
                      <Avatar src={ownerPic} radius="xl">
                        {getInitials(ownerName)}
                      </Avatar>
                    </ActionIcon>
                  </Tooltip>
                ) : userName ? (
                  <Tooltip label={userName}>
                    <ActionIcon>
                      {" "}
                      <Avatar src={userPic} radius="xl">
                        {getInitials(userName)}
                      </Avatar>
                    </ActionIcon>
                  </Tooltip>
                ) : (
                  <Text></Text>
                )}
              </Box>
            );

            return jsxOwner;
          },
        },
        {
          accessor: "status",
          title: (
            <Text sx={{ whiteSpace: "nowrap" }} size="sm">
              Status
            </Text>
          ),

          sortable: true,
          textAlignment: "left",
          width: 130,
          render: ({ status }) => (
            <Text
              lineClamp={1} 
            >
              {status && status.toLowerCase() !== "unprocessed"
                ? capitalizeFirstLetter(status)
                : "-"}
            </Text>
          ),
        },

        {
          accessor: "priority",
          title: (
            <Text sx={{ whiteSpace: "nowrap" }} size="sm">
              Priority
            </Text>
          ),
          sortable: true,
          render: ({ priority }) => {
            const defaultPriority = ideaPriorities?.find(
              (p: any) => p?.isDefault
            );
            const selectedPriority = priority
              ? ideaPriorities?.find(
                (p: any) =>
                  p?.key?.toLowerCase() === priority?.toLowerCase() ||
                    p?.label?.toLowerCase() == priority?.toLowerCase()
              )
              : {
                color: "#ccc",
                index: 1,
                key: "Unassigned",
                label: "Unassigned",
              };
            return (
              <Flex>
                <div
                  style={{
                    marginTop: "5px",
                    borderRadius: "8px",
                    width: "15px",
                    height: "15px",
                    marginRight: "10px",
                    backgroundColor:
                      selectedPriority?.color ||
                      defaultPriority?.color ||
                      "#ccc",
                  }}
                ></div>
                <div>
                  {selectedPriority?.label ||
                    defaultPriority?.label ||
                    "Unassigned"}
                </div>
              </Flex>
            );
          },
        },
        {
          accessor: "aggregations.matches",
          title: (
            <Tooltip label="Evidence of product gaps, found by Bagel AI">
              <Box>
                <ActionIcon disabled>
                  <BagelAiIcon />
                </ActionIcon>
              </Box>
            </Tooltip>
            
            
          ),
          sortable: true,
          textAlignment: "center",
          hidden: !ideasTableDisplay.pendingSuggestions,
          render: ({ aggregations }) => (
            <Flex justify={{ sm: "center" }} align={"center"}>
              <Text sx={{ marginLeft: "3px" }}>
                {aggregations?.matches && aggregations.matches > 0
                  ? `${aggregations.matches?.toLocaleString()}`
                  : "-"}
              </Text>
            </Flex>
          ),
        },
        {
          accessor: "aggregations.customEvidence.total",
          title: (
            <Tooltip label={customEvidenceObj?.tooltip || ''}>
              <Box>
                {customEvidenceObj?.header || ''}
              </Box>
            </Tooltip> 
          ),
          sortable: true,
          textAlignment: "center",
          hidden: !ideasTableDisplay.customEvidenceTypes,
          render: ({ aggregations }) => (
            <Flex justify={{ sm: "center" }} align={"center"}>
              <Text sx={{ marginLeft: "3px" }}>
                {aggregations?.customEvidence?.customRequests && aggregations.customEvidence?.customRequests > 0
                  ? `${aggregations.customEvidence?.customRequests?.toLocaleString()}`
                  : "0"}
              </Text>
              <Text sx={{ marginLeft: "3px" }}>
                {aggregations?.customEvidence?.customMatches && aggregations.customEvidence?.customMatches > 0
                  ? `(${aggregations.customEvidence?.customMatches?.toLocaleString()})`
                  : "(0)"}
              </Text>
            </Flex>
          ),
        },
        {
          accessor: "aggregations.accounts.count",
          title: (
            <Text sx={{ whiteSpace: "nowrap" }} size="sm">
              Companies
            </Text>
          ),
          sortable: true,
          textAlignment: "center",
          render: ({ aggregations }) =>
            aggregations && aggregations.accounts.count > 0
              ? aggregations.accounts.count
              : "-",
        },

        {
          accessor: "aggregations.amount",
          title: (
            <Text sx={{ whiteSpace: "nowrap" }} size="sm">
              Tot. impact ($)
            </Text>
          ),
          sortable: true,
          textAlignment: "center",
          render: ({ aggregations }) => (
            <Flex justify={{ sm: "center" }} align={"center"}>
              <Text sx={{ marginLeft: "3px" }}>
                {aggregations?.amount && aggregations.amount > 0
                  ? `$${Math.round(aggregations.amount).toLocaleString()}`
                  : "-"}
              </Text>
            </Flex>
          ),
        },
        {
          accessor: "aggregations.customers.amount",
          title: (
            <Text sx={{ whiteSpace: "nowrap" }} size="sm">
              Customers ($)
            </Text>
          ),
          sortable: true,
          textAlignment: "center",
          render: ({ aggregations }) => (
            <Flex justify={{ sm: "center" }} align={"center"}>
              <Text fz="md">
                {aggregations?.customers?.accounts &&
                aggregations.customers.accounts > 0
                  ? aggregations.customers.accounts
                  : "-"}
              </Text>
              <Text sx={{ marginLeft: "3px" }}>
                {aggregations?.customers?.amount &&
                aggregations.customers.amount > 0
                  ? `($${Math.round(
                    aggregations.customers.amount
                  ).toLocaleString()})`
                  : ""}
              </Text>
            </Flex>
          ),
        },
        {
          accessor: "aggregations.prospects.amount",
          title: (
            <Text sx={{ whiteSpace: "nowrap" }} size="sm">
              Prospects ($)
            </Text>
          ),
          sortable: true,
          textAlignment: "center",
          render: ({ aggregations }) => (
            <Flex justify={{ sm: "center" }} align={"center"}>
              <Text fz="md">
                {aggregations?.prospects?.accounts &&
                aggregations.prospects.accounts > 0
                  ? aggregations.prospects.accounts
                  : "-"}
              </Text>
              <Text sx={{ marginLeft: "3px" }}>
                {aggregations?.prospects?.amount &&
                aggregations.prospects.amount > 0
                  ? `($${Math.round(
                    aggregations.prospects.amount
                  ).toLocaleString()})`
                  : ""}
              </Text>
            </Flex>
          ),
        },
        {
          accessor: `aggregations.priority.${dealBreakerObj.label}.amount`,
          title: (
            <Text sx={{ whiteSpace: "nowrap" }} size="sm">
              Deal Breakers ($)
            </Text>
          ),
          sortable: true,
          textAlignment: "center",
          render: ({ aggregations }) => (
            <Flex justify={{ sm: "center" }} align={"center"}>
              <Text fz="md">
                {aggregations?.priority[dealBreakerObj.label] &&
                aggregations?.priority[dealBreakerObj.label]?.count &&
                aggregations.priority[dealBreakerObj.label].count > 0
                  ? aggregations.priority[dealBreakerObj.label]?.count
                  : "-"}
              </Text>
              <Text sx={{ marginLeft: "3px" }}>
                {aggregations?.priority[dealBreakerObj.label]?.amount &&
                aggregations.priority[dealBreakerObj.label].amount > 0
                  ? `($${Math.round(
                    aggregations.priority[dealBreakerObj.label].amount
                  ).toLocaleString()})`
                  : ""}
              </Text>
            </Flex>
          ),
        },
        {
          accessor: "aggregations.totalArr",
          title: (
            <Text sx={{ whiteSpace: "nowrap" }} size="sm">
              Curr. ARR ($)
            </Text>
          ),
          sortable: true,
          textAlignment: "center",
          render: ({ aggregations }) => (
            <Flex justify={{ sm: "center" }} align={"center"}>
              <Text sx={{ marginLeft: "3px" }}>
                {aggregations?.totalArr && aggregations.totalArr > 0
                  ? `$${Math.round(aggregations.totalArr).toLocaleString()}`
                  : "-"}
              </Text>
            </Flex>
          ),
        },
        {
          accessor: "aggregations.churned.amount",
          title: (
            <Text sx={{ whiteSpace: "nowrap" }} size="sm">
              Loss - Churn ($)
            </Text>
          ),
          sortable: true,
          textAlignment: "center",
          render: ({ aggregations }) => (
            <Flex justify={{ sm: "center" }} align={"center"}>
              <Text fz="md">
                {aggregations?.churned?.accounts &&
                aggregations.churned.accounts > 0
                  ? aggregations.churned.accounts
                  : "-"}
              </Text>
              <Text sx={{ marginLeft: "3px" }}>
                {aggregations?.churned?.amount &&
                aggregations.churned.amount > 0
                  ? `($${Math.round(
                    aggregations.churned.amount
                  ).toLocaleString()})`
                  : ""}
              </Text>
            </Flex>
          ),
        },
        {
          accessor: "aggregations.committed.closest",
          title: (
            <Text sx={{ whiteSpace: "nowrap" }} size="sm">
              Commitments
            </Text>
          ),
          sortable: true,
          textAlignment: "center",
          hidden: !displayState.committed,
          render: ({ aggregations }) => {
            if (!isAllowedToEditDates) {
              return aggregations?.committed?.closest ? (
                <Text>
                  {getQuarter(new Date(aggregations.committed.closest))}
                </Text>
              ) : (
                ""
              );
            } else {
              return aggregations?.committed?.closest ? (
                <ReactTimeAgo date={new Date(aggregations.committed.closest)} />
              ) : (
                ""
              );
            }
          },
        },
        {
          accessor: "updatedAt",
          title: "updatedAt",
          sortable: true,
          hidden: true,
        },
      ]}
    />
  );
}
