import {
  Box,
  Button,
  Divider,
  Grid,
  Group,
  Loader,
  Modal,
  Paper,
  Text,
} from "@mantine/core";
import { useElementSize } from "@mantine/hooks";
import { DataTable } from "mantine-datatable";
import { useEffect, useState } from "react";
import { SourceIcon } from "../../../utils/SourceIcon";
import { IconGripVertical, IconPencil } from "@tabler/icons";
import "./CheckboxStyles.css";
import { useAuth0 } from "@auth0/auth0-react";
import FormModal from "./FormModal";
import ReassignValueModal from "./ReassignValueModal";
import { listObjectsFields, updateObjectsFields } from "../../../Api";

function Priority() {
  const [field, setField] = useState<any | null>(null);
  const fieldId = field?._id;
  const auth0 = useAuth0();
  const [isChecked, setIsChecked] = useState(true);
  const [opened, setOpened] = useState(false);
  const [openReassignModal, setOpenReassignModal] = useState(false);
  const [optionToEdit, setOptionToEdit] = useState(null);
  const [currentKey, setCurrentKey] = useState<null | string>(null);
  const [loading, setLoading] = useState(false);
  const [editForm, setEditForm] = useState(false);
  const [objectCount, setObjectCount] = useState(field?.options?.length);
  const [addValueLoader, setAddValueLoader] = useState(false);

  const getField = async () => {
    await listObjectsFields("priority", auth0).then(async (res) => {
      const sorted = res?.options.sort((a: any, b:any) => a.index - b.index)
      res.options = sorted
      setField(res);
    });
  };

  useEffect(() => {
    getField();
  }, []);

  const handleAddValue = () => {
    setAddValueLoader(true);
    setEditForm(false);
    setOpened(true);
    // axios dealt with in the FormModal.tsx
  };

  const handleEditValue = async (key: string) => {
    setOptionToEdit(field?.options?.find((o: any) => o.key === key));
    setEditForm(true);
    setCurrentKey(key);
    setOpened(true);
    // axios dealt with in the FormModal.tsx
  };

  // const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
  //   const checked = event.target.checked;
  //   setIsChecked(checked);
  // };

  const handleRemoveValue = async (fieldId: string, key: string) => {
    setCurrentKey(key);
    setOpenReassignModal(true);
    // axios dealt with in the ReassignValueModal.tsx
  };

  useEffect(() => {
    setLoading(true);
    if (field && field.options) {
      setLoading(false);
    }
  }, [field]);

  useEffect(() => {
    setObjectCount(field?.options?.length);
  }, [field?.options]);

  if (loading) {
    return <Loader variant="dots" mt={"lg"} ml={"lg"} />;
  }

  return (
    <Box
      p={"xl"}
      mt={7}
      bg={"#F1F3F5"}
      sx={{ height: "calc(100vh - 83px - 23px - 48px)" }}
    >
      <Paper mt={"lg"} shadow={"sm"} withBorder>
        <Grid p={0} m={0}>
          <Grid.Col span={2} p={"xl"}>
            <Text weight={700} color={"#5C5CEA"} size={"md"}>
              General
            </Text>
            <Text size={"sm"} mt={"sm"} mb={"sm"} c="dimmed">
              Field level settings
            </Text>
          </Grid.Col>
          <Divider size="xs" orientation="vertical" />
          <Grid.Col span={"auto"} p={"xl"}>
            <Grid>
              <Grid.Col span={3}>
                <Text color={"#161832"} weight={"650"}>
                  Display name:
                </Text>
              </Grid.Col>
              <Grid.Col span={3}>
                <Text color={"#161832"} weight={"500"}>
                  Priority
                </Text>
              </Grid.Col>
            </Grid>
            <Grid>
              <Grid.Col span={3}>
                <Text mt={"xl"} color={"#161832"} weight={"650"}>
                  Type:
                </Text>
              </Grid.Col>
              <Grid.Col span={3}>
                <Text mt={"xl"} color={"#161832"} weight={"500"}>
                  Single selection
                </Text>
              </Grid.Col>
            </Grid>
            <Grid>
              <Grid.Col span={3}>
                <Text mt={"xl"} color={"#161832"} weight={"650"}>
                  Description:
                </Text>
              </Grid.Col>
              <Grid.Col span={8}>
                <Text mt={"xl"} color={"#161832"} weight={"500"}>
                  Idea priority for roadmap (by impact)
                </Text>
              </Grid.Col>
            </Grid>
            <Grid>
              <Grid.Col
                span={4}
                mt={"md"}
                style={{ display: "flex", alignItems: "center" }}
              >
                <input
                  className="checkbox"
                  type="checkbox"
                  checked={isChecked}
                  // onChange={handleCheckboxChange}
                  disabled
                />
                <Text ml={"md"}>Mandatory field</Text>
              </Grid.Col>
            </Grid>
          </Grid.Col>
        </Grid>
      </Paper>

      <Paper mt={"lg"} withBorder>
        <Grid align="flex-start" m={0}>
          <Grid.Col span={2} px={"xl"}>
            <Text weight={700} color={"#5C5CEA"} size={"md"} my={"md"}>
              Field values
            </Text>
          </Grid.Col>
        </Grid>

        {currentKey && (
          <ReassignValueModal
            openReassignModal={openReassignModal}
            setOpenReassignModal={setOpenReassignModal}
            options={field.options}
            currentOption={
              currentKey &&
              field.options?.find((o: any) => o.key === currentKey)
            }
            currentKey={currentKey}
            fieldId={fieldId}
            key={fieldId}
            type="priority"
          />
        )}

        {editForm === true ? (
          currentKey &&
          optionToEdit && (
            <Modal
              centered
              opened={opened}
              onClose={() => setOpened(false)}
              overlayOpacity={0.55}
              overlayBlur={3}
            >
              <FormModal
                editForm={editForm}
                opened={opened}
                setOpened={setOpened}
                options={field?.options}
                currentKey={currentKey}
                optionToEdit={optionToEdit}
                fieldId={fieldId}
                type="priority"
              />
            </Modal>
          )
        ) : (
          <Modal
            centered
            opened={opened}
            onClose={() => setOpened(false)}
            overlayOpacity={0.55}
            overlayBlur={3}
          >
            <FormModal
              editForm={editForm}
              opened={opened}
              setOpened={setOpened}
              options={field?.options}
              fieldId={fieldId}
              objectCount={objectCount}
              setObjectCount={setObjectCount}
              setAddValueLoader={setAddValueLoader}
              addValueLoader={addValueLoader}
              type="priority"
            />
          </Modal>
        )}

        <DataTable
          minHeight={150}
          noRecordsText="No results were found..."
          horizontalSpacing="sm"
          verticalSpacing="sm"
          idAccessor={"_id"}
          sx={{
            "thead tr th": {
              background: "#f3f3f3 !important",
            },
          }}
          columns={[
            {
              accessor: "icon",
              title: "",
              width: 30,
              render: () => <IconGripVertical size={16} />,
            },

            {
              accessor: "label",
              title: "Display value",
              width: 150,
              textAlignment: "left",
              render: ({ label }: any) => {
                return <Text>{label}</Text>;
              },
            },
            {
              accessor: "isDefault",
              title: "Default",
              width: 60,
              textAlignment: "left",
              render: ({ isDefault: defaultVal }) => {
                if (defaultVal === true) {
                  return <SourceIcon sourceName="tick" />;
                } else {
                  return null;
                }
              },
            },
            {
              accessor: "color",
              title: "Color",
              width: 150,
              textAlignment: "center",
              render: ({ color }: any) => {
                return (
                  <Text
                    sx={{
                      backgroundColor: color,
                      width: "20px",
                      height: "20px",
                      borderRadius: "50%",
                      border: "1px solid grey",
                    }}
                    mx={"auto"}
                  ></Text>
                );
              },
            },
            {
              accessor: "remove",
              title: "",
              width: 120,
              textAlignment: "right",
              render: ({ key, isDefault }: any) => {
                return (
                  <Group noWrap>
                    <Button
                      variant="subtle"
                      color="dark"
                      aria-label="Edit"
                      leftIcon={<IconPencil size={16} />}
                      onClick={() => handleEditValue(key)}
                    >
                      Edit
                    </Button>
                    {/* {key !== "High" && key !== "Medium" && key !== "Urgent" && ( */}
                    <Button
                      variant="subtle"
                      color="dark"
                      disabled={isDefault}
                      leftIcon={<SourceIcon sourceName={"bin"} />}
                      aria-label="Remove"
                      onClick={() => handleRemoveValue(fieldId, key)}
                    >
                      Remove
                    </Button>
                    {/* )} */}
                  </Group>
                );
              },
            },
          ]}
          records={field?.options}
        />

        <Box
          px="xl"
          py="md"
          sx={{
            borderTop: "1px solid #dee2e6",
          }}
        >
          <Button
            bg={"#5C5CEA"}
            sx={{
              fontWeight: 700,
              ":hover": { backgroundColor: "#9f9af8" },
              "&[disabled]": {
                pointerEvents: "all",
                backgroundColor: "#fff",
                cursor: "not-allowed",
                ":hover": {
                  backgroundColor: "#fff",
                },
              },
            }}
            color={"#fff"}
            onClick={handleAddValue}
            disabled={objectCount < 10 ? false : true}
          >
            {addValueLoader === false ? (
              "+ Add Value"
            ) : (
              <Loader color="violet" variant="dots" />
            )}
          </Button>
        </Box>
      </Paper>
    </Box>
  );
}

export default Priority;
