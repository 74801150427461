export interface Company {
    tier?: string;
    type?: string;
    plan?: string;
    businessName?: string;
  }
  
  interface CategoryData {
    dataArr: any[];
    requestsCount: number;
    matchesCount: number; 
    title: string;
    inboxCount?: number;
  }
  
export const getCalculatedPerspectiveForRequest = async (
  allRequests: any[]
): Promise<{ tierData: CategoryData, typeData: CategoryData, planData: CategoryData, businessNameData: CategoryData }> => {
  const categories = {
    tiers: new Set<string>(),
    types: new Set<string>(),
    plans: new Set<string>(),
    businessNames: new Set<string>()
  };
  // console.log({allRequests}) 
  // Populate categories from all requests
  allRequests.forEach((request:any) => {
    // const company = request.company || request.chatCompany;
    // console.log({company, request})
    if (request.company) {
      if (request.company.tier) categories.tiers.add(request.company.tier);
      if (request.company.type) categories.types.add(request.company.type);
      if (request.company.plan) categories.plans.add(request.company.plan);
    }
    if (request.businessName) {
      categories.businessNames.add(request.businessName);
    }
  });

  const initializeCategoryArray = (categorySet: Set<string>, key: string) => {
    const arr = Array.from(categorySet, value => ({
      [key]: value,
      value: value,
      requestsCount: 0,
      matchesCount: 0,
      inboxCount: 0,
      ...(key === 'businessName' ? {
        businessCategory: `${value} Category`, // Example category mapping
        icon: `bagel_${value.replace(/\s+/g, '_')}`,
        value: value,
        total: 0,
        display: true,
        validatedPercentage: 0,
        matchesPercentage: 0,
        bgPercentage: 100
      } : {})
    }));

    return arr;
  };
  
  // Initialize category arrays
  const tierArr = initializeCategoryArray(categories.tiers, 'tier');
  const typeArr = initializeCategoryArray(categories.types, 'type');
  const planArr = initializeCategoryArray(categories.plans, 'plan');
  const businessNameArr = initializeCategoryArray(categories.businessNames, 'businessName');
  
  // Count requests and matches for each category
  allRequests.forEach((request:any) => {
    const company = request.company;
  
    // Function to increment the appropriate counters based on request status
    const incrementCounters = (arr: any[], key: string) => {
      let value = company?.[key] || 'N/A';
      
      if(key === 'businessName'){
        value = request?.[key]
      }

      // const value = request[key] || company[key] || 'N/A'; // Adjusted to prioritize direct request properties
      const item = arr.find((item:any) => item[key]?.toLowerCase() === value?.toLowerCase());
      if (item) {
        if (request?.status?.toLowerCase() === 'validated') {
          item.requestsCount++;
        } else if (request.status === 'match' && request.type === 'IdeaXConversation') 
        {
          item.matchesCount++;
        }
        else if (request.type === 'IdeaXRequest' || request.status === 'new') 
        {
          item.inboxCount++;
        }
      }
    };
  
    // Increment counters for each category
    incrementCounters(tierArr, 'tier');
    incrementCounters(typeArr, 'type');
    incrementCounters(planArr, 'plan');
    incrementCounters(businessNameArr, 'businessName');
  });

  businessNameArr.forEach(item => {
    item.total = item.requestsCount + item.matchesCount;
    if (item.total > 0) { // To avoid division by zero
      item.validatedPercentage = (item.requestsCount / item.total) * 100;
      item.matchesPercentage = (item.matchesCount / item.total) * 100;
      item.bgPercentage = 100 - item.validatedPercentage - item.matchesPercentage;
    }
  });

  // Calculate the highest total for each category
  const maxTierTotal = Math.max(...tierArr.map(item => item.requestsCount + item.matchesCount + item.inboxCount));
  const maxTypeTotal = Math.max(...typeArr.map(item => item.requestsCount + item.matchesCount + item.inboxCount));
  const maxPlanTotal = Math.max(...planArr.map(item => item.requestsCount + item.matchesCount + item.inboxCount));
  const maxBusinessNameTotal = Math.max(...businessNameArr.map(item => item.requestsCount + item.matchesCount + item.inboxCount));

  // Adjust the percentages based on the highest total and sort the array
  const adjustAndSortPercentages = (arr: any[], maxTotal: number) => {
    arr.forEach(item => {
      item.total = item.requestsCount + item.matchesCount + item.inboxCount;
      if (item.total > 0) { // To avoid division by zero
        item.validatedPercentage = (item.requestsCount / maxTotal) * 100;
        item.matchesPercentage = (item.matchesCount / maxTotal) * 100;
        item.inboxPercentage = (item.inboxCount / maxTotal) * 100; 
        item.bgPercentage = 100 - item.validatedPercentage - item.matchesPercentage - item.inboxPercentage;
      } else {
        item.validatedPercentage = 0;
        item.matchesPercentage = 0;
        item.inboxPercentage = 0;
        item.bgPercentage = 100;
      }
    });

    // Sort array by descending order of total percentage (validatedPercentage + matchesPercentage)
    arr.sort((a, b) => (b.validatedPercentage + b.matchesPercentage) - (a.validatedPercentage + a.matchesPercentage));
  };

  // Apply the adjustments and sorting
  adjustAndSortPercentages(tierArr, maxTierTotal);
  adjustAndSortPercentages(typeArr, maxTypeTotal);
  adjustAndSortPercentages(planArr, maxPlanTotal);
  adjustAndSortPercentages(businessNameArr, maxBusinessNameTotal);

  
  // Return structured data for each category
  return {
    tierData: 
    
    { dataArr: tierArr,
      requestsCount: tierArr.reduce((sum, curr) => sum + curr.requestsCount, 0),
      matchesCount: tierArr.reduce((sum, curr) => sum + curr.matchesCount, 0),
      inboxCount: tierArr.reduce((sum, curr) => sum + curr.inboxCount, 0),
      title: "Tiers" },

    typeData: { dataArr: typeArr, 
      requestsCount: typeArr.reduce((sum, curr) => sum + curr.requestsCount, 0),
      matchesCount: typeArr.reduce((sum, curr) => sum + curr.matchesCount, 0),
      inboxCount: tierArr.reduce((sum, curr) => sum + curr.inboxCount, 0),
      title: "Types" },

    planData: { dataArr: planArr,
      requestsCount: planArr.reduce((sum, curr) => sum + curr.requestsCount, 0),
      matchesCount: planArr.reduce((sum, curr) => sum + curr.matchesCount, 0),
      inboxCount: tierArr.reduce((sum, curr) => sum + curr.inboxCount, 0),
      title: "Plans" },

    businessNameData: { dataArr: businessNameArr, 
      requestsCount: businessNameArr.reduce((sum, curr) => sum + curr.requestsCount, 0),
      matchesCount: businessNameArr.reduce((sum, curr) => sum + curr.matchesCount, 0), 
      inboxCount: tierArr.reduce((sum, curr) => sum + curr.inboxCount, 0),
      title: "Business Names" }

  };
};

export const getCalculatedPerspectiveForRequestDiscovery = async (
  allRequests: any[]
): Promise<{ tierData: CategoryData, typeData: CategoryData, planData: CategoryData, businessNameData: CategoryData }> => {
  const categories = {
    tiers: new Set<string>(),
    types: new Set<string>(),
    plans: new Set<string>(),
    businessNames: new Set<string>()
  };
  // console.log({allRequests}) 
  // Populate categories from all requests
  allRequests.forEach((request:any) => {
    // const company = request.company || request.chatCompany;
    // console.log({company, request})
    if (request.company) {
      if (request.company.tier) categories.tiers.add(request.company.tier);
      if (request.company.type) categories.types.add(request.company.type);
      if (request.company.plan) categories.plans.add(request.company.plan);
    }
    if (request.businessName) {
      categories.businessNames.add(request.businessName);
    }
  });

  const initializeCategoryArray = (categorySet: Set<string>, key: string) => {
    const arr = Array.from(categorySet, value => ({
      [key]: value,
      value: value,
      requestsCount: 0,
      matchesCount: 0,
      ...(key === 'businessName' ? {
        businessCategory: `${value} Category`, // Example category mapping
        icon: `bagel_${value.replace(/\s+/g, '_')}`,
        value: value,
        total: 0,
        display: true,
        validatedPercentage: 0,
        matchesPercentage: 0,
        bgPercentage: 100
      } : {})
    }));

    return arr;
  };
  
  // Initialize category arrays
  const tierArr = initializeCategoryArray(categories.tiers, 'tier');
  const typeArr = initializeCategoryArray(categories.types, 'type');
  const planArr = initializeCategoryArray(categories.plans, 'plan');
  const businessNameArr = initializeCategoryArray(categories.businessNames, 'businessName');
  
  // Count requests and matches for each category
  allRequests.forEach((request:any) => {
    const company = request.company;
  
    // Function to increment the appropriate counters based on request status
    const incrementCounters = (arr: any[], key: string) => {
      let value = company?.[key] || 'N/A';
      
      if(key === 'businessName'){
        value = request?.[key]
      }

      // const value = request[key] || company[key] || 'N/A'; // Adjusted to prioritize direct request properties
      const item = arr.find((item:any) => item[key]?.toLowerCase() === value?.toLowerCase());
      if (item) {
        if (request?.status?.toLowerCase() === 'validated') {
          item.requestsCount++;
        } else 
        {
          item.matchesCount++;
        }
        
      }
    };
  
    // Increment counters for each category
    incrementCounters(tierArr, 'tier');
    incrementCounters(typeArr, 'type');
    incrementCounters(planArr, 'plan');
    incrementCounters(businessNameArr, 'businessName');
  });

  businessNameArr.forEach(item => {
    item.total = item.requestsCount + item.matchesCount;
    if (item.total > 0) { // To avoid division by zero
      item.validatedPercentage = (item.requestsCount / item.total) * 100;
      item.matchesPercentage = (item.matchesCount / item.total) * 100;
      item.bgPercentage = 100 - item.validatedPercentage - item.matchesPercentage;
    }
  });

  // Calculate the highest total for each category
  const maxTierTotal = Math.max(...tierArr.map(item => item.requestsCount + item.matchesCount));
  const maxTypeTotal = Math.max(...typeArr.map(item => item.requestsCount + item.matchesCount));
  const maxPlanTotal = Math.max(...planArr.map(item => item.requestsCount + item.matchesCount));
  const maxBusinessNameTotal = Math.max(...businessNameArr.map(item => item.requestsCount + item.matchesCount));

  // Adjust the percentages based on the highest total and sort the array
  const adjustAndSortPercentages = (arr: any[], maxTotal: number) => {
    arr.forEach(item => {
      item.total = item.requestsCount + item.matchesCount
      if (item.total > 0) { // To avoid division by zero
        item.validatedPercentage = (item.requestsCount / maxTotal) * 100;
        item.matchesPercentage = (item.matchesCount / maxTotal) * 100;
      
        item.bgPercentage = 100 - item.validatedPercentage - item.matchesPercentage 
      } else {
        item.validatedPercentage = 0;
        item.matchesPercentage = 0;
        item.inboxPercentage = 0;
        item.bgPercentage = 100;
      }
    });

    // Sort array by descending order of total percentage (validatedPercentage + matchesPercentage)
    arr.sort((a, b) => (b.validatedPercentage + b.matchesPercentage) - (a.validatedPercentage + a.matchesPercentage));
  };

  // Apply the adjustments and sorting
  adjustAndSortPercentages(tierArr, maxTierTotal);
  adjustAndSortPercentages(typeArr, maxTypeTotal);
  adjustAndSortPercentages(planArr, maxPlanTotal);
  adjustAndSortPercentages(businessNameArr, maxBusinessNameTotal);

  
  // Return structured data for each category
  return {
    tierData: 
    
    { dataArr: tierArr,
      requestsCount: tierArr.reduce((sum, curr) => sum + curr.requestsCount, 0),
      matchesCount: tierArr.reduce((sum, curr) => sum + curr.matchesCount, 0),
    
      title: "Tiers" },

    typeData: { dataArr: typeArr, 
      requestsCount: typeArr.reduce((sum, curr) => sum + curr.requestsCount, 0),
      matchesCount: typeArr.reduce((sum, curr) => sum + curr.matchesCount, 0),
   
      title: "Types" },

    planData: { dataArr: planArr,
      requestsCount: planArr.reduce((sum, curr) => sum + curr.requestsCount, 0),
      matchesCount: planArr.reduce((sum, curr) => sum + curr.matchesCount, 0),
      
      title: "Plans" },

    businessNameData: { dataArr: businessNameArr, 
      requestsCount: businessNameArr.reduce((sum, curr) => sum + curr.requestsCount, 0),
      matchesCount: businessNameArr.reduce((sum, curr) => sum + curr.matchesCount, 0), 
     
      title: "Business Names" }

  };
};
