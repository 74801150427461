import { useAuth0 } from "@auth0/auth0-react";
import {
  Menu,
  Text,
  TextInput,
  Box,
  ScrollArea,
  createStyles,
  Paper,
  UnstyledButton,
  Card,
  Group,
  Badge,
  Button,
  Stack,
} from "@mantine/core";
import { IconExternalLink, IconSearch, IconUnlink } from "@tabler/icons";
import { useEffect, useState, useContext } from "react";
import {
  searchProductItems,
} from "../../Api";
import { itemPropsByType } from "../../utils";
import { SourceIcon } from "../../utils/SourceIcon";
import { InfoCard } from "../idea/InfoCard";
import { SearchResultItem } from "./SearchResultItem";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";
import { useHover } from "@mantine/hooks";
import  { SyncContext } from "../../context/SyncContext";

const useStyles = createStyles((theme) => ({
  item: {
    border: "1px solid #000",
    "&[data-hovered]": {
      backgroundColor: "#f5f5f5",
      color: theme.white,
    },
  },
  tabs: {
    "&[data-active]": {
      borderColor: "#5B60E3",
      color: "#5B60E3",
      "&:hover": {
        borderColor: "#5B60E3",
      },
    },
  },
}));

export function Link({
  linkToProduct,
}: {
  request: any;
  getRequestFn: (useLoading?: boolean) => void
  linkToProduct: (item: any) => void

}) {
  const { classes } = useStyles();
  const auth0 = useAuth0();
  const { hovered: cardHovered, ref } = useHover();
  const [query, setQuery] = useState<string>("");
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [link, setLink] = useState(false);
  const [status, setStatus] = useState('');
  const [url, setUrl] = useState('');
  const [originType, setOriginType] = useState(null)
  const { sync, activeSource } = useContext(SyncContext);

  const fetchOptions = async () => {
    searchProductItems(activeSource.type, query, auth0)
      .then(({ results }: any) => {
        setSearchResults(results);
      })
      .catch((e) => console.log(e));
  };

  const unLinkToProductItemFn = () => {
    setLink(!link)
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (query !== "") {
        fetchOptions();
      } else {
        setSearchResults([]);
      }
    }, 200);

    return () => {
      clearTimeout(timeout);
    };
  }, [query]);

  const linkProduct = (item: any)=> {
    setLink(!link)
    setStatus(item.status)
    setUrl(item.link)
    setOriginType(item.origin)
    linkToProduct(item)
  }
  return (
    <Menu
      classNames={classes}
      shadow="md"
      width={link ? 200 : 600}
      position="bottom"
      withArrow
      styles={{
        dropdown: {
          minWidth: `${link ? 200 : 600}px !important`,
        },
      }}
    >
      <Menu.Target>
        <UnstyledButton sx={{ cursor: "pointer" }}>
          <Card p="xs" radius="md" withBorder ref={ref}>
            <Text fz="xs" c="dimmed" lineClamp={1}>
              {capitalizeFirstLetter(sync ?? 'jira')} link{" "}
              {link && (
                <Text span fw={400} fz="xs">
                  ({originType || "Task"})
                </Text>
              )}
            </Text>
            <Group spacing="xs" noWrap>
              <SourceIcon
                sourceName={sync ?? 'jira'}
                width={25}
                height={25}
              />
              {link ? (
                <Badge
                  sx={{
                    backgroundColor: "#C4C4C4",
                    color: "black",
                  }}
                >
                  {cardHovered ? (originType) : status}
                </Badge>
              ) : (
                <Text fz="md" fw={500} lineClamp={1}>
                  {capitalizeFirstLetter(sync ?? 'jira')}
                </Text>
              )}
            </Group>
          </Card>
        </UnstyledButton>
      </Menu.Target>

      <Menu.Dropdown p={0}>
        {link ? (
          <Stack spacing="xs" p="xs" >
            <Button
              component="a"
              variant="outline"
              styles={() => ({
                root: {
                  borderColor: "#5B60E3",
                  color: "#5B60E3",
                  height: 35,
                },
              })}
              radius="md"
              href={`${url}`}
              size="sm"
              fullWidth
              rightIcon={<IconExternalLink size={16} />}
              target="_blank"
            >
              Open in {capitalizeFirstLetter(sync ?? 'jira')}
            </Button>
            <Button
              size="sm"
              radius="md"
              fullWidth
              variant="subtle"
              color="red"
              rightIcon={<IconUnlink size={16} />}
              onClick={() => unLinkToProductItemFn()}
            >
              Unlink
            </Button>
          </Stack>
        ) : (
          <>
            <Box mx="sm" mt="sm">
              <TextInput
                placeholder="Type here"
                icon={<IconSearch size={14} />}
                value={query}
                onChange={(event) => setQuery(event.currentTarget.value)}
              />
              <ScrollArea style={{ height: 300, overflowX: "unset" }}>
                <Box w="561px">
                  {searchResults.length > 0 &&
                    searchResults.map((item: any) => {
                      return (
                        <Paper
                          sx={{
                            border: "1px solid #E5E7EB",
                            ":hover": {
                              borderColor: "#5B60E3",
                              backgroundColor: "#5B60E34D",
                            },
                            cursor: "pointer",
                          }}
                          my="xs"
                          key={item._id}
                        >
                          <SearchResultItem
                            item={item}
                            link={linkProduct}
                          />
                        </Paper>
                      );
                    })}
                </Box>
              </ScrollArea>
            </Box>
            {searchResults.length > 0 && (
              <Box
                px="sm"
                py="xs"
                sx={{
                  borderTop: "1px solid #e5e7eb",
                }}
              >
                <Text size="sm">{searchResults.length} results found</Text>
              </Box>
            )}
          </>
        )}
      </Menu.Dropdown>
    </Menu>
  );
}
