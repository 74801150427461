import {
  Box,
  Card,
  Timeline
} from "@mantine/core";
import React from "react";
import {ExplainCard} from "./ExplainCard";
import {SetupAuth} from "./SetupAuth";
import {SetupAccounts} from "./SetupAccounts";
import {SetupOpportunities} from "./SetupOpportunities";
import {SetupImport} from "./SetupImport";

export const SalesforceSetup = (props: { integration: any, reloadIntegration: () => void, customizations?: any }) => {
  const integration = props.integration
  const step = integration ? integration.setupStep : 0;
  return (<>
    <Box p={"xl"} mt={7} bg={"#F1F3F5"}>
      <ExplainCard />
      <Card mt={"15px"}>
        <Timeline active={step} bulletSize={40} lineWidth={1} m={"md"}>
          <Timeline.Item
            title="Connect your Salesforce account"
            sx={{minHeight: '35px'}}
          >
            { step === 0 ? (
              <SetupAuth />
            ): '' }
          </Timeline.Item>
          <Timeline.Item
            title="Account preferences"
            sx={{minHeight: '35px'}}
          >
            { step === 1 ? (
              <SetupAccounts integration={integration} reloadIntegration={props.reloadIntegration} />
            ): '' }
          </Timeline.Item>
          <Timeline.Item
            title="Opportunities preferences"
            sx={{minHeight: '35px'}}
          >
            { step === 2 ? (
              <SetupOpportunities integration={integration} reloadIntegration={props.reloadIntegration} />
            ): '' }
          </Timeline.Item>
          {/*<Timeline.Item*/}
          {/*  title="Cases preferences"*/}
          {/*  sx={{minHeight: '35px'}}*/}
          {/*>*/}
          {/*  { step === 3 ? (*/}
          {/*    <SetupCases integration={integration} reloadIntegration={props.reloadIntegration}  customization={props.customizations ? props.customizations["conversation"] :  undefined} />*/}
          {/*  ): '' }*/}
          {/*</Timeline.Item>*/}
          <Timeline.Item
            title="Import"
            sx={{minHeight: '35px'}}
          >
            { step === 3 ? (
              <SetupImport  integration={integration} reloadIntegration={props.reloadIntegration} />
            ): '' }
          </Timeline.Item>
        </Timeline>
      </Card>
    </Box>
  </>)
}