import * as React from "react"
const ActiveIcon = (props:any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width}
    height={props.height}
    fill="none"
    {...props}
  >
    <circle cx={7.5} cy={7.5} r={7.5} fill="#83EDAE" />
  </svg>
)
export default ActiveIcon
