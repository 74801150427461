import { useEditor, FloatingMenu, BubbleMenu } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { RichTextEditor, Link } from "@mantine/tiptap";
import { Spoiler, createStyles } from "@mantine/core";
import { useEffect } from "react";

const useStyles = createStyles(() => ({
  root: {
    borderRadius: 0,
    borderWidth: 0,
  },
  content: {},
}));

export function IdeaTextEditor({
  text,
  borderRadius = 0,
  borderWidth = 0,
  py = 0,
  editable,
  updateDescriptionRequest,
}: {
  updateDescriptionRequest: (html: string) => void;
  text: string;
  borderRadius?: number;
  borderWidth?: number;
  editable: boolean,
  py?: number;
}) {
  const { classes } = useStyles();
  const editor = useEditor({
    extensions: [StarterKit, Link],
    
    content: text,
    onBlur({ editor }) {
      const html = editor.getHTML();
      updateDescriptionRequest(html);
    },
    editable: editable  ,
  });

  useEffect(() => {
    editor?.commands.setContent(text);
  }, [text]);

  return (
    <Spoiler
      maxHeight={120}
      showLabel="See more"
      hideLabel="Hide"
      styles={{
        control: {
          paddingLeft: 16,
        }
      }}
    >
      <RichTextEditor

        styles={{
          root: {
            borderRadius,
            borderWidth,
          },
          content: {
            borderRadius,
          },
        }}
        editor={editor}
      >
        {editor && (
          <>
            <BubbleMenu editor={editor}>
              <RichTextEditor.ControlsGroup>
                <RichTextEditor.H1 />
                <RichTextEditor.H2 />
                <RichTextEditor.BulletList />
              </RichTextEditor.ControlsGroup>
            </BubbleMenu>
            <FloatingMenu editor={editor}>
              <RichTextEditor.ControlsGroup>
                <RichTextEditor.H1 />
                <RichTextEditor.H2 />
                <RichTextEditor.BulletList />
              </RichTextEditor.ControlsGroup>
            </FloatingMenu>
          </>
        )}
        <RichTextEditor.Content py={py} />
      </RichTextEditor>
    </Spoiler>
    
  );
}
