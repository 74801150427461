import { Box, Flex, ScrollArea } from "@mantine/core";
import {useContext, useEffect, useState} from "react";
import { RightBar } from "./RightBar";
import StatesCard from "./StatesCard";
import Top from "./Top";
import TopRequestsBarChart from "./TopRequestsBarChart";
import TopRequestsTable from "./TopRequestsTable";
import { getTopRequests } from "../../../Api";
import { useAuth0 } from "@auth0/auth0-react";
import SyncContext from "../../../context/SyncContext";

const states = [
  {
    accessor: "accounts",
    title: "Accounts",
  },
  {
    accessor: "opportunities",
    title: "Opportunities",
  },
  {
    accessor: "nextQtrCommitments",
    title: "Next Qtr Commitments",
  },
  {
    accessor: "nextQtrDealBreakers",
    title: "Next Qtr Deal Breakers",
  },
  {
    accessor: "90DaysToRenew",
    title: "90 days to renew",
  },
];

function TopRequests() {
  const auth0 = useAuth0();
  const [activeAmount, setActiveAmount] = useState(10);
  const [activeProductArea, setActiveProductArea] = useState<string[]>([]);
  const [activeTopBy, setActiveTopBy] = useState<string>("aggregations.accounts.amount");
  const [slectedRow, setSlectedRow] = useState<{
    record: any;
    recordIndex: number;
  } | null>(null);
  const [tab, setTab] = useState<string | null>(null);

  const [productAreaOptions, setProductAreaOptions] = useState<any[]>([]);
  const [requests, setRequests] = useState<any[] | null>(null);
  const [accountData, setAccountData] = useState<any>({});
  const [chartData, setChartData] = useState<any[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);


  const {components} = useContext(SyncContext);
  const getTopRequestsFn = async () => {
    setLoading(true);
    try {
      await getTopRequests(activeAmount, activeProductArea, activeTopBy, auth0).then((res) => {
        const { requests, accountData, chartData } = res.data;
        setRequests(requests);
        setAccountData(accountData);
        setChartData(chartData);
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    getTopRequestsFn();
  }, [activeAmount, activeProductArea, activeTopBy]);

  useEffect(() => {
    getTopRequestsFn();
    const options = components.map(
      ({ value, name }: { value: string; name: string }) => ({
        value,
        label: name,
      })
    );
    setProductAreaOptions(options);
  }, []);

  return (
    <Flex>
      <Box
        pt={"md"}
        px={"lg"}
        w={"100%"}
        sx={{
          height: "calc(100vh - 63px)",
          overflow: "scroll",
        }}
      >
        <Top
          activeAmount={activeAmount}
          setActiveAmount={setActiveAmount}
          amountOptions={[10, 20, 50]}
          activeProductArea={activeProductArea}
          setActiveProductArea={(value: string[]) =>
            setActiveProductArea(value)
          }
          productAreaOptions={productAreaOptions}
          activeTopBy={activeTopBy}
          setActiveTopBy={(value: string) =>
            setActiveTopBy(value)
          }
        />
        <StatesCard
          states={states}
          accountData={accountData}
          loading={loading}
          setTab={setTab}
        />
        {chartData && <TopRequestsBarChart data={chartData} />}
        {requests && (
          <TopRequestsTable
            requests={requests}
            loading={loading}
            slectedRow={slectedRow}
            onRowClick={(record: any, recordIndex: number) => {
              if (
                slectedRow?.record &&
                slectedRow?.record?._id === record?._id
              ) {
                setSlectedRow(null);
              } else {
                setSlectedRow({ record, recordIndex });
              }
            }}
          />
        )}
      </Box>

      {slectedRow && (
        <ScrollArea
          px="md"
          sx={{
            height: "100vh",
            width: 400,
            background: "#F1F3F5",
            borderLeft: "1px solid #E5E7EB",
          }}
        >
          <RightBar />
        </ScrollArea>
      )}
    </Flex>
  );
}

export default TopRequests;
