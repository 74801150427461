import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  ColorInput,
  Flex,
  Loader,
  Menu,
  Modal,
  Select,
  Text,
  TextInput,
  Tooltip,
  useMantineTheme,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useEffect, useRef, useState } from "react";
import { updateObjectsFields } from "../../../Api";
import { useAuth0 } from "@auth0/auth0-react";
import { showNotification } from "@mantine/notifications";
import { SourceIcon } from "../../../utils/SourceIcon";

interface FormModalProps {
  dragOptions: any[],
  editForm: boolean;
  opened: boolean;
  setOpened: (opened: boolean) => void;
  options: any;
  fieldId: string;
  objectCount?: number;
  setObjectCount?: (objectCount: number) => void;
  currentKey?: string;
  optionToEdit?: {
    color: string;
    index: number;
    isDefault: boolean;
    isDefaultDelivery: boolean;
    key: string;
    label: string;
    category: string;
  };
  addValueLoader?: boolean;
  setAddValueLoader?: (addValueLoader: boolean) => void;
  type: "status" | "priority";
}

function FormModal({
  dragOptions,
  editForm,
  opened,
  setOpened,
  options,
  fieldId,
  objectCount,
  currentKey,
  setObjectCount,
  optionToEdit,
  addValueLoader,
  setAddValueLoader,
  type,
}: FormModalProps) {
  const auth0 = useAuth0();
  const colorRef = useRef<HTMLInputElement>(null);
  const categoryRef = useRef<HTMLInputElement>(null);
  const theme = useMantineTheme();
  const [formLoader, setFormLoader] = useState(false);

  if (setAddValueLoader) {
    setAddValueLoader(false);
  }

  const saveNotification = () => {
    showNotification({
      title: "Your settings have been saved!",
      message: "",
      icon: <SourceIcon sourceName="tick-circle" />,
    });
  };

  const form = useForm({
    initialValues: optionToEdit,
  });

  const addObject = async (newValue: any) => {
    try {
      if (newValue.isDefault) {
        const currentDefaultIndex = options.findIndex(
          (option: any) => option.isDefault === true
        );
        options[currentDefaultIndex].isDefault = false;
      }

      if (newValue.isDefaultDelivery) {
        const currentDefaultIndex = options.findIndex(
          (option: any) => option.isDefaultDelivery === true
        );
        options[currentDefaultIndex].isDefaultDelivery = false;
      }
      console.log({newValue})
      // if (newValue.category.toLowerCase() == 'done') {
      //   newValue.key = 'In delivery'
      // }
      options.push(newValue);
      await updateObjectsFields(auth0, fieldId, { options });
      setFormLoader(false);
      setOpened(false);
      setObjectCount!(objectCount! + 1);
      saveNotification();
    } catch (error) {
      console.log(error);
    }
    setFormLoader(false);
    setOpened(false);
  };

  const editObject = async (newValue: any) => {
    try {
      console.log({ newValue, currentKey });
      const currentOption = options.findIndex(
        (option: any) => option.key === currentKey
      );
      if (newValue.isDefault) {
        const currentDefaultIndex = options.findIndex(
          (option: any) => option.isDefault === true
        );
        options[currentDefaultIndex].isDefault = false;
      }
      
      if (newValue.isDefaultDelivery) {
        const currentDefaultIndex = options.findIndex(
          (option: any) => option.isDefaultDelivery === true
        );
        options[currentDefaultIndex].isDefaultDelivery = false;
      }
      const oldValue = options[currentOption]
      const oldLabel = oldValue.label
      const newLabel = newValue.label
      options[currentOption] = newValue;
      console.log(currentOption)
      await updateObjectsFields(auth0, fieldId, { options, oldLabel : oldLabel, newLabel: newLabel });
      setFormLoader(false);
      setOpened(false);
      saveNotification();
    } catch (error) {
      console.log(error);
    }
    setFormLoader(false);
    setOpened(false);
  };

  const handleSaveValues = async (formValues: any) => {
  

    setFormLoader(true);

    if (editForm === false) {
      const newValues = {
        label: formValues.label,
        color: formValues.color,
        isDefault: formValues.isDefault,
        isDefaultDelivery: formValues.isDefaultDelivery,
        key: formValues.label,
        index: options.length,
        category: formValues.category,
      };
      // adding a value
      addObject(newValues);
    }
    if (editForm === true) {
      const newValues = {
        label: formValues.label,
        color: formValues.color,
        isDefault: formValues.isDefault,
        isDefaultDelivery: formValues.isDefaultDelivery,
        key: formValues.label,
        index: formValues.index,
        category: formValues.category,
      };
      // editing a value
      editObject(newValues);
    }
    form.reset();
  };

  console.log({
    options,
    currentKey,
  });
  const disableEditDefaultCheck =
    options.find((option: any) => option.key === currentKey)?.isDefault &&
    options.filter((o: any) => o?.isDefault).length == 1;
  const disableEditDefaultDeliveryCheck =
    options.find((option: any) => option.key === currentKey)?.isDefaultDelivery &&
    options.filter((o: any) => o?.isDefaultDelivery).length == 1;

  return (
    <Modal
      centered
      opened={opened}
      onClose={() => setOpened(false)}
      overlayColor={
        theme.colorScheme === "dark"
          ? theme.colors.dark[9]
          : theme.colors.gray[2]
      }
      overlayOpacity={0.55}
      overlayBlur={3}
    >
      <Box>
        <TextInput
          label={
            <Text>
              Display value{" "}
              <Text span c="red">
                *
              </Text>
            </Text>
          }
          placeholder="Add a value..."
          {...form.getInputProps("label")}
        />

        {type === "status" && (
          <Select
            my={"md"}
            placeholder="Click here to choose..."
            label="Category"
            withAsterisk
            data={[
              { value: 'New', label: 'New' },
              { value: "In progress", label: "In progress" },
              { value: "Done", label: "Done"},
            ]}
            ref={categoryRef}
            maxDropdownHeight={280}
            nothingFound={<Text>No matching categories...</Text>}
            {...form.getInputProps("category")}
          />
        )}

        <ColorInput
          my={"md"}
          label={
            <Text>
              Choose a color{" "}
              <Text span c="red">
                *
              </Text>
            </Text>
          }
          ref={colorRef}
          format="hex"
          swatches={[
            "#FC0A0A",
            "#F26786",
            "#FDAE16",
            "#1DB547",
            "#4194F9",
            "#6B7280",
          ]}
          {...form.getInputProps("color")}
          sx={{ width: "130px" }}
        />
        <Tooltip
          label={
            disableEditDefaultCheck
              ? "must be at least one default (New)"
              : "check to make the status default (New)"
          }
        >
          <Checkbox
            label="Set as default (New)"
            disabled={disableEditDefaultCheck || false}
            {...form.getInputProps("isDefault", { type: "checkbox" })}
          />
        </Tooltip>

        <Tooltip
          label={
            disableEditDefaultDeliveryCheck
              ? "must be at least one Default (Delivery)"
              : "check to make the status Default (Delivery)"
          }
        >
          <Checkbox
            mt="sm"
            label="Set as default (Delivery)"
            disabled={disableEditDefaultDeliveryCheck || false}
            {...form.getInputProps("isDefaultDelivery", { type: "checkbox" })}
          />
        </Tooltip>


        <Flex
          mih={50}
          justify="flex-end"
          align="center"
          direction="row"
          wrap="wrap"
        >
          <Button
            bg={"#5C5CEA"}
            disabled={
              type === "priority"
                ? !form.values.label || form.values.color?.length < 3
                : !form.values.label ||
                  form.values.color?.length < 3 ||
                  !form.values.category
            }
            sx={{
              fontWeight: 700,
              ":hover": { backgroundColor: "#9f9af8" },
              "&[disabled]": {
                pointerEvents: "all",
                backgroundColor: "#fff",
                cursor: "not-allowed",
                ":hover": {
                  backgroundColor: "#fff",
                },
              },
            }}
            color={"#fff"}
            onClick={() => handleSaveValues(form.values)}
          >
            {formLoader === false ? (
              "Save"
            ) : (
              <Loader color="violet" variant="dots" />
            )}
          </Button>
        </Flex>
      </Box>
    </Modal>
  );
}

export default FormModal;
