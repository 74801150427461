import { Box, Flex, Group, Tabs, Text, TextInput } from '@mantine/core';
import { IconPlus, IconSearch } from '@tabler/icons';
import { useContext } from 'react';
import InitialHeaderRow from './InitialHeaderRow';
import SearchRow from './SearchRow';
import Sort from './sort/Sort';
import FilterBar from './Filters/FilterBar';
import FiltersContext from '../../ideas/filters/context/FiltersContext';
import posthog from 'posthog-js';
import IdeaContext from '../IdeaContext';

interface EvidencesHeaderProps {
  addEvidenceFormScreen: boolean;
  setAddEvidenceFormScreen: React.Dispatch<React.SetStateAction<boolean>>;
  setCardView: React.Dispatch<React.SetStateAction<boolean>>;
  searchEvidence: (query: string) => void;
  activeSearch: boolean;
  searchedEvidence: any[]; // Specify the type of elements in searchedEvidence if possible
  showMatchesResults: boolean;
  setShowMatchesResults: React.Dispatch<React.SetStateAction<boolean>>;
  showRequestsResults: boolean;
  setShowRequestsResults: React.Dispatch<React.SetStateAction<boolean>>;
  showQueryResults: boolean;
  setShowQueryResults: React.Dispatch<React.SetStateAction<boolean>>;
  textItemsResultsLength: number;
  query: string;
  sortDescending: boolean;
}


const EvidencesHeader: React.FC<EvidencesHeaderProps> = ({
  addEvidenceFormScreen,
  setAddEvidenceFormScreen,
  setCardView,
  searchEvidence,
  activeSearch, 
  searchedEvidence,
  showMatchesResults,
  setShowMatchesResults,
  showRequestsResults,
  setShowRequestsResults,
  showQueryResults,
  setShowQueryResults,
  textItemsResultsLength,
  query,
  sortDescending,
}) => {

  const {
    activeFilters,
    setActiveFilters
  } = useContext(FiltersContext);
  const {
    idea
  } = useContext(IdeaContext);

  return (
    <Box mt={24}  /* sx={{ borderBottom: "1px solid #D8D8DB" }} */>
      {/* Actions */}

      <Group mb={12} position='apart' px="20px">
        <Group spacing={8}>
          <TextInput
            styles={{
              wrapper:{
                border: "1px solid #D8D8DB",
                borderRadius: "7px",
                backgroundColor: "#fff"
              },
              input:{
                height:32
              }
            }}
            mr={8}
            variant="unstyled"
            placeholder="Search evidence..."
            icon={<IconSearch size={14} />}
            value={query}
            onChange={(event) => {
              searchEvidence(event.currentTarget.value);
              posthog.capture('search_evidence_in_idea', {
                ideaId: idea._id,
                query: event.currentTarget.value
              })
            }}
          />
          <FilterBar 
            activeFilters={activeFilters}
            setActiveFilters={setActiveFilters}
          />
        </Group>
        <Flex>
          {/* Sort */}
          <Sort />

          <Flex
            w={70}
            align="center"
            px="2px"
            sx={{
              borderRadius: "6px",
              border: "1px solid #5C5CEB",
              cursor: "pointer",
            }}
            bg="#5C5CEB"
            h={32}
            ml="xs"
            onClick={() => {
              setCardView(false);
              setAddEvidenceFormScreen(true);
              posthog.capture('add_evidence_clicked', {
                ideaId: idea._id,
                type: 'manually'
              })
            }}
          >
            <Text ml={4} mt={4} p={0} color="white">
              <IconPlus height={16} />
            </Text>
            <Text color="white" fz="14px" fw={500}>
              Add
            </Text>
          </Flex>
        </Flex>
      </Group>

      {/* Header and filter by suggested */}
      <>
        {!activeSearch ? (
          <InitialHeaderRow/>
        ) : (
          
          <SearchRow
            searchEvidence={searchEvidence}
            searchedEvidence={searchedEvidence}
            showMatchesResults={showMatchesResults}
            setShowMatchesResults={setShowMatchesResults}
            showRequestsResults={showRequestsResults}
            setShowRequestsResults={setShowRequestsResults}
            showQueryResults={showQueryResults}
            setShowQueryResults={setShowQueryResults}
            resultsLength={textItemsResultsLength}
          />
  
        )}
      </>
    </Box>
  );
};

export default EvidencesHeader