import { verifyEmail } from "./Api";
import LogoIcon from "../src/components/layouts/LogoIcon";
import { useAuth0 } from "@auth0/auth0-react";
import { Flex, Button, Text, Box } from "@mantine/core";
import {useContext, useState} from "react";
import SyncContext from "./context/SyncContext";
import {logout} from "./utils";
import {useNavigate} from "react-router-dom";

const VerifyEmail = () => {
  const auth0 = useAuth0();
  const [emailSent, setEmailSent] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const verify = () => {
    setLoading(true)
    verifyEmail(auth0)
      .then((res) => {
        setEmailSent(true)
        setLoading(false)
      })
      .catch((e) => {
        setLoading(false)
        console.log(e)
      });
  };
  return (
    <Box>
      <Flex
        style={{ width: "100vw", height: "100vh" }}
        justify="center"
        align="center"
        direction="column"
      >
        <LogoIcon />
        <Text fz="xl" mt="md" mb="md">
          { emailSent ? 'Hello, please verify your Email' : 'Verification email was sent to your mailbox' }
        </Text>
        {emailSent && (
          <Button onClick={() => {
            window.location.reload()
          }} loading={loading}>
            Complete verification on your inbox and retry
          </Button>
        )}
        {!emailSent && (
          <Button onClick={verify} loading={loading}>
            Verify Email
          </Button>
        )}
      </Flex>
    </Box>
  );
};

export default VerifyEmail;
