import { Card, Paper, Box, Text } from "@mantine/core";
import React from "react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

interface ChartData {
  [key: string]: {
    value: number;
    color: string;
  };
}

interface TopRequestData {
  closingDate: string;
  chartData: ChartData;
}

function TopRequestsBarChart({ data }: { data: TopRequestData[] }) {
  const categories = data.reduce((acc, { chartData }) => {
    Object.keys(chartData).forEach((category) => {
      if (!acc.includes(category)) {
        acc.push(category);
      }
    });
    return acc;
  }, [] as string[]);

  const dataForChart = data.map(({ closingDate, chartData }) => {
    const categoryData = categories.reduce((acc, category) => {
      const value = chartData[category]?.value || 0;
      acc[category] = value;
      return acc;
    }, {} as { [key: string]: number });

    return {
      closingDate,
      ...categoryData,
    };
  });

  const dollarFormatter = (value: number) =>
    `$${value.toLocaleString(undefined, { minimumFractionDigits: 0 })}`;

  return (
    <Paper
      my="md"
      radius="md"
      withBorder
      sx={{
        ".recharts-default-legend": {
          marginLeft: "10px !important",
          fontSize: 12,
        },
      }}
    >
      <Box
        bg={"#F3F3F3"}
        sx={{
          borderRadius: "8px 8px 0 0",
        }}
      >
        <Text size="sm" ml="sm" p="xs" weight={700} color={"#5C5CEA"}>
          Ideas by expected closing date
        </Text>
      </Box>
      <Box p="md" pl="xl">
        <ResponsiveContainer height={200}>
          <BarChart
            data={dataForChart}
            margin={{ bottom: 0, left:15, top: 10 }}
            barCategoryGap={15}
          >
            <CartesianGrid strokeDasharray="3 3" vertical={false} />
            <XAxis dataKey="closingDate" fontSize={12} />
            <YAxis
              tickFormatter={dollarFormatter}
              fontSize={12}
            />
            <Tooltip formatter={dollarFormatter as any} />
            <Legend
              layout="vertical"
              align="right"
              verticalAlign="middle"
              margin={{ left: 10 }}
              fontSize={12}
            />
            {categories.map(
              (category, index) =>
                data[index]?.chartData[category] && (
                  <Bar
                    key={category}
                    dataKey={category}
                    stackId="a"
                    fill={data[index].chartData[category].color}
                  />
                )
            )}
          </BarChart>
        </ResponsiveContainer>
      </Box>
    </Paper>
  );
}

export default TopRequestsBarChart;
