import { Checkbox, Group, Flex, Box, Text } from "@mantine/core";
import { useContext, useEffect, useState } from "react";
import DiscoveryContext from "../../DiscoveryContext";
import ProgressBarPart from "./ProgressBarPart";

export const SingleSideCheckboxDiscovery = ({
  item,
  validatedCount,
  pendingCount,
}: {
  item: any;
  validatedCount: number;
  pendingCount: number;
}) => {

  const { perspectiveData, type, showmatches, showValidated } = useContext(DiscoveryContext);

  const [onlyValidatedPercentage, setOnlyValidatedPercentage] = useState(0); 
  const [onlyPendingPercentage, setOnlyPendingPercentage] = useState(0); 
  const [onlyBgPercentage, setOnlyBgPercentage] = useState(0);

  useEffect(() => {

    if(!showmatches){
      const validatedPerc = item?.requestsCount/validatedCount*100
      setOnlyValidatedPercentage(validatedPerc)
      setOnlyBgPercentage(100 - validatedPerc)
    }
    if(!showValidated){
      const pendingPerc = item?.matchesCount/pendingCount*100
      setOnlyPendingPercentage(pendingPerc)
      setOnlyBgPercentage(100 - pendingPerc)
    }
    
  }, [showmatches, showValidated, type]);

  return (
    <Checkbox
      styles={{
        label: {
          cursor: "pointer",
        },
        input: {
          cursor: "pointer",
        },
        labelWrapper: {
          width: "-webkit-fill-available",
          cursor: "pointer",
        },
      }}
      color="indigo"
      value={item?.value.toLowerCase()}
      w="100%"
      label={
        <Group position="apart" noWrap w="100%">
          <Text lineClamp={1} fz="12px" fw={500} color="#0A122E">
            {item?.value}
          </Text>

          <Group noWrap position="right" w="60%"> 
            <Flex
              w="100%"
              h="8px"
              mr='3.45%' 
              sx={{ borderRadius: "8px", overflow: "hidden" }}
            >
           

              <ProgressBarPart
                widthPercentage={onlyPendingPercentage}
                color="#5C5CEB"
              />

              <ProgressBarPart
                widthPercentage={onlyBgPercentage}
                color="rgba(92, 92, 235, 0.165)"
              />

            </Flex>

            <Flex h={16} align="center">
          
             
              <>
                <Text fz={39} color="rgba(92, 92, 235, 0.6)" mb={22} w={10}>
                    .
                </Text>
                <Text
                  color="rgba(92, 92, 235, 0.6)"
                  fz="13px"
                  w={15}
                  align="center"
                >
                  {item?.matchesCount || 0}
                </Text>
              </>
              
            </Flex>
          </Group>
        </Group>
      }
    />
  );
};