import * as React from "react"
const RedIcon = (props:any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width}
    height={props.height}
    fill="none"
    {...props}
  >
    <circle cx={10} cy={10} r={10} fill="#FC0A0A" />
  </svg>
)
export default RedIcon
