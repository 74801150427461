import { Accordion, Box, Button, Flex, Grid, Text, Tooltip } from "@mantine/core";
import { IconCategory, IconCoin } from "@tabler/icons";
import React from "react";
import { useState, useContext } from "react";
import SyncContext from "../../../context/SyncContext";

export const AccountCard = (props: { company: any }) => {

  const { accountAdditionalInfo } = useContext(SyncContext);
  const company = props?.company;
  const customer = company?.name;
  const [show, setShow] = useState(true)

  const existingAdditionalFields = accountAdditionalInfo ? Object.keys(accountAdditionalInfo).filter(key => company[key]) : []
  return (
    <Accordion.Item value="account">
      <Accordion.Control bg="#F8F9FB" h={44} sx={{ borderRadius: "8px" }}>
        <Flex h={44} onClick={() => setShow(!show)}>
          <Flex
            justify="space-between"
            align="center"
            bg="#F8F9FB"
            p="md"
            pl={0}
            sx={{ borderRadius: "8px" }}
            w={"100%"} 
          >
            <Flex w={"100%"}  justify="space-between" align="center">
              {/* <IconCaretRight fill="" size={22} /> */}
              <Text>Account</Text>
              {customer && show && (
                <Button variant="light" compact color="gray" m={4}>
                  <Text fz={12} mr={2} color="black"></Text>
                  <Text color="black" fw="lighter" lineClamp={1}>
                    {" "}
                    {customer}
                  </Text>
                </Button>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Accordion.Control>
      <Accordion.Panel mb="md">
        <Grid>
          {company && company.arr && (
            <>
              <Grid.Col span={3}>
                <Text fz='14px' color="#6B7083" ml={5} lineClamp={1}>
                  Account ARR:{" "}
                </Text>
              </Grid.Col>
              <Grid.Col span={3}>
                <Flex
                  align="center"
                  justify="center"
                  p={6}
                  bg="#fff"
                  h={32}
                  sx={{ borderRadius: "10px" }}
                >
                  <Text mr={4} mt={5}>
                    <IconCoin color="black" size={18} />
                  </Text>
                  <Text fz='14px' color="black" fw="lighter" lineClamp={1}>
                    {" "}
                    {"$" + company.arr.toLocaleString()}
                  </Text>{" "}
                </Flex>
              </Grid.Col>
            </>
          )}
          {company && company.type && (
            <>
              <Grid.Col span={3}>
                <Text fz='14px' color="#6B7083"> Type: </Text>
              </Grid.Col>
              <Grid.Col span={3}>
                <Flex
                  align="center"
                  justify="center"
                  p={6}
                  bg="#fff"
                  h={32}
                  sx={{ borderRadius: "10px" }}
                >
                  <Text fz='14px' color="black" fw="lighter" lineClamp={1}>
                    {" "}
                    {company.type}
                  </Text>{" "}
                </Flex>
              </Grid.Col>
            </>
          )}
          {company && company.plan && (
            <>
              <Grid.Col span={3}>
                <Text fz='14px' color="#6B7083" ml={5}>
                  {" "}
                  Plan:{" "}
                </Text>
              </Grid.Col>
              <Grid.Col span={3}>
                <Flex
                  align="center"
                  justify="center"
                  p={6}
                  bg="#fff"
                  h={32}
                  sx={{ borderRadius: "10px" }}
                >
                  <Text mr={4} mt={5}>
                    <IconCategory color="black" size={14} />
                  </Text>
                  <Box>
                    <Tooltip
                      disabled={company.plan.length < 13}
                      label={company.plan}
                    >
                      <Text
                        fz='14px'
                        color="black"
                        fw="lighter"
                        lineClamp={1}
                        sx={
                          company.plan.length > 13
                            ? {
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              width: "95px",
                            }
                            : {}
                        }
                      >
                        {company.plan}
                      </Text>
                    </Tooltip>
                  </Box>
                </Flex>
              </Grid.Col>
            </>
          )}

          {company && company.tier && (
            <>
              <Grid.Col span={3}>
                <Text fz='14px' color="#6B7083" ml={5}>
                  {" "}
                  Tier:{" "}
                </Text>
              </Grid.Col>
              <Grid.Col span={3}>
                <Flex
                  align="center"
                  justify="center"
                  p={6}
                  bg="#fff"
                  h={32}
                  sx={{ borderRadius: "10px" }}
                >
                  <Box>
                    <Tooltip
                      disabled={company.tier.length < 13}
                      label={company.tier}
                    >
                      <Text
                        color="black"
                        fz='14px'
                        fw="lighter"
                        lineClamp={1}
                        sx={
                          company.tier.length > 13
                            ? {
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              width: "95px",
                            }
                            : {}
                        }
                      >
                        {company.tier}
                      </Text>
                    </Tooltip>
                  </Box>
                </Flex>
              </Grid.Col>
            </>
          )}
          {company && company.employeeCount && (
            <>
              <Grid.Col span={3}>
                <Text fz='14px' color="#6B7083" ml={5}>
                  {" "}
                  Employee count:{" "}
                </Text>
              </Grid.Col>
              <Grid.Col span={3}>
                <Flex
                  align="center"
                  justify="center"
                  p={6}
                  bg="#fff"
                  h={32}
                  sx={{ borderRadius: "10px" }}
                >
                  <Text fz='14px' color="black" fw="lighter" lineClamp={1}>
                    {company.employeeCount}
                  </Text>{" "}
                </Flex>
              </Grid.Col>
            </>
          )}
        </Grid>
        {existingAdditionalFields.length > 0 && (
          <>
            <Text fz='14px' ml={5} my="md">
              Additional info
            </Text>
            <Grid>
              {existingAdditionalFields.map((key: any) => {
                const field = accountAdditionalInfo[key]
                return <React.Fragment key={key}>
                  {" "}
                  <Grid.Col span={3}>
                    <Text fz='14px' color="#6B7083" ml={5} lineClamp={1}>
                      {field.label}:
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={3}>
                    <Flex
                      align="center"
                      justify="center"
                      p={6}
                      bg="#fff"
                      w={"100%"}
                      h={32}
                      sx={{borderRadius: "10px"}}
                    >
                      <Text fz='14px' color="black" fw="lighter" lineClamp={1}>
                        {company[key]}
                      </Text>{" "}
                    </Flex>
                  </Grid.Col>
                </React.Fragment>
              })}
            </Grid>
          </>
        )}
      </Accordion.Panel>
    </Accordion.Item>
  );
};

