import { Box, Title, Text, Anchor } from "@mantine/core";

const CsvImportHead = () => {
  return (
    <Box>
      <Title order={4}>Import potential evidence</Title>
      <Text size={"sm"}>
        How to{" "}
        <Anchor
          href="https://help.getbagel.com/en/articles/8756781-import-potential-evidence-via-csv"
          target="_blank"
        >
          Import potential evidence via CSV
        </Anchor>
      </Text>
    </Box>
  );
};

export default CsvImportHead;
