import {
  Anchor,
  Box,
  Flex,
  Modal,
  ScrollArea,
  Stack,
  Text,
  Tooltip,
} from "@mantine/core";
import { stripHtml } from "../../../../utils";
import { useContext, useEffect, useRef, useState } from "react";
import SyncContext from "../../../../context/SyncContext";
import { SourceIcon } from "../../../../utils/SourceIcon";
import { getRelatedTextItems } from "../../../../Api";
import { useAuth0 } from "@auth0/auth0-react";
import HourDisplay from "./HourDisplay";
import moment from "moment";

const GongCall = ({ item }: { item: any }) => {

  const { members } = useContext(SyncContext);
  const auth0 = useAuth0();
  const [opened, setOpened] = useState(false);
  const [gongCallParts, setGongCallParts] = useState<any[]>([]);
  const [bagelUsers, setBagelUsers] = useState<any[]>([]);
  const [externalUsers, setExternalUsers] = useState<any[]>([]);
  const [callDuration, setCallDuration] = useState<string>("");
  const scrollAreaRef = useRef(null);
  const excerptRef = useRef(null);
  const scrollToElement = (elementRef: any) => {
    if (elementRef.current) {
      elementRef.current.scrollIntoView({
        behavior: "smooth",
        inline: "center",
      });
    }
  };

  const textItem = item?.textItem || {};
  const excerptFirstPartMs = textItem?.excerptFirstPartMs;
  const excerptLastPartMs = textItem?.excerptLastPartMs;
  const title = textItem?.title || item?.title;
  const text = textItem?.text || item?.text;
  const excerpt = textItem?.excerpt;
  const cxItem = item?.chat;
  const system = cxItem?.system || "";
  const createdAtDate = cxItem?.createdAt
    ? new Date(cxItem?.createdAt)
    : new Date();
  const dateDisplay = moment(createdAtDate).format("MM-DD-YY")
  const origin = cxItem?.origin || item?.origin;
  
  const loadGongCall = (cxItemIdForGong: string) => {
    
    getRelatedTextItems(cxItemIdForGong, auth0)
      .then((res) => {

        const parties = res?.data?.cxItem?.parties || [];
        if (parties.length > 0) {
          parties.forEach((p: any) => {
            const mail = p?.emailAddress;
            const foundMember = members?.find((m: any) => m.email === mail);
            if (foundMember) {
              p.isBagelUser = true;
            }
          });
        }

        const bagelParties = parties?.filter((p: any) => p.isBagelUser);
        const externalParties = parties?.filter((p: any) => !p.isBagelUser);
        setBagelUsers(bagelParties);
        setExternalUsers(externalParties);
        const items = res?.data?.cxItem?.parts || {};

        const itemsArray: any[] = Object.values(items);
        if (itemsArray?.length > 0) {
          const length = itemsArray?.length;
          const durationMs = itemsArray[length - 1]?.startMs || 0;
          const duration = formatDuration(durationMs);
          setCallDuration(duration);
          itemsArray.map((itemCall: any) => {

            if(itemCall.startMs >= excerptFirstPartMs &&
              itemCall.startMs <= excerptLastPartMs){
              itemCall.isExcerpt = true 
            }
           
            if (itemCall.startMs === excerptFirstPartMs) {
              itemCall.isStart = true;
              itemCall.isExcerpt = true
            }

            if (itemCall.startMs === excerptLastPartMs) {
              itemCall.isEnd = true;
              itemCall.isExcerpt = true
            }
            
            const text = itemCall.text;
            const split = text.split(":");
            itemCall.cleanText = split[1];
            
          });
         
          setGongCallParts(itemsArray);
        }
      })
      .catch((e) => console.log(e));
  };

  const formatDuration = (milliseconds: number): string => {
    const totalMinutes = Math.floor(milliseconds / 60000);
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    if (hours > 0) {
      return `${hours}h ${minutes}m`;
    } else {
      return `${minutes}m`;
    }
  };

  useEffect(() => {

    const status = item?.status;
    const isMatch = status === "match";
    const isFromSearch = status === "search";
    const itemId = item?.itemId;
    const chatId = item?.chatId;
    const parentId = item?.parentId;
    const cxItemId = isMatch ? chatId : isFromSearch ? parentId : itemId;

    if(cxItemId){
      loadGongCall(cxItemId);
    }
  }, [item]);

  return (
    <Box mt={15}>
      <Modal
        opened={opened}
        zIndex={234}
        centered 
        onClose={() => setOpened(false)}
        overlayOpacity={0}
        size="750px"
        padding={0}
        styles={{
          close: {
            marginRight: '40px', // Adjust margin for the close button
            marginTop: '16px'
          },
        }}
        title={
          <Flex align="center" h={30} mx="xs"  w="100%" p='lg' mt={16}>
            {origin && (
              <Tooltip label={origin || "N/A"}>
                <Stack h={20} w={20} justify="center" align="center">
                  <SourceIcon sourceName={origin?.toLowerCase() || "N/A"} />
                </Stack>
              </Tooltip>
            )}
            <Flex pl={25} align="center">
              <Text fz={17} maw={470} w={470} fw={600} lineClamp={1}>
                {`Call Transcript : ${cxItem?.title || 'N/A'}`} 
              </Text>
            </Flex>
          </Flex>
        }
      >
        <Box
          sx={{ borderTop: "1px solid #D8D8DB" }} w="100%">
          <ScrollArea
            w={720}
            h={542}
            mah={542}
            p={36} 
            offsetScrollbars
            type="always"
            viewportRef={scrollAreaRef}
          >
            <Box>
              <Box mb="md" w={480} px='sm'>
                <Flex mb="sm">
                  <Text mr={5} color="#5A5A5A">Recorded on </Text>
                  <Text mr={5} color="#5A5A5A">{dateDisplay} </Text>
                  <Text mr={5} color="#5A5A5A">via </Text>
                  <Text mr={5} color="#5A5A5A">{system}, </Text>
                  <Text color="#5A5A5A">{callDuration} </Text>
                </Flex>

                <Text color="#5A5A5A" mb='4px'>Participants:</Text>
                <Flex>
                  <Text w={86}>
                    {" "}
                  Getbagel:{" "}
                  </Text>

                  {/* bagelUsers */}

                  <Box w={400}>
                    {bagelUsers &&
                    bagelUsers?.length > 0 &&
                    bagelUsers?.map((user: any, index: number) => (
                      <Box>
                        {user?.name && <Text mr="3px" color="#5A5A5A">{user?.name || 'N/A'}</Text>}
                        {user?.emailAddress && <Flex>
                          <Text color="#5A5A5A">({user?.emailAddress || 'N/A'})</Text>
                          {index !== bagelUsers?.length - 1 && <Text color="#5A5A5A">,</Text>}
                        </Flex>}
                        
                      </Box>
                    ))}{" "}
                  </Box>
                </Flex>

                {/* externalUsers */}

                <Flex>
                  <Text w={86}>
                    {" "}
                  External:
                  </Text>
                  <Box w={400}>
                    {externalUsers &&
                    externalUsers?.length > 0 &&
                    externalUsers?.map((user: any, index: number) => (
                      <Box>
                        {user?.name && <Text mr="3px" color="#5A5A5A">{user?.name || 'N/A'}</Text>}
                        {user?.emailAddress && <Flex>
                          <Text color="#5A5A5A"> ({user?.emailAddress || 'N/A'})</Text>
                          {index !== externalUsers?.length - 1 && <Text color="#5A5A5A">,</Text>}
                        </Flex>}
                        

                      </Box>
                    ))}{" "}
                  </Box>
                </Flex>
              </Box>

              {/*  excerpt */}

              {excerptFirstPartMs && <Text
                color="#5C5CEB"
                px='sm'
                my="md"
                sx={{ cursor: "pointer" }}
                onClick={() => scrollToElement(excerptRef)}
                underline
              >
                Jump to excerpt
              </Text>}

              {gongCallParts &&
            gongCallParts?.length > 0 ? (
                  gongCallParts?.map((callItem: any) => (
                    <Box
                      w={620}
                      px='sm'
                      bg={callItem.isExcerpt ? "#FFFDD4" : ""}
                      ref={callItem.isStart ? excerptRef : null}
                      py='xs'
                      sx={{borderRadius: '6px'}}
                    >
                      <Flex>
                        <Stack align="start" spacing={0}>
                          <HourDisplay
                            date={createdAtDate}
                            milliseconds={callItem?.startMs}
                            link={callItem?.link}
                          />
                        </Stack>
                        <Text fw={400} color="dimmed" fz={13}>
                          {callItem?.speakerName || callItem?.speakerEmail  || ""}
                        </Text>
                      </Flex>
                      <Stack align="start" spacing={0} ml={50} mt='3px'>
                        <Text align="left" w={540} fz={14}>
                          {callItem?.cleanText || ""}
                        </Text>
                      </Stack>

                    </Box>
                  ))
                ) : (
                  <Flex justify='center'><Text ml={80}>Gong call not available</Text></Flex>
                )}

              {gongCallParts?.length > 0 && <Flex justify='center' fw={600} fz={18} m='md'>The End</Flex>}
            </Box>
          </ScrollArea>
        </Box>
      </Modal>
      {title && (
        <Text fz={14} fw={600} lineClamp={1} mb={14}>
          {title}
        </Text>
      )}
      <ScrollArea
        mt={15}
      >
        {text && (
          <Box
            py={8}
            px={12}
            bg="#F8F9FA"
            sx={{ borderLeft: "2px solid rgba(92, 92, 235, 0.6)" }}
          >
            <Text
              fz={12}
              fw={600}
              color="#585C68"
              sx={{
                whiteSpace: "pre-line",
              }}
            >
              {stripHtml(text)}
            </Text>
          </Box>
        )}
        {excerpt && excerpt !== "" && (
          <Box
            py={8}
            px={12}
            bg="#F8F9FA"
            sx={{ borderLeft: "2px solid rgba(92, 92, 235, 0.6)" }}
          >
            <Box fz={12}>
              <Text
                mb="5px"
                fw={600}
                style={{ color: "black", fontSize: "12.5px" }}
              >
                Excerpt:{" "}
              </Text>
              <Text
                sx={{
                  whiteSpace: "pre-line",
                }}
              >
                {stripHtml(excerpt)}
              </Text>
            </Box>
          </Box>
        )}
      </ScrollArea>
      {
        <Box
          w="100%"
          py={8}
          px={12}
          bg="#F8F9FA"
          sx={{ borderLeft: "2px solid rgba(92, 92, 235, 0.6)" }}
        >
          <Text
            mt={10}
            fz={13}
            color="#5C5CEB"
            sx={{
              cursor: "pointer",
            }}
            onClick={() => setOpened(true)}
          >
            View call transcript
          </Text>
        </Box>
      }
    </Box>
  );
};

export default GongCall;

