import { Avatar, Box, Flex, Tooltip, Text } from "@mantine/core";
import { getInitials } from "../../../../utils/getInitials";
import ReactTimeAgo from "react-time-ago";
import { stripHtml } from "../../../../utils";

const TicketCommentItem = ({ sortedItem }: { sortedItem: any }) => {

  return (
    <>
      {sortedItem.text && (
        <Flex ml={35} mt={5}>
          <Box mr="xs" w="33px" pt="4.4px">
            {sortedItem?.fromUser?.picture ? (
              <Tooltip
                label={
                  sortedItem?.fromUser?.name || sortedItem?.fromName || "N/A"
                }
              >
                <Avatar
                  src={sortedItem?.fromUser?.picture}
                  radius="xl"
                  size={40}
                >
                  {sortedItem?.fromName &&
                    getInitials(sortedItem?.fromName || "")}
                </Avatar>
              </Tooltip>
            ) : (
              <Tooltip
                label={
                  sortedItem?.fromUser?.name || sortedItem?.fromName || "Unknown user"
                }
              >
                <Avatar radius="xl" size={40}  color="dark" />
              </Tooltip>
            )}
          </Box>
          <Box px={10} py={8} sx={{ borderRadius: "11px" }}>
            <Flex h={25} justify="space-between" w={600} mb={8}>
              <Flex>
                <Flex>
                  {sortedItem?.fromName ? (
                    <Text fw={600} fz={16} >
                      {sortedItem?.fromName}
                    </Text>
                  ): 
                    <Text fw={600} fz={16} h={25}>
                     
                    </Text>}
                  {sortedItem?.fromEmail ? (
                    <Text fz={16} h={25}>{`<${sortedItem?.fromEmail}>`}</Text>
                  ): <Text fz={16} h={25}></Text>}
                </Flex>
              </Flex>
              {
                sortedItem.createdAt && (
                  <Text size="xs" c="dimmed">
                    {sortedItem?.createdAt && (
                      <ReactTimeAgo title="" date={new Date(sortedItem?.createdAt)} />
                    )}
                  </Text>
                )}
            </Flex>
            <Flex h={25} mb={4}>
              {sortedItem?.toName ? (
                <>
                  <Text fw={600} fz={16} mr="xs" >
                    To:
                  </Text>
                  <Text fw={600} fz={16} h={25}>
                    {sortedItem?.toName}
                  </Text>
                </>
              ) : <Text fw={600} fz={16}> </Text>
              }
              {sortedItem?.toEmail ? (
                <Text fz={16} h={25}>{`<${sortedItem?.toEmail}>`}</Text>
              ):<Text fz={16} h={25}></Text>}
            </Flex>
            <Flex justify="space-between" fz={16} mt={7} mb={16}>
              <Text w={470}>{stripHtml(sortedItem?.text)}</Text>
              {/* {!sortedItem?.fromName &&
                !sortedItem?.fromEmail &&
                sortedItem.createdAt && (
               
                <Text size="xs" c="dimmed">
                  <ReactTimeAgo title="" date={new Date(sortedItem.createdAt)} />
                </Text>
             
              )} */} 
            </Flex>
          </Box>
        </Flex>
      )}
    </>
  );
};

export default TicketCommentItem;
