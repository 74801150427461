import { useState, useEffect } from "react";
import { Menu, Button, TextInput, Select, UnstyledButton } from "@mantine/core";
import { IconUserPlus } from "@tabler/icons";
import { useAuth0 } from "@auth0/auth0-react";
import { inviteUser } from "../../Api";
import { getConnectedUserRole } from "../../utils";

const InviteMembersDropdown = () => {
  const auth0 = useAuth0();
  const [inviteName, setInviteName] = useState<string>("");
  const [inviteEmail, setInviteEmail] = useState<string>("");
  const [inviteRole, setInviteRole] = useState<string | null>("agent");

  const submitInvite = () => {
    if (!inviteRole) {
      
      return;
    }
    const user = {
      name: inviteName,
      email: inviteEmail,
      role: inviteRole,
    };
    inviteUser(auth0, user)
      .then(() => {
        console.log("succees");
      })
      .catch((e) => console.log(e.error));
  };
  const role = getConnectedUserRole(auth0);
  const roles = [
    { value: "admin", label: "Admin", disabled: false },
    { value: "manager", label: "Manager", disabled: false },
    { value: "agent", label: "Agent", disabled: false },
  ];

  return (
    <Menu width={300} shadow="md" position={"bottom-end"} withArrow>
      <Menu.Target>
        <UnstyledButton>
          <IconUserPlus fill="#363699" color="#363699" size={26} />
        </UnstyledButton>
      </Menu.Target>
      <Menu.Dropdown p={"md"}>
        <TextInput
          value={inviteName}
          onChange={(event: any) => setInviteName(event.currentTarget.value)}
          label="Name"
          placeholder="Name"
          size="xs"
        />
        <TextInput
          value={inviteEmail}
          onChange={(event: any) => setInviteEmail(event.currentTarget.value)}
          label="Email"
          placeholder="john@doe.com"
          size="xs"
          mt="xs"
        />
        <Select
          value={inviteRole}
          label="Role"
          onChange={setInviteRole}
          data={roles}
          mt={"md"}
        />
        <Menu.Item p={0} mt={"md"}>
          <Button
            onClick={submitInvite}
            bg={"#5C5CEA"}
            fullWidth
            sx={{
              fontWeight: 700,
              ":hover": { backgroundColor: "#9f9af8" },
            }}
            color={"#fff"}
          >
            Invite User
          </Button>
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

export default InviteMembersDropdown;
