import React from "react";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Auth0Provider } from "@auth0/auth0-react";
import { MantineProvider } from "@mantine/core";
import { createRoot } from "react-dom/client";
import { NotificationsProvider } from "@mantine/notifications";
import posthog from 'posthog-js';
import { PostHogProvider } from "posthog-js/react";

import FullStory from "react-fullstory";

// const options = {
//   api_host: window.__RUNTIME_CONFIG__.REACT_APP_PUBLIC_POSTHOG_HOST,
// };

if (typeof window !== 'undefined') {
  posthog.init(window.__RUNTIME_CONFIG__.REACT_APP_PUBLIC_POSTHOG_KEY || '', {
    api_host: window.__RUNTIME_CONFIG__.REACT_APP_PUBLIC_POSTHOG_HOST,
    disable_session_recording: true,
    session_recording: {
      maskAllInputs: false,
      maskInputOptions: {
        password: true, // Highly recommended as a minimum!!
        color: false,
        date: false,
        'datetime-local': false,
        email: false,
        month: false,
        number: false,
        range: false,
        search: false,
        tel: false,
        text: false,
        time: false,
        url: false,
        week: false,
        textarea: false,
        select: false,
      }
    },
    loaded: (posthog) => {
      // if (process.env.NODE_ENV != 'development') 
      posthog.startSessionRecording()
    }
  })
}


const container = document.getElementById("root");
const root = createRoot(container!);
const audience = window.__RUNTIME_CONFIG__.REACT_APP_AUTH0_AUDIENCE || "";
root.render(
  <React.StrictMode>
    <Auth0Provider
      domain={
        window.__RUNTIME_CONFIG__.REACT_APP_AUTH0_ISSUER_BASE_URL
          ? window.__RUNTIME_CONFIG__.REACT_APP_AUTH0_ISSUER_BASE_URL
          : ""
      }
      clientId={
        window.__RUNTIME_CONFIG__.REACT_APP_AUTH0_CLIENT_ID
          ? window.__RUNTIME_CONFIG__.REACT_APP_AUTH0_CLIENT_ID
          : ""
      }
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience,
      }}
      cacheLocation={"memory"}
      skipRedirectCallback={window.location.pathname === "/newIntegration"}
    >
      <MantineProvider withGlobalStyles withNormalizeCSS theme={{
        fontFamily: 'Inter', 
   
      }}>
        <NotificationsProvider>
          <PostHogProvider client={posthog}>
            <App />
          </PostHogProvider>
        </NotificationsProvider>
      </MantineProvider>
    </Auth0Provider>
  </React.StrictMode>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
