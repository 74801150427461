import { Flex, Menu, Radio, Text } from "@mantine/core";
import { IconArrowDown, IconArrowNarrowDown } from "@tabler/icons";
import React, { useContext } from "react";
import SortItem from "./SortItem";
import IdeaContext from "../../IdeaContext";

const Sort = () => {
  const sortArr = [
    { label: "Business name", value: "businessName" },
    { label: "Updated at", value: "updatedAt" },
  ];

  const { activeSort, setActiveSort } = useContext(IdeaContext);

  return (
    <Menu>
      <Menu.Target>
        <Flex
          ml="8px"
          sx={{
            borderRadius: "6px",
            border: "1px solid #D8D8DB",
            cursor: "pointer",
          }}
          px={10}
          h={32}
          color="white"
          align="center"
        >
          <IconArrowDown size={16} />
          <Text fz="14px" pl={8}> Sort </Text>
        </Flex>
      </Menu.Target>
      <Menu.Dropdown>
        <Radio.Group
          orientation="vertical"
          spacing={5}
          value={activeSort?.column}
          onChange={value => setActiveSort({ column: value, direction: 1 })}
          p={4} 
          sx={{
            ".mantine-Stack-root":{
              paddingTop: 0
            }
          }}
        >
          {sortArr.map((item, index) => (
            <SortItem
              item={item}
              key={index}
            />
          ))}
        </Radio.Group>
      </Menu.Dropdown>
    </Menu>
  );
};

export default Sort;
