import { createContext, useCallback, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { ChildProps } from "../../types/types";
import { useParams } from "react-router-dom";
import {
  getIdea,
  getIdeaMatches,
  getIdeaRequests,
  getRequestsMatchesForAnIdea,
  getUnreadNotificationsCount,
} from "../../Api";
import { getCalculatedPerspectiveForRequest } from "./helpers/aggregateRequestCompanyData";
import { useLocalStorage } from "@mantine/hooks";


export interface SortState {
  column: string;
  direction: number;  // 1 for ascending, -1 for descending
}

interface IdeaContextType {
  idea: any;
  allRequests: any[];
  ideaId: string | undefined;
  matches: any[];
  computed: string;
  matchesLoading: boolean;
  matchesCount: number;
  requestsCount: number;
  loadIdea: () => void;
  pendingRequests: any[];
  validatedRequests: any[];
  ideaBusinessSources: any;
  loadEvidence: () => void;
  displayedRequests: any[];
  setDisplayedRequests: React.Dispatch<React.SetStateAction<any[]>>;
  tierData: any;
  accountTypesData: any;
  plansData: any;
  perspectiveData: { [key: string]: any };
  setPerspectiveData: React.Dispatch<
  React.SetStateAction<{ [key: string]: any }>>;
  loadingEvidence: boolean;
  roundChartsData: any;
  type: string;
  setType: React.Dispatch<React.SetStateAction<string>>;
  setshowmatches: (val: boolean | ((prevState: boolean) => boolean)) => void;
  showmatches: boolean;
  setShowValidated: (val: boolean | ((prevState: boolean) => boolean)) => void;
  showValidated: boolean;
  unreadNotificationsLoading: boolean;
  unreadNotificationsMap: any[];
  getUnreadNotificationsCountByRequest: (requestId: string) => any;
  reCalculatPerspectiveForRequest: (request: any, action: "init" | "add" | "update" | "remove") => void;
  addEvidenceFormScreen: boolean;
  setAddEvidenceFormScreen: React.Dispatch<React.SetStateAction<boolean>>;
  cardView: boolean;
  setCardView: React.Dispatch<React.SetStateAction<boolean>>;
  chosenEvidence: any | null;
  setChosenEvidence: React.Dispatch<any | null>;
  displayedMatches: any[];
  setDisplayedMatches: React.Dispatch<React.SetStateAction<any[]>>;
  activeSort: SortState | null;
  setActiveSort: React.Dispatch<React.SetStateAction<SortState | null>>;
  matchesTimer: () => void;
  matchesTrigger: number;
  getMatchesUpdate: () => void;
  loadingIdea: boolean;
  setMatchesTrigger: React.Dispatch<React.SetStateAction<number>>;
  triggerImpact: boolean
  setTriggerImpact: React.Dispatch<React.SetStateAction<boolean>>;
  requestsCompanies: any[];
  matchesCompanies: any[];
  requestsMatches: any[];
  displayedRequestsMatches: any[];
  setDisplayedRequestsMatches: React.Dispatch<React.SetStateAction<any[]>>;
  requestsMatchesCompanies: any[];
  displayedPendingRequests: any[];
  displayedValidatedRequests: any[];
  showPending: boolean;
  setShowPending: React.Dispatch<React.SetStateAction<boolean>>;
  activeInboxTab: boolean;
  setActiveInboxTab: React.Dispatch<React.SetStateAction<boolean>>;
}

const IdeaContext = createContext<IdeaContextType>({} as IdeaContextType);

const IdeaContextProvider = ({ children }: ChildProps) => {
  const auth0 = useAuth0();
  const { id: ideaId } = useParams();
  const [idea, setIdea] = useState<any | null>(null);
  const [title, setTitle] = useState("");
  const [matches, setMatches] = useState<any[]>([]);
  const [matchesLoading, setMatchesLoading] = useState<boolean>(true);
  const [matchesCount, setMatchesCount] = useState(0);
  const [displayedMatches, setDisplayedMatches] = useState<any[]>([]);
  // const [showmatches, setshowmatches] = useState(true);
  const [showmatches, setshowmatches] = useLocalStorage({ key: 'showmatches', defaultValue: true });
  const [showValidated, setShowValidated] = useState<boolean>(true);
  const [showPending, setShowPending] = useState<boolean>(false);
  const [requestsLoading, setRequestsLoading] = useState<boolean>(false);
  const [pendingRequests, setPendingRequests] = useState<any[]>([]);
  const [displayedPendingRequests, setDisplayedPendingRequests] = useState<any[]>([]);
  const [requestsCount, setRequestsCount] = useState(0);
  const [validatedRequests, setValidatedRequests] = useState<any[]>([]);
  const [displayedValidatedRequests, setDisplayedValidatedRequests] = useState<any[]>([]);
  const [displayedRequests, setDisplayedRequests] = useState<any[]>([]);
  const [allRequests, setAllRequests] = useState<any[]>([]);
  const [computed, setComputed] = useState("");
  const [ideaBusinessSources, setIdeaBusinessSources] = useState<any>([]);
  const [tierData, setTierData] = useState<any>({});
  const [accountTypesData, setAccountTypesData] = useState<any>({});
  const [plansData, setPlansData] = useState<any>({});
  const [perspectiveData, setPerspectiveData] = useState<{
    [key: string]: any;
  }>({
    "Customer Tier": tierData,
    "Account Type": accountTypesData,
    "Customer Plan": plansData,
    "Business Sources": ideaBusinessSources,
  });
  const [loadingEvidence, setLoadingEvidence] = useState(true);
  const [roundChartsData, setRoundChartsData] = useState<any>({});
  const [type, setType] = useState("Business Sources");
  const [unreadNotificationsLoading, setUnreadNotificationsLoading] =
    useState<boolean>(false);
  const [unreadNotificationsMap, setUnreadNotificationsMap] = useState<any[]>(
    []
  );
  const [addEvidenceFormScreen, setAddEvidenceFormScreen] = useState(false);
  const [cardView, setCardView] = useState(false);
  const [chosenEvidence, setChosenEvidence] = useState<any>(null);
  const [activeSort, setActiveSort] = useState<SortState | null>(null);
  const [matchesTrigger, setMatchesTrigger] = useState(0);
  const [loadingIdea, setLoadingIdea] = useState(true);
  const [triggerImpact, setTriggerImpact]= useState(false);
  const [requestsCompanies, setRequestsCompanies] = useState<any[]>([])
  const [matchesCompanies, setMatchesCompanies] = useState<any[]>([])
  const [requestsMatches, setRequestsMatches] = useState<any[]>([])
  const [displayedRequestsMatches, setDisplayedRequestsMatches] = useState<any[]>([])
  const [requestsMatchesCompanies, setRequestsMatchesCompanies] = useState<any[]>([])
  const [activeInboxTab, setActiveInboxTab] = useState(false)

  const loadIdea = async () => {
    if (!ideaId) {
      return false;
    }

    const res = await getIdea(ideaId, auth0);
    setLoadingIdea(false)
    setIdea(res);
    setTitle(res.title);

    /* console. */
    // const perspectives = res.perspectives;
    // setPerspectiveData((prev) => ({
    //   ...prev,
    //   "Customer Tier": perspectives?.tiers,
    //   "Account Type": perspectives?.accountTypes,
    //   "Customer Plan": perspectives?.plans,
    //   "Business Sources": perspectives?.businessCategories,
    // }));
    // setTierData(perspectives?.tiers);
    // setAccountTypesData(perspectives?.accountTypes);
    // setPlansData(perspectives?.plans);
    // setIdeaBusinessSources(perspectives?.businessCategories);
    if (res.matchesComputeStatus === "done") {
      setComputed("done");
    }
  };

  const getAllRequests = async () => {
    /* console.log(" filters- getAllRequests called"); */
    if (!ideaId) {
      return false;
    }
    setMatchesLoading(true);
    const res = await getIdeaRequests(ideaId, auth0);
    const allRequestsRes = res.requests || [];
    const validated = allRequestsRes.filter((request: any)=> request?.status === 'validated')
    if(validated.length > 0 ){
      setValidatedRequests(validated)
      setDisplayedValidatedRequests(validated)
    }
    const newRequests = allRequestsRes.filter((request: any)=> request?.status === 'new')
    if(newRequests.length > 0 ){
      setPendingRequests(newRequests)
      setDisplayedPendingRequests(newRequests)
    }
    

    const requestsOrgs = allRequestsRes.map((a: any)=> a = a.company).filter((company: any)=> company._id !== 'N/A')

    setRequestsCompanies(requestsOrgs)
    setAllRequests(allRequestsRes);

    setValidatedRequests(allRequestsRes);
   
    if (allRequestsRes?.length > 0) {
      setDisplayedRequests(allRequestsRes)
    }
    setLoadingEvidence(false);
    // setRoundChartsData(res?.roundChartsData)
    setMatchesLoading(false);
    /* console.log(res?.businessCategories); */
  };

  const getUnreadNotifications = async () => {
    if (!ideaId) {
      return false;
    }
    setUnreadNotificationsLoading(true);
    await getUnreadNotificationsCount(ideaId, auth0)
      .then(({ requests }) => {
        setUnreadNotificationsMap(requests);
      })
      .catch((e) => console.log(e))
      .finally(() => setUnreadNotificationsLoading(false));
  };

  const getUnreadNotificationsCountByRequest = (requestId: string) => {
    return (
      unreadNotificationsMap.find((request) => request._id == requestId)
        ?.unreadNotificationsCount || 0
    );
  };

  const getMatches = async () => {
    if (ideaId) {
      setMatchesLoading(true);
      const res = await getIdeaMatches(ideaId, auth0);
      const matches = res?.matches || [];
      const matchesOrgs = res?.matches?.map((a: any)=> a = a.company).filter((company: any)=> company._id !== 'N/A')

      setMatchesCompanies(matchesOrgs)
      setMatches(matches);
      setDisplayedMatches(matches);
      setMatchesCount(matches?.length);
      setMatchesLoading(false); 
      

    }
  };

  const loadEvidence = async () => {
    return await getAllRequests()
      .then(async () => {
        await getMatches().catch((e) => console.log(e))
        await getRequestsMatches().catch((e) => console.log(e));
      })
      .catch((e) => console.log(e));
  };

  const reCalculatPerspectiveForRequest = useCallback(
    (request: any, action: "init" | "add" | 'update' | "remove") => {


      /* console.log('calculating') */
      const matchesMapped = matches.map(
        (m: any) => (m = { ...m, status: "match", source: "suggestion" })
      );
      const requestsMatchesMapped = requestsMatches.map(
        (m: any) => (m = { ...m, status: "match", source: "suggestion" })
      );
      
      const allRequestsMapped = allRequests.map(
        (a: any, index: number) =>
          (a = {
            ...a,
            businessName: a?.textItem?.businessName || a?.businessName || "N/A",
            sortInit: index,
          })
      );

      let requestsToCalculate: any[] = matchesMapped.concat(requestsMatchesMapped).concat(allRequestsMapped);

      if (action === "remove") {
        requestsToCalculate = requestsToCalculate.filter(
          (r) => r._id !== request._id
        );
      } else if (action === "add") {
        requestsToCalculate = requestsToCalculate.concat(request);
      } else if (action === "update") {
        requestsToCalculate = requestsToCalculate.map(r =>
          r._id === request._id ? { ...r, ...request } : r
        );
      }

      getCalculatedPerspectiveForRequest(requestsToCalculate)
        .then((data) => {
          /* console.log("getCalculatedPerspectiveForRequest", { requestsToCalculate, data }); */

          /* console.log({ getCalculatedPerspectiveForRequest: data }); */
          setTierData(data.tierData);
          setAccountTypesData(data.typeData);
          setPlansData(data.planData);
          setIdeaBusinessSources(data.businessNameData);

          setPerspectiveData((prev) => ({
            ...prev,
            "Customer Tier": data.tierData,
            "Account Type": data.typeData,
            "Customer Plan": data.planData,
            "Business Sources": data.businessNameData,
          }));
        })
        .catch((error) => {
          console.error("Error recalculating company data:", error);
        });
    },
    [allRequests, matches, requestsMatches]
  );

  const getMatchesUpdate = () => {
    if (ideaId && matchesTrigger > 0) {
      getIdeaMatches(ideaId, auth0)
        .then((res: any) => {
          setMatches(res?.matches || []);
          
          const matchesOrgs = res?.matches?.map((a: any)=> a = a.company).filter((company: any)=> company._id !== 'N/A')

          setMatchesCompanies(matchesOrgs)

          setDisplayedMatches(res?.matches || []);
          setMatchesCount(res?.matches?.length || 0);
          setComputed(res?.computed || "");
          
        })
        .catch((e: any) => console.log(e))
        .finally(() => {
          setMatchesLoading(false);
        });
    } 
  };

  const getRequestsMatches = async ()=>{
    ideaId && getRequestsMatchesForAnIdea(ideaId, auth0).then((res)=>{
      console.log(res)
      setRequestsMatches(res)
      setDisplayedRequestsMatches(res)
      const mapped = res?.map((a: any)=> a = a.company).filter((company: any)=> company._id !== 'N/A')
      setRequestsMatchesCompanies(mapped)
    })
  }

  const matchesTimer = () => {
    setTimeout(
      () => {
        setMatchesTrigger(matchesTrigger + 1);
      },
      matchesTrigger < 2 ? 10000 : 60000
    );
  };

  

  useEffect(() => {
    async function fetchData() {
      try {
        await loadIdea();
        await loadEvidence();
        
        setDisplayedMatches(matches);
      } catch (e) {
        console.log(e);
      }
      getUnreadNotifications();
    }
    fetchData();
  }, [ideaId]);
  
  //TODO remove on aggregations fix
  useEffect(() => {
    reCalculatPerspectiveForRequest({}, "init")
  }, [allRequests, matches, requestsMatches]);

  return (
    <>
      <IdeaContext.Provider
        value={{
          idea,
          allRequests,
          ideaId,
          matches,
          computed,
          matchesLoading,
          matchesCount,
          requestsCount,
          loadIdea,
          pendingRequests,
          validatedRequests,
          ideaBusinessSources,
          loadEvidence,
          displayedRequests,
          setDisplayedRequests,
          tierData,
          accountTypesData,
          plansData,
          perspectiveData,
          setPerspectiveData,
          loadingEvidence,
          roundChartsData,
          type,
          setType,
          showmatches,
          setshowmatches,
          showValidated, 
          setShowValidated,
          unreadNotificationsLoading,
          unreadNotificationsMap,
          getUnreadNotificationsCountByRequest,
          reCalculatPerspectiveForRequest,
          addEvidenceFormScreen,
          setAddEvidenceFormScreen,
          cardView,
          setCardView,
          chosenEvidence,
          setChosenEvidence,
          displayedMatches,
          setDisplayedMatches,
          activeSort,
          setActiveSort,
          matchesTimer,
          matchesTrigger,
          getMatchesUpdate,
          loadingIdea,
          setMatchesTrigger,
          triggerImpact, 
          setTriggerImpact,
          requestsCompanies,
          matchesCompanies, 
          requestsMatches,
          displayedRequestsMatches, 
          setDisplayedRequestsMatches,
          requestsMatchesCompanies,
          displayedPendingRequests,
          displayedValidatedRequests,
          showPending,
          setShowPending,
          activeInboxTab,
          setActiveInboxTab
          
        }}
      >
        {children}
      </IdeaContext.Provider>
    </>
  );
};

export { IdeaContext, IdeaContextProvider };
export default IdeaContext;
