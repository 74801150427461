import { Flex, Text } from '@mantine/core';
import ReactTimeAgo from 'react-time-ago';

const BodyHeader = ({ item }: { item: any }) => {

  const submitter =
    (item?.internalSubmitter || item?.cxSubmitter) ??
    item?.submitter;
  const submitterName = submitter ? submitter?.name || submitter?.displayName : item?.submitterEmail; 
  const businessName : string = item?.textItem?.businessName || item?.businessName
  const updatedAt = item?.updatedAt || item?.textItem?.updatedAt
  const status = item?.status

  return (
    <Flex justify='space-between' h={16}>
      <Flex>
        {businessName && 
      <Text px={4} color='#5C5CEB' h='16px' fz='10px' bg='rgba(92, 92, 235, 0.2)'>
        {businessName.toUpperCase()}
      </Text>}
        {status !== 'match' && <Text ml='3px' h='16px' fz='10px'>Submitted by: {submitterName || 'N/A'}</Text>}
      </Flex>
      

      {updatedAt && (
        <Text ml={-4} size="xs" c="dimmed">
          <ReactTimeAgo date={new Date(updatedAt)} />
        </Text>
      )}
    </Flex>
  )
}

export default BodyHeader