import { useAuth0 } from "@auth0/auth0-react";
import { Box, Button, Flex, Menu, Text, Tooltip } from "@mantine/core";
import { closeAllModals, openModal } from "@mantine/modals";
import { useContext, useEffect, useState } from "react";
import {
  dismissSuggested,
  validateMatch,
  saveRequest,
  saveMatch
} from "../../../Api";
import LogoIcon from "../../layouts/LogoIcon";
import { DeclineModalContent } from "../RightBar/DeclineModalContent";
import { getConnectedUserEmail, getConnectedUserRole } from "../../../utils";
import { SourceIcon } from "../../../utils/SourceIcon";
import {
  IconAlertOctagon,
  IconCategory,
  IconCheck,
  IconCoin,
  IconUsers,
  IconX,
} from "@tabler/icons";
import { ProductAreaIcon } from "../../../icons/x-symbol-svgrepo-com";
import { capitalizeFirstLetter } from "../../../utils/capitalizeFirstLetter";
import SyncContext from "../../../context/SyncContext";
import BagelAutocomplete from "../../general/BagelAutocomplete";
import { showNotification } from "@mantine/notifications";
import { UseListStateHandlers } from "@mantine/hooks";
import { formatMonetaryValue } from "../../ideaNiv/Funcs";

export function Actions({
  idea,
  request,
  requestsHandlers,
  setOpened,
  onUpdated,
  ownerInModal,
  updateOwner,
  productAreaNameInModal,
  setComponentIdModal,
  componentIdInModal,
  updateProdudtArea,
  editOwner,
  setMatches,
  matches,
  displayProductAreaMsg,
  setDisplayProductAreaMsg
}: {
  idea: any;
  request: any;
  requestsHandlers?: UseListStateHandlers<any[]> | undefined;
  onUpdated: (useLoading?: boolean) => void;
  setOpened: ((bool: boolean) => void) | undefined;
  setValidatedRequests: React.Dispatch<React.SetStateAction<any>>;
  customizations: any;
  ownerInModal: any;
  updateOwner: (owner: any) => void;
  productAreaNameInModal: any;
  componentIdInModal: any;
  setComponentIdModal: React.Dispatch<any>
  updateProdudtArea: (productArea: string, componentId: string) => void;
  editOwner: (owner: any) => void;
  setMatches: React.Dispatch<React.SetStateAction<any>>;
  matches: any;
  displayProductAreaMsg: boolean;
  setDisplayProductAreaMsg: any
}) {
  const {
    components,
    requestPriorities,
    priorityData,
    evidenceDomain,
    domains,
    defaultRequestPriority,
    allComponents,
    owners
  } = useContext(SyncContext);

  const copy = [...allComponents]
  const allComponentsIds = copy.map((c: any) => c = c._id)
  const auth0 = useAuth0();
  const [background, setBackground] = useState("");
  const [productAreaName, setProductAreaName] = useState<any>(productAreaNameInModal);
  const [color, setColor] = useState("");
  const [priorityCapital, setPriorityCapital] = useState("");
  const [domain, setDomain] = useState<any>(evidenceDomain ? request?.componentObj?.domain : null);
  const [ownerName, setOwnerName] = useState<any>(
    ownerInModal?.name || 'Unassigned' 
  );
  console.log({componentIdInModal})

  const [suggestedComponent, setSuggestedComponent] = useState<boolean>(request?.suggestedComponent)
  const [intialValueSuggested, setInitialValueSuggested] = useState<boolean>(request?.suggestedComponent)
  const [loading, setLoading] = useState({
    decline: false,
    validate: false,
  });
  const [open, setOpen] = useState(false)
  const priority = request.priority || null;
  const [mandatoryMessage, setMandatoryMessage] = useState<boolean>(false);
  const isMatch = request.modalType === "match";
  const [componentsByDomain, setComponentsByDomain] = useState<any[]>(evidenceDomain? [] : components);
  const domainsNames = domains.map((d: any) => d.name)
 
  const openDeclineModal = () => {
    setOpened && setOpened(false);
    openModal({
      modalId: "decline",
      title: <LogoIcon />,
      centered: true,
      size: 600,
      children: (
        <DeclineModalContent
          request={request}
          requestsHandlers={requestsHandlers}
          onUpdated={onUpdated}
          idea={idea}
        />
      ),
    });
  };

  const dismissMatch = (matchId: string) => {
    setLoading((prev: any) => ({
      ...prev,
      decline: true,
    }));
    dismissSuggested(auth0 ,matchId, idea._id, request.textId)
      .then((res) => {
        console.log(res)
        onUpdated(false);
        closeAllModals()
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() =>
        setLoading((prev: any) => ({
          ...prev,
          decline: false,
        }))
      );
  };

  const role = getConnectedUserRole(auth0);
  const userEmail = getConnectedUserEmail(auth0);
  const canValidate = role === "admin" || role === "manager";

  const canDismiss = canValidate || request?.internalSubmitter?.email === userEmail;

  const amount = request?.amount ?? request.chat?.amount ?? null;
  const icon =
    request.origin === "zendesk" ? (
      <SourceIcon sourceName={request.origin} width={20} height={20} />
    ) : (
      <SourceIcon sourceName={request.origin} width={25} height={25} />
    );
  const oppurtunityType = request.chat?.opportunityType ?? null;

  
  const company = request?.chatCompany || request?.company || undefined;
  const priorityColor = (priority: string) => {
    if (requestPriorities) {
      const sortedPriorities = requestPriorities.sort((a: any,b: any) => {
        return a.index - b.index
      })
      const defaultPriority = requestPriorities?.find((p: any) =>
        p?.isDefault
      )
      const colorsByPriorityIndex = [
        { color: "#DB2525", bgColor: "#FFF9F9" },
        { color: "#FF8700", bgColor: "#FFFAF2" },
        { color: "#2684FF", bgColor: "#F7FAFF" },
        { color: "#0BAC4D", bgColor: "#F5FEF9" },
      ]

      const index = sortedPriorities.findIndex((p: any) => p.key?.toLowerCase() === priority?.toLowerCase() || p.label?.toLowerCase() === priority?.toLowerCase())
      const currentPriority = sortedPriorities[index]
      const clampedIndex = index < colorsByPriorityIndex.length ? index : colorsByPriorityIndex.length - 1
      if (currentPriority) {
        setColor(colorsByPriorityIndex[clampedIndex].color);
        setBackground(colorsByPriorityIndex[clampedIndex].bgColor)
        setPriorityCapital(currentPriority.label);
      } else {
        setColor(colorsByPriorityIndex[2].color);
        setBackground(colorsByPriorityIndex[2].bgColor)
        setPriorityCapital(defaultPriority?.label ?? 'Unassigned')
      }
    }
  };

  const onValidateMatch = (id: string) => {
    setTimeout(() => {
      showNotification({
        title: "Evidence added successfully",
        color: "teal",
        message: ``,
        icon: <IconCheck size="1.1rem" />,
      });
      closeAllModals();
    }, 900);

    validateMatch(
      auth0,
      id,
      idea._id,
      {
        title: request.textItem?.title,
        businessName: request.textItem?.businessName,
        text: request?.textItem?.text,
        componentId: componentIdInModal || idea.componentId,
        priority: request?.priority || defaultRequestPriority,
      },
      request.textId,   
    )
      .then((res) => {
        console.log(res)
        onUpdated(false);
      })
      .catch((e) => {
        showNotification({
          title: "Problem occured!",
          color: "red",
          message: "Problem occured while adding evidence...",
          icon: <IconX size="1.1rem" />,
        });
        console.log(e);
      });
  };
  
  const onSelectSave = (type: string, value: string) => {

    let updatedValue = value

    if (type === 'componentId'){
      const found = allComponents.find((a: any)=> a._id === value)
      updatedValue = found?.name
    }
    

    if(request.modalType === 'match' && type === 'componentId'){
      const match = matches.find((m: any)=> m._id === request._id)
      const index = matches.findIndex((m: any)=> m._id === request._id)
      match.componentId = value
      const copy2 = [...matches]
      match.suggestedComponent = false
      const spliced = copy2.toSpliced(index,1,match)
      setMatches(spliced) 
    }
    console.log({onSelectSave:{
      type,
      value
    }})
    if (request) {
      const itemObj = {
        id: request._id,
        [type]: value,
      };

      if(request.modalType == 'match'){
        saveMatch(itemObj, auth0).then((res) => {
          console.log({ editOwner: res, itemObj });
         
          requestsHandlers?.applyWhere(
            (i: any) => i._id == request._id,
            //@ts-ignore
            (i: any) => ({ ...i, 
              chat:{
                ...i?.chat,
                ownerId: itemObj.ownerId
              }
            })
          );
          onUpdated(false);
          showNotification({
            title: "Match updated",
            color: "teal",
            message: `Match ${type} updated successfully`,
            icon: <IconCheck size="1.1rem" />,
          });
        });
      }else{
        console.log({request, itemObj})
        saveRequest(itemObj, auth0)
          .then((response) => {
            console.log(response);
            requestsHandlers?.applyWhere(
              (i: any) => i._id == request._id,
              //@ts-ignore
              (i: any) => ({ ...i, [type]: value })
            );
    
            onUpdated(false);
            showNotification({
              title: `${type === 'componentId' ? 'Product area': type ==='priority' ?'Priority': 'Domain'} updated`,
              color: "teal",
              message: `${type === 'componentId' ? 'Product area': type ==='priority' ? 'Priority': 'Domain'} updated to "${updatedValue}" successfully`,
              icon: <IconCheck size="1.1rem" />,
            });
          })
          .catch((e: any) => console.log(e));
      }

    }
  };

  const filterComponentByDomain = (domainValue: string) =>{
    const copy = [...components]
    const arr: any[] = copy.filter((s:any) => s.domain === domainValue) || []
    setComponentsByDomain(arr)
    const filtered = allComponents.filter((s:any) => s.domain === domainValue) || []
    const ids = filtered.map((a:any)=> a._id) 
    if(!ids.includes(componentIdInModal)){
      setSuggestedComponent(false)
      setInitialValueSuggested(false)
      setProductAreaName(null)
      setComponentIdModal(null) 
    }
  }
  


  

  useEffect(() => { 
    if (!productAreaName || productAreaName === "Unassigned") {
      setSuggestedComponent(false);
      setInitialValueSuggested(false);
      if(request.origin === 'salesforce' && idea?.componentId ){
        setProductAreaName(idea?.componentObj?.name)
        idea?.componentId && onSelectSave("componentId", idea?.componentId);
        evidenceDomain && filterComponentByDomain(idea?.componentObj?.domain)
      } 
    }
    if(evidenceDomain && allComponentsIds.includes(request.componentObj?._id)){
      filterComponentByDomain(request.componentObj?.domain) 
    }
    if(evidenceDomain && domain && !allComponentsIds.includes(request.componentObj?._id)){
      filterComponentByDomain(domain)
    }

    else if(!request?.componentId && request?.domainId){
      const foundDomain = domains.find((d: any)=> d._id = request?.domainId)
      if(foundDomain){
        setDomain(foundDomain?.name)
        filterComponentByDomain(foundDomain?.name)

      }
      
    }
   
  }, []);

  const handleSelect = (item: any) => {
    console.log("handleSelect", {item})
    setProductAreaName(item.name);
    updateProdudtArea(item.name, item._id);
    setSuggestedComponent(false);
    onSelectSave("componentId", item._id);
  };

  useEffect(() => {
    priorityColor(priority)
  },[requestPriorities])

  return (
    <Flex
      align="center"
      justify="space-between"
      h={56}
      pl="md"
      py="md"
      sx={{ borderBottom: "1px solid #E5E7EB" }}
    >
      <Flex>
        <Menu>
          <Menu.Target>
            <Tooltip label="Select priority">
              <Flex>
                <Text color="red" mt="4px" w="5px"></Text>
                <Flex
                  bg={background}
                  h="32px"
                  mx={5}
                  px="sm"
                  py={0}
                  justify="center"
                  sx={{ cursor: "pointer", borderRadius: "5px" }}
                  align="center"
                >
                  <Text mr={4} pt="4px" fz="14px" color={color}>
                    {" "}
                    <IconAlertOctagon size={14} />{" "}
                  </Text>
                  <Text color={color} fz="14px">
                    {priorityCapital || "Unassigned"}
                  </Text>
                </Flex>
              </Flex>
            </Tooltip>
          </Menu.Target>

          <Menu.Dropdown>
            {priorityData.map((item: any) => (
              <Menu.Item
                w="180px"
                key={item}
                onClick={() => {
                  setPriorityCapital(item);
                  priorityColor(item)
                  onSelectSave("priority", item);
                  if (displayProductAreaMsg === false) {
                    setMandatoryMessage(false);
                  }
                }}
              >
                {item}
              </Menu.Item>
            ))}
          </Menu.Dropdown>
        </Menu>

        {company?.name && (
          <Tooltip label="Account">
            <Flex
              h="32px"
              mx={5}
              px="sm"
              py={0}
              bg="#F8F9FB"
              align="center"
              sx={{ cursor: "default", borderRadius: "5px" }}
            >
              <Text color="black" fz="14px">
                {company?.name}
              </Text>
            </Flex>
          </Tooltip>
        )}
        {amount !== 0 && amount && (
          <Tooltip label="Amount">
            <Flex
              h="32px"
              mx={5}
              px="sm"
              py={0}
              bg="#F8F9FB"
              align="center"
              sx={{ cursor: "default", borderRadius: "5px" }}
            >
              <Text mr={4} mt={5}>
                <IconCoin color="black" size={18} />
              </Text>
              <Text fz={12} mr={2} color="black">
                {" "}
                ${" "}
              </Text>
              <Text fz="14px" color="black">
                {" "}
                {amount ? formatMonetaryValue(amount): 'N/A'}
              </Text>
            </Flex>
          </Tooltip>
        )}
        {oppurtunityType && (
          <Tooltip label="Oppurtunity type">
            <Flex
              h="32px"
              mx={5}
              px="sm"
              py={0}
              bg="#F8F9FB"
              align="center"
              sx={{ cursor: "default", borderRadius: "5px" }}
            >
              <Text mr={4} mt={5}>
                {" "}
                <IconCategory color="black" size={14} />{" "}
              </Text>
              <Text color="black" fz="14px">
                {oppurtunityType}
              </Text>
            </Flex>
          </Tooltip>
        )}
        {request.origin && (
          <>
            <Tooltip label="Origin">
              <Flex
                h="32px"
                mx={5}
                px="sm"
                py={0}
                bg="#F8F9FB"
                align="center"
                sx={{ cursor: "default", borderRadius: "5px" }}
              >
                <Text mr={6} mt={5}>
                  {icon}
                </Text>
                <Text fz="14px" color="black">
                  {capitalizeFirstLetter(request.origin)}
                </Text>
              </Flex>
            </Tooltip>
          </>
        )}
        {evidenceDomain && (
          <Menu>
            <Menu.Target>
              <Tooltip label="Select domain">
                <Flex>
                  <Text mt="4px" w="5px"></Text>
                  <Flex
                    h="32px"
                    bg="#F8F9FB"
                    mx={5}
                    px="sm"
                    py={0}
                    justify="center"
                    sx={{ cursor: "pointer", borderRadius: "5px" }}
                    align="center"
                  >
                    <Text mr={4} pt="4px" fz="14px"></Text>
                    <Text fz="14px">{domain || "Unassigned"}</Text>
                  </Flex>
                </Flex>
              </Tooltip>
            </Menu.Target>

            <Menu.Dropdown>
              {domainsNames.map((item: string) => (
                <Menu.Item
                  w="180px"
                  key={item}
                  onClick={() => {
                    onSelectSave("domain", item);
                    setDomain(item);
                    filterComponentByDomain(item);
                  }}
                >
                  {item}
                </Menu.Item>
              ))}
            </Menu.Dropdown>
          </Menu>
        )}

        {intialValueSuggested ? (
          <Menu
            trigger={isMatch && productAreaName ? "hover" : "click"}
            opened={open}
            onChange={setOpen}
          >
            <Menu.Target>
              <Tooltip label={domain ? "Select product area" : "Product area"}>
                <Flex>
                  <Flex
                    h="32px"
                    mx={5}
                    px="sm"
                    py={0}
                    bg={"#F8F9FB"}
                    align="center"
                    sx={
                      domain
                        ? { cursor: "pointer", borderRadius: "5px" }
                        : { cursor: "default", borderRadius: "5px" }
                    }
                  >
                    <Text mr={5.5} mt={2}>
                      {" "}
                      <ProductAreaIcon color="black" />{" "}
                    </Text>

                    <Text
                      fz="14px"
                      color={
                        isMatch &&
                        productAreaName &&
                        productAreaName !== idea?.component
                          ? "red"
                          : suggestedComponent &&
                            productAreaName === idea?.component
                            ? "#2284FF"
                            : "black"
                      }
                    >
                      {productAreaName
                        ? capitalizeFirstLetter(productAreaName)
                        : "Unassigned"}
                    </Text>
                  </Flex>
                </Flex>
              </Tooltip>
            </Menu.Target>
            <Menu.Dropdown p="md">
              {suggestedComponent && productAreaName === idea?.component ? (
                <Text size="sm">Product Area recommended by Bagel AI</Text>
              ) : (
                suggestedComponent &&
                productAreaName !== idea?.component && (
                  <Box>
                    <Text size="sm">
                      This product area is recommended by Bagel AI
                    </Text>
                    <Text size="sm">
                      It does not match the Product Area of the Idea
                    </Text>
                  </Box>
                )
              )}
              <Flex justify="center">
                <Tooltip
                  label="Confirm recommended product area"
                  position="bottom"
                  offset={15}
                >
                  <Button
                    variant="light"
                    color="indigo"
                    compact
                    m="md"
                    onClick={() => {
                      setOpen(false);
                      setProductAreaName(productAreaName);
                      setSuggestedComponent(false);
                      setInitialValueSuggested(false);
                      setDisplayProductAreaMsg(false);
                      const found = allComponents.find(
                        (a: any) =>
                          a.name?.toLowerCase() ===
                          request?.textItem?.mlComponent?.toLowerCase()
                      );
                      updateProdudtArea(request?.textItem?.mlComponent, found?._id);
                      setComponentIdModal(found?._id)
                      onSelectSave("componentId", found?._id);
                    }}
                  >
                    Confirm
                  </Button>
                </Tooltip>
                <Tooltip
                  label="Modify product area"
                  position="bottom"
                  offset={15}
                >
                  <Button
                    variant="light"
                    color="teal"
                    compact
                    m="md"
                    onClick={() => {
                      setInitialValueSuggested(false);
                    }}
                  >
                    Modify
                  </Button>
                </Tooltip>
              </Flex>
            </Menu.Dropdown>
          </Menu>
        ) : (
          <BagelAutocomplete
            items={
              evidenceDomain
                ? [...components].filter((s: any) => s.domain == domain)
                : components
            }
            selected={productAreaName}
            onSelect={handleSelect}
            disabled={!domain && evidenceDomain}
          >
            <Tooltip
              multiline={true}
              label={isMatch && productAreaName && productAreaName !== idea.component ? " This product area was set manually by a Bagel user It does not match the Product Area of the Idea":
                !domain && evidenceDomain
                  ? "Select domain first"
                  : "Select product area"
              }
            >
              <Menu.Target>
                <Flex
                  h="32px"
                  bg="#F8F9FB"
                  mx={5}
                  px="sm"
                  py={0}
                  justify="center"
                  sx={{
                    cursor: "pointer",
                    borderRadius: "5px",
                    opacity: !domain && evidenceDomain ? 0.5 : 1,
                  }}
                  align="center"
                >
                  <Text mr={4} pt="4px" fz="14px"></Text>
                  <Text fz="14px" color={isMatch && productAreaName && productAreaName !== idea.component ? 'red' : 'black'}>{productAreaName || "Unassigned"}</Text>
                </Flex>
              </Menu.Target>
            </Tooltip>
          </BagelAutocomplete>
        )}
        {displayProductAreaMsg && <Text color="red" mr='xs'>*</Text>}
        {(ownerName && request.source !== "suggestion") || !request.source ? (
          <BagelAutocomplete
            items={owners.map((member: any) => ({
              name: member.name,
              id: member._id,
            }))}
            selected={ownerName}
            onSelect={(item: any) => {
              setOwnerName(item?.name);
              updateOwner(item);
              editOwner(item);
            }}
          >
            <Tooltip label="Select owner">
              <Flex
                h="32px"
                mx={5}
                px="sm"
                py={0}
                bg="#F8F9FB"
                align="center"
                sx={{ cursor: "pointer", borderRadius: "5px" }}
              >
                <Text mr={6} mt={5}>
                  <IconUsers color="black" size={16} />
                </Text>
                <Text fz="14px" color="black">
                  {capitalizeFirstLetter(ownerName)}
                </Text>
              </Flex>
            </Tooltip>
          </BagelAutocomplete>
        ) : (
          <Tooltip label="Owner">
            <Flex
              h="32px"
              mx={5}
              px="sm"
              py={0}
              bg="#F8F9FB"
              align="center"
              sx={{ cursor: "default", borderRadius: "5px" }}
            >
              <Text mr={6} mt={5}>
                <IconUsers color="black" size={16} />
              </Text>
              <Text fz="14px" color="black">
                {capitalizeFirstLetter(idea?.owner?.name || "Unassigned")}
              </Text>
            </Flex>
          </Tooltip>
        )}
      </Flex>
      <Flex>
        {canDismiss && (
          <Tooltip disabled={isMatch} label="You can provide a reason in the next step">
            <Button
              compact
              color="red"
              variant="light"
              w={130}
              styles={() => ({
                root: {
                  color: "red",
                  backgroundColor: "#F9585814",
                  height: 35,
                  border: "0.7px solid #F9585866",
                },
              })}
              loading={loading["decline"]}
              radius="md"
              ml="sm"
              /* variant="outline" */
              onClick={() => {
                isMatch ? dismissMatch(request._id) : openDeclineModal();
              }}
              mr={30}
            >
              <Text>
                {request.modalType !== "match" ? "Decline" : "Not relevant"}{" "}
              </Text>
            </Button>
          </Tooltip>
        )}
        {isMatch && (
          <Menu>
            <Menu.Target>
              <Button
                onClick={() => onValidateMatch(request._id)}
                styles={() => ({
                  root: {
                    height: 35,
                  },
                })}
                color="indigo"
                variant="outline"
                px="md"
                radius="md"
                mr="sm"
              >
                Add as evidence
              </Button>
            </Menu.Target>
            {mandatoryMessage && (
              <Menu.Dropdown>
                <Menu.Item>
                  <Text color="red" fz="12px">
                    Please select product area <br /> before adding as evidence
                  </Text>
                </Menu.Item>
              </Menu.Dropdown>
            )}
          </Menu>
        )}
      </Flex>
    </Flex>
  );
}
