import React from 'react';
import { Box, Group, TextInput, Tooltip, ActionIcon, Transition } from "@mantine/core";
import { IconSearch, IconFilter } from "@tabler/icons";

interface FilterPanelProps {
  toggle: () => void;
  search: string;
  setSearch: (search: string) => void;
  searchOpened: boolean;
  setSearchOpened: (opened: boolean) => void;  // Add this line in your interface
}

const FilterPanel: React.FC<FilterPanelProps> = ({ toggle, search, setSearch, searchOpened, setSearchOpened }) => (
  <Box p={0}>
    <Group noWrap>
      <Box mb="1.5px">
        <Tooltip label="Filters" withArrow>
          <ActionIcon onClick={toggle}>
            <IconFilter strokeWidth={2} size={22} color="black" />
          </ActionIcon>
        </Tooltip>
      </Box>
      <Group>
        <Transition mounted={searchOpened} transition="fade" duration={400} timingFunction="ease">
          {(styles) => (
            <div style={styles}>
              <TextInput
                value={search}
                onChange={(event) => setSearch(event.currentTarget.value)}
                variant="filled"
                size="xs"
              />
            </div>
          )}
        </Transition>
        <ActionIcon onClick={() => setSearchOpened(!searchOpened)}>
          <IconSearch size={18} />
        </ActionIcon>
      </Group>
    </Group>
  </Box>
);

export default FilterPanel;
