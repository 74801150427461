import { useAuth0 } from "@auth0/auth0-react";
import {
  Table,
  Title,
  Group,
  Button,
  Paper,
  Box,
  TextInput,
  Tabs, Grid, Loader,
} from "@mantine/core";
import { useElementSize } from "@mantine/hooks";
import { IconFilter, IconSearch} from "@tabler/icons";
import React, { useState, useEffect } from "react";
import {
  listIntegrations,
} from "../../../Api";
import {IntegrationRow} from "./IntegrationRow";

export function Integrations() {
  const { ref } = useElementSize();
  const auth0 = useAuth0();
  const [integrations, setIntegrations] = useState<any>({});
  const [integrationsLoaded, setIntegrationsLoaded] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<string | null>("All");

  const integrationNames = [
    "salesforce",
    "zendesk",
    "slack",
    "clickup",
    "jira",
    "productboard"
  ];

  const loadIntegrations = () => {
    listIntegrations(auth0)
      .then((integrations) => {
        const integrationsObject: any = {};
        for (let i = 0; i < integrations.length; i++) {
          integrationsObject[integrations[i].type] = integrations[i];
        }
        setIntegrations(integrationsObject);
        setIntegrationsLoaded(true);
      })
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    loadIntegrations();
  }, []);

  const getIntegration = (type: string) => {
    return integrations[type];
  };


  const rows = integrationNames.map((integrationKey: string) => {
    return <IntegrationRow key={integrationKey} integration={getIntegration(integrationKey)} integrationType={integrationKey} />
  });

  const tabs = [
    { name: "All" },
    // { name: "Active" },
    // { name: "Sales" },
    // { name: "Success" },
    // { name: "Support" },
    // { name: "Marketing" },
    // { name: "Product" },
    // { name: "Others" },
  ];
  if (!integrationsLoaded) {
    return <Loader  variant="dots"  mt={"lg"} ml={"lg"}/>
  }
  return (
    <div>
      <Box ref={ref} pt={"md"} >
        <Title order={3} p={"sm"}>
          Integrations
        </Title>
        <Grid
          mx="sm"
          justify="space-between"
          align="flex-end"
          sx={{ borderBottom: "1px solid #F1F1F1" }}
        >
          <Grid.Col span="content">
            <Tabs ml="sm" value={activeTab} onTabChange={setActiveTab}>
              <Tabs.List m="0" sx={{ borderBottom: 0 }}>
                {tabs.map((tab) => (
                  <Tabs.Tab key={tab.name} value={`${tab.name}`}>
                    {tab.name}
                  </Tabs.Tab>
                ))}
              </Tabs.List>
            </Tabs>
          </Grid.Col>
        </Grid>
      </Box>
      <div
        style={{
          background: "#f1f3f5",
        }}
      >
        <Box py={40} px={60}>
          <Paper radius="md" withBorder>
            <Group position="apart" px="xl" py="md">
              <TextInput
                placeholder="Search integrations..."
                icon={<IconSearch size={14} />}
              />
              <Button
                sx={{
                  color: "#000",
                }}
                color="gray"
                variant="subtle"
                leftIcon={<IconFilter strokeWidth={2} size={16} />}
              >
                Filter
              </Button>
            </Group>
            <Table verticalSpacing="xs" sx={{ td: { textAlign: "left" } }}>
              <thead>
                <tr
                  style={{
                    background: "#F3F4F6",
                    borderTop: "1px solid #dee2e6",
                  }}
                >
                  <th></th>
                  <th>Integration</th>
                  <th>Status</th>
                  <th>Created At</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </Table>
          </Paper>
        </Box>
      </div>
    </div>
  );
}
