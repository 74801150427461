import { Box, Checkbox, Flex, Group, Text } from '@mantine/core'
import React, { useContext } from 'react'
import IdeaContext from '../IdeaContext';
import { IconX } from '@tabler/icons';


const SearchRow = ({
  searchEvidence,
  searchedEvidence,
  showMatchesResults,
  setShowMatchesResults,
  showRequestsResults,
  setShowRequestsResults,
  showQueryResults,
  setShowQueryResults,
  resultsLength, 
}: {
    searchEvidence: any;
    searchedEvidence: any[];
    showMatchesResults: boolean;
    setShowMatchesResults: React.Dispatch<React.SetStateAction<boolean>>;
    showRequestsResults: boolean;
    setShowRequestsResults: React.Dispatch<React.SetStateAction<boolean>>;
    showQueryResults: boolean;
    setShowQueryResults: React.Dispatch<React.SetStateAction<boolean>>;
    resultsLength: number
  }) => {

  const {
    showmatches,
    setshowmatches,
  } = useContext(IdeaContext);

  const searchedMatches = searchedEvidence.filter((s: any) => s.status === 'match') || []
  const matchesLength = searchedMatches?.length || 0
  const searchedRequests = searchedEvidence.filter((s: any)=> s.status === 'validated') || []
  const requestsLength = searchedRequests?.length || 0
  
  return (
    <Flex justify='space-between' /* py={8} */ px="20px" sx={{ borderBottom: "1px solid #D8D8DB" }} h={40}>
      <Flex align='center'>
        
        <Text fw={600} fz="17px" lineClamp={1} >
         Search results
        </Text>

        <Flex 
          align='center' 
          ml='md'
          sx={{
            cursor: 'pointer'
          }}
          onClick={()=>{
            searchEvidence('')
          } }
        >
          <Box mr='3px' mt='2px'>
            <IconX size={12} color='#5C5CEB'/>
          </Box>
          
          <Text fz="12px" color='#5C5CEB'> Clear</Text>
        </Flex> 
        
      </Flex>
      <Group>
        <Checkbox
          checked={showmatches} 
          onClick={(event)=>setshowmatches(event.currentTarget.checked)}
          color="indigo" 
          label={
            <Text fw={600} fz="12px">
              Pending <Text span fz="12px" color='#5C5CEB'>
                {matchesLength}
              </Text>
            </Text>
          }
        />
        <Checkbox
          checked={showRequestsResults} 
          onClick={()=> setShowRequestsResults(!showRequestsResults)}
          color="indigo" 
          label={
            <Text fw={600} fz="12px">
              Validated <Text span fz="12px" color='#5C5CEB'>
                {requestsLength}
              </Text>
            </Text>
          }
        />
        <Checkbox
          checked={showQueryResults} 
          onClick={()=>setShowQueryResults(!showQueryResults)}
          color="indigo" 
          label={
            <Text fw={600} fz="12px">
              Additional results <Text span fz="12px" color='#5C5CEB'>
                {resultsLength}
              </Text>
            </Text>
          }
        />
      </Group>
    </Flex>
  )
}

export default SearchRow