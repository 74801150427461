import { Box, Button, Flex, Text, Textarea } from "@mantine/core";
import { useContext, useEffect, useState } from "react";
import DiscoveryContext from "./DiscoveryContext";
import { IconArrowRight, IconX } from "@tabler/icons";
import { ConvertIcon } from "../../icons/x-symbol-svgrepo-com";
import { getConnectedUserRole } from "../../utils";
import { useAuth0 } from "@auth0/auth0-react";

const DiscoveryTextArea = ({
  setNewIdeaFormView,
}: {
  setNewIdeaFormView: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const {
    createIdeaDiscovery,
    loadIdeaMatches,
    description,
    setDescription,
    ideaId,
    idea,
  } = useContext(DiscoveryContext);

  const [isValidated, setIsValidated] = useState(false);

  useEffect(() => {
    if (idea) {
      setIsValidated(idea.description === description);
    }
  }, [idea, ideaId, description]);

  const auth0 = useAuth0();
  const role = getConnectedUserRole(auth0);
  const canConvert = role === "manager" || role === "admin";
  return (
    <Box
      h={`calc(100vh - 115px)`}
      bg="rgba(92, 92, 235, 0.1)"
      sx={{ borderRight: "1px solid #D8D8DB" }}
      py={24}
      px={36}
    >
      <Text fz={18} fw={600} mb={8}>
        What is the Product Idea?
      </Text>
      <Text fz={12} fw={400}>
        Describe the
        <span style={{ fontWeight: 600 }}> desired product capability </span> in
        1-2 sentences.
      </Text>
      <Text mb={24} fz={12} fw={400}>
        Add the underlying
        <span style={{ fontWeight: 600 }}> need or pain point </span> it solves
        for the customer.
      </Text>
      
      


      <Textarea
        sx={{ borderRadius: 8 }}
        minRows={5}
        mb={16}
        value={description}
        onChange={(event) => setDescription(event.currentTarget.value)}
      />
      <Box w='100%'>
        <Flex
          my='lg'
          h={12}
          w='100%'
          px='xs'
          
          
          justify='flex-end'
          align='center'
        >


          <Flex 
            align='center' 
            ml='md'
            sx={{
              cursor: 'pointer'
            }}
            onClick={()=> setDescription('')}
          >
            <Box mr='3px' mt='2px'>
              <IconX size={12} color='#5C5CEB'/>
            </Box>
          
            <Text fz="12px" color='#5C5CEB'> Clear</Text>
          </Flex> 
          
        
        </Flex>
        <Flex justify="space-between">
          {canConvert && (
            <Button
              disabled={description.length < 4 || !ideaId || !isValidated}
              w={130}
              styles={(theme) => ({
                root: {
                  height: "32px",
                  backgroundColor: "#fff",
                  border: 0,
                  fontWeight: 400,
                  padding: 0,
                  "&:hover": {
                    backgroundColor: theme.fn.darken("#fff", 0.015),
                  },
                },
              })}
              leftIcon={<ConvertIcon />}
              onClick={() => {
                setNewIdeaFormView(true);
              }}
            >
              <Text color="#5C5CEB" fz={12}>
              Convert to idea
              </Text>
            </Button>
          )}
          <Button
            w={83}
            disabled={description.length < 4 || isValidated}
            styles={(theme) => ({
              root: {
                height: "32px",
                backgroundColor: "#5C5CEB",
                border: 0,
                fontWeight: 400,
                padding: 0,
                "&:hover": {
                  backgroundColor: theme.fn.darken("#5C5CEB", 0.05),
                },
              },
            })}
            leftIcon={<IconArrowRight size={14} />}
            onClick={() => {
              createIdeaDiscovery(description);
              loadIdeaMatches(description);
            }}
          >
            <Text fz={12}>Validate</Text>
          </Button>
        </Flex>
      </Box>
      
    </Box>
  );
};

export default DiscoveryTextArea;
