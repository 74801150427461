import { ActionIcon, Button, Indicator, Tooltip } from "@mantine/core";
import FiltersMenu, { ActiveFilter } from "../../../ideas/filters/FiltersMenu";
import { FiltersSpec, evidenceParents } from "../../../ideas/filters/FiltersSpec";
import { capitalizeFirstLetter } from "../../../../utils/capitalizeFirstLetter";
import { IconFilter } from "@tabler/icons";
import FilterBadge from "../../../ideas/filters/FilterBadge";
import { useContext, useEffect, useState } from "react";
import SyncContext from "../../../../context/SyncContext";

// Passing all states and handlers as parameters to the FiltersBar
const FiltersBar = ({
  activeFilters,
  setActiveFilters,
}: {
  activeFilters: any[];
  setActiveFilters:React.Dispatch<React.SetStateAction<ActiveFilter[]>>
}) => {
  // console.log({activeFilters})
  const currentTypeActiveFilters = activeFilters?.filter((activeFilter) => evidenceParents.includes(activeFilter.parent.split(".")[0]))
  const [menuFiltersObj, setMenuFiltersObj] = useState<any>(FiltersSpec);
  const { components, ideaStatuses, showDomain, domains, objectCustomizations, businessCategoryMapping } =
    useContext(SyncContext);
    
  useEffect(() => {
    const componentsData: any = {};
    components &&
      components.map((component: any) => {
        return (componentsData[component.name] = component);
      });

    const domainsData: any = {};
    showDomain &&
      domains.length &&
      domains.map((domain: any) => {
        return (domainsData[domain.name] = { ...domain, value: domain.name });
      });
    const requestStatusesData: { [text: string]: string } = {};
    ideaStatuses &&
      ideaStatuses.sort().map((status: any) => {
        return (requestStatusesData[status.key] = status.key);
      });
    setMenuFiltersObj((prev: any) => ({
      ...prev,
      Account:{
        ...prev["Account"],
        ChildFilters:{
          ...(objectCustomizations?.account?.fields?.plan?.options 
            ? {
              plan: {
                ...prev?.Account?.ChildFilters?.plan,
                options: objectCustomizations.account.fields.plan.options.reduce((acc:any, option:any) => {
                  acc[option] = option;
                  return acc;
                }, {}),
                operations: [],
                type: "checkbox",
              }
            } 
            : {}),
          ...(objectCustomizations?.account?.fields?.tier?.options 
            ? {
              tier: {
                ...prev?.Account?.ChildFilters?.tier,
                options: objectCustomizations.account.fields.tier.options.reduce((acc:any, option:any) => {
                  acc[option] = option;
                  return acc;
                }, {}),
                operations: [],
                type: "checkbox",
              }
            } 
            : {}),
          ...(objectCustomizations?.account?.accountTypesMap ?{
            accountTypesMap:{
              ...prev?.Account?.ChildFilters?.accountTypesMap,
              options: objectCustomizations.account.accountTypesMap,
              operations:[],
              type: "checkbox",
            }} : {}),
            
        }
      },

      "Business Name":{
        ...prev["Business Name"],
        options: businessCategoryMapping,
      }
    }));

  }, [ objectCustomizations, businessCategoryMapping]);


  return (
    <>
      <FiltersMenu
        filters={menuFiltersObj}
        MenuType='evidence'
        activeFilters={activeFilters}
        setActiveFilters={setActiveFilters}
        Target={
          <Tooltip label={`Filter by attributes`} withArrow>
            <ActionIcon
              sx={{
                height: 32,
                width:32,
                borderColor:"#D8D8DB",
                color: "black",
                fontSize:12,
                fontWeight:500,
                ":hover":{
                  backgroundColor:"#5C5CEB1A"
                }
              }}
              variant="outline"
              radius="sm"
            >
              <Indicator mt={2} zIndex={5} disabled={currentTypeActiveFilters.length == 0} inline label="" size={8} offset={3} color="#0BAC4D">
                <IconFilter strokeWidth={1} size={18}/>
              </Indicator>
            </ActionIcon>
          </Tooltip>
        }
      />
      {currentTypeActiveFilters
        .map((filter, index) => (
          <FilterBadge
            key={index}
            filter={filter}
            MenuType={'evidence'}
            ownerOptions={menuFiltersObj.Owner?.options}
            onRemoveClick={() => setActiveFilters(activeFilters.filter((item) => item.parent !== filter.parent))}
          />
        ))}
    </>
  );
};

export default FiltersBar;
