import { ActionIcon, Box, Group, Title } from "@mantine/core";
import {
  IconX,
  IconMessage,
} from "@tabler/icons";

interface ModalHeaderInterface {
  setOpened: (bool: boolean) => void;
}

export function ModalHeader(props: ModalHeaderInterface) {
  return (
    <>
      <Group
        grow
        spacing="xs"
        p="sm"
        sx={{ borderBottom: "1px solid #E5E7EB" }}
      >
        <Group>
          <Box ml='md' mt={8}>
            <IconMessage size={20} />
          </Box>
          <Title
            order={5}
            sx={{
              maxWidth: "100%",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            New idea form
          </Title>
        </Group>

        <Group spacing={0} position="right">
          {/* <ActionIcon>
            <IconDots size={18} />
          </ActionIcon> */}
          {/* <ActionIcon>
            <IconFileExport size={18} />
          </ActionIcon> */}
          <ActionIcon onClick={() => props.setOpened(false)}>
            <IconX size={18} />
          </ActionIcon>
        </Group>
      </Group>
    </>
  );
}
