import { Group, Avatar, Text, SelectItemProps } from "@mantine/core";
import { forwardRef } from "react";
import { SourceIcon } from "../../utils/SourceIcon";

interface UserProps extends SelectItemProps {
  accountId: string;
  createdAt?: number;
  email: string;
  name: string;
  sources: any[];
  updatedAt?: number;
  _id: string;
}
const AutoCompleteInnerItem = forwardRef<HTMLDivElement, UserProps>(
  ({ email, name, sources, ...others }: UserProps, ref) => {
    return (
      <div ref={ref} {...others}>
        <Group noWrap>
          {sources && sources.length > 0 && (<Avatar radius="sm">
            <SourceIcon sourceName={sources[0].origin} />
          </Avatar>)}

          <div>
            <Text>{name}</Text>
            <Text size="xs" color="dimmed">
              {email}
            </Text>
          </div>
        </Group>
      </div>
    )}
);

export default AutoCompleteInnerItem;
