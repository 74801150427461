import { useAuth0 } from "@auth0/auth0-react";
import {
  Menu,
  Text,
  TextInput,
  Box,
  ScrollArea,
  createStyles,
  Paper,
  UnstyledButton,
  Card,
  Group,
  Badge,
  Button,
  Stack,
} from "@mantine/core";
import { IconExternalLink, IconSearch, IconUnlink } from "@tabler/icons";
import { useContext, useEffect, useState } from "react";
import {
  linkToProductItem,
  searchAll,
  searchProductItems,
  SearchResults,
  unLinkToProductItem,
} from "../../../Api";
import { itemPropsByType } from "../../../utils";
import { getFeedbackItemsFromSearchResults } from "../../../utils/feedbackItemFromSearchResults";
import { SourceIcon } from "../../../utils/SourceIcon";
import { InfoCard } from "../InfoCard";
import { ResultItem } from "./ResultItem";
import { capitalizeFirstLetter } from "../../../utils/capitalizeFirstLetter";
import { useHover } from "@mantine/hooks";
import SyncContext from "../../../context/SyncContext";


const useStyles = createStyles((theme) => ({
  item: {
    border: "1px solid #000",
    "&[data-hovered]": {
      backgroundColor: "#f5f5f5",
      color: theme.white,
    },
  },
  tabs: {
    "&[data-active]": {
      borderColor: "#5B60E3",
      color: "#5B60E3",
      "&:hover": {
        borderColor: "#5B60E3",
      },
    },
  },
}));

export function ProductLink({
  request,
  showRequestProductLink,
  getRequestFn,
  itemKey,
  originType,
}: {
  request: any;
  showRequestProductLink: boolean;
  getRequestFn: (useLoading?: boolean) => void;
  itemKey: string;
  originType: string;
}) {
  const { classes } = useStyles();
  const auth0 = useAuth0();
  const { hovered: cardHovered, ref } = useHover();
  const [query, setQuery] = useState<string>("");
  const [linkLoading, setLinkLoading] = useState<boolean>(false);
  const [unLinkLoading, setUnLinkLoading] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const { sync,  activeSource } = useContext(SyncContext);
  
  const fetchOptions = async () => {
    searchProductItems(activeSource.type, query, auth0)
      .then(({ results }: any) => {
        console.log(results);
        setSearchResults(results);
      })
      .catch((e) => console.log(e));
  };

  const linkToProductItemFn = async (productItemId: string) => {
    setLinkLoading(true);
    linkToProductItem(request._id, productItemId, auth0)
      .then((response: any) => {
        console.log(response);
        setLinkLoading(false);
        getRequestFn(false)
      })
      .catch((e) => {
        console.log(e);
        setLinkLoading(false);
      });
  };

  const unLinkToProductItemFn = async (productItemId: string) => {
    setUnLinkLoading(true);
    unLinkToProductItem(request._id, productItemId, auth0)
      .then((response: any) => {
        console.log(response);
        setUnLinkLoading(false);
        getRequestFn(false)
      })
      .catch((e) => {
        console.log(e);
        setLinkLoading(false);
      });
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (query !== "") {
        fetchOptions();
      } else {
        setSearchResults([]);
      }
    }, 200);

    return () => {
      clearTimeout(timeout);
    };
  }, [query]);
  
  const hasProductItem = request.productItem !== undefined
  const hasLink = showRequestProductLink && (request.origin === "clickup" || request.origin === "jira") && request.link !== undefined
  const productItem = request.productItem || (hasLink ? request : undefined)
  return (
    <Menu
      classNames={classes}
      shadow="md"
      width={productItem ? 200 : 600}
      position="bottom"
      withArrow
      styles={{
        dropdown: {
          minWidth: `${productItem ? 200 : 600}px !important`,
        },
      }}
    >
      <Menu.Target>
        <UnstyledButton sx={itemKey ? { cursor: "default" } : { cursor: "pointer" }}>
          <Card p="xs" radius="md" withBorder ref={ref}>
            {itemKey !== '' ? <Text fz="xs" c="dimmed" lineClamp={1}>Delivery ({originType})</Text>:<Text fz="xs" c="dimmed" lineClamp={1} /* tt={"capitalize"} */>
              {/* {capitalizeFirstLetter(sync) || "Jira"} */} Delivery{" "}
              {hasProductItem ? (
                <Text span fw={400} fz="xs">
                  ({productItem.originType || "Task"})
                </Text>
              ): (hasLink && <Text span fw={400} fz="xs">
                ({productItem.originType || "Task"})
              </Text>) }
            </Text>}
            <Group spacing="xs" noWrap>
              {/* <SourceIcon
                sourceName={sync || "jira"}
                width={25}
                height={25}
              /> */}
              {productItem ? (
                <Badge
                  sx={{
                    backgroundColor: "#C4C4C4",
                    color: "black",
                  }}
                >
                  {cardHovered ? (productItem.itemKey || productItem.originId) : productItem?.status}
                </Badge>
              ) : (
                <Text fz="md" fw={500} lineClamp={1}>
                  {/* {capitalizeFirstLetter(sync ?? 'jira')} */} Unassigned
                </Text>
              )}
            </Group>
          </Card>
        </UnstyledButton>
      </Menu.Target>

      {itemKey === '' && <Menu.Dropdown p={0}>
        {hasProductItem || hasLink ? (
          <Stack spacing="xs" p="xs" >
            <Button
              component="a"
              variant="outline"
              styles={() => ({
                root: {
                  borderColor: "#5B60E3",
                  color: "#5B60E3",
                  height: 35,
                },
              })}
              radius="md"
              href={`${productItem.link}`}
              size="sm"
              fullWidth
              rightIcon={<IconExternalLink size={16} />}
              target="_blank"
            >
              Open in {capitalizeFirstLetter(sync) ?? 'Jira'}
            </Button>
            {hasProductItem && (
              <Button
                loading={unLinkLoading}
                size="sm"
                radius="md"
                fullWidth
                variant="subtle"
                color="red"
                rightIcon={<IconUnlink size={16} />}
                onClick={() => unLinkToProductItemFn(productItem._id)}
              >
                Unlink
              </Button>
            ) }

          </Stack>
        ) : (
          <>
            <Box mx="sm" mt="sm">
              <TextInput
                placeholder="Type here"
                icon={<IconSearch size={14} />}
                value={query}
                onChange={(event) => setQuery(event.currentTarget.value)}
              />
              <ScrollArea style={{ height: 300, overflowX: "unset" }}>
                <Box w="561px">
                  {searchResults.length > 0 &&
                    searchResults.map((item: any) => {
                      return (
                        <Paper
                          sx={{
                            border: "1px solid #E5E7EB",
                            ":hover": {
                              borderColor: "#5B60E3",
                              backgroundColor: "#5B60E34D",
                            },
                            cursor: "pointer",
                          }}
                          my="xs"
                          key={item._id}
                        >
                          <ResultItem
                            item={item}
                            linkToProductItemFn={linkToProductItemFn}
                            linkLoading={linkLoading}
                          />
                        </Paper>
                      );
                    })}
                </Box>
              </ScrollArea>
            </Box>
            {searchResults.length > 0 && (
              <Box
                px="sm"
                py="xs"
                sx={{
                  borderTop: "1px solid #e5e7eb",
                }}
              >
                <Text size="sm">{searchResults.length} results found</Text>
              </Box>
            )}
          </>
        )}
      </Menu.Dropdown>}
    </Menu>
  );
}
