interface Option {
  value: string;
  name: string;
  id: number;
}

export function prepareSortedList(selectedOptions: any[]): Option[] {
  if (!selectedOptions) {
    return [];
  }

  // Get keys, sort them, and map to the desired format
  return Object.keys(selectedOptions)
    .sort()
    .map((key: any, index) => ({
      value: key,
      name: selectedOptions[key],
      id: index
    }));
}

export const getBusinessNameList = (selectedOptions: any[]): Option[] => selectedOptions.map(({businessName}: any, index) => ({
  value: businessName, 
  name: businessName, 
  id: index
}));

export const getAccountTypesMapList = (selectedOptions: any[]): Option[] => Object.keys(selectedOptions).sort().map((key, index) => ({
  value: key, 
  name: key, 
  id: index
}));
