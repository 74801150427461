import { Box, Button, Flex, Text } from "@mantine/core";
import { IconBell, IconCheck, IconPlus, IconX } from "@tabler/icons";
import { useContext, useState } from "react";
import { showNotification } from "@mantine/notifications";

import { useAuth0 } from "@auth0/auth0-react";
import SyncContext from "../../../../../context/SyncContext";
import IdeaContext from "../../../IdeaContext";
import { addRequestsToIdea } from "../../../../../Api";


const SearchActions = ({
  navToPerspective,
  setActiveSearch,
}: {
  navToPerspective: any;
  setActiveSearch: any;
}) => {
  const auth0 = useAuth0();

  const { defaultRequestPriority } = useContext(SyncContext);

  const {
    loadEvidence,
    chosenEvidence,
    ideaId,
    idea,
    reCalculatPerspectiveForRequest,
    setAddEvidenceFormScreen,
    setCardView,
    setChosenEvidence,
    triggerImpact,
    setTriggerImpact
  } = useContext(IdeaContext);


  const SALESFORCE = "salesforce";
  const [loading, setLoading] = useState(false);

  const handleAttachFromSearch = (textItem: any) => {
    setLoading(true);

    if (textItem?.chat?.itemType === "account") {
      if (!textItem.origin) {
        //if this is a cxItem account and not a cxOrg
        // check if the request has salesforce id
        let hasSalesforce = textItem.chatCompany.sfId;
        if (!hasSalesforce) {
          // support for sources on cxOrgs
          textItem?.chatCompany?.sources?.forEach((source: any) => {
            if (source.origin === SALESFORCE) {
              hasSalesforce = true;
            }
          });
        }
        if (hasSalesforce) {
          textItem.origin = SALESFORCE;
        } else {
          textItem.origin =
          textItem?.chatCompany.sources?.length > 0
            ? textItem?.chatCompany?.sources[0]?.origin
            : undefined;
        }
      }
    }

    const newRequest: any = {
      title: textItem?.title,
      type: textItem?.chat?.itemType,
      componentId: idea?.componentId,
      origin: textItem?.chat?.origin,
      originId: textItem?.chat?.originId,
      orgId: textItem.chat?.orgId,
      contactId: textItem.contactId,
      internalContactId: textItem.internalContactId,
      priority: defaultRequestPriority, 
      committedDate: textItem.committed,
      text: textItem?.text,
      textId: textItem?._id,
      businessName: textItem?.businessName,
      itemId: chosenEvidence?.parentId,
      ownerId: idea?.ownerId,
      source: "search",
    };
    ideaId &&
      addRequestsToIdea(ideaId, newRequest, auth0)
        .then((res) => {
          console.log("addRequestsToIdea", {requests: res.data.requests});
          reCalculatPerspectiveForRequest( {...res.data.requests[0], status:"validated"}, "add");
          setAddEvidenceFormScreen(false);
          setCardView(false);
          setChosenEvidence({});
          loadEvidence();
          setTriggerImpact(!triggerImpact)
        })
        .catch((e) => console.log(e))
        .finally(() => {
          navToPerspective();
          setActiveSearch(false);
          showNotification({
            title: "Evidence added successfully",
            color: "teal",
            message: ``,
            icon: <IconCheck size="1.1rem" />,
          });
          setLoading(false);
        });
  };

  return (
    <Flex
      justify="space-between"
      px="35px"
      pb="20px"
      h="64px"
      sx={{ /* borderTop: "1px solid #D8D8DB", */ borderBottom: "1px solid #D8D8DB" }}
      align="center"
    >
      <Flex align="center" w="107px" mr="12px" sx={{ cursor: "default" }}>
        <Box>
          <IconBell size={15} color="#D8D8DB" />
        </Box>

        <Text ml="4px" color="#D8D8DB" fz={12} lineClamp={1}>
          Activity
        </Text>
      </Flex>
      <Flex align="center">
        <Box>
          <Button
            fz="12px"
            color="indigo"
            w="75px"
            loading={loading}
            styles={(theme) => ({
              root: {
                height: "32px",
                backgroundColor: "#5C5CEB",
                border: 0,
                fontWeight: 400,
                padding: 0,
                "&:hover": {
                  backgroundColor: theme.fn.darken("#5C5CEB", 0.05),
                },
              },

              leftIcon: {
                marginRight: 15,
              },
            })}
            onClick={() => {
              handleAttachFromSearch(chosenEvidence);
            }}
          >
            <IconPlus size={14} />
            <Text>assign</Text>
          </Button>
        </Box>
      </Flex>
    </Flex>
  );
};

export default SearchActions;