import { Buffer } from "buffer";
import { Button, Text, TextInput } from "@mantine/core";
import { useState } from "react";

export const SetupAuth = () => {
  const [subdomainInputValue, setSubdomainInputValue] = useState<
    string | undefined
  >();

  const getAuthUri = () => {
    const scope = encodeURIComponent(
      "" + window.__RUNTIME_CONFIG__.REACT_APP_ZENDESK_SCOPE
    );
    const redirectUri = encodeURIComponent(
      "" + window.__RUNTIME_CONFIG__.REACT_APP_OAUTH_CALLBACK
    );
    const _subdomainInputValue = subdomainInputValue?.replace('https://','').replace('.zendesk.com','').replace('/','')

    const metadata = { integrationType: "zendesk", subdomain: _subdomainInputValue };
    const base64State = Buffer.from(JSON.stringify(metadata)).toString("base64");
    return `https://${_subdomainInputValue}.zendesk.com/oauth/authorizations/new?response_type=code&client_id=${window.__RUNTIME_CONFIG__.REACT_APP_ZENDESK_APP_ID}&scope=${scope}&redirect_uri=${redirectUri}&state=${base64State}`;
  };

  return (
    <>
      <TextInput
        placeholder={"subdomain e.g. https://mysubdomain.zendesk.com"}
        mt={"md"}
        value={subdomainInputValue}
        onChange={(event) => setSubdomainInputValue(event.currentTarget.value)}
      />
      <Button mt={"md"} component={"a"} href={getAuthUri()}>
        Authorize
      </Button>
    </>
  );
};
