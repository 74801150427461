import Comment from "../../Comment";

export function Comments({
  comments,
  deleteComment,
  editComment,
  handleSubmitComment,
  loading
}: {
  idea: any;
  request: any;
  origin: string;
  comments: any;
  deleteComment: (commentId: string) => void;
  editComment: (comment: any, text: string) => void;
  handleSubmitComment: () => void,
  loading: any
}) {
  return (
    <>
      {comments.map((comment: any, index: number) => (
        <Comment
          comment={comment}
          index={index}
          key={comment._id}
          handleSubmitComment={handleSubmitComment}
          editComment={editComment}
          deleteComment={deleteComment}
          loading={loading}
        />
      ))}
    </>
  );
}
