import { useContext, useState } from "react";
import EvidencesHeader from "./EvidencesHeader";
import InitialEvidenceScreen from "./InitialEvidenceScreen";
import FilteredSortedEvidence from "./FilteredSortedEvidence";
import EvidenceResults from "./EvidenceResults";
import { Box } from "@mantine/core";
import IdeaContext from "../IdeaContext";
import Inbox from "./Inbox";

interface BodyProps {
  chooseMatch: any; // Consider specifying more detailed types if possible
  addEvidenceFormScreen: boolean;
  setAddEvidenceFormScreen: React.Dispatch<React.SetStateAction<boolean>>;
  setCardView: React.Dispatch<React.SetStateAction<boolean>>;
  activeSortOrFilter: boolean;
  displayedRequests: any[]; // Consider specifying what type of objects are in the array
  // sortEvidence: any;
  // activeSortValue: string;
  activeSearch: boolean;
  searchEvidence: any;
  searchedEvidence: any[]; // Consider specifying what type of objects are in the array
  query: string;
  textItemsResults: any[]; // Consider specifying what type of objects are in the array
  sortDescending: boolean;
  chosenEvidence: any; // Consider specifying the type if known
}

const Body: React.FC<BodyProps> = ({
  chooseMatch,
  addEvidenceFormScreen,
  setAddEvidenceFormScreen,
  setCardView,
  activeSortOrFilter,
  displayedRequests,
  activeSearch,
  searchEvidence,
  searchedEvidence,
  query,
  textItemsResults,
  sortDescending,
  chosenEvidence
}) => {

  const {
    activeInboxTab
  } = useContext(IdeaContext);

  /* console.log(activeInboxTab) */

  const [showMatchesResults, setShowMatchesResults] = useState(true);
  const [showRequestsResults, setShowRequestsResults] = useState(true);
  const [showQueryResults, setShowQueryResults] = useState(true);

  return (
    <>
      <Box /* h='80px' */>
        <EvidencesHeader
          addEvidenceFormScreen={addEvidenceFormScreen}
          setAddEvidenceFormScreen={setAddEvidenceFormScreen}
          setCardView={setCardView}
          searchEvidence={searchEvidence}
          activeSearch={activeSearch}
          searchedEvidence={searchedEvidence}
          showMatchesResults={showMatchesResults}
          setShowMatchesResults={setShowMatchesResults}
          showRequestsResults={showRequestsResults}
          setShowRequestsResults={setShowRequestsResults}
          showQueryResults={showQueryResults}
          setShowQueryResults={setShowQueryResults}
          textItemsResultsLength={textItemsResults?.length || 0}
          query={query}
          sortDescending={sortDescending}
        />

      </Box>
      
   
      <Box pt="sm" h="calc(100vh - 256px)" mah="calc(100vh - 256px)" sx={{
        overflowY:"scroll"
      }}>
        
        {activeSearch ? (
          <EvidenceResults
            displayedRequests={searchedEvidence}
            chooseMatch={chooseMatch}
            showMatchesResults={showMatchesResults}
            showRequestsResults={showRequestsResults}
            showQueryResults={showQueryResults}
            textItemsResults={textItemsResults}
            chosenEvidence={chosenEvidence}

          />
        ) : activeSortOrFilter ? (
          <FilteredSortedEvidence
            displayedRequests={displayedRequests}
            chooseMatch={chooseMatch}
            chosenEvidence={chosenEvidence}
          />
        ) : 
          activeInboxTab ?

            <Inbox chooseMatch={chooseMatch}/>
            :
            (
              <InitialEvidenceScreen
                chooseMatch={chooseMatch}
                chosenEvidence={chosenEvidence}
              />
            )}
      </Box>
      
    </>
  );
};

export default Body