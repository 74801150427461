import { SortState } from "../IdeaContext";

export const sortRequests = (requests: any[], activeSort: SortState | null) => {
  if (!activeSort) {
    return requests;
  }

  return [...requests].sort((a, b) => {
    const { column, direction } = activeSort;
    if (a[column] < b[column]) return -1 * direction;
    if (a[column] > b[column]) return 1 * direction;
    return 0;
  });
};