import moment from "moment";

export const getFixedDateRanges: {
  [x: string]: { lable: string; values: [Date | null, Date | null] };
} = {
  thisWeek: {
    lable: "This week",
    values: [
      new Date(moment().startOf("week").format("YYYY-MM-DD")),
      new Date(moment().endOf("week").format("YYYY-MM-DD")),
    ],
  },
  lastWeek: {
    lable: "Last week",
    values: [
      new Date(
        moment().startOf("week").subtract(1, "weeks").format("YYYY-MM-DD")
      ),
      new Date(moment().endOf("week").subtract(1, "weeks").format("YYYY-MM-DD")),
    ],
  },
  nextWeek: {
    lable: "Next week",
    values: [
      new Date(
        moment().startOf("week").add(1, "weeks").format("YYYY-MM-DD")
      ),
      new Date(moment().endOf("week").add(1, "weeks").format("YYYY-MM-DD")),
    ],
  },
  thisMonth: {
    lable: "This month",
    values: [
      new Date(moment().startOf("month").format("YYYY-MM-DD")),
      new Date(moment().endOf("month").format("YYYY-MM-DD")),
    ],
  },
  lastMonth: {
    lable: "Last month",
    values: [
      new Date(
        moment().startOf("month").subtract(1, "months").format("YYYY-MM-DD")
      ),
      new Date(
        moment().endOf("month").subtract(1, "months").format("YYYY-MM-DD")
      ),
    ],
  },
  nextMonth: {
    lable: "Next month",
    values: [
      new Date(
        moment().startOf("month").add(1, "months").format("YYYY-MM-DD")
      ),
      new Date(moment().endOf("month").add(1, "months").format("YYYY-MM-DD")),
    ],
  },
  thisQuarter: {
    lable: "This quarter",
    values: [
      new Date(moment().startOf("quarter").format("YYYY-MM-DD")),
      new Date(moment().endOf("quarter").format("YYYY-MM-DD")),
    ],
  },
  lastQuarter: {
    lable: "Last quarter",
    values: [
      new Date(
        moment().startOf("quarter").subtract(1, "quarters").format("YYYY-MM-DD")
      ),
      new Date(moment().endOf("quarter").subtract(1, "quarters").format("YYYY-MM-DD")),
    ],
  },
  nextQuarter: {
    lable: "Next quarter",
    values: [
      new Date(
        moment().startOf("quarter").add(1, "quarters").format("YYYY-MM-DD")
      ),
      new Date(moment().endOf("quarter").add(1, "quarters").format("YYYY-MM-DD")),
    ],
  },
  thisYear: {
    lable: "This year",
    values: [
      new Date(moment().startOf("year").format("YYYY-MM-DD")),
      new Date(moment().endOf("year").format("YYYY-MM-DD")),
    ],
  },
  lastYear: {
    lable: "Last year",
    values: [
      new Date(
        moment().startOf("year").subtract(1, "years").format("YYYY-MM-DD")
      ),
      new Date(moment().endOf("year").subtract(1, "years").format("YYYY-MM-DD")),
    ],
  },   
  nextYear: {
    lable: "Next year",
    values: [
      new Date(moment().startOf("year").add(1, "years").format("YYYY-MM-DD")),
      new Date(moment().endOf("year").add(1, "years").format("YYYY-MM-DD")),
    ],
  },
};