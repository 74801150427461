import React, {useEffect, useRef, useState} from "react";
import { capitalizeFirstLetters, completeOAuth } from "../../utils";
import {useAuth0} from "@auth0/auth0-react";
import {useLocation, useNavigate} from "react-router-dom";

export const CompleteIntegrationAuth = () => {
  const auth0 = useAuth0()
  const location = useLocation()
  const navigate = useNavigate()
  const [timesRun, setTimesRun] = useState(0);
  const counter = useRef<number>(0);
  const effectCalled = useRef<boolean>(false);
  const [completed, setCompleted] = useState<boolean>(false)
  const [integrationName, setIntegrationName] = useState<string>("New Source")
  const [integrationType, setIntegrationType] = useState<string>("")
  const [error, setError] = useState<string | undefined>(undefined)

  useEffect(() => {
    if (effectCalled.current) return;
    counter.current += 1;
    setTimesRun(counter.current);
    effectCalled.current = true;
    const params = new URLSearchParams(location.search);
    const code = params.get("code");
    const stateBase64 = params.get("state");
    if (code && stateBase64) {
      const metadata = JSON.parse(atob(stateBase64));
      if (metadata.integrationType) {
        const integrationType = metadata.integrationType;
        setIntegrationName(capitalizeFirstLetters(integrationType))
        setIntegrationType(integrationType)
        auth0
          .getAccessTokenSilently()
          .then((token: any) => {
            completeOAuth(token, integrationType, code, metadata.subdomain)
              .then(() => {
                navigate("/settings/integrations/"+integrationType);
              })
              .catch((e) => {
                console.log(e);
                setError("Integration failed: " + e.toString())
              })
              .finally(() => {
                setCompleted(true)
              });
          })
          .catch((e: any) => console.log(e));
      } else {
        console.log("bad state");
      }
    } else {
      setError("Integration failed: did not receive integration code")
      setCompleted(true)
    }
  }, [])
  return (
    <div className="integrations">
      {!completed ? (
        <h1>Connecting {integrationName}</h1>
      ) : (
        ""
      )}
      {!completed ? (
        <img src="../../../public/spinner2.svg" className="loading" />
      ) : (
        ""
      )}
      {completed && !error ? (
        <h1>Integration Complete</h1>
      ) : (
        ""
      )}
      {completed && error ? (
        <h1>{error}</h1>
      ) : (
        ""
      )}
    </div>
  );
}

