import { Box, Title, Text, Anchor } from "@mantine/core";

const CsvImportHead = () => {
  return (
    <Box>
      <Title order={4}>Import Idea and Evidence</Title>
      <Text size={"sm"}>
        How to{" "}
        <Anchor
          href="https://help.getbagel.com/en/articles/8695294-import-idea-and-evidence-data-via-csv"
          target="_blank"
        >
          Import Idea and Evidence data via CSV
        </Anchor>
      </Text>
    </Box>
  );
};

export default CsvImportHead;
