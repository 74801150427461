import React from "react";
import { Anchor, Box, Card, Group, Skeleton, Text } from "@mantine/core";

interface States {
  accessor: string;
  title: string;
}

function StatesCard({
  states,
  accountData,
  loading,
  setTab,
}: {
  states: States[];
  accountData: any;
  loading: boolean;
  setTab: React.Dispatch<React.SetStateAction<string | null>>;
}) {
  return (
    <Card withBorder mt="md" py="xs" radius="md">
      <Group grow noWrap>
        {states.map(({ accessor, title }, index: number) => (
          <Box
            key={accessor}
            sx={{
              borderRight:
                index + 1 < states.length
                  ? "1px solid rgb(222, 226, 230)"
                  : "none",
            }}
          >
            <Text size="sm">{title}</Text>
            <Group>
              <Text size="lg" weight="600">
                {loading ? (
                  <Skeleton my={7} height={14} width={50} />
                ) : (
                  <>
                    {accountData[accessor + "Count"]}{" "}
                    <Text size="xs" span>
                      ($ {accountData[accessor]?.toLocaleString() || 0})
                    </Text>
                  </>
                )}
              </Text>
            </Group>
            <Anchor
              sx={{ color: "#5C5CEA", float: "right" }}
              mr="sm"
              size="xs"
              onClick={() => setTab(accessor)}
            >
              View all
            </Anchor>
          </Box>
        ))}
      </Group>
    </Card>
  );
}

export default StatesCard;