import { useContext, useEffect, useState } from "react";
import { Box, Flex, Text } from "@mantine/core";
import IdeaContext from "../IdeaContext";
import ImpactPieChart from "./ImpactPieChart";
import CompaniesList from "./Companies/CompaniesList";

interface Company {
  _id: string;
  arr?: number;
  name: string;
}

interface Chat {
  _id: string;
  itemType?: string;
  amount?: number;
}

interface Request {
  company?: Company | null;
  chat?: Chat | null;
}

const Impact: React.FC = () => {
  const {
    displayedRequests,
    displayedMatches,
    displayedRequestsMatches,
    ideaId,
    showmatches,
    showValidated,
    showPending,
    activeInboxTab
  } = useContext(IdeaContext);

  const [totalArr, setTotalArr] = useState<number>(0);
  const [orgCount, setOrgCount] = useState<number>(0);
  const [amount, setAmount] = useState<number>(0);
  const [oppCount, setOppCount] = useState<number>(0);
  const [validatedArrPercentage, setValidatedArrPercentage] = useState(0);
  const [validatedAmountPercentage, setValidatedAmountPercentage] = useState(0);
  const [totalValidatedArr, setTotalValidatedArr] = useState<number>(0);
  const [totalValidatedAmount, setTotalValidatedAmount] = useState<number>(0);
  const [dynamicOrgCount, setDynamicOrgCount] = useState<number>(0);
  const [dynamicOrgIds, setDynamicOrgIds] = useState<string[]>([]);
  const [validatedOrgsCount, setValidatedOrgsCount] = useState<number>(0);
  const [validatedOppCount, setValidatedOppCount] = useState<number>(0);

  const calcImpact = (validated: Request[], pending: Request[]) => {
    const companyIds: string[] = [];
    const companyItems: any[] = [];
    const opportunityIds: string[] = [];
    let arr = 0;
    let accCount = 0;
    let oppAmount = 0;
    let cxOppCount = 0;
    let validatedArr = 0;
    let validatedAmount = 0;
    let totalOrgCount = 0;
    let validatedCompanyCount = 0;
    let validatedOpportunityCount = 0;

    validated.forEach((request) => {
      const { company, chat } = request;

      const isOppurtunity = chat?.itemType === "opportunity";

      if (company && company._id !== "N/A") {
        if (!companyIds.includes(company?._id)) {
          companyIds.push(company?._id);
          companyItems.push(company);
          totalOrgCount += 1;

          if (company?.arr && !isOppurtunity) {
            validatedCompanyCount += 1;
            accCount += 1;
            arr += company.arr;
            validatedArr += company.arr;
          }
        }
      }

      if (chat && isOppurtunity) {
        if (!opportunityIds.includes(chat?._id)) {
          opportunityIds.push(chat?._id);

          if (chat?.amount) {
            cxOppCount += 1;
            validatedOpportunityCount += 1;
            oppAmount += chat.amount;
            validatedAmount += chat.amount;
          }
        }
      }
    });

    pending.forEach((request) => {
      const { company, chat } = request;

      const isOppurtunity = chat?.itemType === "opportunity";

      if (company && company._id !== "N/A") {
        if (!companyIds.includes(company?._id)) {
          companyIds.push(company?._id);
          companyItems.push(company);
          totalOrgCount += 1;

          if (company?.arr && !isOppurtunity) {
            accCount += 1;
            arr += company.arr;
          }
        }
      }

      if (chat && isOppurtunity) {
        if (!opportunityIds.includes(chat?._id)) {
          opportunityIds.push(chat?._id);

          if (chat?.amount) {
            cxOppCount += 1;
            oppAmount += chat.amount;
          }
        }
      }
    });

    setTotalValidatedArr(validatedArr);
    setTotalValidatedAmount(validatedAmount);

    const validatedArrPercentageValue =
      arr !== 0 ? (validatedArr / arr) * 100 : 100;
    setValidatedArrPercentage(validatedArrPercentageValue);
    const validatedAmountPercentageValue =
      oppAmount !== 0 ? (validatedAmount / oppAmount) * 100 : 100;
    setValidatedOppCount(validatedOpportunityCount);
    setValidatedOrgsCount(validatedCompanyCount);
    setValidatedAmountPercentage(validatedAmountPercentageValue);
    setOrgCount(accCount);
    setTotalArr(arr);
    setOppCount(cxOppCount);
    setAmount(oppAmount);
    setDynamicOrgCount(totalOrgCount);
    setDynamicOrgIds(companyIds);
  };

  useEffect(() => {
    const _pendingRequests = displayedRequests.filter(
      (r: any) => r.status !== "validated"
    );

    const _validatedRequests = displayedRequests.filter(
      (r: any) => r.status === "validated"
    );

    const matchesCopy = activeInboxTab
      ? [...displayedRequestsMatches, ..._pendingRequests]
      : (showmatches && !showValidated)
        ? [...displayedMatches] : 
        (showmatches && showValidated) 
          ? [...displayedRequestsMatches, ..._pendingRequests, ...displayedMatches]
          : [];
          
    const validatedCopy = showValidated ? [..._validatedRequests] : [];

    calcImpact(validatedCopy, matchesCopy);
  }, [
    displayedRequests,
    displayedMatches,
    displayedRequestsMatches,
    ideaId,
    showmatches,
    showValidated,
  ]);

  return (
    <Box px={36} py={24}>
      <Flex mb={4}>
        <Text fw={600} fz={14} color="#212529" mb={15} mr={4}>
          Impact:
        </Text>
        <CompaniesList
          dynamicCount={dynamicOrgCount}
          dynamicOrgIds={dynamicOrgIds}
        />
      </Flex>

      <Flex>
        {/* Oppurtunity */}

        <ImpactPieChart
          title={["Opportunity", "Amount"]}
          validatedColor={showValidated ? "#5C5CEB" : "rgba(92, 92, 235, 0.6)"}
          pendingColor={(showmatches || showPending) ? "rgba(92, 92, 235, 0.6)" : "#5C5CEB"}
          validatedPercentage={validatedAmountPercentage}
          monetaryValue={amount}
          validatedMonetaryValue={totalValidatedAmount}
          customerCount={oppCount}
          validatedCustomerCount={validatedOppCount}
        />

        {/* Account */}

        <ImpactPieChart
          title={["Account", "ARR"]}
          validatedColor={showValidated ? "#5C5CEB" : "rgba(92, 92, 235, 0.6)"}
          pendingColor={(showmatches || showPending) ? "rgba(92, 92, 235, 0.6)" : "#5C5CEB"}
          validatedPercentage={validatedArrPercentage}
          monetaryValue={totalArr}
          validatedMonetaryValue={totalValidatedArr}
          customerCount={orgCount}
          validatedCustomerCount={validatedOrgsCount}
        />
      </Flex>
    </Box>
  );
};

export default Impact;
