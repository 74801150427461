import { useContext, useEffect, useState } from "react";
import { Box, Flex, Text } from "@mantine/core";
import DiscoveryContext from "../../DiscoveryContext";
import CompaniesList from "./Companies/CompaniesList";
import PieChartDiscovery from "./PieChartDiscovery";



interface Company {
  _id: string;
  arr?: number;
  name: string;
}

interface Chat {
  _id: string;
  itemType?: string;
  amount?: number;
}

interface Request {
  company?: Company | null;
  chat?: Chat | null;
}

const ImpactDiscovery: React.FC = () => {
  const { ideaId, showValidated, displayedMatches, displayedRequestsMatches} = useContext(DiscoveryContext);

  const [totalArr, setTotalArr] = useState<number>(0);
  const [orgCount, setOrgCount] = useState<number>(0);
  const [amount, setAmount] = useState<number>(0);
  const [oppCount, setOppCount] = useState<number>(0);
  const [validatedArrPercentage, setValidatedArrPercentage] = useState(0);
  const [validatedAmountPercentage, setValidatedAmountPercentage] = useState(0);
 
  const [dynamicOrgCount, setDynamicOrgCount] = useState<number>(0)
  const [dynamicOrgIds, setDynamicOrgIds] = useState<string[]>([])
 

  const calcImpact = (pending : Request[]) => {

    const companyIds: string[] = []
    const companyItems: any[] = []
    const opportunityIds: string[] = []
    let arr = 0;
    let accCount = 0;
    let oppAmount = 0;
    let cxOppCount = 0;
    
    let totalOrgCount = 0
    

    

    pending.forEach((request) => {
      const { company, chat } = request;

      const isOppurtunity = chat?.itemType === "opportunity";

      if(company && company?._id !== 'N/A'){
        
        if(!companyIds.includes(company?._id)){
          companyIds.push(company?._id)
          companyItems.push(company)
          totalOrgCount += 1;
      
          if (company?.arr  && !isOppurtunity ) {
            accCount += 1;
            arr += company.arr;
          }
        }   
      }
      
      if(chat && isOppurtunity){
      
        if(!opportunityIds.includes(chat?._id)){
          opportunityIds.push(chat?._id)

          if (chat?.amount) {
            cxOppCount += 1;
            oppAmount += chat.amount;
          }
        }
      }
    });
    
    setOrgCount(accCount);
    setTotalArr(arr);
    setOppCount(cxOppCount);
    setAmount(oppAmount);
    setDynamicOrgCount(totalOrgCount)
    setDynamicOrgIds(companyIds)
  };

  useEffect(() => {
    const matchesCopy = [...displayedMatches, ...displayedRequestsMatches]
      
    calcImpact(matchesCopy);
  }, [
    displayedMatches,
    displayedRequestsMatches,
    ideaId,
  ]);

  return (
    <Box px={60} py={24} w='100%' maw='500px'>
      <Flex mb={4}>
        <Text fw={600} fz={14} color="#212529" mb={15} mr={4}>
          Impact:
        </Text>
        <CompaniesList dynamicCount={dynamicOrgCount} dynamicOrgIds={dynamicOrgIds}/>
        
      </Flex>
      <Flex w='100%' maw='500px'>
        {/* Oppurtunity */}

        <PieChartDiscovery
          title={["Opportunity", "Amount"]}
          validatedColor={showValidated? "#5C5CEB" : "rgba(92, 92, 235, 0.6)"} 
          pendingColor={showValidated ? "rgba(92, 92, 235, 0.6)" : "#5C5CEB"}
          validatedPercentage={validatedAmountPercentage}
          monetaryValue={amount}
          customerCount={oppCount}
        />

        {/* Account */}

        <PieChartDiscovery
          title={["Account", "ARR"]}
          validatedColor={showValidated? "#5C5CEB" : "rgba(92, 92, 235, 0.6)"} 
          pendingColor={showValidated ? "rgba(92, 92, 235, 0.6)" : "#5C5CEB"}
          validatedPercentage={validatedArrPercentage}
          monetaryValue={totalArr}
          customerCount={orgCount}
        />
      </Flex>
    </Box>
  );
};

export default ImpactDiscovery;
