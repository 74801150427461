import {
  Grid,
  Avatar,
  Group,
  ActionIcon,
  Text,
  Menu,
  Box,
} from "@mantine/core";
import {
  IconDots,
  IconSend,
  IconTrash,
  IconPencil,
} from "@tabler/icons";
import moment from "moment";
import { useState } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { getBagelId } from "../../../../../utils";
import { getInitials } from "../../../../../utils/getInitials";
import { SourceIcon } from "../../../../../utils/SourceIcon";
import MentionTextarea, { MentionSuggestion } from "../../../../MentionTextarea";


const Comment = (props: {
  comment: any;
  index: number;
  handleSubmitComment: () => void;
  editComment: (comment: any, text: string) => void;
  deleteComment: (commentId: string) => void;
  searchUser: ({ query, }: {
    query: string;
}) => Promise<MentionSuggestion[]>;
  loading: any;
}) => {
  console.log({ props });

  const [value, setValue] = useState(props.comment.text);
  const [edit, setEdit] = useState(false);
  const { user } = useAuth0();
  const bagelId = getBagelId(user);
  const canEdit = bagelId === props.comment.userId;
  const editEnable = () => {
    setEdit(!edit);
  };

  return (
    <Grid mb={0} p={0} m={0} key={props.index} mr={2}>
      <Grid.Col span="content">
        <Avatar size="sm" color={"orange"} radius="xl">
          {getInitials(props.comment.userName)}
        </Avatar>
      </Grid.Col>
      <Grid.Col pt={0} span="auto">
        <Group position="apart">
          <Text size="xs" mt={4}>
            {props.comment.userName}
          </Text>
          <Group position="right" spacing={8}>
            {canEdit && (
              <Menu>
                <Menu.Target>
                  <Box h={16}>
                    <IconDots size={16} />
                  </Box>
                </Menu.Target>
                <Menu.Dropdown>
                  <Menu.Item
                    icon={<IconPencil size={14} />}
                    onClick={editEnable}
                  >
                    Edit comment
                  </Menu.Item>
                  <Menu.Item
                    color="red"
                    icon={<IconTrash size={14} />}
                    onClick={() => props.deleteComment(props.comment._id)}
                  >
                    Delete comment
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            )}
            {props.comment.createdAt && (
              <Text size="xs">{moment(props.comment.createdAt).fromNow()}</Text>
            )}
          </Group>
        </Group>
        
        <MentionTextarea<MentionSuggestion>
          placeholder="Your comment"
          getMentions={props.searchUser}
          commentValue={value}
          setCommentValue={setValue}
          editable={canEdit && edit}
          debounceWait={1000}
          // sx={{
          //   background: "#fff",
          //   border: "1px solid #D8D8DB",
          //   borderRadius: 8,
          //   fontSize: 12,
          // }}
          sx={{
            fontSize: 12,
            border: "1px solid #D8D8DB",
            borderRadius: 8,
            ...(!(canEdit && edit)
              ? {
                background: "#5C5CEA33",
                borderRadius: 8,
                borderWidth: 0,
              }
              : {})
          }}
          rightSection={
            <ActionIcon
              onClick={() => {
                setEdit(!edit);
                props.editComment(props.comment, value);
              }}
              size="md"
              mr={4}
              variant="filled"
              color="blue"
              radius="md"
              loading={props.loading.comment}
              disabled={value == ""}
            >
              <IconSend size={20} />
            </ActionIcon>
          }
        />

        {props.comment.origin && (
          <Group mt={3} position="right">
            <SourceIcon
              sourceName={props.comment.origin}
              width={15}
              height={15}
            />
          </Group>
        )}
      </Grid.Col>
    </Grid>
  );
};

export default Comment;
