import { Grid } from "@mantine/core";
import { Actions } from "./Actions";
import { RequestDetails } from "./RequestDetails";
import { SideBar } from "./SIdeBar";
import { UseListStateHandlers } from "@mantine/hooks";
import { useState } from "react";
import { saveMatch, saveRequest } from "../../../Api";
import { showNotification } from "@mantine/notifications";
import { IconCheck } from "@tabler/icons";
import { useAuth0 } from "@auth0/auth0-react";
import { MentionProvider } from "../../MentionTextarea/MentionContext"

export interface ModalContentInterface {
  idea: any;
  request: any;
  customizations: any;
  setValidatedRequests: React.Dispatch<React.SetStateAction<any>>;
  setOpened?: (bool: boolean) => void;
  setMatches: React.Dispatch<React.SetStateAction<any>>;
  matches: any;
}
interface ModalContent extends ModalContentInterface {
  onUpdated: (useLoading?: boolean) => void;
  customizations: any;
  requestsHandlers?: UseListStateHandlers<any[]> | undefined
  setMatches: React.Dispatch<React.SetStateAction<any>>;
  matches: any;
}



export function ModalContent(props: ModalContent) {

  const auth0 = useAuth0(); 

  const [ownerInModal, setOwnerInModal] = useState(
    props.request?.owner ??
      // props.request?.chat?.owner ??
      props.request?.idea?.owner ??
      props.idea?.owner ?? 
      null
  );
  const [productAreaNameInModal, setProductAreaNameInModal] = useState(
    props.request?.componentObj?.name);
    
  const [componentIdInModal, setComponentIdModal] = useState(
    props.request?.componentObj?._id);

  const [displayProductAreaMsg, setDisplayProductAreaMsg] = useState<boolean>(false);


  const [suggested, setSuggested] = useState<boolean>(props.request?.suggestedComponent)

  const updateProdudtArea = (productAreaName: string, componentId: string) => {

    setDisplayProductAreaMsg(false)
    setProductAreaNameInModal(productAreaName);
    setComponentIdModal(componentId)
    setSuggested(false)
  };

  const updateOwner = (owner: any) => {
    setOwnerInModal(owner);
  };

  console.log({componentIdInModal})

  const editOwner = (item: any, notification?: boolean) => {

    const itemObj = {
      id: props.request._id,
      ownerId: item.id,
    };

    if(props.request.modalType == 'match'){
      saveMatch(itemObj, auth0).then((res: any) => {
        props.onUpdated(false)
        props.requestsHandlers?.applyWhere(
          (i: any) => i._id == props.request._id,
          //@ts-ignore
          (i: any) => ({ ...i, 
            chat:{
              ...i?.chat,
              ownerId: item.ownerId
            }
          })
        );
        if(notification){
          showNotification({
            title: "Owner updated",
            color: "teal",
            message: `Match owner updated successfully`,
            icon: <IconCheck size="1.1rem" />,
          });
        }
      });
    }else{
      /* console.log({props.request, itemObj}) */
      saveRequest(itemObj, auth0).then((res) => {
        props.onUpdated(false) 
        props.requestsHandlers?.applyWhere(
          (i: any) => i._id == props.request._id,
          //@ts-ignore
          (i: any) => ({ ...i, ownerId: item.ownerId })
        );
        if (notification) {
          showNotification({
            title: "Owner updated",
            color: "teal",
            message: `Request owner updated to "${item?.name}" successfully`,
            icon: <IconCheck size="1.1rem" />,
          });
        }
      });
    }
  };

  return (
    <Grid columns={9}>
      <Grid.Col pb={0} span={9}>
        <Actions
          idea={props.idea}
          request={props.request}
          onUpdated={props.onUpdated}
          requestsHandlers={props.requestsHandlers}
          setOpened={props.setOpened}
          setValidatedRequests={props.setValidatedRequests}
          customizations={props.customizations}
          ownerInModal={ownerInModal}
          updateOwner={updateOwner}
          productAreaNameInModal={productAreaNameInModal}
          componentIdInModal={componentIdInModal}
          setComponentIdModal={setComponentIdModal}
          updateProdudtArea={updateProdudtArea}
          editOwner={editOwner}
          matches={props.matches}
          setMatches={props.setMatches} 
          displayProductAreaMsg={displayProductAreaMsg}
          setDisplayProductAreaMsg={setDisplayProductAreaMsg}
        />
      </Grid.Col>
      <Grid.Col pt={0} span={5}>
        <RequestDetails
          idea={props.idea}
          request={props.request}
          setValidatedRequests={props.setValidatedRequests}
          setOpened={props.setOpened}
          customizations={props.customizations}
          matches={props.matches}
          setMatches={props.setMatches}
        />
      </Grid.Col>

      {props.request.modalType !== "match" && (
        <Grid.Col py={0} span={4}>
          <MentionProvider>
            <SideBar
              idea={props.idea}
              request={props.request}
              setValidatedRequests={props.setValidatedRequests}
              onUpdate={props.onUpdated}
              setOpened={props.setOpened}
              customizations={props.customizations}
              ownerInModal={ownerInModal}
              componentIdInModal={componentIdInModal}
              productAreaNameInModal={productAreaNameInModal}
              updateOwner={updateOwner}
              editOwner={editOwner}
              matches={props.matches}
              setMatches={props.setMatches}
              displayProductAreaMsg={displayProductAreaMsg} 
              setDisplayProductAreaMsg={setDisplayProductAreaMsg}
            />
          </MentionProvider>
        </Grid.Col>
      )}
    </Grid>
  );
}
