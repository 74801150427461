import { Box, Card, Flex, Grid, Tabs, Text, Title } from "@mantine/core";
import { useState } from "react";
import { SourceIcon } from "../../../utils/SourceIcon";
import { DataTable } from "mantine-datatable";
import { useNavigate } from "react-router-dom";

const Objects = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<string | null>("Objects");

  const tabs = [
    { name: "Objects", disabled: false },
    { name: "Forms", disabled: true },
  ];

  const tableRecords = [
    {
      icon: "fingerprint",
      objName: {
        title: "Evidence",
        description: "List of evidence (end users or internal emp.)",
      },
      status: "Active",
      integration: "",
      slug: "evidence",
    },
    {
      icon: "idea-lightbulb",
      objName: {
        title: "Idea",
        description: "List of product ideas",
      },
      status: "Active",
      integration: "",
      slug: "idea",
    },
  ];

  const [data, setData] = useState(tableRecords);

  return (
    <Box bg={"#fff"}>
      <Title order={3} ml="sm" p={"sm"}>
        Objects & fields
      </Title>
      <Grid
        mx="sm"
        justify="space-between"
        align="flex-end"
        sx={{ borderBottom: "1px solid #F1F1F1" }}
      >
        <Grid.Col span="content" pb="0">
          <Tabs value={activeTab} onTabChange={setActiveTab}>
            <Tabs.List m="0" sx={{ borderBottom: 0 }}>
              {tabs.map((tab) => (
                <Tabs.Tab
                  key={tab.name}
                  value={`${tab.name}`}
                  disabled={tab.disabled}
                >
                  {tab.name}
                </Tabs.Tab>
              ))}
            </Tabs.List>
          </Tabs>
        </Grid.Col>
      </Grid>

      <Box
        p={"xl"}
        mt={"xs"}
        bg={"#F1F3F5"}
        sx={{ height: "calc(100vh - 54.8px - 43px - 58px)" }}
      >
        <Card
          p="md"
          radius="md"
          mt={"lg"}
          withBorder
          sx={{ padding: "0 !important" }}
        >
          <Box>
            <DataTable
              minHeight={150}
              noRecordsText="No results were found..."
              horizontalSpacing="sm"
              verticalSpacing="sm"
              sx={{
                "thead tr th": {
                  background: "#f3f3f3 !important",
                },
              }}
              onRowClick={(record: any, recordIndex: number) => {
                navigate(`${record.slug}`);
              }}
              columns={[
                {
                  accessor: "icon",
                  title: "",
                  width: 0,
                  render: ({ icon }: any) => <SourceIcon sourceName={icon} />,
                },
                {
                  accessor: "objName",
                  title: "Object Name",
                  width: 80,
                  textAlignment: "left",
                  render: ({ objName }: any) => (
                    <Text>
                      {objName.title}
                      <Text c="dimmed">{objName.description}</Text>
                    </Text>
                  ),
                },
                {
                  accessor: "status",
                  title: "Status",
                  width: 30,
                  textAlignment: "left",
                  render: ({ status }) => (
                    <Flex
                      mih={50}
                      gap={5}
                      justify="flex-start"
                      align="center"
                      direction="row"
                      wrap="wrap"
                    >
                      <SourceIcon sourceName={"active"} /> <Text>{status}</Text>
                    </Flex>
                  ),
                },
                {
                  accessor: "integration",
                  title: "Integrations",
                  width: 150,
                  textAlignment: "left",
                },
              ]}
              records={data}
            />
          </Box>
        </Card>
      </Box>
    </Box>
  );
};

export default Objects;
