import { Select } from "@mantine/core";
import { Dispatch, SetStateAction, useState } from "react";
import { ValueLabelPair } from "./FiltersMenu";

interface OperationSelectProps {
  operations: ValueLabelPair[];
  onChange?: Dispatch<SetStateAction<string | null | undefined>>;
}

const OperationSelect: React.FC<OperationSelectProps> = ({
  operations,
  onChange,
}) => {
  const [FilterOp, setFilterOp] = useState<string | null | undefined >(null);

  const handleChange = (val: any) => {
    setFilterOp(val);
    onChange && onChange(val);
  };
  return (
    <Select
      radius="md"
      mx={5}
      mb={5}
      placeholder="Pick one"
      value={FilterOp}
      onChange={handleChange}
      data={operations}
      sx={{
        input: {
          backgroundColor: "#F9FAFB",
          ":focus": {
            borderColor: "#b1b1b1;",
          },
        },
      }}
    />
  );
};

export default OperationSelect;

