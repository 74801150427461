import React, { useContext } from 'react';
import { NumberInput } from '@mantine/core';

import { Filter } from '../FiltersMenu';
import FiltersContext from '../context/FiltersContext';
import OperationSelect from '../OperationSelect';
import AddFilterBtn from '../AddFilterBtn';
import FilterFooter from '../FilterFooter';

interface NumberFilterProps {
  filters?: Filter;
  MenuType: "idea" | "evidence";
  // activeFilters: ActiveFilter[];
  // setActiveFilters: (obj: any) => void;

}

const NumberFilter: React.FC<NumberFilterProps> = ({
  MenuType
}) => {
  const {
    filters,
    setActiveFilters,
    operator,
    setOperator,
    numberValue,
    setNumberValue,
    selectedParentFilter,
    childFiltersValue,
    rangeDates  } = useContext(FiltersContext);


  const _selectedParentFilter: string[] = selectedParentFilter?.split('.') || [''];

  const selectedFilter = (_selectedParentFilter && _selectedParentFilter.length === 2)
  //@ts-ignore
    ? filters[MenuType][_selectedParentFilter[0]]?.ChildFilters[_selectedParentFilter[1]]
    : filters[MenuType][_selectedParentFilter[0]]

  const selectedHasOperations = selectedFilter?.operations?.length > 0 || false;

  return (
    selectedParentFilter && <>
      {selectedHasOperations && (
        <OperationSelect
          operations={selectedFilter.operations}
          onChange={(value) => {
            setOperator((prev: any) => ({
              ...prev,
              [selectedParentFilter]: value,
            }));
          }}
        />
      )}
      <NumberInput
        py="sm"
        radius="md"
        px={5}
        value={numberValue?.[selectedParentFilter]}
        onChange={(val: number) =>
          setNumberValue((prev) => ({
            ...prev,
            [selectedParentFilter]: val,
          }))
        }
        parser={(value: any) => value.replace(/\$\s?|(,*)/g, "")}
        formatter={(value: any) =>
          selectedFilter?.formatter
            ? !Number.isNaN(parseFloat(value))
              ? `${selectedFilter?.formatter} ${value}`.replace(
                /\B(?=(\d{3})+(?!\d))/g,
                ","
              )
              : `${selectedFilter.formatter} `
            : value
        }
      />

      <FilterFooter
        ApplyButton={
          <AddFilterBtn
            BtnText={`Apply`}
            selectedParentFilter={selectedParentFilter}
            childFiltersValue={childFiltersValue}
            disabled={
              !numberValue ||
                    !(operator && operator[selectedParentFilter])
            }
            onClick={() => {
              setActiveFilters((prevFilters: any) => [
                {
                  type: selectedFilter.type,
                  parent: selectedParentFilter,
                  operator: operator && operator[selectedParentFilter],
                  selected: rangeDates,
                  query: numberValue?.[selectedParentFilter] || 0,
                },
                ...prevFilters.filter(
                  (prevF: any) => prevF.parent !== selectedParentFilter
                ),
              ]);
              // resetFilters();
            }}
          />
        }
      />

      
    </>
  );
};

export default NumberFilter;
