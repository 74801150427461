import React, { Dispatch, SetStateAction, useState } from "react";
import {
  ActionIcon,
  Box,
  Button,
  Group,
  MultiSelect,
  Select,
  SelectItem,
  Text,
  Title,
} from "@mantine/core";
import { IconDots, IconDotsVertical } from "@tabler/icons";

interface TopProps {
  activeAmount: number;
  setActiveAmount: React.Dispatch<React.SetStateAction<number>>;
  amountOptions: number[];
  activeProductArea: string[];
  setActiveProductArea: (value: string[]) => void;
  productAreaOptions: (string | SelectItem)[];
  activeTopBy: string | undefined;
  setActiveTopBy: (value: string) => void;
}

const Top: React.FC<TopProps> = ({
  activeAmount,
  setActiveAmount,
  amountOptions,
  activeProductArea,
  setActiveProductArea,
  productAreaOptions,
  setActiveTopBy,
  activeTopBy,
  
}) => {
  const handleButtonClick = (value: number) => {
    setActiveAmount(value);
  };

  return (
    <Box
      pb="sm"
      sx={{
        borderBottom: "1px solid #F1F1F1",
      }}
    >
      <Title order={2}>Top Ideas</Title>
      <Group mt="md" position="apart" noWrap>
        <Group noWrap>
          <Group noWrap spacing={5}>
            <Text lineClamp={1} size="xs">
              Show top:
            </Text>
            {amountOptions.map((value) => (
              <Button
                key={value}
                color="indigo"
                size="xs"
                radius="md"
                compact
                onClick={() => handleButtonClick(value)}
                variant={activeAmount === value ? "filled" : "outline"}
              >
                {value}
              </Button>
            ))}
          </Group>
          <Group noWrap spacing={5}>
            <Text lineClamp={1} size="xs">
              By:
            </Text>
            <Select
              size="xs"
              radius="md"
              data={[
                { value: "aggregations.accounts.amount", label: "Accounts count" },
                { value: "aggregations.amount", label: "Total impact" },
              ]}
              value={activeTopBy}
              placeholder="Pick idea top by"
              onChange={setActiveTopBy}
            />
          </Group>
          <Group noWrap spacing={5}>
            <Text lineClamp={1} size="xs">
              Product area:
            </Text>
            <MultiSelect
              size="xs"
              radius="md"
              data={productAreaOptions}
              value={activeProductArea}
              defaultValue={["all"]}
              placeholder="Pick idea product area"
              onChange={setActiveProductArea}
            />
          </Group>
        </Group>
        <ActionIcon>
          <IconDots size="1.125rem" />
        </ActionIcon>
      </Group>
    </Box>
  );
};

export default Top;
