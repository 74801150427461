import moment from "moment";
import {Box, Card, Divider, Grid, Tabs, Text} from "@mantine/core";
import React, {useState} from "react";

const NotionIntegration = (props: { integration: any, reloadIntegration: () => void }) => {
  const tabs = [
    { name: "General" },
  ]
  const [activeTab, setActiveTab] = useState<string | null>("General");
  const integration = props.integration
  const inSetupMode = !integration || integration.setupStep !== -1
  if (inSetupMode) {
    return (<>Setup</>)
  }
  const createdAt = new Date(integration.createdAt)
  const createdAtString = moment(createdAt).format('MMMM Do, YYYY');
  return (<>
    <Grid
      mx="sm"
      justify="space-between"
      align="flex-end"
      sx={{ borderBottom: "1px solid #F1F1F1" }}
    >
      <Grid.Col span="content" pb="0">
        <Tabs value={activeTab} onTabChange={setActiveTab}>
          <Tabs.List m="0" sx={{ borderBottom: 0 }}>
            {tabs.map(tab => (
              <Tabs.Tab
                key={tab.name}
                value={`${tab.name}`}
              >
                {tab.name}
              </Tabs.Tab>
            ))}
          </Tabs.List>
        </Tabs>
      </Grid.Col>
    </Grid>
    <Box p={"xl"} mt={7} bg={"#F1F3F5"}>
      {/*<Card p={0} radius="md" mt={"lg"} withBorder>*/}
      {/*  <Text p={"20px"} color={'rgba(22, 24, 50, 0.5)'}>Deepen your understanding of product requests and user needs by importing from Salesforce accounts, opportunities, evidence, and cases.*/}
      {/*    Create dynamic, up-to-date segments that enable precise and powerful prioritization.*/}
      {/*    Follow-up on product updates and roadmap changes to keep your customers informed and close the feedback loop.*/}
      {/*  </Text>*/}
      {/*  <Text p={"20px"} color={'rgba(22, 24, 50, 0.5)'}>*/}
      {/*    Learn more about Bagel-Salesforce integration.*/}
      {/*  </Text>*/}
      {/*</Card>*/}
      <Card mt={"15px"} p={0}>
        <Grid>
          <Grid.Col span={2} p={"xl"}>
            <Text weight={700} color={"#5C5CEA"} size={"md"}>
            General
            </Text>
            <Text size={"sm"} mt={"sm"} mb={"sm"}>
            Integration details
            </Text>
          </Grid.Col>
          <Divider size="xs" orientation="vertical" />
          <Grid.Col span={"auto"} p={"xl"}>
            <Grid>
              <Grid.Col span={2}>
                <Text mt={"xs"} color={"#161832"} weight={"500"}>Domain:</Text>
              </Grid.Col>
              <Grid.Col span={6}>
                <Text color={"rgba(22, 24, 50, 0.8)"} mt={"xs"}>{integration.subdomain}</Text>
              </Grid.Col>
            </Grid>
            <Grid>
              <Grid.Col span={2}>
                <Text mt={"xs"} color={"#161832"} weight={"500"}>Created At:</Text>
              </Grid.Col>
              <Grid.Col span={6}>
                <Text color={"rgba(22, 24, 50, 0.8)"} mt={"xs"}>{createdAtString}</Text>
              </Grid.Col>
            </Grid>
          </Grid.Col>
        </Grid>
      </Card>
    </Box>
  </>)
}

export default NotionIntegration;