import { Box, Button, Flex, Stack, Textarea, Text, Menu, Tooltip } from "@mantine/core"
import { useContext, useState } from "react";
import DiscoveryContext from "./DiscoveryContext";
import { IconCheck, IconX } from "@tabler/icons";
import SyncContext from "../../context/SyncContext";
import BagelAutocomplete from "../general/BagelAutocomplete";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";


const NewIdeaForm = ({ setNewIdeaFormView }:{setNewIdeaFormView: React.Dispatch<React.SetStateAction<boolean>>;}) => {

  const { description, setDescription , convertFromDiscoveryToIdea, ideaId} = useContext(DiscoveryContext);

  const {
    components,
    domains,
    displayState,
  } = useContext(SyncContext);
  
  const [domain, setDomain] = useState(null);
  const [mandatoryDomain, setMandatoryDomain] = useState(false);
  const [productArea, setProductArea] = useState("Unassigned");
  const [component, setComponent] = useState<any>();
  const [productAreaMandatory, setProductAreaMandatory] =
    useState<boolean>(false);
  const [componentsByDomain, setComponentsByDomain] =
    useState<any[]>(components || []);
  const [title, setTitle] = useState<any>('');
  const filterComponentByDomain = (domainValue: string) => {
    const copy = [...components];
    const arr: any[] = copy.filter((s: any) => s.domain === domainValue) || [];
    setComponentsByDomain(arr);
    const names = arr.map((a: any) => a.name);
    if (!names.includes(productArea)) {
      setProductArea("Unassigned");
    }
  };

  const switchToIdea = ()=>{
    const obj = {
      id: ideaId,
      title:title,
      description: description,
      componentId: component?._id,
      state: 'active'
    }

    convertFromDiscoveryToIdea(obj)
  }


  return (
    <Stack 
      h={`calc(100vh - 115px)`}
      sx={{ borderRight: "1px solid #D8D8DB" }}
      justify="space-between"
    >

      {/* Title */}

      <Box py={24}
        px={36}>
         

        <Box mb={24}>
          <Flex mb={8}>
            <Text fz={14} fw={600} color="#212529" >Title </Text>
            {title.length < 3 && <Text ml={3} fz={12} color="red">*</Text>}
          </Flex>
        

          <Textarea
            sx={{borderRadius: 8}}
            minRows={1}
            mb={16}
            value={title}
            onChange={(event) => setTitle(event.currentTarget.value)}
          />
          <Flex
            my='lg'
            h={12}
            w='100%'
            px='xs'
          
          
            justify='flex-end'
            align='center'
          >


            <Flex 
              align='center' 
              ml='md'
              sx={{
                cursor: 'pointer'
              }}
              onClick={()=> setTitle('')}
            >
              <Box mr='3px' /* mt='2px' */>
                <IconX size={12} color='#5C5CEB'/>
              </Box>
          
              <Text fz="12px" color='#5C5CEB'> Clear</Text>
            </Flex> 
          
        
          </Flex> 
        </Box>

        {/* Description */}
        
        <Box mb={24}>
          <Flex mb={8}>
            <Text fz={14} fw={600} color="#212529">Description </Text>
            {description.length < 3 && <Text  ml={3} fz={12} color="red">*</Text>}
          </Flex>

        
          <Textarea
            sx={{borderRadius: 8}}
            minRows={5}
            mb={16}
            value={description}
            onChange={(event) => setDescription(event.currentTarget.value)}
            onBlur={(event) => {
              console.log(event.currentTarget.value);
          
            }}
          />
          <Flex
            my='lg'
            h={12}
            w='100%'
            px='xs'
          
          
            justify='flex-end'
            align='center'
          >


            <Flex 
              align='center' 
              ml='md'
              sx={{
                cursor: 'pointer'
              }}
              onClick={()=> setDescription('')}
            >
              <Box mr='3px' mt='2px'>
                <IconX size={12} color='#5C5CEB'/>
              </Box>
          
              <Text fz="12px" color='#5C5CEB'> Clear</Text>
            </Flex> 
          
        
          </Flex>
        </Box>
        <Box>


          {/* Domain */}

          {displayState.domain && (
            <Box mb={24}>
              <Flex mb={8}>
                <Text fz={14} fw={600} color="#212529">Domain </Text>
                {!domain && <Text ml={3} fz={12} color="red">*</Text>}
              </Flex>

              {/*  <Grid.Col span={9}> */}
              <Menu shadow="md" width={200} position="bottom-start">
                <Menu.Target>
                  <Box onClick={() => setMandatoryDomain(false)}>
                    <Button w={160} variant="default">
                      <Text fw={400}>
                        {domain ? domain : "Select domain"}
                      </Text>
                    </Button>
                    {mandatoryDomain && !domain ? (
                      <Text color="red" fz="11.6px" mt="2px" h={10}>
                          Please select domain before selecting product area
                      </Text>
                    ) : (
                      <Text color="red" fz="11.6px" mt="2px" h={10}></Text>
                    )}
                  </Box>
                </Menu.Target>

                <Menu.Dropdown mt={-10}>
                  <Menu.Label>Choose one...</Menu.Label>
                  {domains &&
                      domains.length > 0 &&
                      domains.map((item: any) => (
                        <>
                          <Menu.Item
                            key={item.name}
                            onClick={() => {
                              setDomain(item.name);
                              filterComponentByDomain(item.name);
                              
                            }}
                          >
                            {item.name}
                          </Menu.Item>
                        </>
                      ))}
                </Menu.Dropdown>
              </Menu>
            </Box>
          )}
   
          {/* Product area  */}
          <Box>
            
            <Flex mb={8}>
              <Text fz={14} fw={600} color="#212529">Product area </Text>
              {productArea === 'Unassigned' && <Text ml={3} fz={12} color="red">*</Text>}
            </Flex>

            <BagelAutocomplete
              disabled={!domain && displayState.domain}
              items={
                displayState.domain 
                  ? [...components].filter((s: any) => s.domain == domain)
                  : components
              }
              selected={productArea}
              onSelect={(item) => {
                setProductArea(item.name);
                setComponent(item)
                setProductAreaMandatory(true);
              
              }}
            >
              <Tooltip
                label={productArea ? "Product area" : "Select product area"}
              >
                <Button w={160} variant="default">
                  <Text fw={400} color="#212529">
                    {capitalizeFirstLetter(productArea || "unassigned")}
                  </Text>
                </Button>
              </Tooltip>
            </BagelAutocomplete>
          </Box>
        </Box>
      </Box>
      <Stack 
        justify="center"
        h={64}
        sx={{ borderTop: "1px solid #D8D8DB" }} 
        /* py={24} */
        px={36}
      >

        <Flex justify='space-between'>
          <Button 
            /* disabled={description.length < 3} */
            w={104}
            color="dark"
            variant="outline"
            leftIcon={<IconX size={14} color="#212529"/>}
            onClick={()=>{ setNewIdeaFormView(false)}}
          >
            <Text color="#212529" fz={12}>
          Cancel
            </Text>
        
          </Button>
          <Button 
            w={104}
            disabled={description.length < 3 || title.length < 3 || !component}
            styles={(theme) => ({
              root: {
                height: "32px",
                backgroundColor: "#5C5CEB",
                border: 0,
                fontWeight: 400,
                padding: 0,
                "&:hover": {
                  backgroundColor: theme.fn.darken("#5C5CEB", 0.05),
                },
              },
            })}
            leftIcon={<Box mr={1}>
              <IconCheck size={14} />
            </Box> }
            onClick={switchToIdea}
          >
            <Text fz={12}>
            Create
            </Text>
        
          </Button>
        </Flex>
      </Stack>
    </Stack>
  )
}

export default NewIdeaForm