import { Box, Text } from "@mantine/core"
import { useEffect } from "react";

const DescriptionWidthMeasurment = ({
  description,
  longDescriptionCheck
}: {
  description: string;
  longDescriptionCheck: any
}) => {
  useEffect(() => {
    
    
    longDescriptionCheck();
    
  }, [description]);

  return (  
    <>
      <Text h='0px' color="transparent" fw={400} fz='14px' w='150px'>Overview:</Text>
      <Box h='0px'>
        <Text h='0px' color="transparent" fz='14px' fw={400} sx={{lineHeight: 0 }}>{description}</Text>
      </Box>
    </> 
    
  )
}

export default DescriptionWidthMeasurment