import {
  Flex,
  Box,
  Text,
  Avatar,
  Group,
  Grid,
  Spoiler,
  createStyles,
  Highlight,
} from "@mantine/core";
import moment from "moment";
import { SourceIcon } from "../../../utils/SourceIcon";
import { getRequestIconByType, stripHtml } from "../../../utils";
import { useState } from "react";

const useStyles = createStyles(() => ({
  control: {
    color: "#5C5CEA",
    fz: "xs",
  },
}));

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export function EvidenceItem({ item, query}: { item: any, query: string }) {
  const { classes } = useStyles();
  const contactName =
    item.contact && item.contact?.name
      ? item.contact?.name
      : item.contact && item.contact?.email
        ? item.contact?.email
        : "";
  const customer = item.company ? item.company.name : contactName;
  const icon = getRequestIconByType(item.itemType);
  
  return (
    <>
      {item.itemType == "opportunity" && (
        <>
          <Group position="apart">
            <Flex>
              {icon && (
                <Avatar size={18} src={icon} alt={item?.company?.name} />
              )}
              <Text ml="sm" fz="md" tt="capitalize">
                {item.itemType}: {customer}
                {contactName ? <Text span>({contactName})</Text> : ""}
              </Text>
            </Flex>
            {item.amount && (
              <Text mb="sm" fz="md" fw={500}>
                {item.amount.toLocaleString()} $
              </Text>
            )}
          </Group>

          {item.title && (
            <Spoiler
              maxHeight={30}
              showLabel="See more"
              hideLabel="Hide"
              classNames={classes}
              sx={(theme) => ({ fontSize: theme.fontSizes.xs })}
            >
              <Text
                p={"sm"}
                sx={{
                  whiteSpace: "pre-line",
                }}
                fw={500}
                fz="xs"
                mb="xs"
              >
                <Highlight highlight={query}>{item.title ?? ""}</Highlight>
              </Text>

              <Text
                fz="xs"
                fw={400}
                mb="xs"
                sx={{
                  whiteSpace: "pre-line",
                }}
              >
                <Highlight highlight={query}>
                  {item.text
                    ? stripHtml(item.text.replace("</p><p>", "</p>\n<p>"))
                    : ""}
                </Highlight>
              </Text>
            </Spoiler>
          )}
          {item.text === null ||
            ("" && (
              <Box
                classNames={classes}
                sx={(theme) => ({ fontSize: theme.fontSizes.xs })}
              >
                <Text
                  p={"sm"}
                  sx={{
                    whiteSpace: "pre-line",
                  }}
                  fw={500}
                  fz="xs"
                  mb="xs"
                >
                  <Highlight highlight={query}>{item.title ?? ""}</Highlight>
                </Text>

                <Text fz="xs" fw={400} mb="xs">
                  <Highlight highlight={query}>
                    {item.text
                      ? stripHtml(item.text.replace("</p><p>", "</p>\n<p>"))
                      : ""}
                  </Highlight>
                </Text>
              </Box>
            ))}

          <Flex
            align="center"
            justify="space-between"
            hidden={item.type == "manual"}
          >
            {item.opportunityType && (
              <Box mr="sm">
                <Text fz="xs" c="dimmed">
                  Opp Type
                </Text>
                <Text fz="xs" fw={500}>
                  {item.opportunityType}
                </Text>
              </Box>
            )}
            {item.closedAt && (
              <Box mr="sm">
                <Text fz="xs" c="dimmed">
                  Close date
                </Text>
                <Text fz="xs" fw={500}>
                  {moment(item.closedAt).format("MMMM DD, YYYY")}
                </Text>
              </Box>
            )}
            {item.stageName ? (
              <Box mr="sm">
                <Text fz="xs" c="dimmed">
                  Stage
                </Text>
                <Text fz="xs" fw={500}>
                  {item.stageName}
                </Text>
              </Box>
            ) : (
              ""
            )}
            {item.renewalDate && (
              <Box mr="sm">
                <Text fz="xs" c="dimmed">
                  Renewal date
                </Text>
                <Text fz="xs" fw={500}>
                  {moment(item.renewalDate).format("MMMM DD, YYYY")}
                </Text>
              </Box>
            )}
            {item.origin ? (
              <Box mr="sm">
                <Text fz="xs" c="dimmed">
                  Source
                </Text>
                <Text fz="xs" fw={500}>
                  {item.origin ? (
                    <SourceIcon
                      sourceName={item.origin}
                      width={15}
                      height={15}
                    />
                  ) : (
                    "-"
                  )}
                </Text>
              </Box>
            ) : (
              ""
            )}
          </Flex>
        </>
      )}
      {item.itemType == "conversation" && (
        <>
          <Group position="apart">
            <Flex>
              {icon && (
                <Avatar size={18} src={icon} alt={item?.company?.name} />
              )}
              <Text ml="sm" fz="md" tt="capitalize">
                Case: {customer}
                {contactName ? <Text span>({contactName})</Text> : ""}
              </Text>
            </Flex>
          </Group>
          {item.title && (
            <Spoiler
              initialState={false}
              maxHeight={30}
              showLabel="see more"
              hideLabel="Hide"
              classNames={classes}
              sx={(theme) => ({ fontSize: theme.fontSizes.xs })}
            >
              <Text
                p={"sm"}
                sx={{
                  whiteSpace: "pre-line",
                }}
                fz="xs"
                fw={500}
                mb="xs"
              >
                <Highlight highlight={query}>{item.title ?? ""}</Highlight>
              </Text>

              {
                <Text
                  sx={{
                    whiteSpace: "pre-line",
                  }}
                >
                  {" "}
                  <Highlight highlight={query}>
                    {item.text
                      ? stripHtml(item.text.replace("</p><p>", "</p>\n<p>"))
                      : ""}
                  </Highlight>{" "}
                </Text>
              }
            </Spoiler>
          )}

          {item.text === null ||
            ("" && (
              <Box
                classNames={classes}
                sx={(theme) => ({ fontSize: theme.fontSizes.xs })}
              >
                <Text
                  p={"sm"}
                  sx={{
                    whiteSpace: "pre-line",
                  }}
                  fz="xs"
                  fw={500}
                  mb="xs"
                >
                  <Highlight highlight={query}>{item.title ?? ""}</Highlight>
                </Text>

                {(item.text || item.text === "") && (
                  <Highlight highlight={query}>
                    {item.text
                      ? stripHtml(item.text.replace("</p><p>", "</p>\n<p>"))
                      : ""}
                  </Highlight>
                )}
              </Box>
            ))}

          <Flex
            align="center"
            justify="space-between"
            hidden={item.type == "manual"}
          >
            {item.status && (
              <Box mr="sm">
                <Text fz="xs" c="dimmed">
                  Status
                </Text>
                <Text fz="xs" fw={500}>
                  {item.status}
                </Text>
              </Box>
            )}
            {item.conversationType ? (
              <Box mr="sm">
                <Text fz="xs" c="dimmed">
                  Type
                </Text>
                <Text fz="xs" fw={500}>
                  {item.conversationType}
                </Text>
              </Box>
            ) : (
              ""
            )}
            {item.priority ? (
              <Box mr="sm">
                <Text fz="xs" c="dimmed">
                  Priority
                </Text>
                <Text fz="xs" fw={500}>
                  {item.priority}
                </Text>
              </Box>
            ) : (
              ""
            )}
            {item.itemUpdatedAt && (
              <Box mr="sm">
                <Text fz="xs" c="dimmed">
                  Last updated
                </Text>
                <Text fz="xs" fw={500}>
                  {moment(item.itemUpdatedAt).format("MMMM DD, YYYY")}
                </Text>
              </Box>
            )}
            {item.origin ? (
              <Box mr="sm">
                <Text fz="xs" c="dimmed">
                  Source
                </Text>
                <Text fz="xs" fw={500}>
                  {item.origin ? (
                    <SourceIcon
                      sourceName={item.origin}
                      width={15}
                      height={15}
                    />
                  ) : (
                    "-"
                  )}
                </Text>
              </Box>
            ) : (
              ""
            )}
          </Flex>
        </>
      )}
    </>
  );
}
