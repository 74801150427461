import { Box, Card, Flex, Text } from "@mantine/core";
import { SourceIcon } from "../../../utils/SourceIcon";
import { DataTable } from "mantine-datatable";
import { useNavigate } from "react-router-dom";
// import moment from "moment";

function Ideas() {
  const navigate = useNavigate();
  const options = [
    {
      icon: "list",
      title: "Priority",
      description: "Idea priority for roadmap (by impact)",
      type: "Single select",
      key: "priority",
      required: true,
    },
    {
      icon: "list",
      title: "Status",
      description: "Idea status for workflow management",
      type: "Single select",
      key: "status",
      required: true,
    },
    {
      icon: "display",
      title: "Display",
      description: "Toggle on or off displays",
      type: "Single select",
      key: "display",
      required: true,
    },
  ];

  return (
    <Box
      p={"xl"}
      bg={"#F1F3F5"}
      sx={{ height: "calc(100vh - 55.8px - 44px - 48px)" }}
    >
      <Card
        p="md"
        radius="md"
        mt={"lg"}
        withBorder
        sx={{ padding: "0 !important" }}
      >
        <Box>
          {options?.length > 0 && (
            <DataTable
              minHeight={150}
              noRecordsText="No results were found..."
              horizontalSpacing="sm"
              verticalSpacing="sm"
              sx={{
                "thead tr th": {
                  background: "#f3f3f3 !important",
                },
              }}
              onRowClick={(idea: any, ideaIndex: number) => {
                navigate(`${idea.key}`);
              }}
              columns={[
                {
                  accessor: "icon",
                  title: "",
                  width: 30,
                  render: ({ icon }: any) => (
                    <SourceIcon sourceName={icon} />
                  ),
                },

                {
                  accessor: "title",
                  title: "Display Name",
                  width: 150,
                  textAlignment: "left",
                  render: ({ description, required, title }) => (
                    <Flex
                      mih={50}
                      gap={5}
                      justify="flex-start"
                      align="flex-start"
                      direction="column"
                      wrap="wrap"
                    >
                      <Text>
                        {required && (
                          <Text span c="red">
                            *
                          </Text>
                        )}{" "}
                        {title}
                      </Text>
                      <Text c="dimmed">{description}</Text>
                    </Flex>
                  ),
                },
                {
                  accessor: "type",
                  title: "Type",
                  width: 60,
                  textAlignment: "left",
                  render: ({ type }) => <Text fw={500}>{type}</Text>,
                },
                // {
                //   accessor: "date",
                //   title: "Date modified",
                //   width: 60,
                //   textAlignment: "left",
                //   render: ({ icon }) => (
                //     <Text fw={500}>
                //       {moment(updatedAt).format("DD.MM.YYYY")}
                //     </Text>
                //   ),
                // },
              ]}
              records={options}
            />
          )}
        </Box>
      </Card>
    </Box>
  );
}

export default Ideas;
