import { Modal } from "@mantine/core";

export interface ModalContainerInterface {
  opened: boolean;
  setOpened: (bool: boolean) => void;
  children?: React.ReactNode;
}

export function ModalContainer(props: ModalContainerInterface) {
  return (
    <>
      <Modal
        size="60vw"
        padding={0}
        centered
        opened={props.opened}
        onClose={() => props.setOpened(false)}
        withCloseButton={false}
        closeOnClickOutside={false}
      >
        {props.children}
      </Modal>
    </>
  );
}
