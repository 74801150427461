import {
  Box,
  Button,
  Flex,
  Loader,
  Menu,
  Modal,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { deleteOptionAndReassign } from "../../../Api";
import { useAuth0 } from "@auth0/auth0-react";
import { SourceIcon } from "../../../utils/SourceIcon";
import { showNotification } from "@mantine/notifications";

interface ReassignModalProps {
  openReassignModal: boolean;
  setOpenReassignModal: (opened: boolean) => void;
  options: any;
  currentOption: {
    color: string;
    index: number;
    isDefault: boolean;
    key: string;
    label: string;
  };
  currentKey: string;
  fieldId: string;
  type: "status" | "priority"
}

interface FilteredOption {
  label: string;
  color: string;
  key: string;
  index?: number;
}

function ReassignValueModal({
  openReassignModal,
  setOpenReassignModal,
  options,
  currentOption,
  currentKey,
  fieldId,
  type
}: ReassignModalProps) {
  const auth0 = useAuth0();
  const theme = useMantineTheme();
  const [filteredOptions, setFilteredOptions] = useState<FilteredOption[]>([]);
  const [newKey, setNewKey] = useState("");
  const [formLoader, setFormLoader] = useState(false);

  const saveNotification = () => {
    showNotification({
      title: "Your settings have been saved!",
      message: "",
      icon: <SourceIcon sourceName="tick-circle" />,
    });
  };

  const cancelAndClose = () => {
    setOpenReassignModal(false);
    setNewKey("");
  };

  const reassignAndRemove = async (
    fieldId: string,
    currentKey: string,
    newKey: string
  ) => {
    try {
      setFormLoader(true);
      const index = options.findIndex((obj: any) => obj.key === currentKey);
      if(options[index].isDefault === true){
        options[index].isDefault = false
        options[0].isDefault = true
      }
      options.splice(index, 1);
      await deleteOptionAndReassign(
        auth0,
        fieldId,
        type,
        currentKey,
        newKey,
        options,
      ).then((res) => {
        setOpenReassignModal(false);
        saveNotification();
        setFormLoader(false);
        setNewKey("");
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const filteredOptions = options.filter(
      (option: any) => option !== currentOption
    );
    setFilteredOptions(filteredOptions);
    setNewKey("");
  }, []);

  return (
    <Modal
      opened={openReassignModal}
      onClose={() => setOpenReassignModal(false)}
      overlayColor={
        theme.colorScheme === "dark"
          ? theme.colors.dark[9]
          : theme.colors.gray[2]
      }
      overlayOpacity={0.55}
      overlayBlur={3}
    >
      {currentOption?.label && (
        <Box>
          <Text fw={700}>
            To remove the value{" "}
            <Text fs="italic" span>
              {currentOption.label}
            </Text>
            , you need to reassign the ideas which are linked to it.{" "}
            <Text color="red">This is irreversable.</Text>
          </Text>
          <Text>
            Please click "reassign value" to choose a new value to assign to the
            ideas with the current value of{" "}
            <Text fs="italic" span>
              {currentOption.label}
            </Text>
          </Text>

          {newKey.length > 0 && (
            <Text my={"md"}>
              You are about to reassign all ideas with value of{" "}
              <Text fs="italic" span>
                {currentOption?.label}
              </Text>{" "}
              to{" "}
              <Text fs="italic" span>
                {newKey}
              </Text>
            </Text>
          )}

          <Menu shadow="md" width={200} position="top" withArrow>
            <Menu.Target>
              <Button
                rightIcon={<SourceIcon sourceName="dropdown" />}
                variant="subtle"
                color="dark"
                px={"md"}
              >
                Reassign value
              </Button>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Label>Choose from the list below</Menu.Label>

              {filteredOptions &&
                filteredOptions.map((option) => (
                  <Menu.Item
                    key={option.key}
                    onClick={() => setNewKey(option.key)}
                  >
                    {option?.label}
                  </Menu.Item>
                ))}
            </Menu.Dropdown>
          </Menu>
          <Flex
            mt={"md"}
            gap="sm"
            justify="flex-end"
            align="center"
            direction="row"
          >
            <Button
              bg={"#5C5CEA"}
              sx={{
                fontWeight: 700,
                ":hover": { backgroundColor: "#9f9af8" },
              }}
              color={"#fff"}
              onClick={() => cancelAndClose()}
            >
              Cancel
            </Button>
            <Button
              bg={"#5C5CEA"}
              sx={{
                fontWeight: 700,
                ":hover": { backgroundColor: "#9f9af8" },
                "&[disabled]": {
                  pointerEvents: "all",
                  backgroundColor: "#fff",
                  cursor: "not-allowed",
                  ":hover": {
                    backgroundColor: "#fff",
                  },
                },
              }}
              color={"#fff"}
              onClick={() => reassignAndRemove(fieldId, currentKey, newKey)}
              disabled={newKey.length > 0 ? false : true}
            >
              {formLoader === false ? (
                "Save"
              ) : (
                <Loader color="violet" variant="dots" />
              )}
            </Button>
          </Flex>
        </Box>
      )}
    </Modal>
  );
}

export default ReassignValueModal;
