import { Buffer } from "buffer";
import { Button, Text } from "@mantine/core";

export const SetupAuth = () => {
  const getAuthUri = () => {
    const metadata = { integrationType: "productboard" };
    const base64State = Buffer.from(JSON.stringify(metadata)).toString(
      "base64"
    );
    return `${
      window.__RUNTIME_CONFIG__.REACT_APP_PRODUCTBOARD_OAUTH_URI
    }?&client_id=${
      window.__RUNTIME_CONFIG__.REACT_APP_PRODUCTBOARD_CLI_ID
    }&state=${base64State}&redirect_uri=${encodeURIComponent(
      window.__RUNTIME_CONFIG__.REACT_APP_OAUTH_CALLBACK
    )}&response_type=code`;
  };

  return (
    <>
      <Button mt={"md"} component={"a"} href={getAuthUri()}>
        Authorize
      </Button>
    </>
  );
};
