import React from "react";
import { Box, UnstyledButton, Group, Text } from "@mantine/core";
import { IconX } from "@tabler/icons";

interface FilterFooterProps {
  onClickClear?: () => void; // Function to call when the clear filter button is clicked
  ApplyButton?: React.ReactNode; // Optional React node for the apply button
}

const FilterFooter: React.FC<FilterFooterProps> = ({
  onClickClear,
  ApplyButton,
}) => {
  return (
    <Box
      sx={{
        backgroundColor: "#F8F9FA",
        borderRadius: "0 0 8px 8px",
        height: 48,
        alignContent: "center",
      }}
      pl="md"
      pr={8}
      py={8}
    >
      <Group position="apart">
        {onClickClear ? (
          <UnstyledButton onClick={onClickClear}>
            <Group
              spacing={5}
              sx={{
                color: "#5C5CEB",
              }}
            >
              <IconX size={13} />
              <Text size={13}>Clear filter</Text>
            </Group>
          </UnstyledButton>
        ) : (
          <Box></Box>
        )}
        {ApplyButton}
      </Group>
    </Box>
  );
};

export default FilterFooter;
