import { Accordion, Box, Button, Flex, Grid, Paper, Text } from "@mantine/core";
import { useState, useContext } from "react";
import SyncContext from "../../../../context/SyncContext";

export const ContactCard = (props: {contact: any}) => {

  const [show, setShow] = useState(true)
  const contact = props.contact
  const contactName =
    contact && contact?.name
      ? contact?.name
      : contact && contact?.email
        ? contact?.email
        : "Unknown User";

  return (
    <Accordion.Item value="contact" mt={"lg"} fz='14px'>
      <Accordion.Control bg="#F8F9FB" h={44} sx={{ borderRadius: "8px"}}>
        <Flex h={44} onClick={()=> setShow(!show)}>
          <Flex w={"100%"} justify='space-between' align="center">
            <Text>Contact</Text>
            {contactName && show && (
              <Button variant="light" compact color="gray" m={4}>
                <Text fz={12} mr={2} color="black"></Text>
                <Text color="black" fw="lighter" lineClamp={1}>
                  {" "}
                  {contactName}
                </Text>
              </Button>
            )}
          </Flex>
        </Flex>
      </Accordion.Control>
      <Accordion.Panel mb='md'>
        <>
          <Grid>
            {contact?.email && (
              <>
                <Grid.Col span={3}>
                  <Text color="#6B7083"> Email: </Text>
                </Grid.Col>
                <Grid.Col span={3}>
                  <Flex
                    w={"100%"}
                    align="center"
                    justify="center"
                    p={6}
                    bg="#fff"
                    h={32}
                    sx={{ borderRadius: "10px" }}
                  >
                    <Text color="black" fw="lighter" lineClamp={1}>
                      {" "}
                      {contact.email}
                    </Text>{" "}
                  </Flex>
                </Grid.Col>
              </>
            )}
            {contact?.title && (
              <>
                <Grid.Col span={3}>
                  <Text color="#6B7083"> Position: </Text>
                </Grid.Col>
                <Grid.Col span={3}>
                  <Flex
                    align="center"
                    justify="center"
                    p={6}
                    bg="#fff"
                    h={32}
                    sx={{ borderRadius: "10px" }}
                  >
                    <Text color="black" fw="lighter" lineClamp={1}>
                      {" "}
                      {contact.title}
                    </Text>{" "}
                  </Flex>
                </Grid.Col>
              </>
            )}

            {contact?.role && (
              <>
                <Grid.Col span={3}>
                  <Text color="#6B7083"> Role: </Text>
                </Grid.Col>
                <Grid.Col span={3}>
                  <Flex
                    align="center"
                    justify="center"
                    p={6}
                    bg="#fff"
                    h={32}
                    sx={{ borderRadius: "10px" }}
                  >
                    <Text color="black" fw="lighter" lineClamp={1}>
                      {" "}
                      {contact.role}
                    </Text>{" "}
                  </Flex>
                </Grid.Col>
              </>
            )}
          </Grid>
        </>
      </Accordion.Panel>
    </Accordion.Item>)


}