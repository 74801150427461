import * as React from "react"

const TeamsIcon = (props: any) => (
  <svg {...props} width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <rect y="0.5" width="25" height="25" fill="url(#pattern-image0_3470_12939)"/>
    <defs>
      <pattern id="pattern-image0_3470_12939" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use xlinkHref="#image0_3470_12939" transform="scale(0.0078125)"/>
      </pattern>
      <image id="image0_3470_12939" width="128" height="128" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIAAAACACAMAAAD04JH5AAAAA3NCSVQICAjb4U/gAAAAGFBMVEVHcEwVFzITFi8UFDAWFjAVFzEAACoWGDJ7mRxgAAAAB3RSTlMAj0AicswGMRPy4AAAAAFiS0dEAIgFHUgAAAFnSURBVHic7dvtEoIgEIVhAYH7v+OmErUCPdvA9DHv/tWBR2oYPFvTJJePWa3o9WHlmuXprzV3nz+Y5s859AbYFmDAEjgjwAH4W4Dzx+WGAzrdBwAAAAAAAAAAAAAAAAAAAAB4G6AWAAC9AckbAd3j6mQEpN6AKehpfc6xe1Z9IzQzwecLQ6av1LBdDwAAAAAAAADwWUCYnVwFINd8flSxtumtddbWtx5+7XVyXLacPN+reAwYPn/OAH4EMGAfsAGkd0vTTugBAAAAAAAAAF8M2CegK6CVe+5vriWlzZtrQ4cHWbvcmjwLgfEWEych4vfaKbCssRaZF67UYhAbEfenEpsGy/dH+zG4eAz2hmcq66VpAQA42foagPrW93T1eO8tVwEAAAAAAAAAAAAA2KaoVgug/NWw+qb9MvT3HMsBfAygdSqX1EPr6967s0nMB7Qxl9BDG3PRanGK1qxdNwmFu7anlc8gXgAh5o/aEacAzAAAAABJRU5ErkJggg=="/>
    </defs>
  </svg>

)

export default TeamsIcon
