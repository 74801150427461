import {
  Avatar,
  Grid,
  Table, Text,
} from "@mantine/core";
import React  from "react";
import MemberProfileIcon from "../../utils/icons/MemberProfileIcon";
import ReactTimeAgo from "react-time-ago";
import {getInitials} from "../../utils/getInitials";

const InvitesList = (props: { invites: any[]}) => {
  const inviteRows = props.invites.map((invite) => {
    return (
      <tr
        key={invite._id}
        // onClick={(event) =>
        //   preventClicksOnSubElements(event, ["BUTTON", "I", "svg"], () =>
        //     handleOnRowClick(request._id)
        //   )
        // }
      >
        <td>
          <Grid>
            <Grid.Col span={1} pl={"md"}>
              { invite?.picture ?
                (<Avatar src={invite.picture} radius="xl" />) :
                (<Avatar color="cyan" radius="xl">{invite.name && getInitials(invite.name)}</Avatar>)
              }
            </Grid.Col>
            <Grid.Col span={4}>
              <Text ml={"xl"}>{invite.name}</Text>
              <Text color={"#5B60E3"} ml={"xl"}>{invite.email}</Text>
            </Grid.Col>
          </Grid>
        </td>
        <td style={{textTransform: "capitalize"}}>{invite.role}</td>
        <td></td>
        <td><ReactTimeAgo date={invite.createdAt} /></td>
      </tr>
    );
  });
  return (
    <Table
      sx={{
        "thead tr:first-of-type": {
          borderColor: "#dee2e6",
          background: "#F3F4F6",
        },
        "tbody tr:last-of-type": {
          borderBottom: " 1px solid #dee2e6",
        },
        td: {
          textAlign: "left",
        },
        "tbody tr td": {
          padding: "13px 10px",
          borderBottom: "1px solid #dee2e6",
          fontSize: "14px",
        },
        "tbody tr:hover .flag:after": {
          borderRight: " 25px solid #f5f5f5",
        },
      }}
    >
      <thead>
        <tr>
          <th>Member</th>
          <th>Role</th>
          <th>Team</th>
          <th>Invited Date</th>
        </tr>
      </thead>
      <tbody>{ inviteRows }</tbody>
    </Table>
  )
};

export default InvitesList;