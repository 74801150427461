import {Paper, Text} from "@mantine/core";
import React from "react";

export const ExplainCard = () => {
  return <Paper mt={"lg"} withBorder  shadow={"lg"}>
    <Text p={"20px"} color={'rgba(22, 24, 50, 0.5)'}>With the ClickUp integration, you can access accounts, opportunities, evidence, and cases to deepen your understanding of product ideas and user needs. Dynamic and up-to-date segments enable you to prioritize your work effectively, ensuring that you deliver products that meet your customers' expectations. Keep your customers in the loop with updates and roadmap changes, closing the feedback loop for stronger relationships and greater satisfaction. For more information on the ClickUp integration, please visit our website.
    </Text>
    <Text p={"20px"} color={'rgba(22, 24, 50, 0.5)'}>
      Learn more about Bagel-ClickUp integration.
    </Text>
  </Paper>
}