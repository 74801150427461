import { Box, Button, Menu } from "@mantine/core";
import { IconPlus } from "@tabler/icons";

const AddFilterBtn = (props: {
  selectedParentFilter: string;
  childFiltersValue: { [key: string]: string[] };
  disabled?: boolean;
  onClick: () => void;
  BtnText: string;
}) => {
  return (
    <Box p={0} m={0} sx={{
      maxWidth:'-webkit-fill-available',
      "div":{
        maxWidth:'-webkit-fill-available',
      }
    }}>
      <Button
        fullWidth
        disabled={props.disabled}
        styles={(theme) => ({
          root: {
            backgroundColor: "#5B60E3",
            border: 0,
            height: 32,
            fontSize: 12,
            borderRadius: "4px",
            "&:hover": {
              backgroundColor: theme.fn.darken("#5B60E3", 0.05),
            },
          },
          label:{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }
        })}
        leftIcon={<IconPlus size={15}/>}
        onClick={props.onClick}
      >
        {props.BtnText}
      </Button>
    </Box>
  );
};

export default AddFilterBtn;
