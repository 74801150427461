import { Box, Text } from "@mantine/core"
import { stripHtml } from "../../../../utils"


const CardTextArea = ({ item }: { item: any }) => {

  const title = item?.textItem?.title || item?.title 
  const text = item?.textItem?.text || item?.text
  const origin =item?.origin || item?.textItem?.origin || item?.chat?.origin 
  const isGongCall = origin ==='gong'
  const excerpt = item?.textItem?.excerpt

  return (
    <Box mt={15} sx={{
      /* overflow:"scroll",
      maxHeight:"calc(100vh - 640px)",
      height:"calc(100vh - 640px)" */
    }}>
      {title &&
      <Text fz={14} fw={600} lineClamp={1}  mb={14}>
        {title}
      </Text>
      }

      {text &&
       <Box  py={8} px={12} bg='#F8F9FA' sx={{borderLeft: '2px solid rgba(92, 92, 235, 0.6)',}}>
         <Text fz={12} fw={600} color="#585C68" sx={{
           whiteSpace: "pre-line",
         }}>
           {stripHtml(text)}
         </Text>
         
       </Box>} 
      {excerpt && excerpt !=='' &&
      <Box  py={8} px={12} bg='#F8F9FA' sx={{borderLeft: '2px solid rgba(92, 92, 235, 0.6)'}} /* mt={2000} */>
        <Box fz={12}  /* color="#585C68" */ sx={{
          /* whiteSpace: "pre-line", */
        }}>
          <Text mb='5px' fw={600} style={{color:'black', fontSize:'12.5px' }}>Excerpt: </Text>  
          <Text sx={{
            whiteSpace: "pre-line",
          }}>{stripHtml(excerpt)}</Text>
        </Box>
         
      </Box>}
     
    </Box>
  )
}

export default CardTextArea