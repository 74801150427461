import { CreateNewForm } from "./CreateNewForm";

export interface ModalContentInterface {
  setOpened: (bool: boolean) => void;
  onUpdated: (useLoading?: boolean) => void
}

export function ModalContent(props: ModalContentInterface) {
  return (
    // <Grid>
    //   <Grid.Col span={8}>
    <CreateNewForm setOpened={props.setOpened} onUpdated={props.onUpdated}/>
    /* </Grid.Col>
      <Grid.Col span={4}>
        <SideAccordion/>
      </Grid.Col>
    </Grid> */
  );
}
