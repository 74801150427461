import {
  Flex,
  Text,
  Avatar,
  Group,
  createStyles,
  Spoiler,
  Badge,
  Tooltip,
  Box,
  Button,
} from "@mantine/core";
import { IconBolt } from "@tabler/icons";
import { stripHtml } from "../../../utils";
import { useHover } from "@mantine/hooks";

const useStyles = createStyles(() => ({
  control: {
    color: "#5C5CEA",
    fz: "xs",
  },
}));

export function ResultItem({
  item,
  linkToProductItemFn,
  linkLoading,
}: {
  item: any;
  linkToProductItemFn: (productItemId: string) => Promise<void>;
  linkLoading: boolean;
}) {
  const { classes } = useStyles();
  const { hovered, ref } = useHover();

  return (
    <Box ref={ref} p="xs">
      <Group position="apart" pb="xs">
        <Flex p={0} m={0}>
          <Avatar size={18} color="indigo">
            <IconBolt />
          </Avatar>
          <Text mt={-4} ml="xs" fz="md" tt="capitalize" fw={500}>
            {item?.itemKey || item?.title}{" "}
            <Text fw={500} span color="#00000090" fz="sm">
              ({item?.originType || "Task"})
            </Text>
          </Text>
        </Flex>
        {!hovered && item.status && (
          <Badge color="gray">
            {item.status}
          </Badge>
        )}
      </Group>
      <Text mb='2px' fz='13.5px' fw={500}>{item?.title}</Text>
      <Group position="apart" noWrap>
        
        <Spoiler
          maxHeight={20}
          showLabel="see more"
          hideLabel="Hide"
          classNames={classes}
          sx={(theme) => ({ fontSize: theme.fontSizes.xs, width: "100%" })}
        >
          {stripHtml(item?.description)}
        </Spoiler>
        {hovered && (
          <Button
            loading={linkLoading}
            styles={(theme) => ({
              root: {
                backgroundColor: "#5B60E3",
                border: 0,
                height: 35,
                "&:hover": {
                  backgroundColor: theme.fn.darken("#5B60E3", 0.05),
                },
              },

              leftIcon: {
                marginRight: 15,
              },
            })}
            mt='-30px !important'
            px="xl"
            radius="md"
            onClick={() => linkToProductItemFn(item._id)}
          >
            Link
          </Button>
        )}
      </Group>
    </Box>
  );
}
