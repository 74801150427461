import { Flex, Stack, Text } from "@mantine/core"
import { IconX } from "@tabler/icons";
import { useContext } from "react";
import IdeaContext from "../../IdeaContext";
import { useNavigate } from "react-router-dom";

const CardHeader = ({navToPerspective}:{navToPerspective: any}) => {
  const {
    setChosenEvidence,
  } = useContext(IdeaContext);
  const navigate = useNavigate();

  return (
    <>
      
      <Stack fz="20px" fw={600} h='20px' ta='center' justify="center">
        Evidence details
      </Stack>
      <Flex
        mr='8px'
        sx={{ cursor: " pointer" }}
        onClick={() => {
          navToPerspective()
          setChosenEvidence(null)
          const newPath = new URL(window.location.href);
          newPath.searchParams.delete('requestId');
          navigate(newPath.toString().substring(newPath.origin.length), { replace: true });
          /* setCardView(false); */
          /* setAddEvidenceFormScreen(!addEvidenceFormScreen); */
        }}
      >
        <IconX size={16} color="#5C5CEB" />
        <Text ml='3px' color="#5C5CEB" fz={12}>Close</Text>
            
      </Flex>
    
    </>
  )
}

export default CardHeader