import React, { createContext, ReactNode, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ActiveFilter, Filter } from '../FiltersMenu';

interface FiltersContextData {
    openedMenuType: "idea" | "evidence" | null;
    setOpenedMenuType: React.Dispatch<React.SetStateAction<"idea" | "evidence" | null>>;
    filters: {[key: string]: Filter};
    setFilter: React.Dispatch<React.SetStateAction<{[key: string]:Filter}>>;
    activeFilters: ActiveFilter[];
    setActiveFilters: React.Dispatch<React.SetStateAction<ActiveFilter[]>>
    selectedParentFilter: string | null;
    setSelectedParentFilter: React.Dispatch<React.SetStateAction<string | null>>;
    checkedComponents: { [key: string]: any[] };
    setCheckedComponents: React.Dispatch<React.SetStateAction<{[key: string]: any[];}>>
    childFiltersValue: { [key: string]: any[] };
    setChildFiltersValue: React.Dispatch<React.SetStateAction<{ [key: string]: any[] }>>;
    parentQuery: string;
    setParentQuery: React.Dispatch<React.SetStateAction<string>>;
    childQuery: { [x: string]: string } | undefined;
    setChildQuery: React.Dispatch<React.SetStateAction<{ [x: string]: string } | undefined>>;
    query: string | undefined;
    setQuery: React.Dispatch<React.SetStateAction<string | undefined>>;
    filteredComponents: any[];
    setFilteredComponents: React.Dispatch<React.SetStateAction<any[]>>;
    operator: { [x: string]: string } | undefined;
    setOperator: React.Dispatch<React.SetStateAction<{ [x: string]: string } | undefined>>;
    numberValue: { [parent: string]: number } | undefined;
    setNumberValue: React.Dispatch<React.SetStateAction<{ [parent: string]: number } | undefined>>;
    rangeDates: { [parent: string]: [Date | null, Date | null] } | undefined;
    setRangeDates: React.Dispatch<React.SetStateAction<{ [parent: string]: [Date | null, Date | null] } | undefined>>;
    searchedOptions: {[x: string]: string[];} | null
    setSearchedOptions: React.Dispatch<React.SetStateAction<{[x: string]: string[];} | null>>
  }
  

interface FiltersContextProviderProps {
  children: ReactNode;
}

const FiltersContext = createContext<FiltersContextData>({} as FiltersContextData);

export const FiltersContextProvider = ({ children }: FiltersContextProviderProps) => {
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search); 
  const _filters = queryParams.get("filters");

  // console.log({_filters})
  
  const [activeFilters, setActiveFilters] = useState<ActiveFilter[]>(
    _filters ? JSON.parse(_filters) : []
  );

  const [filters, setFilter] = useState<{[key: string]: Filter}>({});
  const [openedMenuType, setOpenedMenuType] = useState<"idea" | "evidence" | null>(null);
  const [selectedParentFilter, setSelectedParentFilter] = useState<string | null>(null);
  const [checkedComponents, setCheckedComponents] = useState<{ [key: string]: any[] }>({});
  const [childFiltersValue, setChildFiltersValue] = useState<{ [key: string]: any[] }>({});
  const [parentQuery, setParentQuery] = useState<string>("");
  const [childQuery, setChildQuery] = useState<{ [x: string]: string } | undefined>(undefined);
  const [query, setQuery] = useState<string | undefined>(undefined);
  const [filteredComponents, setFilteredComponents] = useState<any[]>([]);
  const [operator, setOperator] = useState<{ [x: string]: string } | undefined>(undefined);
  const [numberValue, setNumberValue] = useState<{ [parent: string]: number } | undefined>(undefined);
  const [rangeDates, setRangeDates] = useState<{ [parent: string]: [Date | null, Date | null] } | undefined>(undefined);
  const [searchedOptions, setSearchedOptions] = useState<{[x: string]: string[];} | null>(null);

  return (
    <FiltersContext.Provider value={{
      openedMenuType,
      setOpenedMenuType,
      filters,
      setFilter,
      activeFilters,
      setActiveFilters,
      selectedParentFilter,
      setSelectedParentFilter,
      checkedComponents,
      setCheckedComponents,
      childFiltersValue,
      setChildFiltersValue,
      parentQuery,
      setParentQuery,
      childQuery,
      setChildQuery,
      query,
      setQuery,
      filteredComponents,
      setFilteredComponents,
      operator,
      setOperator,
      numberValue,
      setNumberValue,
      rangeDates,
      setRangeDates,
      searchedOptions, 
      setSearchedOptions,
    }}>
      {children}
    </FiltersContext.Provider>
  );
  
};

export default FiltersContext;


