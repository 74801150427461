import * as React from "react"

const MembersIcon = (props: any) => (
  <svg {...props} width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <rect width="25" height="25" fill="url(#pattern-image0_3470_12935)"/>
    <defs>
      <pattern id="pattern-image0_3470_12935" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use xlinkHref="#image0_3470_12935" transform="scale(0.0078125)"/>
      </pattern>
      <image id="image0_3470_12935" width="128" height="128" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIAAAACACAMAAAD04JH5AAAAA3NCSVQICAjb4U/gAAAAOVBMVEVHcEwUFjASFC8VFzETFi8QEiwUFjAUFS8VFzEUFzALCyYVFzEUFjAVFzEVFzEUFjEVFjAVFzEWGDLSvq1fAAAAEnRSTlMAWSDrPhNKMHWtCNlouMeaifZpusUJAAAAAWJLR0QAiAUdSAAABL1JREFUeJztW9mW3CgMbfCO1/L/f+yUFwlhJHDXFE5yDvetA4iLENpc+fnJyMjIyPh3UZXL9FrX9TUtZfX87qpYKQr1KIdqWX0sz1EYmO03DM9sX03C/us6PaGERtx+Q5N8/zK4/7qWifcPnz+9DrSz12tRZVOq5eX8q064v+nJRovdSdNn2Zt0BFq7TecafNXZoTbZ/uQClDeoHrgEe8qaGa2tdhLtrwPn36BSqwAtbRYmzGifaQjgASWHW+GMJPujD5JjDkapJN5oiCmAqCBJXBxP4eP/nPMxwk/gAD6ElARC99t8hUA1QMoxDeS+4yZAjCAqTYaGezxvEw/8EYHmIi3qosy8XjGbjwkEpEnH9xbYW4e/QofA9cfxWWmh9VK+VVICXCACYECKSmMh51ubDiAZCTl6CBd9VBqHSlyxXzzGogABmLJEpXEYA0smolFZhXSKXD1IjpJe2bBZiqYVUG2P1IsEMGWsSHrCSWPPYKvNFp6KIUkg0ZBkhrjpSGI3K61gVlubqTmZb9JWRfxL0uxcXhpjh+gz3FCK4eWd6GH+/+KsqLLDJH10QxdeA5NUwdD1hvFeDTkAw8Du/z60iUrz1qP+rvaBytSOZYvT9hdzS5owcvXVeJja9dROXHEimCaXLUrz3gHctW+foLXNNujjWvvh4KAHWrHtVgd37b9YeGteWgNLfB8B9rRXXO0agzPNL1FAVV7WCAQmcclR8vkB1sVh3kDAPw6YkUfAiWMOwLpPpXH9KYszUsGFvjxpMNFzZuiHrg8MPTD4DlKFelC/luaPXItrvxzQbovQotCetKum0YZ8R1LwQ2y5xSuBGjb+oyCNCQZ40om+XYO+x9GMUVctFMp58m1EGlM62aOOdo2xDubquvRg/eI0eKMRaVwwIQ8MTJT4Hb7poJum4UNjF5TGFvhODjWrWjlPnnSkylrxqEsyKyiNz8lC7+u8s8r1ugx6KH+khvIGqbyUvdxxAboTJ1CcYUqeLHVYiJFesJsyU+aIO5ioNIEBz3o35HiTlmJzdIZPs7tgccZFu90D1MxACHVQWgD6amX97rVDBspjN7SGlxZGQ+9hPDKXWJOew7mS3kN3s3llyrbri75ry/O6LmVW0fO4uOdKkPYB6Ck8r0vhlD+fdKq0mqfCxTQrcgFt7BS0mCpZafIBdDjZsfcaRtxeFpbDHTd3z4ZueIzOpxBy3YBQh5Dizpu9JASC03LBlbQ8pJyNYqTWVN1ZEWwOubjlNgsbk82t/b0yS4aJC9sYoMA7+udKFhmh/owFOArH/qa2dtGCsN98Blmi0nYclkiTJ66b69SGNyHXhu+kiuyn6QZvlbCp2pcJvCnYK9/GrQIEHX+dAK0vNflD+tqXgIDV+kJejNQFT0GAFHzouEU/m4IA+baDNim6mSQEUPFYC8jZQxIC6PycFtSTBEDz2ICRI00aAhCvsJMg5zppCJSZQCaQCWQCmUAmkAlkAplAJgAE4r+NSUMASlL7kULsHyYhYH/xZPtJbcOji4xzQAKR8TeB0M99nsAP/b3LH8Ac/sVTemxNid9+jPkmjor0Tps4DaAk/1M6sBV5df+T4PcwO02pSt3r134L47P/MSojIyPjb8Z/aHV65uivK4wAAAAASUVORK5CYII="/>
    </defs>
  </svg>

)

export default MembersIcon