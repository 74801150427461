import { Buffer } from "buffer";
import { Button } from "@mantine/core";


export const SetupAuth = () => {

  const getAuthUri = () => {
    const metadata = {
      integrationType: "clickup",
    };
    const base64State = Buffer.from(JSON.stringify(metadata)).toString(
      "base64"
    );
    return `https://${ window?.__RUNTIME_CONFIG__?.REACT_APP_CLICKUP_OAUTH_URI
    }?client_id=${
      window?.__RUNTIME_CONFIG__?.REACT_APP_CLICKUP_CLI_ID
    }&redirect_uri=${encodeURIComponent(
      "" + window?.__RUNTIME_CONFIG__?.REACT_APP_OAUTH_CALLBACK
    )}&state=${base64State}`;
  };

  return (
    <>
      <Button
        mt={"md"}
        component={"a"}
        href={getAuthUri()}
      >
        Authorize
      </Button>
    </>
  );
};
