import { Box, Title, Divider, Text } from "@mantine/core";

const ImportResults = ({ importRes }: { importRes: any }) => {
  if (!importRes) {
    return null;
  }
  console.log({ ImportResults: importRes });
  return (
    <Box p="md">
      <Title order={4} mb="md">
        Import Results
      </Title>

      <Text size="xs">Evidence count: {importRes.evidenceCount}</Text>
      <Text size="xs">Ideas count: {importRes.ideasCount}</Text>
      <Text size="xs">Org IDs count: {importRes.orgIdsCount}</Text>
      <Text size="xs">
        Users by emails count: {importRes.usersByEmailsCount}
      </Text>
      <Text size="xs">CX orgs count: {importRes.cxOrgsCount}</Text>
      <Text size="xs">Op IDs count: {importRes.opIdsCount}</Text>
      <Text size="xs">CX items count: {importRes.cxItemsCount}</Text>
      <Divider my="sm" />

      <Text>Inserted Count: {importRes.ideasInsertManyRes?.insertedCount}</Text>
      <Divider my="sm" />

      <Text>Skipped: {importRes.skipped}</Text>
    </Box>
  );
};
export default ImportResults;
