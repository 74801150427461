import { Box } from "@mantine/core";
import Comment from "./Comment";
import { MentionSuggestion } from "../../../MentionTextarea";

export function CommentsNiv({
  comments,
  deleteComment,
  editComment,
  handleSubmitComment,
  searchUser,
  loading,
}: {
  comments: any;
  deleteComment: (commentId: string) => void;
  editComment: (comment: any, text: string) => void;
  searchUser: ({ query }: { query: string }) => Promise<MentionSuggestion[]>;
  handleSubmitComment: () => void;
  loading: any;
}) {
  return (
    <>
      {/* <Grid align="flex-start" gutter="xs" p={0}>
        <Grid.Col span={12}>
          <Box
          // sx={{ overflowY: "scroll", overflowX: "hidden", maxHeight: 400 }}
          > */}
      {comments.map((comment: any, index: number) => (
        <Box>
          <Comment
            comment={comment}
            index={index}
            key={comment._id}
            handleSubmitComment={handleSubmitComment}
            editComment={editComment}
            deleteComment={deleteComment}
            searchUser={searchUser}
            loading={loading}
          />
        </Box>
      ))}
    </>
  );
}
