import React from "react";
import { DataTable } from "mantine-datatable";
import moment from "moment";
import { Text, Box, Flex } from "@mantine/core";

interface TopRequestsTableProps {
  requests: any[];
  loading: boolean;
  slectedRow: {
    record: any;
    recordIndex: number;
  } | null;
  onRowClick: (record: any, recordIndex: number) => void;
}

function TopRequestsTable({
  requests,
  loading,
  slectedRow,
  onRowClick,
}: TopRequestsTableProps) {
  const opTypes = Object.keys(requests[0]?.aggregations?.opType || {});

  const columns = [
    {
      accessor: "title",
      title: (
        <Text sx={{ whiteSpace: "nowrap" }} size="sm">
          Request
        </Text>
      ),
      sortable: true,
      textAlignment: "left",
      render: ({ title }: any) => (
        <Box sx={{ maxWidth: "350px", textOverflow: "ellipsis" }}>
          <Text
            sx={{
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {title}
          </Text>
        </Box>
      ),
    },
    {
      accessor: "aggregations.accounts.count",
      title: (
        <Text sx={{ whiteSpace: "nowrap" }} size="sm">
          Accounts
        </Text>
      ),
      sortable: true,
      textAlignment: "center",
      render: ({ aggregations }: any) =>
        aggregations && aggregations.accounts.count > 0
          ? aggregations.accounts.count
          : "-",
    },
    {
      accessor: "aggregations.committed.closest",
      title: (
        <Text sx={{ whiteSpace: "nowrap" }} size="sm">
          Commitments
        </Text>
      ),
      sortable: true,
      textAlignment: "center",
      render: ({ aggregations }: any) =>
        aggregations?.committed?.count > 0 ? (
          <Flex justify={{ sm: "center" }} align={"center"}>
            <Text fz="md">{aggregations?.committed?.count}</Text>
            <Text size="sm" sx={{ marginLeft: "3px" }}>
              {aggregations?.committed?.closest
                ? `(${moment(aggregations?.committed?.closest).fromNow()})`
                : ""}
            </Text>
          </Flex>
        ) : (
          "-"
        ),
    },
    {
      accessor: "aggregations.internal.count",
      title: (
        <Text sx={{ whiteSpace: "nowrap" }} size="sm">
          Internal
        </Text>
      ),
      sortable: true,
      textAlignment: "center",
      render: ({ aggregations }: any) =>
        aggregations?.internal?.count && aggregations.internal.count > 0
          ? aggregations.internal.count
          : "-",
    },
    ...(opTypes.length > 0
      ? opTypes.map((opType) => ({
        accessor: "aggregations",
        title: opType,
        render: ({ aggregations }: any) =>  
          aggregations?.opType?.[`${opType}`]?.count > 0 || true /*TODO remove true */? (
            <Flex justify={{ sm: "center" }} align={"center"}>
              <Text fz="md">{aggregations?.opType?.[`${opType}`]?.count}</Text>
              <Text size="sm" sx={{ marginLeft: "3px" }}>
                {aggregations?.opType?.[`${opType}`]?.amount
                  ? `($${aggregations?.opType?.[`${opType}`]?.amount})`
                  : ""}
              </Text>
            </Flex>
          ) : (
            "-"
          ),
      }))
      : []),
    {
      accessor: "updatedAt",
      title: (
        <Text sx={{ whiteSpace: "nowrap" }} size="sm">
          Last Update
        </Text>
      ),
      render: ({ updatedAt }: any) => <Text size="sm">{moment(updatedAt).fromNow()}</Text> ,
    },
  ];

  return (
    <DataTable<any>
      height="auto"
      sx={{
        "thead tr th": {
          color: "#5B60E3 !important",
          background: "#f3f3f3 !important",
        },
        marginBottom: "20px",
      }}
      fetching={loading}
      columns={columns as any}
      records={requests}
      withBorder
      borderRadius="md"
      onRowClick={onRowClick}
    />
  );
}

export default TopRequestsTable;
