import { Box, Text } from "@mantine/core"
import { useEffect } from "react";

const TitleWidthMeasurment = ({
  title,
  longTitleCheck
}: {
  title: string;
  longTitleCheck: any
}) => {

  useEffect(() => {
    
    
    longTitleCheck();
    
  }, [title]);

  return (  
      
    <Box h='0px'>
      <Text h='0px' color="transparent" fz='20px' fw={400} sx={{lineHeight: 0 }}>{title}</Text>
    </Box> 
    
  )
}

export default TitleWidthMeasurment