export function replaceNullsWithZeros(arr: Array<any>): Array<any> {
  return arr.map((obj) => {
    const newObj = { ...obj };
    for (const key in newObj) {
      if (newObj[key] === null) {
        newObj[key] = 0;
      }
    }
    return newObj;
  });
}
