import {
  Paper,
  Text,
  Button,
  Group,
  Spoiler,
  ActionIcon,
  Avatar,
  Flex,
  Box,
  createStyles,
  LoadingOverlay,
  Tooltip,
} from "@mantine/core";
import { IconExternalLink } from "@tabler/icons";
import {
  getConnectedUserRole,
  getConnectedUserEmail,
  getDisplayFeedbackPriority,
  stripHtml
} from "../../../utils";
import { getInitials } from "../../../utils/getInitials";
import moment from "moment";
import { SourceIcon } from "../../../utils/SourceIcon";
import { validateRequest } from "../../../Api";
import { useContext, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { openModal } from "@mantine/modals";
import LogoIcon from "../../layouts/LogoIcon";
import { DeclineModalContent } from "./DeclineModalContent";
import { getRequestIconByType } from "../../../utils";
import wrapLinksWithElement from "../../../utils/WrapLinksWithElement";
import SyncContext from "../../../context/SyncContext";
import BusinessCategoryIcon from "../../../icons/BusinessCategoryIcon";

const useStyles = createStyles(() => ({
  control: {
    color: "#5C5CEA",
    fz: "xs",
  },
}));

export function RequestCard(props: {
  item: any;
  ideaId: string;
  handleModalOpen: (id: string, bool: boolean, modalType:string) => void;
  onRequestUpdated: (useLoading?: boolean) => void;
  idea: any,
}) {

  const auth0 = useAuth0();
  const { priorityData } = useContext(SyncContext)
  const [businessName, setBusinessName] = useState(props.item.textItem?.businessName || props.item.businessName);
  const role = getConnectedUserRole(auth0);
  const userEmail = getConnectedUserEmail(auth0);
  const canValidate = role === 'admin' || role === 'manager';
  const canDismiss = canValidate || props.item?.internalSubmitter?.email === userEmail;
  const { classes } = useStyles();
  const [validateLoading, setValidateLoading] = useState<boolean>(false);
  const company = props.item.company ? props.item.company : undefined;
  const attachedItem = props.item.chat
  const customer = company ? company?.name : "Internal";
  const origin = props.item.origin ? props.item.origin : props.item.type === 'conversation' ? props.item.chat.origin : 'Manual';
  const icon = getRequestIconByType(props.item.type ?? undefined)
  const submitter = props.item.internalSubmitter || props.item.cxSubmitter;
  const submitterName = submitter?.name || props.item.submitter?.name || props.item.submitter?.displayName || 'Unknown';

  const openDeclineModal = () => openModal({
    modalId: 'decline',
    title: <LogoIcon />,
    centered: true,
    size: 600,
    children: (
      <DeclineModalContent request={props.item} onUpdated={props.onRequestUpdated} idea={props.idea}/>
    )
  });

  const onValidateFeedback = (id: string) => {
    setValidateLoading(true)
    validateRequest(auth0, id).then(() => {
      props.onRequestUpdated(false)
      setValidateLoading(false)
    }).catch(e => console.log(e))
  }

  const text = attachedItem?.text ?? '';

  const element = <span style={{ color: 'blue' }} />
  const wrappedText = wrapLinksWithElement({ text: stripHtml(props.item.text ?? '') || '', element });
  const createdAtText = props.item?.createdAt ? moment(props.item.createdAt).fromNow() : ''
  useEffect(() => {

    if(!props.item.textItem?.businessName && !props.item.businessName){
      if(props.item.chat?.itemType === 'conversation' && props.item.chat?.caseNumber  && props.item.origin === 'salesforce'){
        setBusinessName('Support Request')
      }
      else if(props.item.chat?.itemType === 'conversation' && props.item.origin === 'zendesk'){
        setBusinessName('Support Request')
      }
      else if(props.item.chat?.itemType === 'account' || props.item.chat?.itemType === 'opportunity'){
        setBusinessName('GTM Request')
      }
      
    }
    
  }, []);
  
  return (
    <Paper p="md" radius="md" mb={"md"} withBorder>
      <LoadingOverlay visible={validateLoading} overlayBlur={1} />
      <Group position="apart" mb="xs">
        <Flex align="center" maw={"80%"}>
          {businessName ? (
            <Tooltip label={businessName}>
              <Box>
                <BusinessCategoryIcon businessName={businessName} />
              </Box> 
            </Tooltip>
          ) : (
            <Avatar src={icon} alt={customer} color="#5B60E3" size={26}>
              {getInitials(customer)}
            </Avatar>
          )}

          <Box ml="sm" mr="sm">
            <Text weight={500} lineClamp={1}>
              {customer}{" "}
              {origin && (
                <SourceIcon sourceName={origin} width={15} height={15} />
              )}
            </Text>
            <Text fz="xs" c="dimmed" lineClamp={1}>
              {submitterName}, {createdAtText}
            </Text>
          </Box>
        </Flex>
        <ActionIcon
          variant="transparent"
          sx={{ justifyContent: "right", marginTop: "-25px" }}
        >
          <IconExternalLink
            onClick={() =>
              props.handleModalOpen(props.item._id, true, "request")
            }
            size={16}
          />
        </ActionIcon>
      </Group>

      <Text size="sm" mb="sm">
        <Text>{props.item && props.item.title ? props.item.title : ""}</Text>
        <Spoiler
          maxHeight={52}
          showLabel="see more"
          hideLabel="Hide"
          classNames={classes}
        >
          <Text
            color="dimmed"
            mb="sm"
            py={"sm"}
            fz="sm"
            sx={{
              whiteSpace: "pre-line",
            }}
          >
            {wrappedText}
          </Text>
        </Spoiler>
      </Text>

      <Flex align="center">
        {attachedItem && attachedItem.opportunityType && (
          <Box mr="sm">
            <Text fz="xs" c="dimmed">
              Type
            </Text>
            <Text fz="xs" fw={500} sx={{ textTransform: "capitalize" }}>
              {attachedItem.opportunityType}
            </Text>
          </Box>
        )}

        <Box mr="sm">
          <Text fz="xs" c="dimmed">
            Priority
          </Text>
          <Text fz="xs" fw={500}>
            {priorityData.includes(props.item.priority)
              ? props.item.priority
              : getDisplayFeedbackPriority(props.item.priority)}
          </Text>
        </Box>
        {attachedItem && attachedItem.amount && (
          <Box mr="sm">
            <Text fz="xs" c="dimmed">
              Amount
            </Text>
            <Text fz="xs" fw={500}>
              {"$ " + attachedItem.amount.toLocaleString()}
            </Text>
          </Box>
        )}
      </Flex>
      <Flex justify="flex-end" align="flex-end">
        {canDismiss && (
          <Button
            styles={() => ({
              root: {
                borderColor: "#5B60E3",
                color: "#5B60E3",
                height: 35,
              },
            })}
            mt="sm"
            mr="md"
            px="md"
            radius="md"
            variant="outline"
            onClick={openDeclineModal}
          >
            Decline
          </Button>
        )}

        {canValidate && (
          <Tooltip label="Assign this Request to the Idea">
            <Button
              loading={validateLoading}
              styles={(theme) => ({
                root: {
                  backgroundColor: "#5B60E3",
                  border: 0,
                  height: 35,
                  "&:hover": {
                    backgroundColor: theme.fn.darken("#5B60E3", 0.05),
                  },
                },

                leftIcon: {
                  marginRight: 15,
                },
              })}
              mt="sm"
              px="xl"
              radius="md"
              onClick={() => onValidateFeedback(props.item._id)}
            >
              Assign
            </Button>
          </Tooltip>
        )}
      </Flex>
    </Paper>
  );
}
