import { Box, Flex, Menu, Text } from '@mantine/core'
import { IconBell } from '@tabler/icons'
import React, { useContext, useState } from 'react'
import CommentsSection from '../CommentsSection'
import { BusinessNameNotification } from '../../../../../icons/x-symbol-svgrepo-com'
import IdeaContext from '../../../IdeaContext'

const CommentsMenu = () => {

  const [opened, setOpened] = useState(false);

  /* console.log({item}) */
  const {
    getUnreadNotificationsCountByRequest,
    chosenEvidence
  } = useContext(IdeaContext);

  return (
    <Menu opened={opened} 
      // onChange={setOpened}
      position="left" offset={35}>
      <Menu.Target>
        <Flex align="center" w="107px" mr="12px" sx={{ cursor: "pointer" }}>
          <Box>
            {getUnreadNotificationsCountByRequest(chosenEvidence?._id) > 0 ? <BusinessNameNotification/> : <IconBell size={15} color="#5C5CEB" />}
            
          </Box>

          <Text ml="4px" color="#5C5CEB" fz={12} lineClamp={1} onClick={()=>setOpened((prev)=> !prev)}>
              Activity
          </Text>
        </Flex>
      </Menu.Target>

      <Menu.Dropdown p={0} m={0}>
        <CommentsSection item={chosenEvidence} setOpened={setOpened}/>
      </Menu.Dropdown>
    </Menu>
  )
}

export default CommentsMenu