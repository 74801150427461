import React  from 'react';
import {useNavigate} from "react-router-dom";

function AuthCallback() {
  const navigate = useNavigate()
  navigate('/')
  return <div className={"app-content-page"}>Loading...</div>
}

export default AuthCallback;
