import { useContext, useEffect, useState } from "react";
import { Card, Text, Box, Flex, Button, Tooltip } from "@mantine/core";
import ReactTimeAgo from "react-time-ago";
import {
  IconCoin,
  IconAlertOctagon,
  IconCategory,
  IconUsers,
} from "@tabler/icons";
import { SourceIcon } from "../../utils/SourceIcon";
import { UseListStateHandlers } from "@mantine/hooks";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";
import { DeclineModalContent } from "../idea/RightBar/DeclineModalContent";
import { openModal } from "@mantine/modals";
import LogoIcon from "../layouts/LogoIcon";
import { ProductAreaIcon } from "../../icons/x-symbol-svgrepo-com";
import { stripHtml } from "../../utils";
import SyncContext from "../../context/SyncContext";

const RequestCard = (props: {
  request: any;
  onAction: () => void;
  members: any[];
  handleModalOpen: (request: any, modalType: string) => void;
  requestsHandlers?: UseListStateHandlers<any[]>;
}) => {
  const initialAmount = props.request?.amount ?? props.request?.chat?.amount;
  const { requestPriorities } = useContext(SyncContext);
  const [color, setColor] = useState("");
  const [background, setBackground] = useState("");
  const [priorityCapital, setPriorityCapital] = useState("");
  const [hover, setHover] = useState(false);
  const [decline, setDecline] = useState(false);
  const [amount, setAmount] = useState(
    initialAmount > 0 ? initialAmount : null
  );

  const priority = props.request.priority ?? null;
  const priorityColor = (priority: string) => {
    if (requestPriorities) {
      const sortedPriorities = requestPriorities.sort((a: any, b: any) => {
        return a.index - b.index;
      });
      const defaultPriority = requestPriorities?.find((p: any) => p?.isDefault);
      const colorsByPriorityIndex = [
        { color: "#DB2525", bgColor: "#FFF9F9" },
        { color: "#FF8700", bgColor: "#FFFAF2" },
        { color: "#2684FF", bgColor: "#F7FAFF" },
        { color: "#0BAC4D", bgColor: "#F5FEF9" },
      ];

      const index = sortedPriorities.findIndex(
        (p: any) =>
          p.key?.toLowerCase() === priority?.toLowerCase() ||
          p.label?.toLowerCase() === priority?.toLowerCase()
      );
      const currentPriority = sortedPriorities[index];
      const clampedIndex =
        index < colorsByPriorityIndex.length
          ? index
          : colorsByPriorityIndex.length - 1;
      if (currentPriority) {
        setColor(colorsByPriorityIndex[clampedIndex].color);
        setBackground(colorsByPriorityIndex[clampedIndex].bgColor);
        setPriorityCapital(currentPriority.label);
      } else {
        setColor(colorsByPriorityIndex[2].color);
        setBackground(colorsByPriorityIndex[2].bgColor);
        setPriorityCapital(defaultPriority?.label ?? "Unassigned");
      }
    }
  };

  const openDeclineModal = () =>
    openModal({
      modalId: "decline",
      title: <LogoIcon />,
      centered: true,
      size: 600,
      children: (
        <DeclineModalContent
          request={props.request}
          onUpdated={props.onAction}
          idea={props.request.idea}
        />
      ),
    });

  useEffect(() => {
    priorityColor(priority);
  }, [requestPriorities]);

  const ownerName: any = props.request?.owner?.name || "Unassigned";
  const submitter =
    props.request.internalSubmitter || props.request.cxSubmitter;
  const submitterName = submitter?.name || props.request.submitter?.displayName;

  /* const amount = props.request.amount ?? props.request.chat?.amount ?? props.request.idea?.aggregations?.amount ?? null;*/

  const icon =
    props.request.origin === "zendesk" ? (
      <SourceIcon sourceName={props.request.origin} width={20} height={20} />
    ) : (
      <SourceIcon sourceName={props.request.origin} width={25} height={25} />
    );
  const oppurtunityType = props.request.chat?.opportunityType ?? null;
  const company =
    props.request?.chatCompany || props.request?.company || undefined;
  const productArea =
    props.request?.componentObj?.name ||
    props.request?.idea?.componentObj?.name ||
    "Unassigned";

  return (
    <Card
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      m="sm"
      mb={0}
      sx={{ "&:hover": { cursor: "pointer" } }}
      withBorder
      onClick={() => {
        if (!decline) {
          props.handleModalOpen(props.request, "request");
        }
      }}
    >
      <Box>
        <Flex justify="space-between" h={30}>
          <Flex>
            <Text
              fw={500}
              mr={7}
              maw="800px"
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                height: "30px",
              }}
            >
              {props.request.title}
            </Text>
            <Text fz="11px" color="#998e9c" mt="5.74px">
              {" "}
              <ReactTimeAgo date={new Date(props.request.updatedAt)} />
            </Text>

            {submitterName && (
              <Text ml={4.75} fz="11px" color="#998e9c" mt="5.74px">
                by {submitterName}
              </Text>
            )}
          </Flex>
          {hover && (
            <Flex justify="space-around">
              {" "}
              <div
                onMouseEnter={() => setDecline(true)}
                onMouseLeave={() => setDecline(false)}
              >
                <Tooltip label="You can provide a reason in the next step">
                  <Button
                    variant="outline"
                    color="indigo"
                    mr={7}
                    sx={{ borderRadius: "5px" }}
                    onClick={openDeclineModal}
                  >
                    Decline
                  </Button>
                </Tooltip>
              </div>
              <Button
                styles={(theme) => ({
                  root: {
                    backgroundColor: "#5B60E3",
                    border: 0,
                    height: 35,
                    "&:hover": {
                      backgroundColor: theme.fn.darken("#5B60E3", 0.05),
                    },
                  },

                  leftIcon: {
                    marginRight: 15,
                  },
                })}
              >
                Review
              </Button>
            </Flex>
          )}
        </Flex>

        {props.request.text && (
          <Text
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              paddingRight: "210px",
              height: "30px",
            }}
          >
            {props.request?.text ? stripHtml(props.request.text) : ""}
          </Text>
        )}
      </Box>
      <Flex
        w="75%"
        pt={10}
        pb={2}
        mt={2}
        sx={{ cursor: "default" }}
        onMouseEnter={() => setDecline(true)}
        onMouseLeave={() => setDecline(false)}
      >
        {priority && (
          <Tooltip label="Priority">
            <Flex
              bg={background}
              h="32px"
              mx={5}
              px="sm"
              py={0}
              sx={{ cursor: "default", borderRadius: "5px" }}
              align="center"
            >
              <Text mr={4} pt="4.2px" fz="14px" color={color}>
                {" "}
                <IconAlertOctagon size={14} />{" "}
              </Text>
              <Text color={color} fz="14px">
                {priorityCapital}
              </Text>
            </Flex>
          </Tooltip>
        )}
        {company?.name && (
          <Tooltip label="Account">
            <Flex
              h="32px"
              mx={5}
              px="sm"
              py={0}
              bg="#F8F9FB"
              align="center"
              sx={{ cursor: "default", borderRadius: "5px" }}
            >
              <Text color="black" fz="14px">
                {company?.name}
              </Text>
            </Flex>
          </Tooltip>
        )}
        {amount && amount !== 0 && (
          <Tooltip label="Amount">
            <Flex
              h="32px"
              mx={5}
              px="sm"
              py={0}
              bg="#F8F9FB"
              align="center"
              sx={{ cursor: "default", borderRadius: "5px" }}
            >
              <Text mr={4} mt={5}>
                <IconCoin color="black" size={18} />
              </Text>
              <Text fz={12} mr={2} color="black">
                {" "}
                ${" "}
              </Text>
              <Text fz="14px" color="black">
                {" "}
                {amount.toLocaleString()}
              </Text>
            </Flex>
          </Tooltip>
        )}
        {oppurtunityType && (
          <Tooltip label="Oppurtunity type">
            <Flex
              h="32px"
              mx={5}
              px="sm"
              py={0}
              bg="#F8F9FB"
              align="center"
              sx={{ cursor: "default", borderRadius: "5px" }}
            >
              <Text mr={4} mt={5}>
                {" "}
                <IconCategory color="black" size={14} />{" "}
              </Text>
              <Text fz="14px" color="black">
                {oppurtunityType}
              </Text>
            </Flex>
          </Tooltip>
        )}

        {props.request.origin && (
          <>
            <Tooltip label="Origin">
              <Flex
                h="32px"
                mx={5}
                px="sm"
                py={0}
                bg="#F8F9FB"
                align="center"
                sx={{ cursor: "default", borderRadius: "5px" }}
              >
                <Text mr={6} mt={props.request.origin === "pendo" ? 8 : 5}>
                  {icon}
                </Text>
                <Text fz="14px" color="black">
                  {capitalizeFirstLetter(props.request.origin)}
                </Text>
              </Flex>
            </Tooltip>
          </>
        )}
        {productArea && (
          <Tooltip label="Product area">
            <Flex
              h="32px"
              mx={5}
              px="sm"
              py={0}
              bg="#F8F9FB"
              align="center"
              sx={{ cursor: "default", borderRadius: "5px" }}
            >
              <Text mr={5.5} mt={2}>
                {" "}
                <ProductAreaIcon color="black" />{" "}
              </Text>

              <Text fz="14px" color="black">
                {capitalizeFirstLetter(productArea)}
              </Text>
            </Flex>
          </Tooltip>
        )}
        {ownerName && (
          <>
            <Tooltip label="Owner">
              <Flex
                h="32px"
                mx={5}
                px="sm"
                py={0}
                bg="#F8F9FB"
                align="center"
                sx={{ cursor: "default", borderRadius: "5px" }}
              >
                <Text mr={6} mt={5}>
                  <IconUsers color="black" size={16} />
                </Text>
                <Text fz="14px" color="black">
                  {capitalizeFirstLetter(ownerName)}
                </Text>
              </Flex>
            </Tooltip>
          </>
        )}
      </Flex>
    </Card>
  );
};

export default RequestCard;
