import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Checkbox,
  TextInput,
  Text,
  Highlight,
  Box,
  Group,
  Avatar,
  Divider,
} from "@mantine/core";
import { IconListSearch } from "@tabler/icons";
import { debounce } from "lodash"; // For debouncing input changes

import FiltersContext from "../context/FiltersContext";
import AddFilterBtn from "../AddFilterBtn";
import { useAuth0 } from "@auth0/auth0-react";
import { searchSelectedOrgs } from "../../../../Api";
import { getInitials } from "../../../../utils/getInitials";
import FilterFooter from "../FilterFooter";

// interface FetchableCheckboxFilterProps {}

const FetchableCheckboxFilter: React.FC<any> = ({
  MenuType,
  resetFilters,
}: {
  MenuType: "idea" | "evidence";
  resetFilters: (
    MenuType: "idea" | "evidence",
    selectedParentFilter?: string | null
  ) => void;
}) => {
  const auth0 = useAuth0();
  const {
    filters,
    activeFilters,
    setActiveFilters,
    selectedParentFilter,
    checkedComponents,
    setCheckedComponents,
    childFiltersValue,
    childQuery,
    setChildQuery,
    operator,
    setOpenedMenuType,
    setSelectedParentFilter
  } = useContext(FiltersContext);
  const [fetchedOptions, setFetchedOptions] = useState([]);

  const _selectedParentFilter: string[] = selectedParentFilter?.split(".") || [
    "",
  ];

  const selectedFilter =
    _selectedParentFilter && _selectedParentFilter.length === 2
      ? //@ts-ignore
      filters[MenuType][_selectedParentFilter[0]]?.ChildFilters[
        _selectedParentFilter[1]
      ]
      : filters[MenuType][_selectedParentFilter[0]];

  const selectedHasOperations = selectedFilter?.operations?.length > 0 || false;

  const searchSelectedOrgsFn = debounce((search: string) => {
    const ids = selectedParentFilter
      ? checkedComponents[selectedParentFilter]
      : undefined;
    console.log({ ids, checkedComponents, selectedFilter });
    searchSelectedOrgs({ query: search, ids }, auth0)
      .then((accounts) => {
        console.log({ accounts });
        setFetchedOptions(accounts);
      })
      .catch((e) => console.error("Fetching organizations failed:", e));
  }, 300);

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;
      selectedParentFilter &&
        setChildQuery((prev) => ({
          ...prev,
          [selectedParentFilter]: newValue,
        }));
      searchSelectedOrgsFn(newValue);
    },
    [selectedParentFilter, searchSelectedOrgsFn]
  );

  const handleCheckboxChange = useCallback(
    (selectedIds: any[]) => {
      console.log({ selectedIds });
      selectedParentFilter &&
        setCheckedComponents((prev) => ({
          ...prev,
          [selectedParentFilter]: selectedIds,
        }));
    },
    [selectedParentFilter]
  );

  useEffect(() => {
    const currentFilters: any = activeFilters.find(
      (aF) => aF.parent === selectedParentFilter
    );

    if (currentFilters?.selected && selectedParentFilter && currentFilters) {
      setCheckedComponents((prev) => ({
        ...prev,
        ...(currentFilters.selected
          ? {
            [selectedParentFilter]: currentFilters.selected.map((o: string) =>
              o.toLowerCase()
            ),
          }
          : {}),
      }));
    }
  }, [activeFilters]);

  useEffect(() => {
    selectedParentFilter &&
      searchSelectedOrgsFn(childQuery?.[selectedParentFilter] || "");
    const currentFilters: any = activeFilters.find(
      (aF) => aF.parent === selectedParentFilter
    );

    if (currentFilters?.selected && selectedParentFilter && currentFilters) {
      setCheckedComponents((prev) => ({
        ...prev,
        ...(currentFilters.selected
          ? {
            [selectedParentFilter]: currentFilters.selected.map((o: string) =>
              o.toLowerCase()
            ),
          }
          : {}),
      }));
    }
  }, []);

  return (
    selectedParentFilter && (
      <>
        <TextInput
          mx={5}
          radius="md"
          value={childQuery?.[selectedParentFilter] || ""}
          onChange={handleInputChange}
          icon={<IconListSearch size={14.5} />}
          sx={{
            input: {
              backgroundColor: "#F9FAFB",
              ":focus": {
                borderColor: "#b1b1b1;",
              },
            },
          }}
        />
        <Box
          h={300}
          sx={{
            overflowX: "hidden",
            msOverflowY: "scroll",
          }}
        >
          <Checkbox.Group
            value={checkedComponents[selectedParentFilter]}
            onChange={handleCheckboxChange}
            orientation="vertical"
            spacing="xs"
          >

            {checkedComponents[selectedParentFilter]?.length > 0 && <>
              {fetchedOptions
                ?.filter((option: any) =>
                  checkedComponents[selectedParentFilter]?.includes(option._id)
                )
                .map((option: any) => (
                  <Checkbox
                    key={option._id}
                    maw={260}
                    styles={{
                      body: {
                        alignItems: "center",
                      },
                    }}
                    sx={{
                      cursor: "pointer",
                      input: {
                        ":checked": {
                          backgroundColor: "#5B60E3",
                          borderColor: "#5B60E3",
                        },
                      },
                    }}
                    pr="sm"
                    pl="sm"
                    py={3}
                    value={option._id}
                    label={
                      <Group noWrap>
                        <Avatar size="md" radius="xl">
                          {getInitials(option.name)}
                        </Avatar>
                        <Text lineClamp={1}>
                          <Highlight
                            highlight={childQuery?.[selectedParentFilter] || ""}
                          >
                            {option.name}
                          </Highlight>
                        </Text>
                      </Group>
                    }
                  />
                ))}
              <Divider my={0} />
            </>}
            
            {fetchedOptions
              ?.filter(
                (option: any) =>
                  !checkedComponents[selectedParentFilter]?.includes(option._id)
              )
              .map((option: any) => (
                <Checkbox
                  key={option._id}
                  maw={260}
                  styles={{
                    body: {
                      alignItems: "center",
                    },
                  }}
                  sx={{
                    cursor: "pointer",
                    input: {
                      ":checked": {
                        backgroundColor: "#5B60E3",
                        borderColor: "#5B60E3",
                      },
                    },
                  }}
                  pr="sm"
                  pl="sm"
                  py={3}
                  value={option._id}
                  label={
                    <Group noWrap>
                      <Avatar size="md" radius="xl">
                        {getInitials(option.name)}
                      </Avatar>
                      <Text lineClamp={1}>
                        <Highlight
                          highlight={childQuery?.[selectedParentFilter] || ""}
                        >
                          {option.name}
                        </Highlight>
                      </Text>
                    </Group>
                  }
                />
              ))}
          </Checkbox.Group>
        </Box>

        <FilterFooter
          onClickClear={() => resetFilters(MenuType, selectedParentFilter)}
          ApplyButton={
            <AddFilterBtn
              BtnText="Apply"
              selectedParentFilter={selectedParentFilter}
              childFiltersValue={childFiltersValue}
              disabled={
                (selectedParentFilter !== null &&
                  childFiltersValue[selectedParentFilter]?.length === 0) ||
                (selectedHasOperations && !operator)
              }
              onClick={() => {
                setActiveFilters((prevFilters: any) => [
                  {
                    type: selectedFilter.type,
                    parent: selectedParentFilter,
                    operator: operator && operator[selectedParentFilter],
                    selected: checkedComponents[selectedParentFilter],
                    query: null,
                  },
                  ...prevFilters.filter(
                    (prevF: any) => prevF.parent !== selectedParentFilter
                  ),
                ]);
                setOpenedMenuType(null)
                setSelectedParentFilter(null)
              }}
            />
          }
        />
      </>
    )
  );
};

export default FetchableCheckboxFilter;
