import {
  Avatar,
  Box,
  Flex,
  Modal,
  ScrollArea,
  Stack,
  Text,
  Title,
  Tooltip,
} from "@mantine/core";
import { stripHtml } from "../../../../utils";
import { useContext, useEffect, useState } from "react";
import SyncContext from "../../../../context/SyncContext";
import { SourceIcon } from "../../../../utils/SourceIcon";
import { getRelatedTextItems } from "../../../../Api";
import { useAuth0 } from "@auth0/auth0-react";
import { getInitials } from "../../../../utils/getInitials";
import ReactTimeAgo from "react-time-ago";
import moment from "moment";
import TicketCommentItem from "./TicketCommentItem";

const ZendeskTicket = ({ item }: { item: any }) => {
  const { members } = useContext(SyncContext);
  const auth0 = useAuth0();
  const [opened, setOpened] = useState(false);
  const [sortedTextItems, setSortedTextItems] = useState<any[]>([]);
  const title = item?.textItem?.title || item?.title;
  const text = item?.textItem?.text || item?.text;
  const excerpt = item?.textItem?.excerpt;
  const cxItem = item?.chat;
  const origin = cxItem?.origin || item?.origin;
  const originContactId = cxItem?.originContactId;
  const originOwnerId = cxItem?.originOwnerId;
  const originSubmitterId = cxItem?.originSubmitterId;
  const contactEmail = cxItem?.contactEmail;
  const ownerEmail = cxItem?.ownerEmail;
  const submitterEmail = cxItem?.submitterEmail;
  const contactName = cxItem?.contactName;
  const ownerName = cxItem?.ownerName;
  const submitterName = cxItem?.submitterName;
  const status = item?.status
  const isMatch = status === 'match'
  const isFromSearch = status === 'search'
  const itemId = item?.itemId
  const chatId = item?.chatId
  const parentId = item?.parentId

  const loadRelatedTextItems = (idForCxItem : string) => {
    /* const cxItemId = isMatch ? chatId : isFromSearch ? parentId : itemId; */
    if (!idForCxItem) {
      /* console.log(`loadRelatedTextItems: no cx item id`, {chatId, itemId, item}) */
      return;
    }
    getRelatedTextItems(idForCxItem , auth0)
      .then((res) => {
        /* console.log(res?.data); */

        const items = res?.data?.cxItem?.comments || {};
        const commentFieldName = item?.textItem?.fieldName.split(".")[1] || "";
        /* console.log(commentFieldName); */
        // items[commentFieldName].highest = true;

        const itemsArray: any[] = Object.values(items);
        if (itemsArray?.length > 0) {
          const sortedItems: any[] =
            itemsArray?.sort(
              (a: any, b: any) =>
                new Date(a.createdAt).getTime() -
                new Date(b.createdAt).getTime()
            ) || [];

          sortedItems.forEach((commentItem: any) => {
            if (commentItem?.originSubmitterId && !commentItem?.fromEmail) {
              const orginSub = commentItem?.originSubmitterId;

              if (orginSub === originContactId) {
                commentItem.fromEmail = contactEmail;
              } else if (orginSub === originOwnerId) {
                commentItem.fromEmail = ownerEmail;
              } else if (orginSub === originSubmitterId) {
                commentItem.fromEmail = submitterEmail;
              }
            }

            if (commentItem?.originSubmitterId && !commentItem?.fromName) {
              const orginSub = commentItem?.originSubmitterId;

              if (orginSub === originContactId) {
                commentItem.fromName = contactName;
              } else if (orginSub === originOwnerId) {
                commentItem.fromName = ownerName;
              } else if (orginSub === originSubmitterId) {
                commentItem.fromName = submitterName;
              }
            }
          });

          sortedItems.forEach((commentItem: any) => {
            if (commentItem?.fromEmail) {
              const memberFromEmail = members.find(
                (m: any) => m.email === commentItem?.fromEmail
              );
              if (memberFromEmail) {
                commentItem.fromUser = memberFromEmail;
              }
            }
          });
          setSortedTextItems(sortedItems);
        }
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {

    const status = item?.status;
    const isMatch = status === "match";
    const isFromSearch = status === "search";
    const itemId = item?.itemId;
    const chatId = item?.chatId;
    const parentId = item?.parentId;
    const cxItemId = isMatch ? chatId : isFromSearch ? parentId : itemId;
    
    if(cxItemId){
      loadRelatedTextItems(cxItemId);
    }
  }, [item]);

  return (
    <Box mt={15}>
      <Modal
        opened={opened}
        zIndex={4567956}
        centered 
        onClose={() => setOpened(false)}
        overlayOpacity={0}
        size="800px"
        title={
          <Flex align="center" h={70} mx="sm">
            {origin && (
              <Tooltip label={origin || "N/A"}>
                <Stack h={20} w={20} mr={4} justify="center" align="center">
                  <SourceIcon sourceName={origin?.toLowerCase() || "N/A"} />
                </Stack>
              </Tooltip>
            )}
            <Flex pl={35} align="center" w={580}>
              <Tooltip label={cxItem?.title || item?.textItem?.title || "N/A"}>
                <Text ml="3px" fz={17} maw={470} w={470} fw={600} lineClamp={1}>
                  { item?.textItem?.title || cxItem?.title || "N/A"}
                </Text>
              </Tooltip>
            </Flex>
          </Flex>
        }
      >
        <Box h={600}>
          <Box h={542} w={578}>
            <ScrollArea h={500} mah={500} w={750} maw={750}>
              {sortedTextItems &&
                sortedTextItems.length > 0 ?
                sortedTextItems.map((sortedItem: any) => (
                  <TicketCommentItem sortedItem={sortedItem}/>
                )): <Text ml={80}>Support ticket not available</Text>  }
            </ScrollArea>
          </Box>
        </Box>
      </Modal>
      {title && (
        <Text fz={14} fw={600} lineClamp={1} mb={14}>
          {title}
        </Text>
      )}
      <ScrollArea mt={15} /* h={"calc(100vh - 700px)"} mah="calc(100vh - 700px)" */>
        
        {text && (
          <Box
            py={8}
            px={12}
            bg="#F8F9FA"
            sx={{ borderLeft: "2px solid rgba(92, 92, 235, 0.6)" }}
          >
            <Text
              fz={12}
              fw={600}
              color="#585C68"
              sx={{
                whiteSpace: "pre-line",
              }}
            >
              {stripHtml(text)}
            </Text>
          </Box>
        )}
        {excerpt && excerpt !== "" && (
          <Box
            py={8}
            px={12}
            bg="#F8F9FA"
            sx={{ borderLeft: "2px solid rgba(92, 92, 235, 0.6)" }}
          >
            <Box fz={12}>
              <Text
                mb="5px"
                fw={600}
                style={{ color: "black", fontSize: "12.5px" }}
              >
                Excerpt:{" "}
              </Text>
              <Text
                sx={{
                  whiteSpace: "pre-line",
                }}
              >
                {stripHtml(excerpt)}
              </Text>
            </Box>
          </Box>
        )}
      </ScrollArea>
      {
        <Box
          w='100%'
          py={8}
          px={12}
          bg="#F8F9FA"
          sx={{ borderLeft: "2px solid rgba(92, 92, 235, 0.6)" }}
        >
          <Text
            mt={10}
            fz={13}
            color="#5C5CEB"
            sx={{
              cursor: "pointer",
            }}
            onClick={() => setOpened(true)}
          >
            View full ticket
          </Text>
        </Box>
      }
    </Box>
  );
};

export default ZendeskTicket;

/* {sortedTextItems[0]?.text && <Box mr='xs' w='33px' pt='4.4px'>
  {item?.fromUser?.picture ? <Avatar  src={item?.fromUser?.picture} radius="xl" size={30}>
    {getInitials(item?.comment?.fromName)}
  </Avatar> : <Avatar radius="xl" size={30}/>} 
</Box>} */
