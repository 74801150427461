import React, { useState } from "react";
import Evidence from "./Evidence";
import Ideas from "./Ideas";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Button, Flex, Loader, Title } from "@mantine/core";
import { SourceIcon } from "../../../utils/SourceIcon";
import Display from "./Display";

const ObjectPages: any = {
  evidence: { component: Evidence },
  idea: { component: Ideas },
  display: { component: Display }
};

const Object = () => {
  const navigate = useNavigate();
  const { slug: ObjType } = useParams();
  const ObjectPage = ObjectPages[ObjType!].component;
  const [loading, setLoading] = useState<boolean>(false);

  const handlePrevPage = () => {
    navigate(-1);
  };

  if (loading) {
    return <Loader variant="dots" mt={"lg"} ml={"lg"} />;
  }

  return (
    <div>
      <Box pt={"md"}>
        <Flex
          ml="lg"
          mt={"xs"}
          mih={50}
          gap={0}
          justify="flex-start"
          align="center"
          direction="row"
          wrap="wrap"
          sx={{ borderBottom: "1px solid #F1F1F1" }}
        >
          <Button
            variant="subtle"
            color="gray"
            onClick={handlePrevPage}
            sx={{ borderRadius: "50%", padding: 0 }}
          >
            <SourceIcon sourceName="back" />
          </Button>
          <Title order={3} py={"sm"} ml={"md"} sx={{ textTransform: "capitalize" }}>
            {ObjType}
          </Title>
        </Flex>

        <ObjectPage />
      </Box>
    </div>
  );
};

export default Object;
