import { ModalContainer, ModalContainerInterface } from "./ModalContainer";
import { ModalContent } from "./ModalContent";
import { ModalHeader } from "./ModalHeader";


export function CreateNewModal(props: ModalContainerInterface) {
  return (
    <ModalContainer
      opened={props.opened}
      setOpened={(bool) => props.setOpened(bool)}
    >
      <ModalHeader
        setOpened={(bool) => props.setOpened(bool)}
      />
      <ModalContent
        setOpened={(bool: boolean) => props.setOpened(bool)}
        onUpdated={() => undefined}
      />
    </ModalContainer>
  );
}
