import {
  Badge,
  ActionIcon,
  Tooltip,
  Text,
  HoverCard,
  Stack,
  ScrollArea,
  Sx,
} from "@mantine/core";
import { IconCircleX, IconX } from "@tabler/icons";
import moment from "moment";
import { getFixedDateRanges } from "./FixedDateRanges";
import { useContext } from "react";
import SyncContext from "../../../context/SyncContext";
import { evidenceParents } from "./FiltersSpec";
import FiltersContext from "./context/FiltersContext";
import { OverflownText } from "../../OverflownText";

interface FilterBadgeProps {
  filter: any;
  ownerOptions: { [key: string]: any };
  onRemoveClick: () => void;
  selectedView?: any;
  MenuType: "idea" | "evidence";
  sx?: Sx | (Sx | undefined);
}

const FilterBadge: React.FC<FilterBadgeProps> = ({
  filter,
  ownerOptions,
  onRemoveClick,
  selectedView,
  sx,
}) => {
  const { components, allComponents } = useContext(SyncContext);
  const { setOpenedMenuType, setSelectedParentFilter } =
    useContext(FiltersContext);
  const componentsNames = components.map((c: any) => c.name);
  const allComponentsNames = allComponents.map((c: any) => c.name);
  let filterText = filter.parent.includes(".")
    ? filter.parent.split(".")[1]
    : filter.parent;
  let selected = filter?.selected || [];

  // console.log({filter, selected})

  const handleBadgeClick = () => {
    console.log({ parent: filter.parent });

    // Check if the parent filter's first part matches any of the evidence parents
    const isParentInEvidence = evidenceParents.some(
      (parent) => filter.parent.split(".")[0] === parent
    );

    // Set the menu type based on whether the parent is in evidence
    setOpenedMenuType(isParentInEvidence ? "evidence" : "idea");

    // Set the selected parent filter
    setSelectedParentFilter(filter.parent);

    // Log the selected parent filter
    console.log(filter.parent);
  };

  if (filter.parent === "Product area") {
    const namesArray: any[] = [];
    selected.forEach((s: any) => {
      const found = allComponents.find(
        (a: any) => a._id?.toString() === s?.toString()
      );
      namesArray.push(found?.name);
    });
    selected = namesArray;
  }
  // console.log({filter, selected})
  const safeSelected = Array.isArray(selected) ? selected : [];
  const copy = [...safeSelected];
  const copy2 = [...safeSelected];

  const sliced = copy.slice(0, 3);
  const secondSliced = copy2.slice(3, copy2.length);

  if (filter.type === "date") {
    filterText += filter.operator
      ? " " + getFixedDateRanges[filter.operator].lable
      : ` ${selected
        .map((date: any) => moment(new Date(date)).format("MMM Do YY"))
        .join(" - ")}`;
  } else if (filter.type === "checkbox") {
    if (safeSelected.length === 0) {
      return null;
    }
    if (filter.parent.toLowerCase() === "product area") {
      filterText += ` ${filter.operator ? filter.operator : "-"} ${
        ["owner", "mentions"].includes(filter.parent.toLowerCase())
          ? sliced === undefined
            ? "unassigned"
            : sliced
              .map((selected: string) => ownerOptions[selected])
              .join(", ")
          : sliced.join(", ") + "..."
      }`;
    } else {
      filterText += ` ${filter.operator ? filter.operator : "-"} ${
        ["owner", "mentions"].includes(filter.parent.toLowerCase())
          ? selected === undefined
            ? "unassigned"
            : selected.map((s: string) => ownerOptions[s]).join(", ")
          : selected.join(", ")
      }`;
    }
  } else if (filter.type === "fetchableCheckbox") {
    filterText = `${filter.parent} (${selected.length} selected)`;
  } else if (filter.type === "number") {
    filterText += ` ${filter.operator.replace("_", " ")} ${
      filter.query && filter.query
    }`;
  } else if (filter.type === "boolean") {
    filterText += ` - ${
      typeof filter.operator === "boolean" && filter.operator ? "Yes" : "No"
    }`;
  }

  let removeble = true;
  if (selectedView?.isGlobal) {
    selectedView.filters.forEach((selectedFilter: any) => {
      if (
        JSON.stringify({
          ...selectedFilter,
          name: selectedView.name,
        }).toLowerCase() === JSON.stringify(filter).toLowerCase()
      ) {
        removeble = false;
      }
    });
  }

  return (
    <Badge
      onClick={handleBadgeClick}
      size="lg"
      radius="sm"
      variant="outline"
      styles={{
        root: {
          backgroundColor: "#EFEFFD",
          borderColor: "#EFEFFD",
          color: "black",
          fontSize: 12,
          fontWeight: 400,
          height: 32,
        },
      }}
      sx={{ paddingRight: removeble ? 5 : 9, fontSize: 10, ...sx }}
      rightSection={
        removeble ? (
          <Tooltip
            sx={{ fontSize: 10 }}
            withArrow
            label="Remove filter"
            position="bottom"
          >
            <ActionIcon
              onClick={(event) => {
                event.stopPropagation(); // Prevents the click from bubbling to the Badge
                onRemoveClick(); // Call the specific remove function
              }}
              size="xs"
              color="blue"
              radius="xl"
              variant="transparent"
            >
              <IconX size={15} color="#5C5CEB99" />
            </ActionIcon>
          </Tooltip>
        ) : (
          <></>
        )
      }
    >
      <OverflownText
        maw={"200px"}
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {filterText}
      </OverflownText>
    </Badge>
  );
};
export default FilterBadge;
