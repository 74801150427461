import React, { useContext, useEffect, useRef } from "react";
import { Menu, Box } from "@mantine/core";
import MenuFirstStep from "./MenuFirstStep";
import FiltersContext from "./context/FiltersContext";
import { evidenceParents } from "./FiltersSpec";

export interface ValueLabelPair {
  value: string;
  label: string;
}

export interface Filter {
  [key: string]: {
    type: string;
    options: any[];
    formatter?: string;
    operations: ValueLabelPair[];
    ChildFilters?: Filter;
    filterMenuType: "idea" | "evidence";
  };
}

export interface ActiveFilter {
  operator?: string;
  parent: string;
  query?: string;
  selected?: any[];
  type: string;
}

interface FiltersMenuProps {
  filters: Filter;
  activeFilters: ActiveFilter[];
  setActiveFilters: (obj: any) => void;
  Target?: React.ReactElement;
  MenuType: "idea" | "evidence";
}

const FiltersMenu: React.FC<FiltersMenuProps> = ({
  filters: initialfilters,
  MenuType,
  activeFilters,
  Target,
}) => {
  const {
    setFilter,
    setSelectedParentFilter,
    setChildFiltersValue,
    setNumberValue,
    setRangeDates,
  } = useContext(FiltersContext);

  useEffect(() => {
    setFilter((prev) => ({
      ...prev,
      [MenuType]: Object.entries(initialfilters).reduce((acc, [key, value]) => {
        if (
          (MenuType === "evidence" && evidenceParents.includes(key)) ||
          (MenuType === "idea" && !evidenceParents.includes(key))
        ) {
          acc[key] = value;
        }
        return acc;
      }, {} as any),
    }));
  }, []);
  // console.log("2")

  useEffect(() => {
    const _initialfilters = { ...initialfilters };
    setFilter((prev) => ({
      ...prev,
      [MenuType]: Object.entries(_initialfilters).reduce(
        (acc, [key, value]) => {
          if (
            (MenuType === "evidence" && evidenceParents.includes(key)) ||
            (MenuType === "idea" && !evidenceParents.includes(key))
          ) {
            acc[key] = value;
          }
          return acc;
        },
        {} as any
      ),
    }));
  }, [initialfilters]);

  useEffect(() => {
    const unwantedTypes = ["number", "date"];
    const filteredActiveFilters = activeFilters.filter(
      (f) => !unwantedTypes.includes(f.type)
    );

    const initialState = filteredActiveFilters.reduce<{ [key: string]: any[] }>(
      (acc: any, f) => {
        acc[f.parent] = f?.selected?.map((i) => i.toLowerCase());
        return acc;
      },
      {}
    );

    setChildFiltersValue(initialState);

    const numberFilters = activeFilters.filter((f) => f.type === "number");

    const initialValue = numberFilters.reduce<{ [parent: string]: number }>(
      (acc: any, f) => {
        acc[f.parent] = f.query; // Assign the `query` value to the `parent`.
        return acc;
      },
      {}
    );

    setNumberValue(Object.keys(initialValue).length ? initialValue : undefined);

    const dateFilters = activeFilters.filter((f) => f.type === "date");

    const DateInitialValue = dateFilters.reduce<{
      [parent: string]: [Date | null, Date | null];
    }>((acc: any, f: any) => {
      acc[f.parent] = [f.selected[0], f.selected[1]]; // Convert the strings to Date objects.
      return acc;
    }, {});

    setRangeDates(
      Object.keys(DateInitialValue).length ? DateInitialValue : undefined
    );
  }, []);

  const handleParentFilterClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    const name = event.currentTarget.name;
    setSelectedParentFilter(name);
  };

  const { openedMenuType, setOpenedMenuType } = useContext(FiltersContext);

  return (
    <>
      <Menu
        shadow="md"
        width={260}
        position="bottom-start"
        radius="md"
        opened={openedMenuType == MenuType}
        onOpen={() => setOpenedMenuType(MenuType)}
        onClose={() => setOpenedMenuType(null)}
      >
        <Menu.Target>{Target}</Menu.Target>

        <Menu.Dropdown sx={{ padding: 0 }}>
          <Box>
            <MenuFirstStep
              initialfilters={initialfilters}
              MenuType={MenuType}
              handleParentFilterClick={handleParentFilterClick}
            />
          </Box>
        </Menu.Dropdown>
      </Menu>
    </>
  );
};

export default FiltersMenu;
