// Header.tsx
import React from 'react';
import { Box, Group } from "@mantine/core";
import FilterPanel from './FilterPanel';
import RequestsTabs from '../RequestsTabs';
import Filters from '../../Filters';

interface HeaderProps {
  savedViews: any[];
  selectedView: any;
  setSelectedView: (value: any) => void;
  upsertView: (body: any, callback: (res: any) => void) => void;
  warpWidth: number;
  actionIconsWidth: number;
  toggle: () => void;
  search: string;
  setSearch: (search: string) => void;
  searchOpened: boolean;
  setSearchOpened: (opened: boolean) => void;
  actionIconsRef: React.MutableRefObject<any>
}

const Header: React.FC<HeaderProps> = ({
  savedViews,
  selectedView,
  setSelectedView,
  upsertView,
  warpWidth,
  actionIconsWidth,
  toggle,
  search,
  setSearch,
  searchOpened,
  setSearchOpened,
  actionIconsRef
}) => (
  <Group
    noWrap
    mx="sm"
    mb="md"
    position="apart"
    sx={{ borderBottom: "1px solid #F1F1F1", whiteSpace: "nowrap" }}
  >
    <Box pb="0" sx={{ whiteSpace: "nowrap" }}>
      <RequestsTabs
        savedViews={savedViews}
        selectedView={selectedView}
        setSelectedView={setSelectedView}
        upsertViews={upsertView}
        SplitOn={Math.floor((warpWidth - actionIconsWidth) / 150)}
      />
    </Box>
    <Box ref={actionIconsRef}>
      <FilterPanel
        toggle={toggle}
        search={search}
        setSearch={setSearch}
        searchOpened={searchOpened}
        setSearchOpened={setSearchOpened}
      />
    </Box>
  </Group>
);

export default Header;
