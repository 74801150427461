import { ActionIcon, Box, Group, Radio, Tooltip } from "@mantine/core";
import { IconArrowDown, IconArrowUp } from "@tabler/icons";
import { useContext } from "react";
import IdeaContext from "../../IdeaContext";

const SortItem = ({ item }: { item: any }) => {
  const { activeSort, setActiveSort } = useContext(IdeaContext);

  const handleSortChange = () => {
    // Toggle direction if the current column is already selected
    if (activeSort && activeSort.column === item.value) {
      setActiveSort({
        column: item.value,
        direction: activeSort.direction === 1 ? -1 : 1,
      });
    } else {
      // Set new column and default to ascending
      setActiveSort({ column: item.value, direction: 1 });
    }
  };

  return (
    <Group noWrap spacing={5} position="apart" onClick={handleSortChange} h={28}>
      <Radio
        color="indigo"
        sx={{ cursor: "pointer" }}
        value={item.value}
        label={item.label}
        checked={activeSort?.column === item.value}
      />
      <Box w={28}>
        {activeSort?.column === item.value && (
          <Tooltip
            label={`sort ${
              activeSort?.direction === -1 ? "descending" : "ascending"
            }`}
          >
            <ActionIcon>
              {activeSort?.direction === -1 ? (
                <IconArrowDown size={14} />
              ) : (
                <IconArrowUp size={14} />
              )}
            </ActionIcon>
          </Tooltip>
        )}
      </Box>
    </Group>
  );
};

export default SortItem;
