import {
  Box,
  Card,
  Timeline
} from "@mantine/core";
import React from "react";
import {ExplainCard} from "./ExplainCard";
import {SetupAuth} from "./SetupAuth";
import {SetupImport} from "./SetupImport";
import {SetupLists} from "./SetupLists";

export const JiraSetup = (props: { integration: any, reloadIntegration: () => void, customizations?: any }) => {
  const integration = props.integration
  const step = integration ? integration.setupStep : 0;
  return (<>
    <Box p={"xl"} mt={7} bg={"#F1F3F5"}>
      <ExplainCard />
      <Card mt={"15px"}>
        <Timeline active={step} bulletSize={30} lineWidth={1} m={"md"}>
          <Timeline.Item
            title="Connect your Jira account"
            sx={{minHeight: '35px'}}
          >
            { step === 0 ? (
              <SetupAuth />
            ): '' }
          </Timeline.Item>
          <Timeline.Item
            title="Lists"
            sx={{minHeight: '30px'}}
          >
            { step === 1 ? (
              <SetupLists  integration={integration} reloadIntegration={props.reloadIntegration} />
            ): '' }
          </Timeline.Item>
          <Timeline.Item
            title="Import"
            sx={{minHeight: '35px'}}
          >
            { step === 2 ? (
              <SetupImport  integration={integration} reloadIntegration={props.reloadIntegration} />
            ): '' }
          </Timeline.Item>
        </Timeline>
      </Card>
    </Box>
  </>)
}