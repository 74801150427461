import { Buffer } from "buffer";
import { Button, TextInput } from "@mantine/core";
import { useState } from "react";
import {extractSubdomain, validateSalesforceURL} from "../../../../utils/validateSalesforceURL";



export const SetupAuth = () => {
  const [subdomainInputValue, setSubdomainInputValue] = useState<
    string | undefined
  >(undefined);
  const getAuthUri = () => {
    if (!subdomainInputValue || subdomainInputValue.length === 0) {
      return "#";
    }
    const subdomain = extractSubdomain(subdomainInputValue)
    const metadata = {
      integrationType: "salesforce",
      subdomain,
    };
    const base64State = Buffer.from(JSON.stringify(metadata)).toString(
      "base64"
    );
    const confPostfix = window?.__RUNTIME_CONFIG__?.REACT_APP_SALESFORCE_OAUTH_URI_SUFFIX || '.my.salesforce.com/services/oauth2/authorize'
    const oauthUriPostfix = subdomainInputValue.includes("https://test.salesforce.com") ? confPostfix.replace("my.","") : confPostfix
    return `https://${subdomain}${
      oauthUriPostfix
    }?client_id=${
      window?.__RUNTIME_CONFIG__?.REACT_APP_SALESFORCE_CLI_ID
    }&redirect_uri=${encodeURIComponent(
      "" + window?.__RUNTIME_CONFIG__?.REACT_APP_OAUTH_CALLBACK
    )}&response_type=code&state=${base64State}`;
    
  };

  return (
    <>
      <TextInput
        error={
          subdomainInputValue && !validateSalesforceURL(subdomainInputValue)
            ? "please insert a valid salesforce domain"
            : null
        }
        placeholder={"subdomain e.g. https://mysubdomain.my.salesforce.com"}
        mt={"md"}
        onChange={(event) => setSubdomainInputValue(event.currentTarget.value)}
      />
      <Button
        mt={"md"}
        disabled={
          subdomainInputValue === undefined ||
          subdomainInputValue === "" ||
          (subdomainInputValue !== "" &&
            !validateSalesforceURL(subdomainInputValue))
        }
        component={"a"}
        href={getAuthUri()}
      >
        Authorize
      </Button>
    </>
  );
};
