import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Button, Flex, Grid, Text, Title } from "@mantine/core";
import { SourceIcon } from "../../../utils/SourceIcon";
import Status from "./Status";
import Priority from "./Priority";
import { listObjectsFields } from "../../../Api";
import { useAuth0 } from "@auth0/auth0-react";
import Display from "./Display";

function Idea() {
  const auth0 = useAuth0();
  const navigate = useNavigate();
  const { ideaObject: IdeaType } = useParams();

  const handlePrevPage = () => {
    navigate(-1);
  };

  return (
    <div>
      <Box pt={"md"}>
        <Grid
          mx="sm"
          justify="space-between"
          align="center"
          sx={{ borderBottom: "1px solid #F1F1F1" }}
        >
          <Grid.Col span="content">
            <Flex
              ml="sm"
              mih={50}
              gap={0}
              p={0}
              justify="flex-start"
              align="center"
              direction="row"
              wrap="wrap"
            >
              <Button
                variant="subtle"
                color="gray"
                onClick={handlePrevPage}
                sx={{ borderRadius: "50%", padding: 0 }}
              >
                <SourceIcon sourceName="back" />
              </Button>
              <Title
                order={3}
                py={"sm"}
                mr={"sm"}
                ml={"md"}
                sx={{ textTransform: "capitalize" }}
              >
                {IdeaType}
              </Title>
              <Text fz="xl" c={"gray"}>
                (Idea)
              </Text>
            </Flex>
          </Grid.Col>
        </Grid>
        {IdeaType == "priority" ? (
          <Priority />
        ) : IdeaType == "status" ? (
          <Status />
        ) : IdeaType == "display" ? (
          <Display />
        ) : (
          ""
        )}
      </Box>
    </div>
  );
}

export default Idea;
