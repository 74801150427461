import {
  Menu,
  Text,
  UnstyledButton,
  Button,
  Flex,
  ScrollArea,
  Divider,
  Box,
  SegmentedControl,
  Center,
} from "@mantine/core";
import { IconBell, IconMail, IconMailOpened } from "@tabler/icons";
import { useEffect, useState } from "react";
import Notification from "./Notification";
import { markNotificationsRead } from "../../Api";
import { useAuth0 } from "@auth0/auth0-react";
import { Indicator } from "@mantine/core";

const NotificationsDropdown = (props: {
  notifications: any[];
  getListNotifications: () => void;
}) => {
  const auth0 = useAuth0();
  const notifications = props.notifications;
  const [opened, setOpened] = useState(false);
  const [marked, setMarked] = useState(false);
  const [tab, setTab] = useState("unread");

  const filtered = notifications.filter((n) => n.status === "unread");
  const notificationsCount = filtered.length;
  useEffect(() => {
    const fetchAndMarkNotifications = async () => {
      if (opened && !marked) {
        await markNotificationsRead(auth0).then(() => {
          setMarked(true);
        });
      } else {
        props.getListNotifications();
      }
    };

    fetchAndMarkNotifications();
  }, [opened]);
  return (
    <Menu
      width={400}
      shadow="md"
      position={"bottom-end"}
      withArrow
      opened={opened}
      onChange={setOpened}
    >
      <Menu.Target>
        <UnstyledButton>
          {notificationsCount === 0 && (
            <IconBell fill="#363699" color="#363699" size={26} />
          )}
          {notificationsCount > 0 && (
            <Indicator
              size={20}
              color="#FF9499"
              inline
              label={
                <Text p={0} m={0}>
                  {notificationsCount}
                </Text>
              }
            >
              <IconBell fill="#363699" color="#363699" size={26} />
            </Indicator>
          )}
        </UnstyledButton>
      </Menu.Target>
      <Menu.Dropdown p={0}>
        <Text
          px="xs"
          py={6}
          mb={0}
          fz="lg"
          fw="bold"
          sx={{
            background: "#f1f3f5",
          }}
        >
          {" "}
          Notifications{" "}
        </Text>
        <Box
          mah={500}
          sx={{
            overflowY: "scroll",
          }}
        >
          <SegmentedControl
            sx={{
              position: "sticky",
              top: "12px",
            }}
            value={tab}
            onChange={setTab}
            m="sm"
            fullWidth
            size="xs"
            radius="md"
            data={[
              {
                label: "Unread",
                value: "unread",
              },
              {
                label: "Viewed",
                value: "viewed",
              },
            ]}
          />
          {notifications
            .filter((n) => n.status === tab)
            .map((n, index) => {
              return n.notification.template ? (
                <Box>
                  <Notification
                    key={n._id}
                    notification={{ ...n.notification, status: n.status }}
                  />
                  {index + 1 !== notifications
                    .filter((n) => n.status === tab).length && <Divider my="md" />}
                </Box>
              ) : (
                ""
              );
            })}

          {notifications.filter((n) => n.status === tab).length == 0 && (
            <Center p="md" mb="md">
              <Box sx={{ textAlign: "center" }}>
                {tab == "viewed" ? (
                  <IconMail size={35} color="#5b60e38c" />
                ) : (
                  <IconMailOpened size={35} color="#5b60e38c" />
                )}
                <Text color="gray">
                  {" "}
                  All notifications are {tab == "viewed" ? "unread" : "viewed"}
                </Text>
              </Box>
            </Center>
          )}
        </Box>
      </Menu.Dropdown>
    </Menu>
  );
};

export default NotificationsDropdown;
