import { Buffer } from "buffer";
import {Button, Switch, TextInput} from "@mantine/core";
import React, {useState} from "react";
import {IconSearch} from "@tabler/icons";
import {saveJiraApiToken} from "../../../../Api";
import {useAuth0} from "@auth0/auth0-react";


export const SetupAuth = () => {

  const auth0 = useAuth0();
  const [useOAuth2, setUseOAuth2] = useState<boolean>(true);
  const [jiraOrgId, setJiraOrgId] = useState<string | undefined>(undefined);
  const [apiKey, setApiKey] = useState<string | undefined>(undefined);
  const [onPremiseUrl, setOnPremiseUrl] = useState<string | undefined>(undefined);
  const handleSaveApiToken = () => {
    if (apiKey) {
      saveJiraApiToken(auth0, apiKey, jiraOrgId, onPremiseUrl).then(() => {
        window.location.reload();
      }).catch(e => {
        console.log(e)
      })
    }
  }

  const getAuthUri = () => {
    const metadata = {
      integrationType: "jira",
    };
    const base64State = Buffer.from(JSON.stringify(metadata)).toString(
      "base64"
    );

    return `${window?.__RUNTIME_CONFIG__?.REACT_APP_JIRA_OAUTH_URI}?audience=api.atlassian.com&`+
    `client_id=${window?.__RUNTIME_CONFIG__?.REACT_APP_JIRA_CLI_ID}&`+
    `scope=${encodeURIComponent(window?.__RUNTIME_CONFIG__?.REACT_APP_JIRA_SCOPES)}&`+
    `redirect_uri=${encodeURIComponent("" + window?.__RUNTIME_CONFIG__?.REACT_APP_OAUTH_CALLBACK)}&`+
    `state=${base64State}&`+
    `response_type=code&`+
    `prompt=consent`
  };

  return (
    <>
      <Switch
        mb="sm"
        mt={"lg"}
        checked={useOAuth2}
        label={"Use OAuth2"}
        onChange={(event) =>
          setUseOAuth2(event.currentTarget.checked)
        }
      />
      {  useOAuth2 ? (
        <Button
          mt={"md"}
          component={"a"}
          href={getAuthUri()}
        >
          Authorize
        </Button>
      ) : (
        <>

          <TextInput
            my="md"
            placeholder="Api Key"
            value={apiKey}
            onChange={(event) => setApiKey(event.currentTarget.value)}
          />

          <TextInput
            my="md"
            placeholder="Organization Id"
            value={jiraOrgId}
            onChange={(event) => setJiraOrgId(event.currentTarget.value)}
          />

          <TextInput
            my="md"
            placeholder="On Premise Url"
            value={onPremiseUrl}
            onChange={(event) => setOnPremiseUrl(event.currentTarget.value)}
          />

          <Button
            mt={"md"}
            component={"a"}
            disabled={ apiKey === undefined }
            onClick={handleSaveApiToken}
          >
            Create
          </Button>
        </>

      )}

    </>
  );
};
