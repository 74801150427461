import { getFixedDateRanges } from "./FixedDateRanges";
import { IdeaPriority } from "../../../types/types";
import FiltersMenu from "./FiltersMenu";

export const evidenceParents = ["Account", "Company", "Business Name"]

export const FiltersSpec: any = {
  Accounts: {
    operations: [
      { value: "lower_than", label: "<" },
      { value: "greater_than", label: ">" },
      { value: "not_equal", label: "!=" },
      { value: "equal", label: "=" },
    ],
    options: [],
    type: "number"
  },
  Account: {
    Label:'Account Info',
    operations: [],
    options: [],
    type: "parent",
    ChildFilters:{}
  },
  Commitments: {
    operations:
      Object.keys(getFixedDateRanges).map((key: string) => ({
        value: key,
        label: getFixedDateRanges[key].lable,
      })) || [],
    options: [],
    type: "date"
  },
  "Created at": {
    operations:
      Object.keys(getFixedDateRanges)
        .map((key: string) => ({
          value: key,
          label: getFixedDateRanges[key].lable,
        }))
        .filter((dateRange) => !dateRange.label.includes("Next")) || [],
    options: [],
    type: "date"
  },
  "Company":{
    Label:'Company Name',
    operations: [],
    options: [],
    type: "fetchableCheckbox"
  },
  "Curr. ARR": {
    formatter: "$",
    operations: [
      {
        label: "<",
        value: "lower_than",
      },
      {
        label: ">",
        value: "greater_than",
      },
      {
        label: "!=",
        value: "not_equal",
      },
      {
        label: "=",
        value: "equal",
      },
    ],
    options: [],
    type: "number"
  },
  "Customers ($)": {
    formatter: "$",
    operations: [
      {
        label: "<",
        value: "lower_than",
      },
      {
        label: ">",
        value: "greater_than",
      },
      {
        label: "!=",
        value: "not_equal",
      },
      {
        label: "=",
        value: "equal",
      },
    ],
    options: [],
    type: "number"
  },
  "Deal Breakers ($)": {
    formatter: "$",
    operations: [
      { value: "lower_than", label: "<" },
      { value: "greater_than", label: ">" },
      { value: "not_equal", label: "!=" },
      { value: "equal", label: "=" },
    ],
    options: [],
    type: "number"
  },
  "Domains":{
    operations: [],
    options: [],
    type: "checkbox"
  },
  "Exp. delivery date": {
    operations:
      Object.keys(getFixedDateRanges).map((key: string) => ({
        value: key,
        label: getFixedDateRanges[key].lable,
      })) || [],
    options: [],
    type: "date"
  },
  "Have Jira/ClickUp": {
    operations: [
      { value: true, label: "Yes" },
      { value: [false, null], label: "No" },
    ],
    type: "boolean"
  },
  // "Have Suggested evidence": {
  //   operations: [
  //     { value: true, label: "Yes" },
  //     { value: [false, null], label: "No" },
  //   ],
  //   type: "boolean",
  // },
  "Favorites": {
    operations: [
      { value: true, label: "Yes" },
      { value: [false, null], label: "No" },
    ],
    type: "boolean"
  },
  "Open": {
    operations: [
      { value: true, label: "Yes" },
      { value: [false, null], label: "No" },
    ],
    type: "boolean"
  },
  Owner: {
    operations: [],
    options: [],
    type: "checkbox"
  },
  Priority: {
    operations: [],
    options: IdeaPriority,
    type: "checkbox"
  },
  "Product area": {
    operations: [],
    options: [],
    type: "checkbox"
  },
  "Prospects ($)": {
    formatter: "$",
    operations: [
      { value: "lower_than", label: "<" },
      { value: "greater_than", label: ">" },
      { value: "not_equal", label: "!=" },
      { value: "equal", label: "=" },
    ],
    options: [],
    type: "number"
  },
  Status: {
    operations: [],
    options: [],
    type: "checkbox"
  },
  "To Validate": {
    operations: [
      { value: true, label: "Yes" },
      { value: [false, null], label: "No" },
    ],
    type: "boolean"
  },
  "Total impact ($)": {
    formatter: "$",
    operations: [
      { value: "lower_than", label: "<" },
      { value: "greater_than", label: ">" },
      { value: "not_equal", label: "!=" },
      { value: "equal", label: "=" },
    ],
    options: [],
    type: "number"
  },
  "Updated at": {
    operations:
      Object.keys(getFixedDateRanges)
        .map((key: string) => ({
          value: key,
          label: getFixedDateRanges[key].lable,
        }))
        .filter((dateRange) => !dateRange.label.includes("Next")) || [],
    options: [],
    type: "date"
  },
  "Business Name": {
    operations: [],
    options: [],
    type: "checkbox"
  },
  "Mentions": {
    operations: [],
    options: [],
    type: "checkbox"
  },
};
