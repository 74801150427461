import {Paper, Text} from "@mantine/core";
import React from "react";

export const ExplainCard = () => {
  return <Paper mt={"lg"} withBorder  shadow={"lg"}>
    <Text p={"20px"} color={'rgba(22, 24, 50, 0.5)'}>Deepen your understanding of product ideas and user needs by importing from Salesforce accounts, opportunities, evidence, and cases.
      Create dynamic, up-to-date segments that enable precise and powerful prioritization.
      Follow-up on product updates and roadmap changes to keep your customers informed and close the feedback loop.
    </Text>
    <Text p={"20px"} color={'rgba(22, 24, 50, 0.5)'}>
      Learn more about Bagel-Salesforce integration.
    </Text>
  </Paper>
}