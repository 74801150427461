import React, { useCallback, useContext, useEffect, useMemo } from "react";
import {
  Checkbox,
  ScrollArea,
  TextInput,
  Text,
  Highlight,
} from "@mantine/core";
import { IconListSearch } from "@tabler/icons";

import { capitalizeFirstLetter } from "../../../../utils/capitalizeFirstLetter";
import { searchComponents, searchFilter } from "../../ideasHelpers";
import FiltersContext from "../context/FiltersContext";
import OperationSelect from "../OperationSelect";
import AddFilterBtn from "../AddFilterBtn";
import SyncContext from "../../../../context/SyncContext";
import { Filter } from "../FiltersMenu";
import {
  getAccountTypesMapList,
  getBusinessNameList,
  prepareSortedList,
} from "./CheckboxFilterHelpers";
import FilterFooter from "../FilterFooter";

interface CheckboxFilterProps {
  initialfilters: Filter;
  MenuType: "idea" | "evidence";
  resetFilters: (
    MenuType: "idea" | "evidence",
    selectedParentFilter?: string | null
  ) => void;
}

const CheckboxFilter: React.FC<CheckboxFilterProps> = ({
  MenuType,
  resetFilters,
}) => {
  const { components, allComponents } = useContext(SyncContext);
  const {
    filters,
    activeFilters,
    setActiveFilters,
    selectedParentFilter,
    checkedComponents,
    setCheckedComponents,
    childFiltersValue,
    childQuery,
    setChildQuery,
    query,
    setQuery,
    filteredComponents,
    setFilteredComponents,
    operator,
    setOperator,
    searchedOptions,
    setSearchedOptions,
    setOpenedMenuType,
    setSelectedParentFilter,
  } = useContext(FiltersContext);

  const _selectedParentFilter: string[] = selectedParentFilter?.split(".") || [
    "",
  ];

  const selectedFilter =
    _selectedParentFilter && _selectedParentFilter.length === 2
      ? //@ts-ignore
      filters[MenuType][_selectedParentFilter[0]]?.ChildFilters[
        _selectedParentFilter[1]
      ]
      : filters[MenuType][_selectedParentFilter[0]];

  const selectedHasOperations = selectedFilter?.operations?.length > 0 || false;
  const selectedOperators = selectedFilter?.operations;
  const selectedOptions = selectedFilter?.options;

  useEffect(() => {
    const currentFilters: any = activeFilters.find(
      (aF) => aF.parent === selectedParentFilter
    );

    if (currentFilters?.selected && selectedParentFilter && currentFilters) {
      setCheckedComponents((prev) => ({
        ...prev,
        ...(currentFilters.selected
          ? {
            [selectedParentFilter]: currentFilters.selected.map((o: string) =>
              o.toLowerCase()
            ),
          }
          : {}),
      }));
    }
  }, []);

  useEffect(() => {
    console.log({ checkedComponents });
  }, [checkedComponents]);

  console.log({ searchedOptions, selectedOptions, components });
  const renderComponents = ({
    components,
    inner = false,
    selectedParentFilter,
  }: {
    components: any[];
    inner?: boolean;
    selectedParentFilter: any;
  }) => {
    return (
      components &&
      components.length > 0 &&
      components.map((component, index) => (
        <React.Fragment
          key={`${index}_${
            inner ? component.childComponents?._id : component?._id
          }`}
        >
          <Checkbox
            key={inner ? component.childComponents?._id : component?._id}
            sx={{
              cursor: "pointer",
              input: {
                ":checked": {
                  backgroundColor: "#5B60E3",
                  borderColor: "#5B60E3",
                },
              },
            }}
            pr="sm"
            pl={inner ? 35 : "sm"}
            py={3}
            value={
              ["Product area"].includes(selectedParentFilter)
                ? component?._id
                : component?.value?.toLowerCase()
            }
            label={
              <Text>
                <Highlight highlight={capitalizeFirstLetter(query)}>
                  {capitalizeFirstLetter(component.name)}
                </Highlight>
              </Text>
            }
          />

          {/* If the component has children, render them with added padding */}
          {component.childComponents &&
            component.childComponents.length > 0 &&
            renderComponents({
              components: component.childComponents,
              inner: true,
              selectedParentFilter,
            })}
        </React.Fragment>
      ))
    );
  };

  const handleCheckedComponentsChange = (selectedArray: string[]) => {
    if (
      selectedParentFilter &&
      ["Product area"].includes(selectedParentFilter)
    ) {
      // Finding the value which is the value of the checked product area is
      //Doing it by finding the differnce between the last array (checkedComponents) to the current array (selectedArray)
      const currentCheckedComponents =
        checkedComponents[selectedParentFilter] || [];
      const valueOnlyInCheckedComponenets: string[] =
        currentCheckedComponents.filter(
          (value) => !selectedArray.includes(value)
        );
      const valueOnlyInSelectedArray: string[] = selectedArray.filter(
        (value) => !currentCheckedComponents.includes(value)
      );
      const selectedValue: string[] = valueOnlyInCheckedComponenets.concat(
        valueOnlyInSelectedArray
      ); // Always one value in this array

      const selected = selectedValue[0]; // This is the checked product area
      const arr: string[] = selectedArray;
      const add: boolean = selectedArray.includes(selected); // Determines if we're clicking or unclicking product area
      const addingOrSplicingSubComponents = (id: string) => {
        const copy = [...components];
        const filtered = copy.filter((c: any) => c._id === id);
        filtered.forEach((c: any) => {
          const childComponents: any[] = c.childComponents;
          if (childComponents) {
            if (add) {
              childComponents.forEach((c: any) => {
                if (!arr.includes(c._id)) arr.push(c._id);
              });
            } else if (!add) {
              childComponents.forEach((c: any) => {
                const index = arr.findIndex((a: any) => a === c._id);
                arr.splice(index, 1);
              });
            }
          }
        });
      };
      addingOrSplicingSubComponents(selected);
      const namesArr: any[] = [];
      allComponents.forEach((a: any) => {
        const found = allComponents.find((r: any) => r === a._id);
        namesArr.push(found?.name);
      });
      // setCheckedComponents(arr);
      setCheckedComponents((prev) => ({
        ...prev,
        [selectedParentFilter]: arr,
      }));
    } else {
      // setCheckedComponents(selectedArray)
      console.log({ selectedArray, checkedComponents });
      selectedParentFilter &&
        setCheckedComponents((prev) => ({
          ...prev,
          [selectedParentFilter]: selectedArray,
        }));
    }
  };

  const componentsToRender = useMemo(() => {
    switch (selectedParentFilter?.toLowerCase()) {
    case "product area":
      return query ? filteredComponents : components;
    case "business name":
      const businessNameList = getBusinessNameList(selectedOptions);
      return query && searchedOptions && searchedOptions[selectedParentFilter]
        ? searchedOptions[selectedParentFilter]
        : businessNameList;
    case "account.accounttypesmap":
      const accountTypesMapList = getAccountTypesMapList(selectedOptions);
      return query && searchedOptions && searchedOptions[selectedParentFilter]
        ? searchedOptions[selectedParentFilter]
        : accountTypesMapList;
    default:
      const sortedList = prepareSortedList(selectedOptions);
      return query &&
          selectedParentFilter &&
          searchedOptions &&
          searchedOptions[selectedParentFilter]
        ? searchedOptions[selectedParentFilter]
        : sortedList;
    }
  }, [
    query,
    selectedParentFilter,
    filteredComponents,
    components,
    searchedOptions,
    selectedOptions,
  ]);

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.currentTarget.value;
      selectedParentFilter &&
        setChildQuery((prev) => ({
          ...prev,
          [selectedParentFilter]: newValue,
        }));
      setQuery(newValue);

      if (selectedParentFilter) {
        const parentFilterLower = selectedParentFilter.toLowerCase();
        if (parentFilterLower === "product area") {
          const newFilteredComponents: any = searchComponents(
            newValue,
            components
          );
          setFilteredComponents(newFilteredComponents);
        } else {
          const listPreparation: any = {
            "business name": () => getBusinessNameList(selectedOptions),
            "account.accounttypesmap": () =>
              getAccountTypesMapList(selectedOptions),
            default: () => prepareSortedList(selectedOptions),
          };

          const list = (
            listPreparation[parentFilterLower] || listPreparation["default"]
          )();
          if (list) {
            const filteredOptions = searchFilter(list, newValue);
            setSearchedOptions((prev) => ({
              ...prev,
              [selectedParentFilter]: filteredOptions,
            }));
          }
        }
      }
    },
    [selectedParentFilter, components, selectedOptions]
  );

  console.log({
    selectedParentFilter,
    childQuery,
    query,
    componentsToRender,
    childFiltersValue,
  });

  return (
    selectedParentFilter && (
      <>
        {selectedHasOperations && (
          <OperationSelect
            operations={selectedOperators}
            onChange={(value) => {
              setOperator((prev: any) => ({
                ...prev,
                [selectedParentFilter]: value,
              }));
            }}
          />
        )}
        <TextInput
          mx={5}
          radius="md"
          value={childQuery && childQuery[selectedParentFilter]}
          onChange={handleInputChange}
          icon={<IconListSearch size={14.5} />}
          sx={{
            input: {
              backgroundColor: "#F9FAFB",
              ":focus": {
                borderColor: "#b1b1b1;",
              },
            },
          }}
        />
        <ScrollArea style={{ height: 300 }}>
          <Checkbox.Group
            value={checkedComponents[selectedParentFilter]}
            onChange={handleCheckedComponentsChange}
            orientation="vertical"
            spacing="xs"
          >
            {renderComponents({
              selectedParentFilter,
              components: componentsToRender,
            })}
          </Checkbox.Group>
        </ScrollArea>
        <FilterFooter
          onClickClear={() => resetFilters(MenuType, selectedParentFilter)}
          ApplyButton={
            <AddFilterBtn
              BtnText="Apply"
              selectedParentFilter={selectedParentFilter}
              childFiltersValue={childFiltersValue}
              disabled={
                (!checkedComponents[selectedParentFilter] ||
                  checkedComponents[selectedParentFilter]?.length == 0) ||
                ((selectedParentFilter !== null &&
                  childFiltersValue[selectedParentFilter]?.length === 0) ||
                  (selectedHasOperations && !operator))
              }
              onClick={() => {
                setActiveFilters((prevFilters: any) => [
                  {
                    type: selectedFilter.type,
                    parent: selectedParentFilter,
                    operator: operator && operator[selectedParentFilter],
                    selected: checkedComponents[selectedParentFilter],
                    query: null,
                  },
                  ...prevFilters.filter(
                    (prevF: any) => prevF.parent !== selectedParentFilter
                  ),
                ]);
                setOpenedMenuType(null);
                setSelectedParentFilter(null);
              }}
            />
          }
        />
      </>
    )
  );
};

export default CheckboxFilter;
