import { useState } from "react";
import CsvImport from "../CsvImport";
import CsvImportHead from "./CsvImportHead";
import { useAuth0 } from "@auth0/auth0-react";
import { runImport } from "../../Api";
import ImportResults from "./ImportResults";
import CsvDataTable, { ACCESSORS } from "./CsvDataTable";
import { get18DigitSalesforceId } from "../../utils/sfId";

const exmpleData = [
  {
    Key: null,
    "SFDC Account ID": null,
    "SFDC Opportunity ID": null,
    Origin: null,
    "Origin ID": null,
    "Origin Link": null,
    Category: null,
    "Business Name": "<Business Name>",
    Title: null,
    Description: null,
    createdAt: null,
    "Submitter/Reporter (Email)": null,
    Domain: null,
    "Product Area": null,
    "Sub Area": null,
    "attrbt_<name_1>": "<name_1 value>",
    "attrbt_<name_2>": "<name_2 value>",
    "attrbt_<name_3>": "<name_3 value>",
    "attrbt_<name_4>": "<name_4 value>",
    "attrbt_<name_5>": "<name_5 value>",
    "attrbt_<name_6>": "<name_6 value>",
    "attrbt_<name_7>": "<name_7 value>",
    "attrbt_<name_8>": "<name_8 value>",
    "attrbt_<name_9>": "<name_9 value>",
    "attrbt_<name_10>": "<name_10 value>",
  },
];
const ImportItems = () => {
  const auth0 = useAuth0();
  const [importRes, setImportRes] = useState(null);
  const [importLoading, setImportLoading] = useState(false);

  const cleanUpRows = (rows: any[]) => {
    const cleanRows: any[] = [];
    rows.forEach((row) => {
      const cleanRow = row;
      Object.values(ACCESSORS).forEach((accessor) => {
        if (cleanRow[accessor]) {
          cleanRow[accessor] = cleanRow[accessor]?.trim?.();
        }
      });
      if (cleanRow[ACCESSORS.SFDC_ACCOUNT_ID]?.length === 15) {
        cleanRow[ACCESSORS.SFDC_ACCOUNT_ID] = get18DigitSalesforceId(
          cleanRow[ACCESSORS.SFDC_ACCOUNT_ID]
        );
      }
      if (cleanRow[ACCESSORS.SFDC_OPPORTUNITY_ID]?.length === 15) {
        cleanRow[ACCESSORS.SFDC_OPPORTUNITY_ID] = get18DigitSalesforceId(
          cleanRow[ACCESSORS.SFDC_OPPORTUNITY_ID]
        );
      }
      if (cleanRow[ACCESSORS.SUBMITTER]?.length > 0) {
        cleanRow[ACCESSORS.SUBMITTER] =
          cleanRow[ACCESSORS.SUBMITTER].toLowerCase();
      }
      cleanRows.push(cleanRow);
    });
    return cleanRows;
  };

  const runImportFunc = async (rows: any[]) => {
    setImportLoading(true);
    const resp = await runImport(auth0, { rows })
      .then(({ importRes }) => {
        console.log({ importRes });
        setImportRes(importRes);
      })
      .catch(console.log)
      .finally(() => setImportLoading(false));
    return resp;
  };

  return (
    <CsvImport
      exmpleData={exmpleData}
      Head={CsvImportHead}
      importLoading={importLoading}
      importRes={importRes}
      cleanUpRows={cleanUpRows}
      CsvDataTable={CsvDataTable}
      onImportRun={(values: any) => {
        console.log("import items", {values});
      }}
      ImportResults={ImportResults}
    />
  );
};

export default ImportItems;
