import { Group, Text } from "@mantine/core";
import { IconFile3d, IconX } from "@tabler/icons";
import { Dropzone, FileWithPath } from "@mantine/dropzone";

export function CsvDropzone({
  onDrop,
  accept,
  loading
}: {
  onDrop: (files: FileWithPath[]) => void;
  accept?:
    | {
        [key: string]: string[];
      }
    | string[]
    | undefined;
  loading: boolean;
}) {
  const maxCsvSizeMb = window.__RUNTIME_CONFIG__.REACT_APP_MAX_CSV_SIZE_MB ? +window.__RUNTIME_CONFIG__.REACT_APP_MAX_CSV_SIZE_MB : 5
  return (
    <Dropzone
      onDrop={onDrop}
      onReject={(files) => console.log("rejected files", files)}
      maxSize={maxCsvSizeMb * 1024 ** 2}
      m={"md"}
      accept={accept}
      maxFiles={1}
      loading={loading}
    >
      <Group mih={220} style={{ pointerEvents: "none" }}>
        <Dropzone.Accept>
          <IconFile3d
            style={{
              width: 200,
              height: 200,
              color: "var(--mantine-color-blue-6)",
            }}
            stroke={1.5}
          />
        </Dropzone.Accept>
        <Dropzone.Reject>
          <IconX
            style={{
              width: 200,
              height: 200,
              color: "var(--mantine-color-red-6)",
            }}
            stroke={1.5}
          />
        </Dropzone.Reject>
        <Dropzone.Idle>
          <IconFile3d
            style={{
              width: 200,
              height: 200,
              color: "var(--mantine-color-dimmed)",
            }}
            stroke={1.5}
          />
        </Dropzone.Idle>

        <div>
          <Text size="xl" inline>
            Drag Csv file here or click to select files
          </Text>
          <Text size="sm" c="dimmed" inline mt={7}>
            Attach one file
          </Text>
        </div>
      </Group>
    </Dropzone>
  );
}
export default CsvDropzone;
