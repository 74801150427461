import * as React from "react"

const ZendeskIcon = (props:any) => (
  <svg
    viewBox="0 0 1393 1055.77"
    xmlns="http://www.w3.org/2000/svg"
    width={2500}
    height={1895}
    {...props}
  >
    <path
      d="M643.51 278.74v777H0zm0-278.74c0 177.57-143.84 321.41-321.41 321.41S0 177.57 0 0zm106 1055.77c0-177.57 143.84-321.41 321.41-321.41s321.41 143.84 321.41 321.41zm0-278.74V0H1393z"
      fill="#03363d"
    />
  </svg>
)

export default ZendeskIcon
