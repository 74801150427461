import { useAuth0 } from "@auth0/auth0-react";
import { useListState, UseListStateHandlers } from "@mantine/hooks";
import { DataTableColumn, DataTable } from "mantine-datatable";
import { useContext, useState, useEffect } from "react";
import { validateData } from "../../Api";
import SyncContext from "../../context/SyncContext";
import { Text } from "@mantine/core";
import TableCell from "./TableCell";
import { BusinessCategories } from "../../types/BusinessCategories";
import { RequestStatus } from "../../types/types";

export const ACCESSORS: { [key: string]: string } = {
  LEVEL: "Level",
  KEY: "Key",
  PARENT_KEY: "Parent Key",
  SFDC_ACCOUNT_ID: "SFDC Account ID",
  SFDC_OPPORTUNITY_ID: "SFDC Opportunity ID",
  ORIGIN: "Origin",
  ORIGIN_ID: "Origin ID",
  SUBMITTER: "Submitter/Reporter (Email)",
  DOMAIN: "Domain",
  PRODUCT_AREA: "Product Area",
  SUB_AREA: "Sub Area",
  TITLE: "Title",
  DESCRIPTION: "Description",
  CREATED_AT: "createdAt",
  PRIORITY: "Priority",
  // OWNER: "Owner (Email)",
  STATUS: "Status",
  BUSINESS_NAME: "Business Name",
  BUSINESS_CATEGORY: "Category",
  ERROR: "ERROR",
};
const PAGE_SIZES = [10, 50, 100];

function CsvDataTable({
  data,
  handlers,
  loading,
  setLoading,
}: {
  data: any[];
  handlers: UseListStateHandlers<never>;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const auth0 = useAuth0();
  const {
    fields,
    domains,
    members,
    allComponents,
    businessCategoryMapping,
    currentAccount,
  } = useContext(SyncContext);
  console.log({ businessCategoryMapping });
  const { ideaStatuses, ideaPriorities, requestPriorities } = fields;
  console.log({ allComponents });
  // const [loading, setLoading] = useState<boolean>(true)
  const [_validateData, setValidateData] = useState<any>({
    sfOrgValidations: [],
    userValidations: [],
    oppIdValidations: [],
    domainsComponents: [],
  });

  const [pageSize, setPageSize] = useState(PAGE_SIZES[2]);

  useEffect(() => {
    setPage(1);
  }, [pageSize]);

  const [page, setPage] = useState(1);
  // const [records, setRecords] = useState(data.slice(0, pageSize));

  // useEffect(() => {
  //   setRecords(data.slice((page - 1) * pageSize, ((page - 1) * pageSize) + pageSize));
  // }, [page, pageSize]);

  const _validate = (
    value: string,
    validValues: any,
    checkAccessor?: string
  ) => {
    return validValues.some(
      (item: any) =>
        (!checkAccessor
          ? item.label === value?.trim()
          : item[checkAccessor]?.trim() === value?.trim()) &&
        value !== null &&
        value?.trim() !== ""
    );
  };

  // Define validation criteria based on column titles
  const validationCriteria: Record<string, (value: any, row: any) => boolean> =
    {
      [ACCESSORS.LEVEL]: (value) => value === "IDEA" || value === "EVIDENCE",
      [ACCESSORS.PRIORITY]: (value, row) =>
        _validate(
          value,
          row[ACCESSORS.LEVEL] === "EVIDENCE"
            ? requestPriorities
            : ideaPriorities
        ),
      [ACCESSORS.STATUS]: (value, row) =>
        value?.trim().length > 0
          ? _validate(
            value,
            row[ACCESSORS.LEVEL] === "EVIDENCE"
              ? Object.keys(RequestStatus).map((k) => ({ label: k }))
              : ideaStatuses
          )
          : true,
      [ACCESSORS.DOMAIN]: (value, row) => 
      // _validate(value, domains, "name")
        _validateData.domainsComponents[row.index]?.[ACCESSORS.DOMAIN]?.valid,
      [ACCESSORS.PRODUCT_AREA]: (value, row) => {
        // Check if the value is "NONE" and if the corresponding DOMAIN is filled
        const isNoneValid = value?.toLowerCase() === "none" && row[ACCESSORS.DOMAIN]?.trim() !== "";
        
        // Use existing validation if the value is not "NONE" or if "NONE" is valid due to filled DOMAIN
        return isNoneValid || _validateData.domainsComponents[row.index]?.[ACCESSORS.PRODUCT_AREA]?.valid;
      },
      [ACCESSORS.SUB_AREA]: (value, row) =>
        _validateData.domainsComponents[row.index]?.[ACCESSORS.SUB_AREA]?.valid ||
        value?.toLowerCase() == "none",
      [ACCESSORS.PARENT_KEY]: (value, row) =>
        row[ACCESSORS.LEVEL] === "EVIDENCE" ? 
          value && (value?.toLowerCase() == "none" || value?.toLowerCase() == 'n/a'
          || data.find(item => item[ACCESSORS.KEY].toLowerCase() == value.toLowerCase() ))
          : true,
      [ACCESSORS.TITLE]: (value) => value !== "" && value !== null,
      [ACCESSORS.DESCRIPTION]: () => true,
      [ACCESSORS.CREATED_AT]: (value) => {
        if (value === "" || value === null) {
          return false; // Invalid date format
        }
        // Check if the value is a valid date
        const date = new Date(value);
        if (isNaN(date.getTime())) {
          return false; // Invalid date format
        }

        // Check if the date is in the future
        const now = new Date();
        if (date > now) {
          return false; // Future date
        }

        return true; // Valid date
      },
      // [ACCESSORS.OWNER]: (value) => _validate(value, members, "email"),

      [ACCESSORS.KEY]: (value, row) => {
        // Filter data to get rows with the same Level as the current row
        const sameLevelRows = data.filter(
          (r) => r.Level === row[ACCESSORS.LEVEL]
        );

        // Count how many times the current Key value appears in sameLevelRows
        const keyCount = sameLevelRows.reduce(
          (count, r) => count + (r.Key === value ? 1 : 0),
          0
        );

        // Valid if the Key appears only once (or not at all in the case of a new row)
        return keyCount <= 1 && value !== "" && value !== null;
      },
      [ACCESSORS.SFDC_ACCOUNT_ID]: (value, row) =>
        row[ACCESSORS.LEVEL] === "IDEA" ||
        loading ||
        _validateData.sfOrgValidations.find((org: any) => org.sfId?.trim() === value?.trim())
          ?.valid ||
        (value?.trim().toLowerCase() === "none"),
      [ACCESSORS.SFDC_OPPORTUNITY_ID]: (value, row) =>
        row[ACCESSORS.LEVEL] === "IDEA" ||
        loading ||
        _validateData.oppIdValidations.find((opp: any) => opp.oppId === value)
          ?.valid || value?.trim().toLowerCase() === "none",
      [ACCESSORS.ORIGIN]: (value, row) =>
        row[ACCESSORS.LEVEL] === "EVIDENCE"
          ? value !== "" || value === null
          : value === "" || value === null,
      [ACCESSORS.SUBMITTER]: (value, row) =>
        row[ACCESSORS.LEVEL] === "IDEA" ||
        /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value),
      [ACCESSORS.BUSINESS_NAME]: (value, row) =>
        row[ACCESSORS.LEVEL] === "EVIDENCE"
          ? value && value !== "" && value !== null
          : true,
      [ACCESSORS.BUSINESS_CATEGORY]: (value, row) =>
        row[ACCESSORS.BUSINESS_NAME] && row[ACCESSORS.LEVEL] === "EVIDENCE"
          ? value !== "" &&
            value !== null &&
            _validate(value, BusinessCategories, "businessCategory")
          : true,
    };

  const validateValue = (columnTitle: string, value: any, row: any) => {
    const validationFunction = validationCriteria[columnTitle?.trim()];
    if (validationFunction) {
      return validationFunction(value, row);
    }
    return true; // If no validation criteria is defined, consider it valid
  };

  const validateRow = (row: any, index: number) => {
    console.log("validateRow");
    setLoading(true);
    const validatedRow = { ...row, errors: [] };

    columns.forEach(async (column: DataTableColumn<any>) => {
      const columnTitle = (column.title as string).trim();
      const value = row[column.accessor];

      if (!validateValue(columnTitle, value, row)) {
        validatedRow.errors.push(columnTitle);
      }
      //@ts-ignore
      handlers.setItemProp(index, "ERROR", validatedRow.errors.join(","));
    });
    setLoading(false);
    return validatedRow;
  };
  const extractAndValidateData = async () => {
    try {
      setLoading(true);
      const userEmails = data
        .map((item: any) => item[ACCESSORS.SUBMITTER])
        .filter((email: string) => email != null);
      const sfIds = data
        .map((item: any) => item[ACCESSORS.SFDC_ACCOUNT_ID])
        .filter((id: string) => id != null);
      const oppIds = data
        .map((item: any) => item[ACCESSORS.SFDC_OPPORTUNITY_ID])
        .filter((id: string) => id != null);

      const domainsComponents = data.map((item: any) => ({
        [ACCESSORS.DOMAIN]: item[ACCESSORS.DOMAIN],
        [ACCESSORS.PRODUCT_AREA]: item[ACCESSORS.PRODUCT_AREA],
        [ACCESSORS.SUB_AREA]: item[ACCESSORS.SUB_AREA],
      }));

      const validationResponse = await validateData(auth0, {
        userEmails,
        sfIds,
        oppIds,
        domainsComponents,
      });
      console.log({ validationResponse });

      if (validationResponse.domainsComponents) {
        validationResponse.domainsComponents.map((domainsComponent: any, index: number) => {
          const domain = domainsComponent[ACCESSORS.DOMAIN];
          const component = domainsComponent[ACCESSORS.PRODUCT_AREA];
          const subArea = domainsComponent[ACCESSORS.SUB_AREA];
    
          //@ts-ignore
          domain?._id && handlers.setItemProp(index, 'domainId', domain?._id);
          //@ts-ignore
          component?._id && handlers.setItemProp(index, 'componentId', component?._id);
          //@ts-ignore
          subArea?._id && handlers.setItemProp(index, 'subComponentId', subArea?._id);
        });
      }
      
      setValidateData((current: any) => ({
        ...current,
        ...validationResponse,
      }));
      setLoading(false);
    } catch (error) {
      console.error("Error in extractAndFilterData:", error);
    }
  };

  useEffect(() => {
    extractAndValidateData();
  }, []);

  useEffect(() => {
    // console.log(erroredRows)
    data.forEach((row) => {
      validateRow(row, row.index);
    });
  }, [_validateData]);

  const columns: DataTableColumn<any>[] = [
    {
      title: ACCESSORS.LEVEL,
      accessor: ACCESSORS.LEVEL,
      editable: {
        type: "select",
        data: ["IDEA", "EVIDENCE"],
      },
    },
    {
      title: ACCESSORS.KEY,
      accessor: ACCESSORS.KEY,
      editable: {
        type: "text",
      },
    },
    {
      title: ACCESSORS.PARENT_KEY,
      accessor: ACCESSORS.PARENT_KEY,
      editable: {
        type: "text",
      },
    },
    {
      title: ACCESSORS.SFDC_ACCOUNT_ID,
      accessor: ACCESSORS.SFDC_ACCOUNT_ID,
      editable: {
        type: "text",
      },
    },
    {
      title: ACCESSORS.SFDC_OPPORTUNITY_ID,
      accessor: ACCESSORS.SFDC_OPPORTUNITY_ID,
      editable: {
        type: "text",
      },
    },
    {
      title: ACCESSORS.ORIGIN,
      accessor: ACCESSORS.ORIGIN,
      editable: {
        type: "text",
      },
    },
    {
      title: ACCESSORS.ORIGIN_ID,
      accessor: ACCESSORS.ORIGIN_ID,
      editable: {
        type: "text",
      },
    },
    {
      title: ACCESSORS.SUBMITTER,
      accessor: ACCESSORS.SUBMITTER,
      editable: {
        type: "text",
      },
    },
    {
      title: ACCESSORS.DOMAIN,
      accessor: ACCESSORS.DOMAIN,
      // editable: {
      //   type: "select",
      //   data: domains.map((d: any) => d.name),
      // },
    },
    {
      title: ACCESSORS.PRODUCT_AREA,
      accessor: ACCESSORS.PRODUCT_AREA,
      // editable: {
      //   type: "select",
      //   data: allComponents.map((d: any) => d.name),
      // },
    },
    {
      title: ACCESSORS.SUB_AREA,
      accessor: ACCESSORS.SUB_AREA,
    },
    {
      title: ACCESSORS.TITLE,
      accessor: ACCESSORS.TITLE,
      editable: {
        type: "text",
      },
    },
    {
      title: ACCESSORS.DESCRIPTION,
      accessor: ACCESSORS.DESCRIPTION,
      editable: {
        type: "text",
      },
    },
    {
      title: ACCESSORS.CREATED_AT,
      accessor: ACCESSORS.CREATED_AT,
      editable: {
        type: "text",
      },
    },
    {
      title: ACCESSORS.PRIORITY,
      accessor: ACCESSORS.PRIORITY,
      editable: {
        type: "select",
      },
    },
    // {
    //   title: ACCESSORS.OWNER,
    //   accessor: ACCESSORS.OWNER,
    //   editable: {
    //     type: "select",
    //     data: members.filter((m: any) => m.email).map((m: any) => m.email),
    //   },
    // },
    {
      title: ACCESSORS.BUSINESS_NAME,
      accessor: ACCESSORS.BUSINESS_NAME,
      editable: {
        type: "text",
      },
    },
    {
      title: ACCESSORS.BUSINESS_CATEGORY,
      accessor: ACCESSORS.BUSINESS_CATEGORY,
      editable: {
        type: "select",
        data: BusinessCategories.map((s: any) => s.businessCategory),
      },
    },
    {
      title: ACCESSORS.STATUS,
      accessor: ACCESSORS.STATUS,
      editable: {
        type: "select",
        data: ideaStatuses.map((s: any) => s.label),
      },
    },
    {
      title: ACCESSORS.ERROR,
      accessor: ACCESSORS.ERROR,
      render: ({ ERROR }: any) => (
        <Text miw={150} lineClamp={1}>
          {ERROR}
        </Text>
      ),
    },
  ].map((col: DataTableColumn<any>) => ({
    ...col,
    render: (item) => (
      <TableCell
        // index={index}
        item={item}
        col={col}
        handlers={handlers}
        validateRow={validateRow}
        extraData={{ requestPriorities, ideaPriorities, allComponents }}
        setValidateData={setValidateData}
      />
    ),
    cellsStyle: (record) =>
      record?.ERROR?.split(",").includes(col.accessor)
        ? { backgroundColor: "#feb9b9" }
        : undefined,
  }));
  return (
    <DataTable<any>
      sx={{
        "thead tr:first-of-type": {
          borderColor: "#dee2e6",
          background: "#F3F4F6",
        },
        "thead th": {
          padding: "6px 10px !important",
        },
        "th[role='button'] svg.mantine-9cumsf": {
          display: "none",
        },
      }}
      totalRecords={data.length}
      columns={columns}
      records={data.slice(
        (page - 1) * pageSize,
        (page - 1) * pageSize + pageSize
      )}
      fetching={loading}
      withColumnBorders
      recordsPerPage={pageSize}
      page={page}
      onPageChange={(p) => setPage(p)}
      recordsPerPageOptions={PAGE_SIZES}
      onRecordsPerPageChange={setPageSize}
      height={"80vh"}
    />
  );
}

export default CsvDataTable;
//.filter(row => row[ACCESSORS.ERROR]?.trim().length > 0)
