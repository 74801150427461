import {useState} from "react";
import {Grid, Select, Button, Text, Avatar, Switch, Flex} from "@mantine/core";
import ReactTimeAgo from "react-time-ago";
import {getInitials} from "../../utils/getInitials";
import {getBagelId, getConnectedUserRole} from "../../utils";
import {useAuth0} from "@auth0/auth0-react";
import { openConfirmModal } from "@mantine/modals";

const UserRow = (props: { user: any, onUpdate: (user: any) => Promise<boolean>}) => {
  const auth0 = useAuth0();
  const [role, setRole] = useState<string | undefined>(props.user.role);
  const [enabled, setEnabled] = useState<boolean>(
    props.user.status === "active"
  );
  const connectedUserRole = getConnectedUserRole(auth0);
  const canEdit = connectedUserRole === "admin";
  const memberTeams = props.user.teams?.map((team: any) => team.name).join(", ")
  const userToAdmin = (userId: string, newRole: string) => {
    openConfirmModal({
      title: `Changing ${props.user.name}'s role to admin`,
      centered: true,
      children: (
        <Text size="sm"> Are you sure you want to make {props.user.name} an admin? </Text>
      ),
      labels: { confirm: "Yes", cancel: "No" },
      confirmProps: { color: "red" },
      closeOnCancel: true,
      closeOnConfirm: true,
      onCancel: () => console.log("Cancel"),
      onConfirm: () => {
        setRole(newRole);
        props.onUpdate({ id: props.user._id, role: newRole });
      },
    });
  };
  const updateRole = (newRole: string) => {
    if (newRole === "admin") {
      userToAdmin(props.user._id, newRole);
    }
    else{
      setRole(newRole);
      props.onUpdate({ id: props.user._id, role: newRole });}
    
  };

  const updateStatus = (active: boolean) => {
    setEnabled(active);
    props.onUpdate({
      id: props.user._id,
      status: active ? "active" : "disabled",
    });
  };

  return (
    <tr key={props.user._id}>
      <td>
        <Grid>
          <Grid.Col span={1} pl={"md"}>
            {props.user?.picture ? (
              <Avatar src={props.user.picture} radius="xl" />
            ) : (
              <Avatar color="cyan" radius="xl">
                {props.user.name && getInitials(props.user.name)}
              </Avatar>
            )}
          </Grid.Col>
          <Grid.Col span={4}>
            <Text ml={"xl"}>{props.user.name}</Text>
            <Text color={"#5B60E3"} ml={"xl"}>
              {props.user.email}
            </Text>
          </Grid.Col>
        </Grid>
      </td>
      <td>
        {canEdit && props.user.status !== "invited" ? (
          <Switch
            mb="sm"
            color={"green"}
            checked={enabled}
            // label={enabled ?  "Activate" : "Disable"}
            onChange={(event) => updateStatus(event.currentTarget.checked)}
          />
        ) : (
          <Text tt={"capitalize"}>{props.user.status}</Text>
        )}
      </td>
      <td style={{ textTransform: "capitalize" }}>
        {canEdit ? (
          <Select
            sx={{ width: "150px" }}
            value={role}
            onChange={updateRole}
            data={[
              { value: "admin", label: "Admin" },
              { value: "manager", label: "Manager" },
              { value: "agent", label: "Agent" },
            ]}
          />
        ) : (
          <Text tt={"capitalize"}>{props.user.role}</Text>
        )}
      </td>
      <td><Flex w='100px' wrap="wrap"> {memberTeams} </Flex></td>
      <td></td>
      <td>
        {props.user.lastSignIn ? (
          <ReactTimeAgo date={new Date(props.user.lastSignIn)} />
        ) : (
          ""
        )}
      </td>
    </tr>
  );
}

export default UserRow;
