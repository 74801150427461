import * as React from "react"
const KebabMenu = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width}
    height={props.height}
    fill="none"
    {...props}
  >
    <path
      fill="#161832"
      d="M5.2 3A2.4 2.4 0 1 1 .4 3a2.4 2.4 0 0 1 4.8 0Zm7.2 0a2.4 2.4 0 1 1-4.8 0 2.4 2.4 0 0 1 4.8 0Zm7.2 0a2.4 2.4 0 1 1-4.8 0 2.4 2.4 0 0 1 4.8 0Z"
    />
  </svg>
)
export default KebabMenu
