import { Anchor, Text } from '@mantine/core';

interface TimeDisplayProps {
  date: Date;
  milliseconds: number;
  link: string
}

const HourDisplay: React.FC<TimeDisplayProps> = ({ date, milliseconds, link }) => {
  
  const calculateNewTime = (date: Date, milliseconds: number,): string => {
    const newDate = new Date(date.getTime() + milliseconds);
    return newDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hourCycle: 'h23' });
  };

  const newTime = calculateNewTime(date, milliseconds);

  return (
 
    <Anchor
      color="dimmed"
      w={50}
      size={"sm"}
      component={"a"}
      target={"_blank"}
      href={link}
      fz={13}
    >
      {newTime}
    </Anchor>
 
  );
};

export default HourDisplay;