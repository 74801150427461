import { Checkbox, Flex, Text, Tooltip } from "@mantine/core";

const CompanyCheckboxLine = ({
  company,
  active,
}: {
  company: any;
  active: boolean;
}) => {
  return (
    <Flex
      justify="space-between"
      pl="md"
      pr='lg'
      py={6}
      my={4}
      w={212}
      bg={active ? "rgba(92, 92, 235, 0.05)" : "#FFF"}
      sx={{ borderRadius: 8 , border: '0.2px solid rgba(92, 92, 235, 0.16)'}}
    >
      <Checkbox value={company?._id} color="indigo" />
      <Tooltip label={company?.name || "N/A"}>
        <Text w={150} lineClamp={1} ml="md" fz={13}>
          {company?.name || "N/A"}
        </Text>
      </Tooltip>
    </Flex>
  );
};

export default CompanyCheckboxLine;
