import { Checkbox, Group, Flex, Text, Tooltip } from "@mantine/core";
import { useContext, useEffect, useState } from "react";
import IdeaContext from "../../IdeaContext";
import ProgressBarPart from "./ProgressBarPart";


export const SingleSideCheckbox = ({
  item,
  validatedCount,
  pendingCount,
}: {
  item: any;
  validatedCount: number;
  pendingCount: number;
}) => {
  const {
    showmatches,
    showValidated,
    activeInboxTab
  } = useContext(IdeaContext);


  const [onlyValidatedPercentage, setOnlyValidatedPercentage] = useState(0); 
  const [onlyPendingPercentage, setOnlyPendingPercentage] = useState(0); 
  const [onlyBgPercentage, setOnlyBgPercentage] = useState(0);

  useEffect(() => {

    if(!showmatches){
      const validatedPerc = item?.requestsCount/validatedCount*100
      setOnlyValidatedPercentage(validatedPerc)
      setOnlyBgPercentage(100 - validatedPerc)
    }
    if(!showValidated && !activeInboxTab){
      const pendingPerc = item?.matchesCount/pendingCount*100
      setOnlyPendingPercentage(pendingPerc)
      setOnlyBgPercentage(100 - pendingPerc)
    }
    if(activeInboxTab){
      const inboxPerc = item?.inboxCount / pendingCount*100
      setOnlyPendingPercentage(inboxPerc)
      setOnlyBgPercentage(100 - inboxPerc)
    }

    
  }, [showmatches, showValidated , activeInboxTab]);


  return (
    <Checkbox
      styles={{
        label: {
          cursor: "pointer",
        },
        input: {
          cursor: "pointer",
        },
        labelWrapper: {
          width: "-webkit-fill-available",
          cursor: "pointer",
        },
      }}
      color="indigo"
      value={item?.value.toLowerCase()}
      w="100%"
      label={
        <Group position="apart" noWrap>
          <Tooltip label={item?.value}>
            <Text lineClamp={1} fz="12px" fw={500} color="#0A122E">
              {item?.value}
            </Text>
          </Tooltip>
          

          <Group noWrap>
            <Flex
              w="110px"
              h="8px"
              mr="8px"
              sx={{ borderRadius: "8px", overflow: "hidden" }}
            >
              {showValidated && <ProgressBarPart
                widthPercentage={(!showmatches) ? onlyValidatedPercentage : item?.validatedPercentage}
                color="#5C5CEB"
              />}

              {(showmatches || activeInboxTab) && <ProgressBarPart
                widthPercentage={(!showValidated ) ? onlyPendingPercentage : item?.matchesPercentage}
                color={"rgba(92, 92, 235, 0.6)"}
              />}

              <ProgressBarPart
                widthPercentage={(!showValidated || !showmatches) ? onlyBgPercentage : item?.bgPercentage}
                color="rgba(92, 92, 235, 0.05)"
              />

              {/* <Box
                w={`${item?.validatedPercentage || "0"}%`}
                bg=  "#5C5CEB" 
              ></Box>
              <Box
                w={`${item?.matchesPercentage || "0"}%`}
                bg= {showmatches ? "rgba(92, 92, 235, 0.6)" : "rgba(92, 92, 235, 0.05)"}
              ></Box>
              <Box
                w={`${item?.bgPercentage}%`}
                bg={"rgba(92, 92, 235, 0.05)"}
              ></Box> */}
            </Flex>

            <Flex h={16} w={62} align="center" justify="flex-end">
              {showValidated  && (
                <>
                  <Text fz={39} color="#5C5CEB" mb={22} w={10}>
                    .
                  </Text>
                  <Text
                    color="#5C5CEB"
                    fz="13px"
                    mr="4px"
                    w={15}
                    align="center"
                  >
                    {item?.requestsCount || 0}
                  </Text>
                </>
              )}
              {(showmatches || activeInboxTab) && (
                <>
                  <Text fz={39} color="rgba(92, 92, 235, 0.6)" mb={22} w={10}>
                    .
                  </Text>
                  <Text
                    color="rgba(92, 92, 235, 0.6)"
                    fz="13px"
                    w={15}
                    align="center"
                  >
                    {activeInboxTab ? 
                      item?.inboxCount :
                      (showmatches && showValidated) 
                        ? (item?.matchesCount + item?.inboxCount)
                        : (showmatches && !showValidated) ? (item?.matchesCount):
                        
                          0}
                  </Text>
                </>
              )}
            </Flex>
          </Group>
        </Group>
      }
    />
  );
};