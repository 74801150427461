import LogoIcon from "../src/components/layouts/LogoIcon";
import { useAuth0 } from "@auth0/auth0-react";
import {Flex, Text, Box, Button} from "@mantine/core";
import {logout} from "./utils";
import {usePostHog} from "posthog-js/react";

const UnauthorizedMessage = () => {
  const auth0 = useAuth0();
  const posthog = usePostHog();
  return (
    <Box>
      <Flex
        style={{ width: "100vw", height: "100vh" }}
        justify="center"
        align="center"
        direction="column"
      >
        <LogoIcon />
        <Text fz="xl" mt="md" mb="md">
          { 'Not authorized: Please contact your admin for an invite to Bagel' }
        </Text>
        <Button onClick={() => {
          posthog?.capture('logged_out')
          logout(auth0);
        }}>
          Logout
        </Button>
      </Flex>
    </Box>
  );
};

export default UnauthorizedMessage;
