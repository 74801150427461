import React, { useState, useEffect } from "react";
import { Menu, Button, TextInput, Select, UnstyledButton } from "@mantine/core";


const NewTeamDropdown = (props: {onNewTeamSubmitted: (team: {name: string, description: string}) => void}) => {
  const [teamName, setTeamName] = useState<string>("");
  const [teamDescription, setTeamDescription] = useState<string>("");
  const onSubmitNewTeam = () => {
    const team = {name: teamName, description: teamDescription};
    props.onNewTeamSubmitted(team)
    setTeamName('')
    setTeamDescription('')
  }

  return (
    <Menu width={300} shadow="md" position={"bottom-end"}>
      <Menu.Target>
        <Button
          bg={"#5C5CEA"}
          sx={{
            fontWeight: 700,
            ":hover": { backgroundColor: "#9f9af8" },
          }}
          color={"#fff"}
          mr={30}
          mb={30}
        >
          {" "}
          + New Team
        </Button>
      </Menu.Target>
      <Menu.Dropdown p={"md"}>
        <TextInput
          value={teamName}
          onChange={(event: any) =>
            setTeamName(event.currentTarget.value)
          }
          label="Name"
          placeholder="Ex. Sales"
          size="xs"
        />
        <TextInput
          value={teamDescription}
          onChange={(event: any) =>
            setTeamDescription(event.currentTarget.value)
          }
          label="Description"
          placeholder="Ex. Sales managers and operations"
          size="xs"
          mt="xs"
        />

        <Menu.Item p={0} mt={"md"}>
          <Button
            onClick={onSubmitNewTeam}
            bg={"#5C5CEA"}
            fullWidth
            sx={{
              fontWeight: 700,
              ":hover": { backgroundColor: "#9f9af8" },
            }}
            color={"#fff"}
          >
            Submit
          </Button>
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

export default NewTeamDropdown;
