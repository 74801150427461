import {
  Box,
  Flex,
  Stack,
} from "@mantine/core";
import CardHeader from "./CardHeader";
import ValidatedRequestActions from "./actions/ValidatedRequestActions";
import { useContext } from "react";
import { MentionProvider } from "../../../MentionTextarea/MentionContext";
import IdeaContext from "../../IdeaContext";
import CardScroller from "./CardScroller";
import MatchActions from "./actions/MatchActions";
import RequestMatchesActions from "./actions/RequestMatchesActions";
import NewRequestActions from "./actions/NewRequestActions";
import SearchActions from "./actions/SearchActions";



const SideBar = ({
  skipMatch,
  index,
  cardView,
  setCardView,
  navToPerspective,
  setActiveSearch,
  reviewMode
}: {
  skipMatch: any;
  index: string | null;
  cardView: boolean;
  setCardView: any;
  navToPerspective: any;
  setActiveSearch: any;
  reviewMode: boolean
}) => {

  const {
    chosenEvidence,
  } = useContext(IdeaContext);

  const status = chosenEvidence?.status;
  const type = chosenEvidence?.type

  return (
    // h="calc(100vh - 137px)"
    <Box  /* h={`calc(100vh - ${reviewMode ? 107: 137}px)`} */  h='100%' >
      <MentionProvider>
        <Stack justify="space-between" h='100%'  /* h={`calc(100vh - ${reviewMode ? 107: 137}px)`} *//* h='100%' */>
          <Box>
            <Flex
              mt="24px"
              pb="16px" 
              px="35px"
              justify="space-between"
              align="center"
              sx={{ borderBottom: "1px solid #D8D8DB" }}
            >
              <CardHeader navToPerspective={navToPerspective} />
            </Flex>
            <CardScroller chosenEvidence={chosenEvidence} reviewMode={reviewMode}/>
          </Box>
          <Box>

            {status === "match" && type === 'IdeaXConversation' && (
              <MatchActions />
            )}
            {status === "match" && type === 'IdeaXRequest' && (
              <RequestMatchesActions navToPerspective={navToPerspective}/>
            )}

            {status === "new" && (
              <NewRequestActions
                navToPerspective={navToPerspective}
              />
            )}
            {status === "validated" && (
              <ValidatedRequestActions
                navToPerspective={navToPerspective}
              />
            )}

            { status === "search" && (
              <SearchActions
                navToPerspective={navToPerspective}
                setActiveSearch={setActiveSearch}
              />
            )}
          </Box>
        </Stack>
      </MentionProvider>
    </Box>
  );
};

export default SideBar;
