import { Text, Center, Flex, Box, Stack, Tooltip, HoverCard, Group } from "@mantine/core";
import { useContext, useEffect, useState } from "react";
import { PieChart, Pie, Cell } from "recharts";
import { formatBigMonetaryValue, formatMonetaryValue } from "../Funcs";
import IdeaContext from "../IdeaContext";

const ImpactPieChart = ({
  title,
  validatedColor,
  pendingColor,
  validatedPercentage,
  monetaryValue,
  validatedMonetaryValue,
  customerCount,
  validatedCustomerCount
}: {
  title: string[];
  validatedColor: string;
  pendingColor: string;
  validatedPercentage: number;
  monetaryValue: number;
  validatedMonetaryValue: number;
  customerCount: number;
  validatedCustomerCount: number;
}) => {
  const {
    
    showmatches,
    showValidated,
  } = useContext(IdeaContext);


  // Calculate the percentage for the second color
  const pendingPercentage = 100 - validatedPercentage;
  const pendingMonetaryValue = monetaryValue - validatedMonetaryValue
  const pendingCustomerCount = customerCount - validatedCustomerCount
  // Data for the Pie chart
  const data = [
    { name: "Color1", value: validatedPercentage },
    { name: "Color2", value: pendingPercentage },
  ];

  // Colors array
  const COLORS = [validatedColor, pendingColor];

  // Define the outer radius and inner radius to create the border effect
  const outerRadius = 82;
  const innerRadius = 75;

  const [value, setValue] = useState(monetaryValue);
  const [count, setCount] = useState(customerCount);
  const [chartKey, setChartKey] = useState(0);

  useEffect(() => {
    setValue(monetaryValue);
    setCount(customerCount);

    // Trigger re-render to restart animation
    setChartKey(prevKey => prevKey + 1);
    
  }, [monetaryValue, customerCount, validatedPercentage]);

  return (
    <Center style={{ position: "relative", width: 164, height: 164 }} mr={36}>

      {/* Pie Chart */}

      <PieChart key={chartKey} width={164} height={164}>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          outerRadius={outerRadius}
          innerRadius={innerRadius}
          dataKey="value"
          startAngle={90}
          endAngle={450}
          stroke="none"
          isAnimationActive={true} // Enable animation
          animationDuration={800}  // Duration in milliseconds
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>

      {/* Center Text */}

      <Stack
        justify="center"
        spacing={0}
        align="center"
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
        }}
      >
        <Stack justify="center" spacing={0} align="center">
          <Text fw={600} fz={14} color="#212529">
            {title[0]}
          </Text>
          <Text fw={600} fz={14} color="#212529" mb={6}>
            {title[1]}
          </Text>
        </Stack>
        <HoverCard offset={10.5} disabled={!showmatches || !showValidated} transition='pop' transitionDuration={104}>
          <HoverCard.Target>
            <Flex align="center" justify="center" w="100%">
              <Tooltip label={formatMonetaryValue(value)} disabled={value <= 99000000}>
                <Text fw={600} fz={13} color="#5C5CEB" style={{ width: 60, textAlign: 'right' }} lineClamp={1}>
                  {formatBigMonetaryValue(value)}
                </Text>
              </Tooltip>
          
              <Box
                sx={{
                  width: "4.74px",
                  height: "4.74px",
                  borderRadius: "50%",
                  backgroundColor: "#5C5CEB",
                  margin: "0 5px",
                  display: "inline-block",
                }}
              />
              <Flex style={{ width: 60, textAlign: 'left' }}>
                <Text fw={600} fz={13} color="#5C5CEB" mr='2px'>
                  {count}
                </Text>
                <Text fw={600} fz={13} color="#5C5CEB" >
            accts.
                </Text>
              </Flex>
          
            </Flex>
          </HoverCard.Target>
          <HoverCard.Dropdown bg='#F8F9FA'>
            <Box w={160} pb={1}>
              <Box w={160} mb='sm'>
                <Text /* mr={5} */ fz={12} color="#212529" mb={7} align="left">Assigned:</Text>
                <Group align='center' position="apart" w={160}>
                  <Text align="left" color="#5C5CEB" fz={12} w={60}>{formatBigMonetaryValue(validatedMonetaryValue)}</Text>
                  <Box
                    sx={{
                      width: 6,       
                      height: 6,      
                      borderRadius: '50%',  
                      backgroundColor: "#5C5CEB", 
                    }}
                  />
                  <Flex /* justify='flex-start' */>
                    <Text align="right" lineClamp={1} color="#5C5CEB" fz={12} w={60}>{`${validatedCustomerCount} accts`}</Text>
                  </Flex>
                  
                </Group>
                
              </Box>
              <Box w={160}>
                <Text /* mr={5} */ color="#212529" fz={12}  mb={7} align="left">Suggested:</Text>
                <Group align='center' position="apart" w={160}>
                
                  <Text align="left" color="#5C5CEB" fz={12} w={60}>{formatBigMonetaryValue(pendingMonetaryValue)}</Text>
                  <Box
                    sx={{
                      width: 6,       
                      height: 6,      
                      borderRadius: '50%',  
                      backgroundColor: "rgba(92, 92, 235, 0.6)", 
                    }}
                  />
                  <Flex /* justify='flex-start' */>
                    <Text /* align="left" */lineClamp={1} align="right" color="#5C5CEB" fz={12} w={60}>{`${pendingCustomerCount} accts`}</Text>
                  </Flex>
                </Group>
              </Box>
              
            </Box>
          </HoverCard.Dropdown>
        </HoverCard>
        
      </Stack>
    </Center>
  );
};

export default ImpactPieChart;