import { ActionIcon, Grid, Group, Title, Text, Flex, Tooltip, Box, Avatar } from "@mantine/core";
import { IconX } from "@tabler/icons";
import ReactTimeAgo from "react-time-ago";
import { closeAllModals } from "@mantine/modals";
import { UseListStateHandlers } from "@mantine/hooks";
import BusinessCategoryIcon from "../../../icons/BusinessCategoryIcon";
import { useEffect, useState } from "react";
import { getRequestIconByType } from "../../../utils";
import { getInitials } from "../../../utils/getInitials";

export interface ModalHeaderInterface {
  title: string,
  idea: any;
  request: any;
  setValidatedRequests: React.Dispatch<React.SetStateAction<any>>;
  onUpdated: (useLoading?: boolean) => void;
  requestsHandlers?: UseListStateHandlers<any[]> | undefined;
  customizations: any;
}



export function ModalHeader(props: ModalHeaderInterface) {

  const [businessName, setBusinessName] = useState(props.request.textItem?.businessName || props.request.businessName);

  const submitter =
    (props.request.internalSubmitter || props.request.cxSubmitter) ??
    props.request.submitter;
  const submitterName = submitter ? submitter?.name || submitter?.displayName : props.request.submitterEmail; 

  const company = props.request?.company;
  const customer = company?.name || "Unknown";
  const icon = getRequestIconByType('opportunity')

  useEffect(() => {

    if(!props.request.textItem?.businessName && !props.request.businessName){
      if(props.request.chat?.itemType === 'conversation' && props.request.chat?.caseNumber  && props.request.origin === 'salesforce'){
        setBusinessName('Support Request')
      }
      else if(props.request.chat?.itemType === 'conversation' && props.request.origin === 'zendesk'){
        setBusinessName('Support Request')
      }
      else if(props.request.chat?.itemType === 'account' || props.request.chat?.itemType === 'opportunity'){
        setBusinessName('GTM Request')
      }
      
    }
    
  }, []);

  return (
    <Flex
      justify="space-between"
      align="center"
      p="sm"
      h={56}
      sx={{
        borderBottom: "1px solid #E5E7EB",
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
      }}
      bg="#F8F9FB"
    >
      {props.request.modalType === "match" && (
        <Flex>
          <Tooltip label={businessName || "General"}>
            <Box mr="6px" mt={4.9}>
              {props.request.textItem?.businessName=== 'Opportunity Requirements' ? <Avatar src={icon} alt={customer} color="#5B60E3" size={21}>
                {getInitials(customer)}
              </Avatar>: <BusinessCategoryIcon
                businessName={
                  businessName || "Sic Description"
                }
              />}
            </Box>
          </Tooltip>
          <Flex align="center">
            <Text mx={5} fw={600}>
              {businessName || "General"}
            </Text>
            <Text mr="md" ml={6} fz="sm">
              Evidence suggested by Bagel AI
            </Text>
            {props.request.textItem?.updatedAt && (
              <Text ml={-4} size="xs" c="dimmed"/*  mb={1} */>
                <ReactTimeAgo date={new Date(props.request.textItem?.updatedAt)} />
              </Text>
            )}
          </Flex>
        </Flex>
      )}

      {props.request.modalType !== "match" && (
        <Flex>
          <Tooltip label={businessName || "General"}>
            <Box mr="6px" mt={4.9}>
              <BusinessCategoryIcon
                businessName={businessName || "Sic Description"}
              />
            </Box>
          </Tooltip>
          <Flex align="center">
            <Text mx={5} fw={600}>
              {businessName || "General"}
            </Text>
            <Text ml={4} fz="sm">
              {props.request?.status === "new"
                ? "Request submitted by"
                : props.request?.source === "request"
                  ? "Evidence requested by"
                  : "Evidence added by"}
            </Text>

            <Group>
              <Text ml={6} mr={0} fz="sm">
                {submitterName ?? "Unknown"}
              </Text>
              <Text size="xs" c="dimmed">
                <ReactTimeAgo date={new Date(props.request.updatedAt)} />
              </Text>
            </Group>
          </Flex>
        </Flex>
      )}

      <ActionIcon onClick={() => closeAllModals()}>
        <IconX size={18} />
      </ActionIcon>
    </Flex>
  );
}
