import {ActionIcon, Box, Button, Group, Switch, Text} from "@mantine/core";
import {SourceIcon} from "../../../utils/SourceIcon";
import ReactTimeAgo from "react-time-ago";
import {IconEdit, IconPlus} from "@tabler/icons";
import React, {useState} from "react";
import {useAuth0} from "@auth0/auth0-react";
import {useNavigate} from "react-router-dom";
import {updateSalesforceIntegration} from "../../../Api";
import {getConnectedUserRole} from "../../../utils";


export const IntegrationRow = (props: {integration: any, integrationType: string}) => {

  const auth0 = useAuth0();
  const navigate = useNavigate();
  const [enabled, setEnabled] = useState(props.integration ? props.integration.disabled === false : false);
  const role = getConnectedUserRole(auth0);
  const canEdit = role === "admin";

  const toggleDisabled = (integrationId: string, checked: boolean) => {
    setEnabled(checked)
    updateSalesforceIntegration(integrationId, {disabled: !checked}, auth0).then((result) => {
      console.log(result);
    }).catch(e => console.log(e))
  }


  const integration = props.integration

  const date =
    integration
      ? new Date(integration.createdAt)
      : new Date();

  return (
    <tr key={props.integrationType}>
      <td style={{ width: 50 }}>
        <Box p="xs">
          <SourceIcon
            height={40}
            width={40}
            sourceName={props.integrationType}
          />
        </Box>
      </td>
      <td>
        <Box>
          <Text sx={{ textTransform: "capitalize" }} color={"#111928"}>
            {props.integrationType}
          </Text>
        </Box>
      </td>
      <td>
        <Text sx={{ textTransform: "capitalize" }} color={"#111928"}>
          {integration ? integration.status : ""}
        </Text>
      </td>
      <td>{integration ? <ReactTimeAgo date={date} locale="en-US" /> : ""}</td>
      <td>
        {integration ? (
          <Switch
            mb="sm"
            checked={enabled}
            disabled={!canEdit}
            label={enabled ? "Enabled" : "Disabled"}
            onChange={(event) =>
              toggleDisabled(integration._id, event.currentTarget.checked)
            }
          />
        ) : (
          ""
        )}
      </td>
      <td>
        {integration && integration.status !== "errored" && canEdit && (
          <Group position="right">
            <Button
              leftIcon={<IconEdit size={20} />}
              variant="subtle"
              size="xs"
              color="dark"
              onClick={() => {
                navigate("/settings/integrations/" + props.integrationType);
              }}
            >
              edit
            </Button>
          </Group>
        )}
        {integration && integration.status === "errored" && canEdit && (
          <Group position="right">
            <Button
              styles={(theme) => ({
                root: {
                  backgroundColor: "#5B60E3",
                  border: 0,
                  height: 35,
                  "&:hover": {
                    backgroundColor: theme.fn.darken("#5B60E3", 0.05),
                  },
                },

                leftIcon: {
                  marginRight: 15,
                },
              })}
              mt="sm"
              px="xl"
              radius="md"
              onClick={() => {
                navigate("/settings/integrations/" + props.integrationType);
              }}
            >
              Re authorize
            </Button>
          </Group>
        )}
        {!integration && canEdit && (
          <ActionIcon
            onClick={() =>
              navigate(`/settings/integrations/${props.integrationType}`)
            }
            sx={{
              border: "1px solid #D9D9D9",
              borderRadius: "8px",
              width: "40px",
              height: "30px",
            }}
          >
            <IconPlus color={"black"} size={20} />
          </ActionIcon>
        )}
      </td>
    </tr>
  );
}