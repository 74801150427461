import * as React from "react"
const TickIcon = (props:any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width}
    height={props.height}
    fill="none"
    {...props}
  >
    <path
      fill="#1DB547"
      d="M5.021 11.25.413 6.919a1.268 1.268 0 0 1 0-1.872 1.473 1.473 0 0 1 1.992 0L5.02 7.505 12.595.388a1.473 1.473 0 0 1 1.992 0c.55.517.55 1.355 0 1.872l-9.566 8.99Z"
    />
  </svg>
)
export default TickIcon
