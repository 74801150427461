import { Menu, Text, Grid, Flex, Box } from "@mantine/core";
import { IconFingerprint, IconDots, IconMessage } from "@tabler/icons";
import ReactTimeAgo from "react-time-ago";
import { populateTemplate } from "../../utils/populateTemplate";
import ReactHtmlParser from "react-html-parser";
import { useState } from "react";
import { RequestsIcon, IdeasIcon } from "./../../icons/x-symbol-svgrepo-com";

const notificationsIcons: { [type: string]: any } = {
  IdeaUpdated: IconMessage,
  IdeaDeleted: IconMessage,
  RequestAttached: IconFingerprint,
  RequestDetached: IconFingerprint,
  RequestValidated: IconFingerprint,
  RequestDeclined: IconFingerprint,
};

const Notification = (props: { notification: any }) => {
  const [type, setType] = useState<string>(props.notification.type ?? "");
  const notification = props.notification;
  const Icon: any = notificationsIcons[notification.type];
  const title: any = notification.params.title;
  // console.log({template:notification.template, params:notification.params})
  const notificationContent: any[] = ReactHtmlParser(
    populateTemplate(notification.template, {...notification.params, ...notification})
  );
  return (
    <Box m={4} px="sm">
      {/* <Menu.Item> */}
      <Flex justify="space-between">
        <Text color="dimmed">{title}</Text>
        <Flex>
          {/* <Text color="dimmed" mr="sm">
              <IconDots size="17px" />
            </Text> */}
          <Text color="dimmed">
            <ReactTimeAgo date={new Date(notification.createdAt)} />
          </Text>
        </Flex>
      </Flex>
      <Text mt="xs">
        <div className={"notification-content"}>{notificationContent}</div>
      </Text>
      {/* </Menu.Item> */}
    </Box>
  );
};

export default Notification;
