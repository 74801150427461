import { useContext } from "react";

import { Box, Grid, Stack, Text } from "@mantine/core";
import BusinessSourcesDiscovery from "./perspectives/BusinessSourcesDiscovery";
import ImpactDiscovery from "./impact";
import EvidenceList from "../EvidenceList";
import EvidencesHeader from "./evidencesTopBar/EvidencesHeader";
import DiscoveryContext from "../DiscoveryContext";
import { Loader } from "../../../icons/x-symbol-svgrepo-com";
import DiscoveryZeroState from "../DiscoveryZeroState";
import FiltersContext from "../../ideas/filters/context/FiltersContext";

const EvidenceTab = () => {


  const { activeFilters } = useContext(FiltersContext)

  const { loadingEvidenceMatches, displayedMatches } =
    useContext(DiscoveryContext);

  return (
    <Box w="100%" bg='#F8F9FA' /* h={`calc(100vh - 174px)`} */ h='100%'>
      {loadingEvidenceMatches ? (
        <Box w="99%" pr="20px" pl="8px" mt={8}  /* h='100%' */>
          <Stack align="center" mt={80}>
            <Box bg='rgba(92, 92, 235, 0.1)' w={353} p={24} sx={{borderRadius: 10}} mb={30}>
              <Text>
                Please wait while Bagel AI calculate matches for this product idea
              </Text>
            </Box>
            <Loader/>
          </Stack>
        </Box>
      ) : (displayedMatches?.length === 0 && activeFilters?.length === 0)? (
        <Box h='100%'>

          <DiscoveryZeroState text="There are no evidence that match your description. 
        Please update the data to get relevant evidence." />
        </Box>
      ) : (
        <>
          <Grid
            gutter={0}
            sx={{ borderBottom: "1px solid #D8D8DB" }}
            pr={36}
            pb={16}
            pt={14}
            w="100%"
          >
            
            <Grid.Col span={6}>
              <ImpactDiscovery />
            </Grid.Col>
            <Grid.Col span={6}>
              <BusinessSourcesDiscovery />
            </Grid.Col>
          </Grid>
          <EvidencesHeader />
          <EvidenceList />
        </>
      )}
    </Box>
  );
};

export default EvidenceTab;
