import { useContext } from 'react'
import { Box, Text, Flex, Menu} from '@mantine/core';
import { IconCaretDown } from '@tabler/icons';
import DiscoveryContext from '../../DiscoveryContext';
import PerspectiveListDiscovery from './PerspectiveListDiscovery';

const BusinessSourcesDiscovery = () => {
  
  const {
    type,
    setType
  } = useContext(DiscoveryContext);

  return (
    <Box>
      <Box mb={16} mt={26} py={2}>
        <Flex>
          <Text fz="14px" fw={600} mr='4px'>
            Perspective: 
          </Text>
        
          <Menu position='bottom-start'>
            <Menu.Target>
              <Flex sx={{cursor: 'pointer'}}>
              
                <Text fz="14px" mr='4px'>
                  {type}
                </Text>
                <IconCaretDown fill='black' size={14}/>
              </Flex>
           
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item 
                onClick={()=>{
                  setType('Customer Tier')
                }}
              >
              Customer Tier
              </Menu.Item>
              <Menu.Item
                onClick={()=>{
                  setType('Account Type')
                }}
              >
              Account type
              </Menu.Item>
              <Menu.Item
                onClick={()=>{
                  setType('Customer Plan')
                }}
              >
              Plan
              </Menu.Item>
              <Menu.Item
                onClick={()=>{
                  setType('Business Sources')
                }}
              >
              Business Sources
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>  
        </Flex>   
      </Box>
      <PerspectiveListDiscovery />
    </Box>
  );
};

export default BusinessSourcesDiscovery