import { Box, Checkbox, Flex, Menu, ScrollArea, Text, Tooltip } from "@mantine/core"
import { useContext, useEffect, useState } from "react";
import FiltersContext from "../../../ideas/filters/context/FiltersContext";
import CompanyCheckboxLine from "./CompanyCheckboxLine";
import IdeaContext from "../../IdeaContext";

const CompaniesList = ( { dynamicCount, dynamicOrgIds } : { dynamicCount: number,dynamicOrgIds: string[] } ) => {

  const { activeFilters, setActiveFilters } = useContext(FiltersContext);
  const { requestsCompanies, matchesCompanies, requestsMatchesCompanies } = useContext(IdeaContext);
  
  const [companies, setCompanies] = useState<any[]>([])
  const key = 'Company'

  const deduplicateOrgs = (requestsOrgs: any[], matchesOrgs: any[])=>{
    const orgIds: string[] = []
    const orgs: any[] = []

    requestsOrgs?.forEach((company) => {
      

      if(company){
        
        if(!orgIds.includes(company?._id)){

          orgIds.push(company?._id)
          orgs.push(company)  
        }   
      }
    });

    matchesOrgs.forEach((company) => {
      

      if(company){
    
        if(!orgIds.includes(company?._id)){
          orgIds.push(company?._id)
          orgs.push(company)
        }   
      }
    });
    orgs.sort((a, b) => a.name?.localeCompare(b.name));
    setCompanies(orgs)
  }

  const currentValues =
    activeFilters.find((f) => f.parent === key)?.selected || [];

  const handleCheckboxChange = (selectedArray: string[]) => {

    setActiveFilters && setActiveFilters((prevFilters) => {
      const index = prevFilters.findIndex((filter) => filter.parent === key);
      if (index !== -1) {
        // Update the existing filter
        const updatedFilters = [...prevFilters];
        updatedFilters[index] = {
          ...updatedFilters[index],
          selected: selectedArray,
        };
        return updatedFilters;
      } else {
        // Add a new filter if it does not exist (optional based on your logic)
        return [
          ...prevFilters,
          {
            operator: undefined, // Set the default operator if necessary
            parent: key,
            type: "fetchableCheckbox", // Assuming type is 'checkbox'; adjust if necessary
            selected: selectedArray,
          },
        ];
      }
    });
  };

  useEffect(() => {

    const allMatchesCompanies = [...matchesCompanies, ...requestsMatchesCompanies]

    deduplicateOrgs(requestsCompanies, allMatchesCompanies)

  }, [requestsCompanies, matchesCompanies, requestsMatchesCompanies]);

  

  return (
    <Menu /* offset={40} */ position="bottom-start">
      <Menu.Target>
        <Flex>
          <Tooltip label='Total'>
            <Text fz={14} mb={15} color='#5C5CEB' sx={{cursor: 'pointer'}} mr={dynamicCount === companies?.length ? 4 : 3}>
              {companies?.length || 0} 
            </Text>
          </Tooltip>
          {dynamicCount !== companies?.length && <Tooltip label='Filtered'>
            
            <Text fz={14} mb={15} color='#212529' sx={{cursor: 'pointer'}} mr={3}>
              {`(${dynamicCount})`}
            </Text>
          </Tooltip>}
          <Text fz={14} mb={15} color='#5C5CEB' sx={{cursor: 'pointer'}}>
            Companies
          </Text>
        </Flex>
        
      </Menu.Target>
      <Menu.Dropdown>

        <Checkbox.Group value={currentValues} onChange={handleCheckboxChange}>
          <Box
            sx={{
              maxHeight: 300,
              width: 250,
              overflowY: 'auto',
              overflowX: 'hidden',
              borderRadius: 8,
              padding: '12px',
            }}
          >
            {companies && companies?.length > 0 && companies?.slice(0, 50).map((company: any)=>
              <CompanyCheckboxLine company={company} active={dynamicOrgIds?.includes(company?._id)}/>)}
            {companies.length > 50 && <Text mt='sm'> {`And ${companies?.length - 50} others...`}</Text>}
          </Box>
          
        </Checkbox.Group>
      </Menu.Dropdown>
    </Menu>
  )
}

export default CompaniesList