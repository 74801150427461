import CsvDropzone from "./CsvDropzone"; // Assuming you have imported CsvTableLoader
import Papa from "papaparse";
import {
  Box,
  Button,
  Group,
  Paper,
  Text,
} from "@mantine/core";
import { UseListStateHandlers, useListState } from "@mantine/hooks";
import { IconDownload, IconFileImport } from "@tabler/icons";
import * as XLSX from "xlsx";
import { useContext, useState } from "react";
import SyncContext from "../../context/SyncContext";

function CsvImport({
  exmpleData,
  Head,
  importLoading,
  importRes,
  cleanUpRows,
  onImportRun,
  ImportResults,
  CsvDataTable
}: {
  exmpleData: any[];
  Head: () => JSX.Element;
  importLoading: boolean;
  importRes: any | null;
  cleanUpRows: (rows: any[]) => any[]
  onImportRun: (values: any) => void;
  ImportResults: ({ importRes }: { importRes: any }) => JSX.Element | null;
  CsvDataTable({ data, handlers, loading, setLoading, }: {
    data: any[];
    handlers: UseListStateHandlers<never>;
    loading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}): JSX.Element
}) {
  const [values, handlers] = useListState([]);
  const [loading, setLoading] = useState(false);
  const { fieldsLoaded } = useContext(SyncContext);

  const handleFileUpload = (files: any[]) => {
    setLoading(true);
    const reader = new FileReader();
    reader.onload = (event) => {
      const result = event.target?.result as string; // Explicitly cast to string

      // Check the file type (CSV or XLSX)
      const fileType = files[0].name.split(".").pop().toLowerCase();

      if (fileType === "csv") {
        // Parse the CSV data using PapaParse
        Papa.parse(result, {
          header: true, // Assumes the first row contains headers
          dynamicTyping: true, // Automatically parse numbers and booleans
          complete: (csvResult: any) => {
            // csvResult.data is an array of objects where each object represents a row
            if (csvResult.data && csvResult.data.length > 0) {
              const processedData = csvResult.data.reduce((acc: any[], row: any) => {
                // Trim keys and build the new row object
                const trimmedRow: any = {};
                let isEmptyRow = true; 
            
                Object.keys(row).forEach((key) => {
                  const trimmedKey = key.trim();
                  const value = row[key];
                  trimmedRow[trimmedKey] = value;
            
                  // Check if the row has at least one non-empty value
                  if (value !== "" && value !== null) {
                    isEmptyRow = false;
                  }
                });
            
                // If the row is not empty, add it to the accumulator
                if (!isEmptyRow) {
                  acc.push(trimmedRow);
                }
            
                return acc;
              }, []).map((item: any, index: number) => ({ ...item, index })); // Add an index 
            

              // Print the parsed CSV data with UUIDs in the _id field to the console
              console.log(
                "Parsed CSV data with UUIDs in the _id field (first 3 non-empty rows):",
                processedData.slice(0, 3)
              );
              // Now you can set the data to your state or process it further
              // @ts-ignore
              handlers.setState(cleanUpRows(processedData));
            } else {
              // Handle empty CSV data or parsing error
              console.error("CSV data is empty or could not be parsed.");
            }
          },
        });
      } else if (fileType === "xlsx" || fileType === "xls") {
        // Create a workbook from the XLSX file data
        const workbook = XLSX.read(result, { type: "binary" });

        // Assuming you want to read the first sheet (you can specify a different sheet name or index)
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];

        // Convert the worksheet to an array of objects
        const excelData: any[] = XLSX.utils.sheet_to_json(worksheet);

        if (excelData && excelData.length > 0) {
          // Handle the Excel data as needed
          console.log(
            "Parsed Excel data (first 3 rows):",
            excelData.slice(0, 3)
          );

          // Now you can set the data to your state or process it further
          //@ts-ignore
          handlers.setState(cleanUpRows(excelData));
        } else {
          // Handle empty Excel data or parsing error
          console.error("Excel data is empty or could not be parsed.");
        }
      } else {
        // Handle unsupported file format
        console.error(
          "Unsupported file format. Please upload a CSV or XLSX file."
        );
      }
    };

    // Read the uploaded file as an array buffer
    reader.readAsBinaryString(files[0]);
  };

  const downloadCSV = (data: any[], example = false) => {
    console.log({ data });
    const csvData = Papa.unparse(data);

    const blob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = example ? "bagel_template_data.csv" : "bagel_import_data.csv";

    document.body.appendChild(a);
    a.click();

    window.URL.revokeObjectURL(url);
  };

  return (
    <Paper m="md" radius="md" withBorder>
      {!importRes && (
        <Box m={"md"}>
          <Group position="apart">
            <Head />
            <Group position="apart">
              <Button
                rightIcon={<IconDownload size={22} />}
                radius="md"
                variant={values.length > 0 ? "outline" : undefined}
                onClick={() =>
                  values.length > 0
                    ? downloadCSV(values)
                    : downloadCSV(exmpleData, true)
                }
              >
                {values.length > 0
                  ? "Download current file"
                  : "Download Template"}
              </Button>
              {values.length > 0 && (
                <Button
                  rightIcon={<IconFileImport size={22} />}
                  onClick={() => {
                    console.log("import items");
                    onImportRun(values);
                  }}
                  loading={importLoading}
                  disabled={
                    values.filter(
                      (item: any) => item.ERROR === "" || item.ERROR === null
                    ).length < values.length
                  }
                  radius="md"
                >
                  Import{" "}
                  <Text ml="sm" size="xs">
                    (
                    {
                      values.filter(
                        (item: any) => item.ERROR === "" || item.ERROR === null
                      ).length
                    }{" "}
                    valid)
                  </Text>
                </Button>
              )}
            </Group>
          </Group>
        </Box>
      )}
      {importRes ? (
        <ImportResults importRes={importRes} />
      ) : fieldsLoaded && values.length > 0 ? (
        <CsvDataTable
          data={values}
          handlers={handlers}
          loading={loading}
          setLoading={setLoading}
        />
      ) : values.length === 0 ? (
        <CsvDropzone
          onDrop={(files: File[]) => handleFileUpload(files)}
          accept={[".csv", "text/csv", ".xls", ".xlsx"]}
          loading={loading}
        />
      ) : (
        "Loading"
      )}
    </Paper>
  );
}

export default CsvImport;


// const handleCsvUpload = (files: any[]) => {
//   const reader = new FileReader();
//   reader.onload = (event) => {
//     const result = event.target?.result as string;
//
//     // Parse the CSV data using papaparse or another library
//     Papa.parse(result, {
//       header: true, // Assumes the first row contains headers
//       dynamicTyping: true, // Automatically parse numbers and booleans
//       complete: (result: any) => {
//         // result.data is an array of objects where each object represents a row
//         if (result.data && result.data.length > 0) {
//           // Remove empty rows
//           const nonEmptyRows = result.data.filter((row: any) =>
//             Object.values(row).some((value) => value !== "" && value !== null)
//           );
//
//           // Generate UUIDv4 for each non-empty row and assign it to the _id field
//           const dataWithUUIDs = nonEmptyRows.map((row: any) => ({
//             ...row,
//             _id: uuidv4(),
//           }));
//
//           // Print the parsed CSV data with UUIDs in the _id field to the console
//           console.log(
//             "Parsed CSV data with UUIDs in the _id field (first 3 non-empty rows):",
//             dataWithUUIDs.slice(0, 3)
//           );
//
//           // Now you can set the csvData state with the data containing UUIDs in the _id field
//           handlers.setState(dataWithUUIDs);
//         } else {
//           // Handle empty CSV data or parsing error
//           console.error("CSV data is empty or could not be parsed.");
//         }
//       },
//     });
//   };
//   reader.readAsText(files[0]);
// };
