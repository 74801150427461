import * as React from "react"

const GongIcon = (props:any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height="px"
    viewBox="0 0 20 21"
    {...props}
  >
    <path
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#8039e9",
        fillOpacity: 1,
      }}
      d="M19.5 8.977h-5.863a.493.493 0 0 0-.473.644l1.41 3.547a.227.227 0 0 1-.14.3c-.036.009-.067.016-.102.016l-1.805-.105a.235.235 0 0 0-.21.098l-1.403 1.93a.24.24 0 0 1-.336.073h-.008l-2.078-1.367a.223.223 0 0 0-.265 0l-2.875 1.89a.25.25 0 0 1-.336-.058.219.219 0 0 1-.032-.195l.797-2.777a.226.226 0 0 0-.144-.278l-1.52-.605a.225.225 0 0 1-.133-.3c.008-.024.024-.04.04-.067l1.343-1.614a.224.224 0 0 0 .012-.277L4.25 8.246a.237.237 0 0 1 .059-.328.229.229 0 0 1 .128-.04l1.754-.132a.235.235 0 0 0 .227-.242l-.133-2.387a.247.247 0 0 1 .235-.254c.035 0 .066.008.101.016l2.176.871a.23.23 0 0 0 .27-.063l1.492-1.632a.25.25 0 0 1 .347-.016c.024.024.04.05.059.082l.898 2.25a.488.488 0 0 0 .63.25c.034-.016.073-.031.1-.055l3.528-2.543a.458.458 0 0 0 .102-.652.488.488 0 0 0-.453-.187l-2.329.3a.234.234 0 0 1-.25-.144L11.965.293a.494.494 0 0 0-.625-.262.408.408 0 0 0-.176.121L8.512 2.95a.252.252 0 0 1-.27.055L4.746 1.57a.494.494 0 0 0-.637.254.321.321 0 0 0-.035.16L3.95 5.48c-.008.125-.101.215-.226.23L.53 5.915a.461.461 0 0 0-.363.727L2.293 9.68a.23.23 0 0 1-.016.285l-2.16 2.402a.456.456 0 0 0 .043.653c.043.03.086.058.125.082l2.496 1.043c.11.039.168.164.137.27l-1.582 5.987a.47.47 0 0 0 .344.57.51.51 0 0 0 .406-.074l5.937-4.128a.24.24 0 0 1 .278 0l2.703 1.84a.491.491 0 0 0 .672-.114l.011-.008 1.688-2.52a.235.235 0 0 1 .234-.097l4.04.473c.328.05.656-.219.538-.512l-1.68-4.23a.236.236 0 0 1 .145-.309l3.075-1.41c.453-.219.293-.906-.227-.906Zm0 0"
    />
  </svg>
)

export default GongIcon
