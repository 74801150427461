import { Badge, Accordion } from "@mantine/core";

export function RightBar() {
  return (
    <Accordion
      multiple
      defaultValue={["accounts", "productAreas"]}
      styles={{
        item: {
          padding: 0,
          margin: 0,
          borderBottom: "none",
        },
        content: {
          padding: 0,
          margin: 0,
        },
        control: {
          ":hover": {
            background: "none",
          },
        },
      }}
    >
      <Accordion.Item value="accounts">
        <Accordion.Control px={0}>
        Companies{" "}
          <Badge
            size="xs"
            px={5}
            variant="filled"
            sx={{
              backgroundColor: "#AFAFAF80",
              color: "black",
            }}
          >
            5
          </Badge>
        </Accordion.Control>
        <Accordion.Panel px={0}></Accordion.Panel>
      </Accordion.Item>

      <Accordion.Item value="productAreas" p={0} m={0}>
        <Accordion.Control px={0} m={0}>
          Product areas{" "}
          <Badge
            size="xs"
            px={5}
            color="#AFAFAF80"
            variant="filled"
            sx={{
              backgroundColor: "#AFAFAF80",
              color: "black",
            }}
          >
            5
          </Badge>
        </Accordion.Control>
        <Accordion.Panel p={0} m={0}></Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  );
}
