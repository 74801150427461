import { Tabs, Text } from "@mantine/core"
import { useState } from "react";
import EvidenceTab from "./evidence/EvidenceTab";
import IdeasMatchesTab from "./ideasTab/IdeasMatchesTab";

const DiscoveryTabs = () => {

  const [activeTab, setActiveTab] = useState<string | null>("evidence");

  return (
    <Tabs color='indigo' value={activeTab} onTabChange={setActiveTab} bg='#F8F9FA' >
      <Tabs.List>
        
        <Tabs.Tab value="evidence" ml={36} >
          <Text fz={12} fw={600} color={activeTab !== 'ideas' ? '#212529' : '#5C5CEB'}>
        Relevant Evidence
          </Text>
        </Tabs.Tab>

        <Tabs.Tab value="ideas" >
          <Text fz={12} fw={600} color={activeTab === 'ideas' ? '#212529' : '#5C5CEB'}>
            Similar Ideas
          </Text>
            
        </Tabs.Tab>
      </Tabs.List>

      <Tabs.Panel value="evidence" bg='#F8F9FA'>
        <EvidenceTab/>
      </Tabs.Panel>

      <Tabs.Panel value="ideas" bg='#F8F9FA' h='100%'>
        <IdeasMatchesTab/>
      </Tabs.Panel>
    </Tabs>
  )
}

export default DiscoveryTabs