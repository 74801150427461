import { Box, Button, Flex, Text, Tooltip } from "@mantine/core";
import { useContext } from "react";
import IdeaContext from "../../../IdeaContext";
import { IconPlus, IconX } from "@tabler/icons";

const ValidateRequestMatchPopUp = ({ validate, setOpened }: { validate: () => void, setOpened: React.Dispatch<React.SetStateAction<boolean>>; }) => {
  const { chosenEvidence} = useContext(IdeaContext);

  console.log(chosenEvidence)

  return (
    <Box w={500}>
      <Tooltip w={200} multiline label={chosenEvidence?.request?.idea?.title || 'Unknown'}>
        <Text
          fz={14}
          style={{ fontWeight: 500 }}
          lineClamp={1}
          maw={350}
          sx={{ cursor: "pointer" }}
          onClick={() => window.open(`/idea/${chosenEvidence?.request?.featureId}`, "_blank")}
          color="#5C5CEB"
        >
          {chosenEvidence?.request?.idea?.title || 'Unknown'}
        </Text>
        
      </Tooltip>
      <Text fz={13.8} mt={10}>
        Are you sure you want to assign it to the current Idea?
      </Text>

      <Flex align="center" justify='space-between' w={376} mt='md'>


        

        <Box /* mr={140} */ /* mr={210} */>
          <Button
            fz="12px"
            color="indigo"
            w="75px"
            mr="12px"
            /* h='32px' */ styles={(theme) => ({
              root: {
                height: "32px",
                backgroundColor: "#DB2525",
                border: 0,
                fontWeight: 400,
                /* height: 35, */
                padding: 0,
                "&:hover": {
                  backgroundColor: theme.fn.darken("#DB2525", 0.05),
                },
              },

              leftIcon: {
                marginRight: 15,
              },
            })}
            onClick={() => setOpened(false)} 
          >
            <IconX size={14} />
            <Text>Cancel</Text>
          </Button>
        </Box>

        <Box>
          <Button
            ml={50}
            fz="12px"
            color="indigo"
            w="75px"
            styles={(theme) => ({
              root: {
                height: "32px",
                backgroundColor: "#5C5CEB",
                border: 0,
                fontWeight: 400,
                padding: 0,
                "&:hover": {
                  backgroundColor: theme.fn.darken("#5C5CEB", 0.05),
                },
              },

              leftIcon: {
                marginRight: 15,
              },
            })}
            onClick={() => {
              ()=> setOpened(false)
              validate()}}
          >
            <IconPlus size={14} />
            <Text>assign</Text>
          </Button>
        </Box>
      </Flex>
    </Box>
  );
};

export default ValidateRequestMatchPopUp;
