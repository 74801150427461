import {
  Box,
  Flex,
  Stack,
} from "@mantine/core";

import CardHeader from "./CardHeader";
import { useContext } from "react";
import { MentionProvider } from "../../../MentionTextarea/MentionContext";

import CardScroller from "./CardScroller";
import DiscoveryContext from "../../DiscoveryContext";

const SideBar = () => {

  const {
    chosenEvidence,
  } = useContext(DiscoveryContext);

  return (
    // h="calc(100vh - 137px)"
    <Box  bg='#fff' h='100%' w={496}>
      <MentionProvider>
        <Stack justify="space-between" h='100%' bg='#fff'  /* h={`calc(100vh - ${reviewMode ? 107: 137}px)`} *//* h='100%' */>
          <Box>
            <Flex
              mt="24px"
              pb="16px" 
              px="35px"
              justify="space-between"
              align="center"
              sx={{ borderBottom: "1px solid #D8D8DB" }}
              bg='#fff'
            >
              <CardHeader /> 
            </Flex>
            <CardScroller chosenEvidence={chosenEvidence} />
          </Box>
        </Stack>
      </MentionProvider>
    </Box>
  );
};

export default SideBar;
