import * as React from "react"

const AccountIcon = (props: any) => (
  <svg {...props} width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <rect width="25" height="25" fill="url(#pattern-image0_3470_12931)"/>
    <defs>
      <pattern id="pattern-image0_3470_12931" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use xlinkHref="#image0_3470_12931" transform="scale(0.0078125)"/>
      </pattern>
      <image id="image0_3470_12931" width="128" height="128" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIAAAACACAMAAAD04JH5AAAAA3NCSVQICAjb4U/gAAAAJFBMVEVHcEwVFzIPDy4TFDAUFjATFjAVFzEUFjAUFzEVFzEVFzEWGDJMGUZeAAAAC3RSTlMAjxAlXz+8ddub7mb8BdIAAAABYktHRACIBR1IAAAC80lEQVR4nO2b25aDIAxFFVS0/P//zrQdbhogQgLtGs5TlwrZRUjCxWkaGhoaGrpIrGrmkVpF3vqiebWkGbjNvxASf39rYF/rLdYI4tHEvtZ7hGBvZP+XALQ/N7Ov9QzYlw3tay2vAF4H3A8WN3B473hLNMAG0BFJbvEmUObOwWb+qcOYUec7tnlY7U+Tfc2xGwk/RaIl9kcT3ZNUtq+dnVHsOrUEKYBc1KyWW41GCCCc65xvlCIDsOMWHlXcAJfIHY2xPADiGjljMZYHAMpcru6dDwDO3FA+jARAgPZvlq0B8Bpgm723gWkCEgDbA4/n08JGODjTogewdZhut5UUrgCw8UTGLjADrObZS+H1nwBcQrqttNErsHWYYbeUFK4ZhubZv3/sEmpEWRIAO/C1kpN0cRmTUJMAxCZRmNSIJhjB03hUOKQBgKPRvaJVAPBEGprysgAA6dBbmKSIACCWDdwsXQyQXMh55ItXA6QXsrIjoRogSAfVc+TLYIaQy4pqAfwO4GLf6l3FVlAI4AZg6Hedd84MxkoAEbXjyJA1lAHYHnDtbLZzpntBJcAef8zWnM6N6wBsaehFz/eqKAJIpr+43LgOILrCFFSR7AR1AMblbOBKqOmGycWKOgDcenbSEwyALwfA7WkxjoJUMuTE6IiCuBtTeoZanQ8smW2RzMYk8VpxgQbAAPhSgOzgww/IIgCM+4EEuaQSAJwDhpSq7AZA+bEKICyVAJTv6wOB+StboHsf6D4K+vsBSmUBuu+edzs/0P0ERfczJB1P0cj38cHG54heV17LfOE5gNZSxV6OSpmV1wYAne0PgA8CYBn5KZ0BEPvOpFoHwAD4GACzC8idip1lUjMbjHAnwehk7Lp0gDsdDmUTMPcrv+tKKLf/6x0IadgPvSwoTMl4csGzDs+iqpn7UkhM5XNfCr3fessvG0KZFZQj/yiL3CSsz9zAn52trT6wcXqcRv3a7hubp3bA6Yi1ZCGmRAvim7OhoaGhZvoBo/fmCapIWakAAAAASUVORK5CYII="/>
    </defs>
  </svg>

)

export default AccountIcon
