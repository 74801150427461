import { Box, Flex, ScrollArea, Text, TextInput, Textarea, Tooltip } from '@mantine/core'
import { useContext, useEffect, useState } from 'react';
import IdeaContext from '../IdeaContext';
import { IconX } from '@tabler/icons';
import { useHover } from '@mantine/hooks';
import { saveIdea } from '../../../Api';
import { useAuth0 } from '@auth0/auth0-react';

const OverView = ({
  reviewMode,
  setReviewMode,
  description,
  setDescription,
  updateIdeaDescription,
  longDescriptionCheck,
}: {
  reviewMode: boolean;
  setReviewMode: any;
  description: string;
  setDescription: React.Dispatch<React.SetStateAction<string>>;
  updateIdeaDescription: any;
  longDescriptionCheck: any
}) => {
  const { hovered, ref } = useHover();
  const [editing, setEditing] = useState(false);

  return (
    <Box
      mah="386px"
      pt={16}
      pl={24}
      pr={24}
      pb={24}
      sx={{ borderBottom: "1px solid #D8D8DB" }}
    >
      <Flex justify="space-between" ml={16} mb={12}>
        <Box>
          <Text color="#212529" fw={600}>
            Description
          </Text>
        </Box>
        <Flex 
          
          sx={{ cursor: " pointer" }}
          onClick={() => setReviewMode(!reviewMode)}
        >
          <IconX size={16} color="#5C5CEB" />
          <Text ml='3px' color="#5C5CEB">Close</Text>
          
        </Flex>
      </Flex>
      <Tooltip label="Edit description" disabled={editing}>
        <ScrollArea h="200px" mah={308} pr="20px">
          <Box m={16} ref={ref}sx={{
            border: editing
              ? "1.6px solid rgba(92, 92, 235, 0.3)"
              : hovered
                ? "1.6px solid #D8D8DB"
                : "1.6px solid transparent",
            borderRadius: "6px",
          }}>
          
            <Textarea
              autosize
              py='2px'
              px='lg'
              /* maxRows={200} */
              onClick={() => {
                if (!editing) {
                  setEditing(true);
                }
              }}
              placeholder='Enter description'
              minRows={2}
              value={description}
              onChange={(event) => {
                setDescription(event.currentTarget.value)
                /* longDescriptionCheck() */
              }}
              onBlur={(event) => {
                longDescriptionCheck()
                updateIdeaDescription(event.currentTarget.value)
                setEditing(false)
              }}
              sx={{
                whiteSpace: "pre-line",
              }}
              styles={() => ({
                input: {
                  border: "none",
                  width: "100%",
                  fontSize: "14px",
                  /* fontWeight: "bold", */
                  backgroundColor: hovered ? "#F8F9FA" : "#F8F8FB",
                  margin: "0px",
                  padding: "0px",
                  whiteSpace: "pre-line",
                /* "&:focus-within": {
                  border: "none",
                }, */
                },
              })}
            />
          
          </Box>
        </ScrollArea>
      </Tooltip>
    </Box>
  );
};

export default OverView