import {
  Text,
  Button,
  Group,
  Spoiler,
  ActionIcon,
  Avatar,
  Flex,
  Box,
  createStyles,
  Select,
  Paper,
  Tooltip,
  Menu,
  Stack,
  Grid,
  Collapse,
  HoverCard,
} from "@mantine/core";
import { IconCheck, IconCoin, IconExternalLink, IconX } from "@tabler/icons";
import { getInitials } from "../../../utils/getInitials";
import moment from "moment";
import { SourceIcon } from "../../../utils/SourceIcon";
import { useContext, useEffect, useState } from "react";
import { getConnectedUserRole, getRequestIconByType, stripHtml } from "../../../utils";
import { useAuth0 } from "@auth0/auth0-react";
import { dismissSuggested, saveMatch, validateMatch } from "../../../Api";
import { DatePicker } from "@mantine/dates";
import { isAllowedToEditDates } from "../../../utils/isAllowedToEditDates";
import SyncContext from "../../../context/SyncContext";
import { ProductAreaIcon } from "../../../icons/x-symbol-svgrepo-com";
import { capitalizeFirstLetter } from "../../../utils/capitalizeFirstLetter";
import { sortComponentsByScore } from "../../../utils/sortComponentsByScore";
import { showNotification } from "@mantine/notifications";
import BagelAutocomplete from "../../general/BagelAutocomplete";
import { useHover } from "@mantine/hooks";
import BusinessCategoryIcon from "../../../icons/BusinessCategoryIcon";
import { IdeaTextEditor } from "../IdeaTextEditor";

const useStyles = createStyles(() => ({
  control: {
    color: "#5C5CEA",
    fz: "xs",
  },
}));

export function MatchCard(props: {
  item: any;
  idea: any;
  onUpdated: () => void;
  handleModalOpen: (id: string, bool: boolean, modalType: string) => void;
  customizations: any;
  matches: any;
  setMatches: React.Dispatch<React.SetStateAction<any>>;
  hideMatch: (index: number) => void;
  index: number;
  validateOpen: string | null;
  setValidateOpen: React.Dispatch<React.SetStateAction<string | null>>;
}) {

  /* console.log('props.item')
  console.log(props.item) */
  const {
    components,
    allComponents,
    priorityData,
    defaultRequestPriority,
    domains,
    evidenceDomain,
  } = useContext(SyncContext);
  const { hovered, ref } = useHover();
  const { classes } = useStyles();
  const auth0 = useAuth0();
  // const [validateOpen, setValidateOpen] = useState<boolean>(false);
  const [priority, setPriority] = useState<string | null>(
    props.item?.priority || defaultRequestPriority || "Unassigned"
  );
  const [committedDate, setCommittedDate] = useState<Date | null>(null);
  const [componentId, setComponentId] = useState<any>(props.item?.componentId)
  const [productArea, setProductArea] = useState<any>();
  const [suggestion, setSuggestion] = useState<boolean>(
    props.item?.suggestedComponent 
  );
  const [suggested, setSuggesed] = useState<boolean>(
    props.item?.suggestedComponent
  );
  const [domain, setDomain] = useState<any>();
  const [componentsByDomain, setComponentsByDomain] = useState<any[]>(
    evidenceDomain ? props.item?.components : components
  );
  const [open, setOpen] = useState(false);
  const [pick, setPick] = useState(false);
  const [hoveringButtons, setHoveringButtons] = useState(false);
  const [loading, setLoading] = useState<
    | {
        [id: string]: {
          validate: boolean;
          dismiss: boolean;
        };
      }
    | any
  >({});
  const matches = props.matches || [];
  const domainsNames = domains.map((d: any) => d.name);

  const filterComponentByDomain = (domainValue: string) => {
    const copy = [...components];
    const arr: any[] = copy.filter((s: any) => s.domain === domainValue) || [];
    setComponentsByDomain(arr);
    const names = arr.map((a: any) => a.name);
    if (!names.includes(productArea)) {
      setSuggesed(false);
      setSuggestion(false);
      setProductArea(null);
    }
  };

  const onValidateMatch = (
    matchId: string,
    ideaId: string,
    textId: string,
    request: any
  ) => {
    setTimeout(() => {
      props.hideMatch(props.index);
      showNotification({
        title: "Evidence added successfully",
        color: "teal",
        message: ``,
        icon: <IconCheck size="1.1rem" />,
      });
    }, 958);
    if (suggestion || !productArea) {
      request.componentId = props.idea?.componentId
    } else {
      request.componentId = componentId
    }

    request.priority = priority;
    request.title = request.textItem?.title;
    request.businessName = request.textItem?.businessName;
    console.log('request added')
    console.log(request)
    validateMatch(auth0, matchId, ideaId, request, textId)
      .then((res) => {
        console.log(res);
        props.onUpdated();
      })
      .catch((e) => {
        showNotification({
          title: "Problem occured!",
          color: "red",
          message: "Problem occured while adding evidence...",
          icon: <IconX size="1.1rem" />,
        });
        props.onUpdated();
        console.log(e);
      });
  };

  const dismissMatch = (matchId: string) => {
    dismissSuggested(auth0, matchId, props.idea._id, props.item.textId )
      .then((res) => {
        console.log(res);
        props.onUpdated();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const onSelectSave = (type: string, value: string) => {
    if (type === "componentId") {
      setComponentId(value)
      const match = matches.find((m: any) => m._id === props.item._id);
      const index = matches.findIndex((m: any) => m._id === props.item._id);
      match.componentId = value;
      match.suggestedComponent = false;
      const copy = [...matches];
      const spliced = copy.toSpliced(index, 1, match);
      props.setMatches(spliced);
    }
    const itemObj = {
      id: props.item._id,
      [type]: value,
    };

    console.log('itemObj')
    console.log(itemObj)

    saveMatch(itemObj, auth0)
      .then((res) => {
        console.log(res);
        props.onUpdated();
      })
      .catch((e) => console.log(e));
  };

  const company = props.item?.company;
  const customer = company?.name || "Unknown";
  const icon = getRequestIconByType('opportunity')

  

  useEffect(() => {
    if (!productArea || productArea === "Unassigned") {
      setSuggestion(false);
    }
    if (
      props.item?.componentObj &&
      props.item?.components?.length > 0 &&
      evidenceDomain
    ) {
      const parentComponents: any[] = props.item?.components;
      const parentIds = parentComponents.map((p: any) => (p = p._id));
      const relevantComponents: any[] = [];
      components.forEach((s: any) => {
        if (parentIds.includes(s._id)) {
          relevantComponents.push(s);
        }
      });
      if (relevantComponents.length > 0 && evidenceDomain) {
        setComponentsByDomain(relevantComponents);
      }
    } else if (props.item?.componentId && props.item?.components?.length === 0 || props.item?.componentId && !props.item?.components) {
      props.item.componentObj = null;
    }
  }, []);

  const handleSelect = (item: any) => {
    setProductArea(item.name); // Update the state with the selected product area
    onSelectSave("componentId", item._id); // Call onSelectSave with the selected item's name
  };

  return (
    <Paper
      ref={ref}
      p="md"
      radius="md"
      mb={"md"}
      withBorder
      onClick={() => {
        !hoveringButtons &&
          props.handleModalOpen(props.item._id, true, "match");
      }}
      sx={{ cursor: "pointer" }}
    >
      <Group position="apart" mb="xs">
        <Flex align="center" maw={"80%"}>
          <Tooltip
            disabled={!props.item?.textItem?.businessName}
            label={props.item?.textItem?.businessName}
          >
            <Box mr="1.8px">
              {props.item?.textItem?.businessName && <BusinessCategoryIcon
                businessName={
                  props.item?.textItem?.businessName || "Sic Description"
                }
              />}
            </Box>
          </Tooltip>
          <Box ml="sm" mr="sm">
            <Grid>
              <Grid.Col span={10}>
                <Tooltip label={customer} disabled={customer.length < 20}>
                  <Text weight={500} lineClamp={1}>
                    {customer}
                  </Text>
                </Tooltip>
              </Grid.Col>
              <Grid.Col span={1}>
                <Text>
                  <SourceIcon
                    sourceName={props.item?.chat?.origin}
                    width={30}
                    height={15}
                  />
                </Text>
              </Grid.Col>
            </Grid>
            <Text fz="xs" c="dimmed" lineClamp={1}>
              {props.item?.textItem?.updatedAt && "Updated "}
              {(props.item?.textItem?.updatedAt &&
                moment(props.item?.textItem?.updatedAt).fromNow()) ||
                ""}
            </Text>
          </Box>
        </Flex>
        <ActionIcon
          variant="transparent"
          sx={{ justifyContent: "right", marginTop: "-25px" }}
        >
          <IconExternalLink size={16} />
        </ActionIcon>
      </Group>
      {props.validateOpen == props.item?._id ? (
        <Box
          onMouseEnter={() => setHoveringButtons(true)}
          onMouseLeave={() => setHoveringButtons(false)}
        >
          <Text fz={13.5} fw={500}>
            Priority
          </Text>
          <Select
            mt="sm"
            placeholder="Pick one"
            data={priorityData ?? []}
            value={priority}
            onChange={(priority) => {
              setPriority(priority);
              onSelectSave("priority", priority || defaultRequestPriority);
            }}
          />
          {evidenceDomain && (
            <>
              <Text fz={13.5} fw={500} my="sm">
                Domain
              </Text>
              <Menu>
                <Menu.Target>
                  <Tooltip label={"Select domain"}>
                    <Flex>
                      <Flex
                        w="260px"
                        h="32px"
                        bg={"#F8F9FB"}
                        mx={5}
                        px="sm"
                        py={0}
                        sx={{ cursor: "pointer", borderRadius: "5px" }}
                        align="center"
                      >
                        <Text mr={4} pt="4px" fz="14px"></Text>
                        <Text fz="14px">{domain || "Unassigned"}</Text>
                      </Flex>
                    </Flex>
                  </Tooltip>
                </Menu.Target>

                <Menu.Dropdown>
                  {domainsNames.map((item: string) => (
                    <Menu.Item
                      w="180px"
                      key={item}
                      onClick={() => {
                        setDomain(item);
                        onSelectSave("domain", item);
                        filterComponentByDomain(item);
                      }}
                    >
                      {item}
                    </Menu.Item>
                  ))}
                </Menu.Dropdown>
              </Menu>
            </>
          )}
          <Text fz={13.5} fw={500} my="sm">
            Product area
          </Text>
          {suggestion ? (
            <Menu
              trigger="hover"
              opened={open}
              onChange={setOpen}
              disabled={evidenceDomain && !domain}
            >
              <Menu.Target>
                <Flex
                  h="32px"
                  mx={5}
                  px="sm"
                  py={0}
                  bg={"#F8F9FB"}
                  align="center"
                  sx={
                    evidenceDomain && !domain
                      ? { cursor: "default", borderRadius: "5px" }
                      : { cursor: "pointer", borderRadius: "5px" }
                  }
                >
                  <Text mr={5.5} mt={2}>
                    {" "}
                    <ProductAreaIcon color="black" />{" "}
                  </Text>

                  <Text
                    fz="14px"
                    color={
                      productArea && productArea !== props.idea?.component
                        ? "red"
                        : suggestion && productArea === props.idea?.component
                          ? "#2284FF"
                          : "black"
                    }
                  >
                    {capitalizeFirstLetter(productArea || "Unassigned")}
                  </Text>
                </Flex>
              </Menu.Target>
              <Menu.Dropdown p="md">
                {productArea === props.idea?.component ? (
                  <Text size="sm">
                    This product area is recommended by Bagel AI.
                  </Text>
                ) : (
                  <Text size="sm">
                    This product area is recommended by Bagel AI.
                    <br />
                    It does not match the Product Area of the Idea
                  </Text>
                )}
                <Flex justify="center">
                  <Tooltip
                    label="Confirm recommended product area"
                    position="bottom"
                    offset={15}
                  >
                    <Button
                      variant="light"
                      color="indigo"
                      compact
                      m="md"
                      onClick={() => {
                        setOpen(false);
                        setSuggestion(false);
                        const found = allComponents.find(
                          (a: any) =>
                            a.name?.toLowerCase() ===
                           props.item?.textItem?.mlComponent?.toLowerCase()
                        );

                        onSelectSave("componentId", found?._id);
                      }}
                    >
                      Confirm
                    </Button>
                  </Tooltip>
                  <Tooltip
                    label="Modify product area"
                    position="bottom"
                    offset={15}
                  >
                    <Button
                      variant="light"
                      color="teal"
                      compact
                      m="md"
                      onClick={() => {
                        setSuggestion(false);
                      }}
                    >
                      Modify
                    </Button>
                  </Tooltip>
                </Flex>
              </Menu.Dropdown>
            </Menu>
          ) : (
            <HoverCard position="left"
              disabled={!productArea || productArea === props.idea?.component}
            >
              <HoverCard.Target>
                <Box>
                  <BagelAutocomplete
                    items={
                      evidenceDomain
                        ? [...components].filter((s: any) => s.domain == domain)
                        : components
                    }
                    selected={productArea}
                    onSelect={handleSelect}
                    disabled={evidenceDomain && !domain}
                  >
                    <Tooltip
                      label={evidenceDomain && !domain && "Select domain first"}
                      disabled={!evidenceDomain || domain}
                    >
                      <Flex
                        h="32px"
                        mx={5}
                        px="sm"
                        py={0}
                        bg={"#F8F9FB"}
                        align="center"
                        sx={{
                          /* opacity: !domain ? 0.5 : 1, */
                          ...(evidenceDomain && !domain
                            ? { cursor: "default", borderRadius: "5px" }
                            : { cursor: "pointer", borderRadius: "5px" }),
                        }}
                      >
                        <Text mr={5.5} mt={2}>
                          <ProductAreaIcon color="black" />
                        </Text>

                        <Text
                          fz="14px"
                          color={
                            productArea && productArea !== props.idea?.component
                              ? "red"
                              : "black"
                          }
                        >
                          {capitalizeFirstLetter(productArea || "Unassigned")}
                        </Text>
                      </Flex>
                    </Tooltip>
                  </BagelAutocomplete>
                </Box>
              </HoverCard.Target>
              <HoverCard.Dropdown p="lg">
                <Text size="sm">
                  This product area was set manually by a Bagel user
                  <br />
                  It does not match the Product Area of the Idea
                </Text>
              </HoverCard.Dropdown>
            </HoverCard>
          )}
          {isAllowedToEditDates(props.customizations) && (
            <DatePicker
              mt="sm"
              label="Committed"
              placeholder="Select Date"
              onChange={(Date) => setCommittedDate(Date)}
            />
          )}
          {pick && (
            <Text fz="11.5px" color="red" mt="sm">
              {evidenceDomain
                ? "Please pick priority, domain and product area before adding as evidence"
                : "Please pick priority and product area before adding as evidence"}
            </Text>
          )}
          <Flex justify="flex-end" align="flex-end" mt="md">
            <Button
              styles={() => ({
                root: {
                  borderColor: "#5B60E3",
                  color: "#5B60E3",
                  height: 35,
                },
              })}
              mt="sm"
              mr="md"
              px="md"
              radius="md"
              variant="outline"
              onClick={() => {
                props.setValidateOpen(null);
              }}
            >
              Cancel
            </Button>
            <Button
              styles={(theme) => ({
                root: {
                  backgroundColor: "#5B60E3",
                  border: 0,
                  height: 35,
                  "&:hover": {
                    backgroundColor: theme.fn.darken("#5B60E3", 0.05),
                  },
                },

                leftIcon: {
                  marginRight: 15,
                },
              })}
              mt="sm"
              px="xl"
              radius="md"
              onClick={() => {
                const feedback: any = {
                  priority,
                };
                if (isAllowedToEditDates(props.customizations)) {
                  feedback.committedDate = committedDate;
                }
                onValidateMatch(
                  props.item._id,
                  props.idea._id,
                  props.item.textId,
                  feedback
                );
              }}
              loading={loading[props.item?._id]?.validate || false}
            >
              Submit
            </Button>
          </Flex>
        </Box>
      ) : (
        <>
          {props.item?.textItem?.title ? (
            <Text weight={500}>{props.item?.textItem?.title}</Text>
          ) : (
            <Text weight={450}> {props.item?.textItem?.businessName}</Text> || (
              <Text></Text>
            )
          )}
          <Text size="sm" color="dimmed" mb="xs">
            <Spoiler
              maxHeight={44}
              showLabel="see more"
              hideLabel="Hide"
              classNames={classes}
              sx={{ cursor: "default", whiteSpace: "pre-line"}}
              onMouseEnter={() => setHoveringButtons(true)}
              onMouseLeave={() => setHoveringButtons(false)}
            > 
              {stripHtml(props.item?.textItem?.text)}
            </Spoiler>
          </Text>
          {props.item?.chat?.amount ? (
            <Flex mr="sm">
              <Text fz="xs" c="dimmed" mr="sm">
                Opportunity amount:
              </Text>
              <Text fz="xs" fw={500}>
                {"$ " + props.item.chat?.amount?.toLocaleString()}
              </Text>
            </Flex>
          ) : (
            <Flex mr="sm">
              <Text fz="xs" c="dimmed" mr="sm">
                Account ARR:
              </Text>
              <Text fz="xs" fw={500}>
                {company?.arr ? "$ " + company?.arr?.toLocaleString() : "N/A"}
              </Text>
            </Flex>
          )}
          <Collapse in={hovered}>
            <Group noWrap position="left" mt="md">
              <Tooltip label="This evidence is not relevant to the Idea">
                <Button
                  fz="13.5px"
                  px={8}
                  styles={() => ({
                    root: {
                      borderColor: "#5B60E3",
                      color: "#5B60E3",
                      height: 35,
                    },
                  })}
                  maw={"50%"}
                  radius="md"
                  variant="outline"
                  loading={loading![props.item?._id]?.dismiss || false}
                  onClick={() => dismissMatch(props.item._id)}
                  onMouseEnter={() => setHoveringButtons(true)}
                  onMouseLeave={() => setHoveringButtons(false)}
                >
                  Not relevant
                </Button>
              </Tooltip>
              <Tooltip label="This evidence is relevant to the idea">
                <Button
                  fz="13.5px"
                  px={8}
                  styles={(theme) => ({
                    root: {
                      backgroundColor: "#5B60E3",
                      border: 0,
                      height: 35,
                      "&:hover": {
                        backgroundColor: theme.fn.darken("#5B60E3", 0.05),
                      },
                    },
                  })}
                  maw={"50%"}
                  radius="md"
                  onClick={() => props.setValidateOpen(props.item._id)}
                  onMouseEnter={() => setHoveringButtons(true)}
                  onMouseLeave={() => setHoveringButtons(false)}
                >
                  Add as Evidence
                </Button>
              </Tooltip>
            </Group>
          </Collapse>
        </>
      )}
    </Paper>
  );
}
