import * as React from "react"
const DropdownIcon = (props:any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width}
    height={props.height}
    fill="none"
    {...props}
  >
    <path fill="#161832" fillOpacity={0.8} d="M5 8 .67.5h8.66L5 8Z" />
  </svg>
)
export default DropdownIcon
