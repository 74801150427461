import { useAuth0 } from "@auth0/auth0-react";
import {
  addCommentToRequest,
  deleteCommentFromRequest,
  getCommentsForRequest,
  markItemNotificationsRead,
  searchMembers,
  searchUsers,
  updateRequestComment,
} from "../../../../Api";
import MentionTextarea, { MentionSuggestion } from "../../../MentionTextarea";
import { useMentions } from "../../../MentionTextarea/MentionContext";
import { useContext, useEffect, useRef, useState } from "react";
import IdeaContext from "../../IdeaContext";
import {
  ActionIcon,
  Box,
  Button,
  Flex,
  Group,
  ScrollArea,
  Stack,
  Text,
} from "@mantine/core";
import { IconSend, IconX } from "@tabler/icons";
import { CommentsNiv } from "./CommentsNiv";
import { getUsersFromTags } from "../../helpers/tags";

const CommentsSection = ({
  item,
  setOpened,
}: {
  item: any;
  setOpened: any;
}) => {


  const { idea, chosenEvidence, getUnreadNotificationsCountByRequest } =
    useContext(IdeaContext);

  const { setSuggestions, setLoading: setSuggestionsLoading } = useMentions();
  const [comments, setComments] = useState<any[]>([]);
  const [commentValue, setCommentValue] = useState("");
  const [loading, setLoading] = useState({
    getSuggestions: false,
    comment: false,
  });
  const auth0 = useAuth0();
  const viewport = useRef<HTMLDivElement>(null);
  const scrollToCommentsBottom = () =>
    viewport?.current?.scrollTo({ top: viewport.current.scrollHeight, behavior: 'smooth' });


  console.log("---item", item);
  const searchUser = ({
    query,
  }: {
    query: string;
  }): Promise<MentionSuggestion[]> => {
    /* setSuggestionsLoading(true); */

    return new Promise<MentionSuggestion[]>((resolve, reject) => {
      console.log({ query });

      Promise.all([searchUsers(query, auth0), searchMembers(query, auth0)])
        .then(([users, members]) => {
          console.log({ users, members });

          const userSuggestions: MentionSuggestion[] =
            users?.map((user: any) => ({
              id: user._id,
              email: user.email,
              name: user.name,
              picture: user.picture,
            })) ?? [];

          const memberSuggestions: MentionSuggestion[] =
            members
              ?.filter((member: any) => member.name)
              .map((member: any) => ({
                id: member._id,
                email: member.email,
                name: member.name,
                picture: member.picture,
              })) ?? [];

          const combinedSuggestions = [
            ...userSuggestions,
            ...memberSuggestions,
          ];
          const emailToUserMap = new Map<string, MentionSuggestion>();

          combinedSuggestions.forEach((suggestion) => {
            if (
              !emailToUserMap.has(suggestion.email) ||
              memberSuggestions.find(
                (member) => member.email === suggestion.email
              )
            ) {
              emailToUserMap.set(suggestion.email, suggestion);
            }
          });

          const uniqueSuggestions = Array.from(emailToUserMap.values());
          console.log({ emailToUserMap });

          setSuggestions(uniqueSuggestions);
          resolve(uniqueSuggestions);
        })
        .catch((error) => {
          console.error("Error searching users and members:", error);
          reject(error);
        })
        .finally(() => setSuggestionsLoading(false));
    });
  };

  const handleSubmitComment = () => {
    setLoading((prev) => ({
      ...prev,
      comment: true,
    }));
    const _commentValue = commentValue.trim();
    const usersMentioned = getUsersFromTags(_commentValue);
    addCommentToRequest(
      idea?._id,
      item?._id,
      commentValue,
      usersMentioned,
      auth0
    )
      .then((response) => {
        if (response.data.success) {
          comments.push(response.data.comment);
          setCommentValue("");
        }
      })
      .then(()=>{
        setTimeout(() => {
          scrollToCommentsBottom()
        }, 300);
      })
      .catch((e: any) => console.log(e))
      .finally(() => {
        setLoading((prev) => ({
          ...prev,
          comment: false,
        }));
      });
  };

  const deleteComment = (commentId: string) => {
    const commentsFiltered = comments.filter((c: any) => c._id !== commentId);
    setComments(commentsFiltered);
    deleteCommentFromRequest(item?._id, commentId, auth0)
      .then(() => {
        /* onUpdate() */
      })
      .catch((e: any) => console.log(e));
  };

  const editComment = (comment: any, text: string) => {
    comment.text = text;
    const editedCommentIndex = comments.findIndex(
      (c: any) => comment._id === c._id
    );
    comments.splice(editedCommentIndex, 1, comment);

    const commentValue = text.trim();
    const usersMentioned = getUsersFromTags(commentValue);
    updateRequestComment(comment._id, commentValue, usersMentioned, auth0)
      .then((response) => {
        console.log(response);
        /* onUpdate() */
      })
      .catch((e: any) => console.log(e));
  };

  const getComments = () => {
    getCommentsForRequest(item?._id, auth0)
      .then((res) => {
        setComments(res.data.comments ?? []);
        scrollToCommentsBottom()
      })
      .catch((e) => console.log(e));
  };

  const markItemNotificationsReadFunc = async () => {
    await markItemNotificationsRead(item._id, auth0).then((res) => {
      console.log({ res });
    });
  };
  useEffect(() => {
    getComments();

    if (getUnreadNotificationsCountByRequest(item?._id) > 0)
      markItemNotificationsReadFunc();
    /* scrollToBottom(); */
  }, [chosenEvidence]);

  return (
    <Box h="calc(100vh - 137px)" w="496px">
      {/* Comments header */}

      <Group
        py={24}
        px={35}
        // justify="space-between"
        position="apart"
        sx={{ borderBottom: "1px solid #D8D8DB" }}
        bg="#FDFEFF"
      >
        <Text color="#212529" fz={20} fw={600}>
          Activity
        </Text>
        <Group
          align="center"
          sx={{ cursor: " pointer" }}
          onClick={() => setOpened(false)}
        >
          <IconX size={16} color="#5C5CEB" />
          <Text ml="3px" color="#5C5CEB" fz={12}>
            Close
          </Text>
        </Group>
      </Group>

      {/* Comments Body */}
      <ScrollArea viewportRef={viewport} style={{ width: "100%", height: "calc(100vh - 311px)" }}>
        <CommentsNiv
          comments={comments}
          deleteComment={deleteComment}
          editComment={editComment}
          searchUser={searchUser}
          handleSubmitComment={handleSubmitComment}
          loading={loading}
        />
      </ScrollArea>
     
      {/* Add a comment */}

      <Box
        sx={{
          background: "#F5F6FE",
          borderTop: "1px solid #E5E7EB",
          padding: 10,
        }}
      >
        <MentionTextarea<MentionSuggestion>
          placeholder="Write your message"
          getMentions={searchUser}
          commentValue={commentValue}
          setCommentValue={setCommentValue}
          editable
          clearOnRightSectionClick
          debounceWait={1000}
          sx={{
            background: "#fff",
            border: "1px solid #D8D8DB",
            borderRadius: 8,
            fontSize: 12,
          }}
        />
        <Group position="right" mt={8}>
          <Button
            compact
            onClick={handleSubmitComment}
            variant="filled"
            color="blue"
            radius="md"
            loading={loading.comment}
            disabled={commentValue == ""}
            leftIcon={<IconSend size={16} />}
          >
            Send
          </Button>
          {/* <ActionIcon
            onClick={handleSubmitComment}
            mr={4}
            variant="filled"
            color="blue"
            radius="md"
            loading={loading.comment}
            disabled={commentValue == ""}
          >
            <IconSend size={20} />
          </ActionIcon> */}
        </Group>
      </Box>
    </Box>
  );
};

export default CommentsSection;
