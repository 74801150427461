import { Menu, Group, Input, ActionIcon, Text } from "@mantine/core";
import { UseListStateHandlers } from "@mantine/hooks";
import { IconSelector, IconCheck, IconPencil } from "@tabler/icons";
import { useEffect, useState } from "react";
import { validateData } from "../../Api";
import { useAuth0 } from "@auth0/auth0-react";
import {ACCESSORS} from "./CsvDataTable";
import {get18DigitSalesforceId} from "../../utils/sfId";

const TableCell = ({
  // index,
  item,
  col,
  handlers,
  validateRow,
  extraData,
  setValidateData,
}: {
  // index: number;
  item: any;
  col: any;
  handlers: UseListStateHandlers<never>;
  validateRow: (row: any, index: number) => any;
  extraData: any;
  setValidateData: React.Dispatch<any>;
}) => {
  const auth0 = useAuth0();
  const is_valid = !item.ERROR?.split(",").includes(col.accessor);
  const [value, setValue] = useState<string>(item[col.accessor]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setValue(item[col.accessor])
  }, [item])
  

  function removeError(item: any, errorToRemove: string): string {
    const currentErrorArray = item["ERROR"].split(",");
    const updatedErrorArray = currentErrorArray.filter(
      (error: string) => error !== errorToRemove
    );
    return updatedErrorArray.join(",");
  }

  if (!is_valid && col.editable?.type == "select") {
    const menuItemOptions =
        col.accessor == "Priority"
          ? (item.Level === "EVIDENCE"
            ? extraData?.requestPriorities
            : extraData?.ideaPriorities
          ).map((i: any) => i.label)
          : col?.editable?.data;
    return (
      <Menu shadow="md" width={200}>
        <Menu.Target>
          <Group position="apart" noWrap>
            <IconSelector size={18} color={"#fff"} />
            <Text sx={{ cursor: "pointer" }} miw={150} mih={24} lineClamp={1}>
              {item[col.accessor]}
            </Text>
          </Group>
        </Menu.Target>

        <Menu.Dropdown mah={250} sx={{ overflowX: "scroll" }}>
          {/* Assuming you have a list of options for the menu. Replace `menuOptions` with your actual options data. */}
          {menuItemOptions.map((option: any) => (
            <Menu.Item
              key={option}
              onClick={async () => {
                //@ts-ignore
                await handlers.setItemProp(item.index, col.accessor, option);
                await validateRow({ ...item, [col.accessor]: option }, item.index);
              }}
            >
              {option}
            </Menu.Item>
          ))}
        </Menu.Dropdown>
      </Menu>
    );
  } else if (!is_valid && col.editable?.type == "text") {

    return (
      <Input
        variant="unstyled"
        h={24}
        pr={value !== item[col.accessor] ? "34px" : 0}
        styles={{
          input: {
            height: 24,
          },
        }}
        value={value || ''}
        onChange={(event) => {
          setValue(event.target.value);
          console.log(event);
        }}
        rightSection={
          value !== item?.[col.accessor]  ? (
            <ActionIcon
              size="xs"
              loading={loading}
              onClick={async () => {
                // if (col.accessor === ACCESSORS.SUBMITTER) {
                //   setLoading(true)
                //   const { userValidations } = await validateData(auth0, {
                //     userEmails: [value],
                //   }).finally(() => setLoading(false));

                //   setValidateData((curr: any) => ({
                //     ...curr,
                //     userValidations: [
                //       ...curr.userValidations,
                //       ...userValidations,
                //     ],
                //   }));
                //   // console.log("ActionIcon", { userValidations });
                //   //@ts-ignore
                //   await handlers.setItem(item.index, {
                //     ...item,
                //     [col.accessor]: value,
                //     ...(userValidations[0]?.valid
                //       ? { ["ERROR"]: removeError(item, col.accessor) }
                //       : {}),
                //   });
                  
                // } else 
                if (col.accessor === ACCESSORS.SFDC_ACCOUNT_ID) {
                  setLoading(true)
                  const { sfOrgValidations } = await validateData(auth0, {
                    sfIds: [value],
                  }).finally(() => setLoading(false));

                  setValidateData((curr: any) => ({
                    ...curr,
                    sfOrgValidations: [
                      ...curr.sfOrgValidations,
                      ...sfOrgValidations,
                    ],
                  }));
                  console.log("ActionIcon", { sfOrgValidations, bo: sfOrgValidations[0]?.valid });

                  //@ts-ignore
                  await handlers.setItem(item.index, {
                    ...item,
                    [col.accessor]: value,
                    ...(sfOrgValidations[0]?.valid
                      ? { ["ERROR"]: removeError(item, col.accessor) }
                      : {}),
                  });
                } else if (col.accessor === ACCESSORS.SFDC_OPPORTUNITY_ID) {
                  setLoading(true)
                  {const { oppIdValidations } = await validateData(auth0, {
                    oppIds: [value.trim().length === 15 ? get18DigitSalesforceId(value.trim()) : value.trim()],
                  }).finally(() => setLoading(false));

                  oppIdValidations && setValidateData((curr: any) => ({
                    ...curr,
                    oppIdValidations: [
                      ...curr.oppIdValidations,
                      ...oppIdValidations,
                    ],
                  }));
                  console.log("ActionIcon", { oppIdValidations, bo: oppIdValidations[0]?.valid });

                  //@ts-ignore
                  oppIdValidations && await handlers.setItem(item.index, {
                    ...item,
                    [col.accessor]: value,
                    ...(oppIdValidations[0]?.valid
                      ? { ["ERROR"]: removeError(item, col.accessor) }
                      : {}),
                  });}
                }else {
                  //@ts-ignore
                  await handlers.setItemProp(item.index, col.accessor, value);
                  await validateRow({ ...item, [col.accessor]: value }, item.index);
                }
              }}
            >
              <IconCheck size={18} color="green" />
            </ActionIcon>
          ) : undefined
        }
        icon={<IconPencil size={18} color={"#fff"} />}
      />
    );
  } else {
    return (
      <Text miw={150} mih={24} lineClamp={1}>
        {item[col.accessor]}
      </Text>
    );
  }
};

export default TableCell;
