import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  Divider,
  Grid, Loader,
  Paper,
  Tabs, Text, Title,
} from "@mantine/core";
import { useElementSize } from "@mantine/hooks";
import React, { useEffect, useState } from "react";
import { getSettingsForAccount } from "../../Api";

const Account = () => {
  const { height } = useElementSize()
  const auth0 = useAuth0();

  const [account, setAccount] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState<string | null>("Info");

  const loadAccount = () => {
    setLoading(true)
    getSettingsForAccount(auth0)
      .then(account => {
        setAccount(account)
        setLoading(false)
      })
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    loadAccount();
  }, []);

  const tabs = [
    { name: "Info", disabled: false },
    // { name: "Billing", disabled: true },
    // { name: "Security", disabled: true },
    // { name: "Appearance", disabled: true },
    // { name: "Audit", disabled: true },
  ]

  if (loading) {
    return <Loader  variant="dots"  mt={"lg"} ml={"lg"}/>
  }
  return (
    <Box bg={"#fff"} >
      <Title order={3} ml="sm" p={"sm"}>Account</Title>
      <Grid
        mx="sm"
        justify="space-between"
        align="flex-end"
        sx={{ borderBottom: "1px solid #F1F1F1" }}
      >
        <Grid.Col span="content" pb="0">
          <Tabs value={activeTab} onTabChange={setActiveTab}>
            <Tabs.List m="0" sx={{ borderBottom: 0 }}>
              {tabs.map(tab => (
                <Tabs.Tab
                  key={tab.name}
                  value={`${tab.name}`}
                  disabled={tab.disabled}
                >
                  {tab.name}
                </Tabs.Tab>
              ))}
            </Tabs.List>
          </Tabs>
        </Grid.Col>
      </Grid>
      <Box p={"xl"} mt={7} bg={"#F1F3F5"} sx={{ height: `calc(100vh - ${height}px)` }}>
        <Paper mt={"lg"} shadow={"lg"} withBorder>
          <Grid p={0} m={0}>
            <Grid.Col span={2} p={"xl"}>
              <Text weight={700} color={"#5C5CEA"} size={"md"}>
                General
              </Text>
              <Text size={"sm"} mt={"sm"} mb={"sm"}>
                Customize and configure your general account details.
              </Text>
            </Grid.Col>
            <Divider size="xs" orientation="vertical" />
            <Grid.Col span={"auto"} p={"xl"}>
              <Grid>
                <Grid.Col span={2}>
                  <Text mt={"xs"} color={"#161832"} weight={"500"}>Company Name:</Text>
                  <Text mt={"xs"} color={"#161832"} weight={"500"}>Domain:</Text>
                  <Text mt={"xs"} color={"#161832"} weight={"500"}>Timezone:</Text>
                </Grid.Col>
                <Grid.Col span={6}>
                  <Text color={"rgba(22, 24, 50, 0.8)"} mt={"xs"}>{account.name}</Text>
                  <Text color={"rgba(22, 24, 50, 0.8)"} mt={"xs"}>{account.domain ? account.domain : ''}</Text>
                  <Text color={"rgba(22, 24, 50, 0.8)"} mt={"xs"}>{account.timezone}</Text>
                </Grid.Col>
              </Grid>
            </Grid.Col>
          </Grid>
        </Paper>

        <Paper mt={"44px"}  shadow={"lg"} withBorder sx={{ padding: '0 !important' }}>
          <Grid p={0} m={0}>
            <Grid.Col span={2} p={"xl"}>
              <Text weight={700} color={"#5C5CEA"} size={"md"}>
                Financials
              </Text>
              <Text size={"sm"} mt={"sm"} mb={"sm"}>
                Customize and configure financial details.
              </Text>
            </Grid.Col>
            <Divider size="xs" orientation="vertical" p={0} m={0} />
            <Grid.Col span={"auto"} p={"xl"}>
              <Grid>
                <Grid.Col span={2}>
                  <Text mt={"xs"} color={"#161832"} weight={"500"}>Fiscal Year Start:</Text>
                  <Text mt={"xs"} color={"#161832"} weight={"500"}>Currency:</Text>
                  <Text mt={"xs"} color={"#161832"} weight={"500"}>Default Timeline:</Text>
                </Grid.Col>
                <Grid.Col span={6}>
                  <Text color={"rgba(22, 24, 50, 0.8)"} mt={"xs"}>01/01</Text>
                  <Text color={"rgba(22, 24, 50, 0.8)"} mt={"xs"}>{account.currency}</Text>
                  <Text color={"rgba(22, 24, 50, 0.8)"} mt={"xs"}>Quarterly</Text>
                </Grid.Col>
              </Grid>
            </Grid.Col>
          </Grid>
        </Paper>

        <Paper mt={"44px"} shadow={"lg"} withBorder>
          <Grid p={0} m={0}>
            <Grid.Col span={2} p={"xl"}>
              <Text weight={700} color={"#5C5CEA"} size={"md"}>
                Extreme
              </Text>
              <Text size={"sm"} mt={"sm"} mb={"sm"}>
                Perform extreme actions.
              </Text>
            </Grid.Col>
            <Divider size="xs" orientation="vertical" p={0} m={0} />
            <Grid.Col span={"auto"} p={"xl"}>
              <Grid>
                <Grid.Col span={2}>
                  <Text mt={"xs"} color={"#F95858"} weight={"500"}>Delete this Account</Text>
                </Grid.Col>
                <Grid.Col span={6}>
                  <Text color={"rgba(22, 24, 50, 0.8)"} mt={"xs"}>Contact Bagel’s support by filling this form.</Text>
                </Grid.Col>
              </Grid>
            </Grid.Col>
          </Grid>
        </Paper>
      </Box>
    </Box>
  );
};

export default Account;