import { useState } from "react";
import CsvImport from "../CsvImport";
import CsvImportHead from "./CsvImportHead";
import { useAuth0 } from "@auth0/auth0-react";
import { runImport } from "../../Api";
import ImportResults from "./ImportResults";
import CsvDataTable, { ACCESSORS } from "./CsvDataTable";
import { get18DigitSalesforceId } from "../../utils/sfId";

const exmpleData = [
  {
    Level: null,
    Key: null,
    "Parent Key": null,
    "SFDC Account ID": null,
    "SFDC Opportunity ID": null,
    Origin: null,
    "Origin ID": null,
    "Submitter/Reporter (Email)": null,
    Domain: null,
    "Product Area": null,
    "Sub Area": null,
    Title: null,
    Description: null,
    createdAt: null,
    Priority: null,
    // "Owner (Email)": null,
    "Business Name": null,
    Category: null,
    Status: null,
    ERROR: null,
  },
  {
    Level: null,
    Key: null,
    "Parent Key": null,
    "SFDC Account ID": null,
    "SFDC Opportunity ID": null,
    Origin: null,
    "Origin ID": null,
    "Submitter/Reporter (Email)": null,
    Domain: null,
    "Product Area": null,
    Title: null,
    Description: null,
    createdAt: null,
    Priority: null,
    // "Owner (Email)": null,
    "Business Name": null,
    Category: null,
    Status: null,
    ERROR: null,
  },
  {
    Level: null,
    Key: null,
    "Parent Key": null,
    "SFDC Account ID": null,
    "SFDC Opportunity ID": null,
    Origin: null,
    "Origin ID": null,
    "Submitter/Reporter (Email)": null,
    Domain: null,
    "Product Area": null,
    Title: null,
    Description: null,
    createdAt: null,
    Priority: null,
    "Owner (Email)": null,
    "Business Name": null,
    Category: null,
    Status: null,
    ERROR: null,
  },
];

const ImportIdeasEvidence = () => {
  const auth0 = useAuth0();
  const [importRes, setImportRes] = useState(null);
  const [importLoading, setImportLoading] = useState(false);

  const cleanUpRows = (rows: any[]) => {
    const cleanRows: any[] = [];
    rows.forEach((row) => {
      const cleanRow = row;
      Object.values(ACCESSORS).forEach((accessor: any) => {
        if (cleanRow[accessor]) {
          cleanRow[accessor] = cleanRow[accessor]?.trim?.();
        }
      });
      if (cleanRow[ACCESSORS.SFDC_ACCOUNT_ID]?.length === 15) {
        cleanRow[ACCESSORS.SFDC_ACCOUNT_ID] = get18DigitSalesforceId(
          cleanRow[ACCESSORS.SFDC_ACCOUNT_ID]
        );
      }
      if (cleanRow[ACCESSORS.SFDC_OPPORTUNITY_ID]?.length === 15) {
        cleanRow[ACCESSORS.SFDC_OPPORTUNITY_ID] = get18DigitSalesforceId(
          cleanRow[ACCESSORS.SFDC_OPPORTUNITY_ID]
        );
      }
      if (cleanRow[ACCESSORS.SUBMITTER]?.length > 0) {
        cleanRow[ACCESSORS.SUBMITTER] =
          cleanRow[ACCESSORS.SUBMITTER].toLowerCase();
      }
      // if (cleanRow[ACCESSORS.OWNER]?.length > 0) {
      //   cleanRow[ACCESSORS.OWNER] = cleanRow[ACCESSORS.OWNER].toLowerCase();
      // }
      cleanRows.push(cleanRow);
    });
    return cleanRows;
  };

  const runImportFunc = async (rows: any[]) => {
    setImportLoading(true);
    const resp = await runImport(auth0, { rows })
      .then(({ importRes }) => {
        console.log({ importRes });
        setImportRes(importRes);
      })
      .catch(console.log)
      .finally(() => setImportLoading(false));
    return resp;
  };

  return (
    <CsvImport
      exmpleData={exmpleData}
      Head={CsvImportHead}
      importLoading={importLoading}
      importRes={importRes}
      cleanUpRows={cleanUpRows}
      CsvDataTable={CsvDataTable}
      onImportRun={(values: any) => {
        console.log("import items");
        runImportFunc(values);
      }}
      ImportResults={ImportResults}
    />
  );
};

export default ImportIdeasEvidence;
