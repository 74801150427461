import { Table } from "@mantine/core";
import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import UserRow from "./UserRow";
import { updateMember } from "../../Api";

const UsersList = (props: { users: any[] }) => {
  const auth0 = useAuth0();

  const onUpdate = async (user: any) => {
    await updateMember(user, auth0);
    return true;
  };

  return (
    <Table
      sx={{
        "thead tr:first-of-type": {
          borderColor: "#dee2e6",
          background: "#F3F4F6",
        },
        "tbody tr:last-of-type": {
          borderBottom: " 1px solid #dee2e6",
        },
        td: {
          textAlign: "left",
        },
        "tbody tr td": {
          padding: "13px 10px",
          borderBottom: "1px solid #dee2e6",
          fontSize: "14px",
        },
        "tbody tr:hover .flag:after": {
          borderRight: " 25px solid #f5f5f5",
        },
      }}
    >
      <thead>
        <tr>
          <th>Member</th>
          <th>Status</th>
          <th>Role</th>
          <th>Team</th>
          <th>2FA</th>
          <th>Last sign-in</th>
        </tr>
      </thead>
      <tbody>
        {props.users.map((user) => (
          <UserRow user={user} onUpdate={onUpdate} key={user._id} />
        ))}
      </tbody>
    </Table>
  );
};

export default UsersList;
